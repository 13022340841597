<nav class="navbar navbar-secondary" [ngSwitch]="isAuthenticated()" [ngClass]="{'authenticated' : isAuthenticated(), 'justify-content': (router.url === '/account/login') }">
	<a class="navbar-brand logo" routerLink="/" [ngClass]="{'hide-logo': !isNavbarCollapsed , 'mobile-hide-logo': (router.url === '/account/login')}" *ngIf="(router.url !== '/' || isAuthenticated()) && router.url !== '/account/login' && router.url !== '/account/reset/password' && router.url !== '/maintenance'">
		<span class="logo-img"></span>
	</a>
	<a class="nav-link nav-create-account" [routerLink]="['profile-rx/questions','1']" routerLinkActive="active" *ngIf="router.url === '/account/login'">
		<span class="create-account">CREATE ACCOUNT</span>
	</a>

    <a class="navbar-toggler login-logout font14 collapse-login " *ngIf="router.url !== '/account/login' &&  !isAuthenticated() && !isSystemMaintenanceOn" (click)="login()" href="javascript:void(0);">
        LOGIN
    </a>
    <a class="navbar-toggler login-logout font14" *ngSwitchCase="true" (click)="logout()" href="javascript:void(0);">
        <span *ngIf="router.url !== '/account/login'"> LOGOUT </span>
    </a>
    <a *ngIf="router.url !== '/account/login' && router.url !== '/account/reset/password'" class="navbar-toggler boder-left collapse-toggle" [ngClass]="{'slide-toggle': !isNavbarCollapsed }" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
       aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
        <fa-icon icon="bars"></fa-icon>
        <span class="nav-username" [ngClass]="{'show' : !isNavbarCollapsed , 'hide' : isNavbarCollapsed  }"><span >{{userName}}</span></span>
    </a>
    <a class="navbar-toggler" routerLink="/" *ngIf="router.url === '/account/login' && !checkIfPasswordExpired()">
        <fa-icon icon='times'></fa-icon>
    </a>

	<div class="navbar-collapse collapse" id="navbarResponsive" *ngIf="!isNavbarCollapsed">
		<ul class="navbar-nav ml-auto">
			<li class="nav-item create-ac-box" *ngSwitchCase="false">
				<a class="nav-link width-100-p text-center" [routerLink]="['profile-rx/questions','1']" routerLinkActive="active" (click)="collapseNavbar()">
					<span class="create-account">CREATE ACCOUNT</span>
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<a class="nav-link" routerLink="/prescription-rx/refill/list" (click)="collapseNavbar()" *ngIf="isAuthenticated()">
					<div>
						<fa-icon icon='prescription'></fa-icon>
						<span class="pad-l-10">Prescriptions</span>
					</div>
				</a>
				<a class="nav-link" routerLink="/prescription-rx" (click)="collapseNavbar()" *ngIf="!isAuthenticated()">
					<div>
						<fa-icon icon='prescription'></fa-icon>
						<span class="pad-l-10">Prescriptions</span>
					</div>
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<a class="nav-link" routerLink="/prescription-tx-req-rx" (click)="collapseNavbar()">
					<div>
						<fa-icon icon='random' class="font-size20"></fa-icon>
						<span class="pad-l-8">Transfer Prescriptions</span>
					</div>
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngSwitchCase="true">
				<a class="nav-link" routerLink="/coupon-rx" (click)="collapseNavbar()">
					<div>
						<fa-icon icon='barcode' class="font-size20"></fa-icon>
						<span class="pad-l-8">Submit Coupon</span>
					</div>
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<a class="nav-link" routerLink="/pharmacy-locations-rx" (click)="collapseNavbar()">
					<div>
						<fa-icon icon='map-marker-alt'></fa-icon>
						<span class="pad-l-10">Find a Pharmacy</span>
					</div>
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngSwitchCase="true">
				<a class="nav-link" routerLink="/card-info-rx" (click)="collapseNavbar()">
					<div>
						<fa-icon icon='dollar-sign' class="font-size20"></fa-icon>
						<span class="pad-l-13">Manage Payment</span>
					</div>
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngSwitchCase="true">
				<a class="nav-link" routerLink="/customer-statement-rx" (click)="collapseNavbar()">
					<div>
						<fa-icon icon='file-prescription' class="font-size20"></fa-icon>
						<span class="pad-l-10">Prescription Statement</span>
					</div>
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngSwitchCase="true">
				<a class="nav-link" routerLink="/profile-rx" (click)="collapseNavbar()">
					<div>
						<fa-icon icon='user-alt' class="font-size20"></fa-icon>
						<span class="pad-l-3">Profile</span>
					</div>
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<a class="nav-link" routerLink="/information" (click)="collapseNavbar()">
					<div>
						<fa-icon icon='info-circle' class="font-size20"></fa-icon>
						<span class="pad-l-8">Helpful Links</span>
					</div>
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngSwitchCase="true">
				<a class="nav-link" [routerLink]="navigateNotification()" (click)="collapseNavbar()">
					<div>
						<fa-icon icon='bell' class="font-size20"></fa-icon>
						<span class="pad-l-5">Notifications</span>
					</div>
				</a>
			</li>

			<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<a class="nav-link" routerLink="/contact-us" routerLinkActive="router-link-active" (click)="collapseNavbar()">
					<div>
						<fa-icon icon='phone-alt' class="font-size20"></fa-icon>
						<span class="pad-l-8">Contact Us</span>
					</div>
				</a>
			</li>

			<li ngbDropdown class="nav-item dropdown pointer" display="dynamic" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu">
					<li *ngSwitchCase="true">
						<a class="dropdown-item" routerLink="account/settings" routerLinkActive="active" (click)="collapseNavbar()">
							<fa-icon icon="wrench" fixedWidth="true"></fa-icon>
							<span jhiTranslate="global.menu.account.settings">Settings</span>
						</a>
					</li>
					<li *ngSwitchCase="true">
						<a class="dropdown-item" routerLink="account/password" routerLinkActive="active" (click)="collapseNavbar()">
							<fa-icon icon="lock" fixedWidth="true"></fa-icon>
							<span jhiTranslate="global.menu.account.password">Password</span>
						</a>
					</li>
					<li *ngSwitchCase="true">
						<a class="dropdown-item" (click)="logout()" id="logout">
							<fa-icon icon="sign-out-alt" fixedWidth="true"></fa-icon>
							<span jhiTranslate="global.menu.account.logout">Sign out</span>
						</a>
					</li>
					<li *ngSwitchCase="false">
						<a class="dropdown-item" routerLink="account/login" id="login">
							<fa-icon icon="sign-in-alt" fixedWidth="true"></fa-icon>
							<span jhiTranslate="global.menu.account.login">Sign in</span>
						</a>
					</li>
					<li *ngSwitchCase="false">
						<a class="dropdown-item" [routerLink]="['profile-rx/questions','1']" routerLinkActive="active" (click)="collapseNavbar()">
							<fa-icon icon="user-plus" fixedWidth="true"></fa-icon>
							<span jhiTranslate="global.menu.account.register">Register</span>
						</a>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</nav>

<h1 class="pageTitle" jhiTranslate="bjcrxApp.prescriptionTxReq.home.title">
    Transfer Prescription
</h1>
<form name="form" role="form" (ngSubmit)="transferPrescription()" [formGroup]="transPrescriptionForm"
    *ngIf="!showMessage">
    <div class="content with-action" *ngIf="!showErrorMessage">
        <div class="row" id="transferSection">
            <div class="col-md-4">
                <label class="sub-title">Transfer From</label>
                <div class="form-group">
                    <label for="pname">Pharmacy Name</label><span class="required">*</span>
                    <input type="text" class="form-control"
                           onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode === 32"
                           id="pname" maxlength="100" formControlName="sourcePharmacyName" placeholder="Name">
                    <div
                        *ngIf="transPrescriptionForm.get('sourcePharmacyName').invalid && (isSubmitted || transPrescriptionForm.get('sourcePharmacyName').touched)">
                        <small class="form-text error-msg"
                            *ngIf="transPrescriptionForm.get('sourcePharmacyName').errors.required">
                            Please enter pharmacy name.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="pnumber">Pharmacy Phone</label><span class="required">*</span>
                    <input type="tel" class="form-control" id="pnumber" autocomplete="off" jhi-appPhoneMask
                        maxlength="14" formControlName="sourcePharmacyPhoneNumber" placeholder="(555) 555-5555">
                    <div
                        *ngIf="transPrescriptionForm.get('sourcePharmacyPhoneNumber').invalid && (isSubmitted || transPrescriptionForm.get('sourcePharmacyPhoneNumber').touched)">
                        <small class="form-text error-msg"
                            *ngIf="transPrescriptionForm.get('sourcePharmacyPhoneNumber').errors.required">
                            Please enter pharmacy phone.
                        </small>
                        <small class="form-text error-msg"
                            *ngIf="transPrescriptionForm.get('sourcePharmacyPhoneNumber').errors.minlength">
                            Please enter valid phone number.
                        </small>
                    </div>
                </div>

                <label class="sub-title">Transfer To</label>
                <div class="form-group">
                    <label for="selectPharmacy" jhiTranslate="bjcrxApp.prescriptionTxReq.home.SelectPharmacy">Pharmacy
                        Name</label><span class="required">*</span>
                    <select class="form-control" id="selectPharmacy" formControlName="selectPharmacy">
                        <option disabled value="" selected>Select Pharmacy Name</option>
                        <option [value]="pharmacy.pharmacyId" *ngFor="let pharmacy of pharmacyList">{{pharmacy.name}}
                        </option>
                    </select>
                    <div
                        *ngIf="transPrescriptionForm.get('selectPharmacy').invalid && (isSubmitted || transPrescriptionForm.get('selectPharmacy').touched)">
                        <small class="form-text error-msg"
                            *ngIf="transPrescriptionForm.get('selectPharmacy').errors.required">
                            Please select pharmacy.
                        </small>
                    </div>
                </div>
                <div formArrayName="addPatient" *ngFor="let addPatient of addPatient['controls']; let i = index">
                    <div [formGroupName]="i">
                        <label class="sub-title">Patient Information <span
                                *ngIf="showCount">- {{ i+1}}</span>
                            <span class="remove text-right" (click)="removePatient(i)" *ngIf="i > 0">
                                <img src="../../../content/images/thrash_icon.png" class="remove-icon" alt="">
                            </span>
                            <span class="clear"></span>
                        </label>
                        <div class="form-group" *ngIf="isAuthenticated() && showDropDown">
                            <label for="dependentId" jhiTranslate="bjcrxApp.prescriptionTxReq.home.SelectPatient">
                                Patient</label><span class="required">*</span>
                            <select class="form-control" id="dependentId" name="dependentId" formControlName="dependentId"
                                    (change)="calculateAge(addPatient)">
                                <option disabled value="">Select dropdown item</option>
                                <option *ngFor="let dependent of dependentListFromApi | orderBy: 'sequence'" [value]="dependent.id">
                                    {{dependent.firstName +' '+dependent.lastName}}</option>
                            </select>
                            <div
                                *ngIf="addPatient.get('dependentId').errors && addPatient.get('dependentId').errors.dependentNotMinor">
                                <small class="form-text error-msg">You cannot manage dependents who are older than 18
                                    years</small>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!isAuthenticated()">
                            <label for="pname">Patient Name</label><span class="required">*</span>
                            <input type="text" class="form-control" id="pname" formControlName="guestPatientName" maxlength="40" placeholder="Name">
                            <div
                                *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientName').invalid && (isSubmitted || transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientName').touched)">
                                <small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientName').errors.required">
                                    Please enter the patient's full name.
                                </small>
                                <small class="form-text error-msg"
                                       *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientName').errors.minlength
                                                || transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientName').errors.maxlength">
                                    Please enter the patient's full name. It must be between 3 and 40 characters long.
                                </small>
                                <small class="form-text error-msg"
                                       *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientName').errors.pattern">
                                    Please enter the patient's first and last name.
                                </small>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!isAuthenticated()">
                            <label for="dob">Date of Birth</label><span class="required">*</span>
                            <div class="input-group date-group" *ngIf="checkIsIEBrowser() === false">
                                <input type="date" class="form-control" placeholder="mm-dd-yyyy" id="dob"
                                    [max]="today | date:'yyyy-MM-dd'" formControlName="guestPatientDob" ngbDatepicker
                                    #dobDp="ngbDatepicker">
                                <span class="input-group-append" (click)="dobDp.toggle()">
                                    <fa-icon [icon]="'calendar-alt'"></fa-icon>
                                </span>
                            </div>

                            <div class="input-group date-group" *ngIf="checkIsIEBrowser() === true">
                                <input type="text" tabindex="-1" class="form-control" placeholder="mm/dd/yyyy" id="dob"
                                    [max]="today | date:'yyyy-MM-dd'" formControlName="guestPatientDob" ngbDatepicker
                                    #dobDp="ngbDatepicker">
                                <span class="input-group-append">
                                    <fa-icon [icon]="'calendar-alt'"></fa-icon>
                                </span>
                                <div class="date-pick-div" (click)="dobDp.toggle()"></div>
                            </div>
                            <div *ngIf="false">
                                <small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientDob').errors.required">
                                    Please enter valid date.
                                </small>
                            </div>
                            <div
                                *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientDob').invalid && (isSubmitted || (transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientDob').touched && checkIsIEBrowser() === false))">
                                <small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientDob').errors.required">
                                    Please enter date of birth.
                                </small>
                                <small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientDob').errors.invalidDate">
                                    Please enter valid date.
                                </small>
                                <small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPatientDob').errors.futuredate">
                                    Please enter valid date of birth.
                                </small>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!isAuthenticated()">
                            <label for="guestPrimaryPhoneNumber">Primary Phone Number</label><span
                                class="required">*</span>
                            <input type="tel" class="form-control" id="guestPrimaryPhoneNumber" autocomplete="off"
                                jhi-appPhoneMask maxlength="14" placeholder="(555) 555-5555"
                                formControlName="guestPrimaryPhoneNumber">
                            <div
                                *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPrimaryPhoneNumber').invalid && (isSubmitted || transPrescriptionForm.get('addPatient')['controls'][i].get('guestPrimaryPhoneNumber').touched)">
                                <small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPrimaryPhoneNumber').errors.required">
                                    Please enter phone number.
                                </small>
                                <small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('guestPrimaryPhoneNumber').errors.minlength">
                                    Please enter a valid phone number.
                                </small>

                            </div>
                        </div>
						<div class="form-group" *ngIf="isAuthenticated() && i === 0">
                            <label for="primaryPhoneNumber">Primary Phone Number</label><span
                                class="required">*</span>
                            <input type="tel" class="form-control" id="primaryPhoneNumber" autocomplete="off"
                                jhi-appPhoneMask maxlength="14" placeholder="(555) 555-5555"
                                formControlName="primaryPhoneNumber" [(ngModel)]="primaryPhoneNumber">
                            <div
                                *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('primaryPhoneNumber').invalid && (isSubmitted || transPrescriptionForm.get('addPatient')['controls'][i].get('primaryPhoneNumber').touched)">
								<small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('primaryPhoneNumber').errors.lengthRequired">
                                    {{transPrescriptionForm.get('addPatient')['controls'][i].get('primaryPhoneNumber').errors.lengthRequired.text}}
                                </small>
                            </div>
						</div>
                        <div class="form-group">
                            <label for="prescriptionInfo" class="sub-title">Prescription Information</label>
                            <small id="prescriptionInfo" class="form-text text-muted">Enter your prescription number(s)
                                include all numbers before and after the dash.
                                <br />You may submit up to 10 prescriptions per request.</small>
                            <div
                                *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('rxNumberArr').invalid && (isSubmitted || transPrescriptionForm.get('addPatient')['controls'][i].get('rxNumberArr').touched)">
                                <small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('rxNumberArr').errors.atLeastOneRequired">
                                    {{transPrescriptionForm.get('addPatient')['controls'][i].get('rxNumberArr').errors.atLeastOneRequired.text}}
                                </small>
                                <small class="form-text error-msg"
                                    *ngIf="transPrescriptionForm.get('addPatient')['controls'][i].get('rxNumberArr').errors.atLeastOneDrugNameRequired">
                                    {{transPrescriptionForm.get('addPatient')['controls'][i].get('rxNumberArr').errors.atLeastOneDrugNameRequired.text1}}
                                </small>
                            </div>
                            <div formArrayName="rxNumberArr">
                                <div class="row">
                                    <div class="col-7">
                                        <label for="dob">Prescription Number</label><span class="required">*</span>
                                    </div>
                                    <div class="col p-0">
                                        <label for="dob" class="drg-name">Drug Name</label><span
                                            class="required">*</span>
                                    </div>
                                </div>
                                <div class="row padd2"
                                    *ngFor="let rxNum of transPrescriptionForm['controls'].addPatient['controls'][i]['controls'].rxNumberArr['controls']; let k = index"
                                    [formGroupName]="k">
                                    <div class="col-7 p-0">
                                        <input type="text"
                                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                            class="form-control" placeholder="Prescription Number" maxlength="20"
                                            formControlName="rxNumber" name="rxNumber">
                                    </div>
                                    <div class="col p-0">
                                        <input type="text" class="form-control drg-name"
                                            [readonly]="!rxNum.value.rxNumber" name="drugName" placeholder="Drug Name"
                                            maxlength="50" formControlName="drugName" id="drugName">
                                    </div>
                                </div>

                            </div>
                            <div class="prescription-wrapper" *ngIf="transPrescriptionForm['controls'].addPatient['controls'][i]['controls'].rxNumberArr['controls'].length < 10">
                                <hr>
                                <span class="another-precription"
                                    (click)="addMorePrescriptions(addPatient)">&nbsp;&nbsp;+ Add more</span>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="form-group" *ngIf="!isAuthenticated() && addPatient['controls'].length < 4">
                    <button type="button" [disabled]="!transPrescriptionForm.touched"
                        class="btn btn-block  btn-transparent cursor-pointer"
                        jhiTranslate="bjcrxApp.prescriptionTxReq.home.AddPatient" (click)="addAnotherPatient()">+ ADD
                        ANOTHER PATIENT</button>
                </div>
                <div class="form-group"
                    *ngIf="isAuthenticated() && (dependentListFromApi.length > 0 && addPatient['controls'].length < dependentListFromApi.length)">
                    <button type="button" [disabled]="!transPrescriptionForm.touched"
                        class="btn btn-block  btn-transparent cursor-pointer"
                        jhiTranslate="bjcrxApp.prescriptionTxReq.home.AddPatient" (click)="addAnotherPatient()">+ ADD
                        ANOTHER PATIENT</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-md-3 p-0" *ngIf="!showErrorMessage">
            <button type="submit" class="btn btn-enable btn-block cursor-pointer"
                jhiTranslate="bjcrxApp.prescriptionTxReq.home.Submit"
                [disabled]="!transPrescriptionForm.touched">SUBMIT</button>
        </div>
    </div>
</form>
<ng-container *ngIf="showMessage && showLoader">
    <div class="content">
        <div>
            <div class="col-md-4">
                <div class="success-message">
                    Your transfer request was successfully submitted.
                </div>
            </div>
            <div class="result-label">
                Transfer Request Submitted
            </div>
            <div class="result-data" *ngFor="let items of detailObj">
                <p>Patient Name: <span> {{  items.patientName}} </span> </p>
                <p *ngIf="!isAuthenticated()">Date of Birth: <span> {{ changeDateFormat(items.dateOfBirth)}} </span> </p>
                <p>Transfer From: <span> {{  items.sourcePharmacyName}} </span></p>
                <p>Transfer To: <span>{{ targetPharmacy }} </span></p>

                <table class="mt-4">
                    <tr>
                        <th id="prescriptions">Prescriptions</th>
                        <th id="drugname">Drug Name</th>
                    </tr>
                    <tr *ngFor="let item of items.strDrugNamePrescription | keyvalue">
                        <td>{{ item.key }}</td>
                        <td>{{ item.value }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-md-3 p-0">
            <button type="button" class="btn btn-enable btn-block cursor-pointer" (click)="resetForm(false)">ADD
                MORE</button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="showErrorMessage && showLoader">
    <div class="content">
        <div class="col-md-4">
            <div class="error-message">
                Your transfer request was not submitted.
            </div>
        </div>
    </div>
    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-md-3 p-0">
            <button type="button" class="btn btn-enable btn-block cursor-pointer" (click)="resetForm(true)">TRY
                AGAIN</button>
        </div>
    </div>
</ng-container>

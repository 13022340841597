<h1 class="pageTitle">
    <span>Submit Coupon</span>
</h1>
<div class="member-selection-box content" *ngIf="!isNonBJC && !isSubmitSuccess && isDependents">
    <form name="form" role="form" [formGroup]="dependentForm">
        <div class="form-group member-selection-row col-md-4">
            <label for="patientName" class="mr-3 mt-1">Patient<span class="required">*</span></label>
            <select class="form-control" [attr.disabled]="imageLoaded === true ? '' : null" id="patientName"
                formControlName="patientName" (change)="calculateAge()">
                <option value="null" disabled>Select dropdown item</option>
                <option *ngFor="let dependent of dependentList" [value]="dependent.patientId">
                    {{dependent.firstName +' '+dependent.lastName}}</option>
            </select>

            <div class="skeleton-box" *ngIf="dependentList.length === 0">

            </div>
        </div>

        <div class="top-error-message"
            *ngIf="!isPatientSelected && (dependentForm.get('patientName').value === 'null' || dependentForm.get('patientName').value === null)">
            Please select patient.
        </div>
    </form>
</div>

<div *ngIf="!isSubmitSuccess">
    <div class="content" *ngIf="isUnauthorized">
        <div>
            <div class="col-md-4">
                <div class="error-message">
                    {{errorMessage}}
                </div>
            </div>

        </div>
    </div>

    <div class="content with-action" *ngIf="!isUnauthorized">
        <div class="manualEntryFormSection">
            <div class="row">
                <h3 class="subtitle">Coupon Information</h3>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="row image-form">
                    <div class="form-group">
                        <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="image" name="couponType" value="image"
                                (click)="couponToggle('image')" [disabled]="imageLoaded" [checked]="isImageCoupon" />
                            <label class="custom-control-label form-check-label font14" for="image" id="imageRadio">Image</label>
                        </div>
                    </div>
                    <div class="form-group">

                        <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="manualEntry" name="couponType"
                                value="manualEntry" [disabled]="imageLoaded" (click)="couponToggle('manualEntry')"
                                [checked]="isImageCoupon === false">
                            <label class="custom-control-label form-check-label font14" for="manualEntry" id="manualRadio">Manual
                                Entry</label>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="isUnauthorized">
                    <div class="col p-0 text-center">
                        <div class="form-group" *ngIf="errorMessage !== ''">
                            <small class="form-text error-msg">
                                {{errorMessage}}
                            </small>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="isImageCoupon">
                    <div class="row">
                        <div class="col p-0">
                            <div class="form-group" *ngIf="errorMessage !== ''">
                                <small class="form-text error-msg">
                                    {{errorMessage}}
                                </small>
                            </div>
                            <form name="form" role="form" [formGroup]="imageEntryForm">
                                <div class="form-group">
                                    <label class="form-control-label" for="imgDrugName">Drug Name</label><span
                                        class="required">*</span>
                                    <input type="text" (keypress)="validateAlphanumeric($event)" class="form-control" id="imgDrugName" name="imgDrugName"
                                        placeholder="Drug Name" maxlength="20" formControlName="imgDrugName"
                                        autocomplete="off">

                                    <div
                                        *ngIf="(submitted || imageEntryForm.get('imgDrugName').touched) && imageEntryForm.get('imgDrugName').invalid">
                                        <small class="form-text error-msg"
                                            *ngIf="imageEntryForm.get('imgDrugName').errors.required">
                                            Please enter drug name.
                                        </small>
                                        <small class="form-text error-msg"
                                            *ngIf="imageEntryForm.get('imgDrugName').errors.pattern">
                                            No special characters allowed.
                                        </small>
                                        <small class="form-text error-msg"
                                            *ngIf="imageEntryForm.get('imgDrugName').errors.maxlength">
                                            Drug name should not be more than 20 characters.
                                        </small>
                                    </div>

                                </div>
                            </form>

                            <div *ngFor="let content of images;let i=index">
                                <div class="image-box mb-4" *ngIf="content.image || content.isLoading=== true">

                                    <img [src]="content.image" alt="" class="coupon-image"
                                        *ngIf="!content.isLoading && content.fileType === 'img'">

                                    <div class="loading-container" *ngIf="!imageLoaded && content.fileType === 'pdf'" >
                                        <label
                                            (click)="downloadPdf(i===0 ? frontImageDataURL : backImageDataURL , i===0 ? frontFileName : backFileName )"
                                            target="_blank">
                                            <img src="../../../content/images/pdfdownload.png" class="pdf-icon" alt="">
                                            <label> {{i===0 ? frontFileName : backFileName}}</label>
                                        </label>
                                    </div>

                                    <div class="loading-container" *ngIf="content.isLoading">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                    <div class="image-footer">
                                        <label>{{content.imageType}}</label>
                                        <fa-icon [icon]="'trash'" *ngIf="!content.isLoading && !imageLoaded"
                                            class="delete-icon" (click)="deleteImage(content.imageType)"></fa-icon>
                                    </div>
                                </div>
                            </div>
                            <label class="action-btn text-center"
                                *ngIf="(images[0].image === null || images[1].image === null) && !imageLoaded">
                                <input type="file" class="uploadType" #imageUpload value=""
                                    (change)="uploadImage($event)" (click)="imageUpload.value = null;"
                                    style="display: none;">
                                {{images[0].image===null ? 'UPLOAD IMAGE FRONT' : 'UPLOAD IMAGE BACK'}}
                            </label>
                            <label class="action-btn disabled-option text-center" *ngIf="imageLoaded === true">
                                {{images[0].image===null ? 'UPLOAD IMAGE FRONT' : 'UPLOAD IMAGE BACK'}}
                            </label>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isImageCoupon">
                    <form name="form" role="form" [formGroup]="manualEntryForm">
                        <div class="form-group">
                            <label class="form-control-label" for="bin">BIN</label><span class="required">*</span>
                            <input type="text" inputmode="numeric" pattern="[0-9]*" class="form-control"
                                (keypress)="validateNumber($event)" autocomplete="off" maxlength="15"
                                formControlName="bin">
                            <div
                                *ngIf="manualEntryForm.get('bin').invalid && (submitted || manualEntryForm.get('bin').dirty)">
                                <small class="form-text error-msg" *ngIf="manualEntryForm.get('bin').errors.required">
                                    This field is required.
                                </small>
                                <small class="form-text error-msg" *ngIf="manualEntryForm.get('bin').errors.pattern">
                                    Only 15 digits allowed.
                                </small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="pcn">PCN</label><span class="required"
                                *ngIf="!manualEntryForm.get('isPcn').value">*</span>
                            <input type="text" class="form-control" autocomplete="off"
                                (keypress)="validateAlphanumeric($event)" maxlength="15" formControlName="pcn">
                            <div
                                *ngIf="manualEntryForm.get('pcn').invalid && (submitted || manualEntryForm.get('pcn').dirty)">
                                <small class="form-text error-msg" *ngIf="manualEntryForm.get('pcn').errors.required">
                                    This field is required.
                                </small>
                                <small class="form-text error-msg" *ngIf="manualEntryForm.get('pcn').errors.pattern">
                                    No special characters allowed.
                                </small>
                            </div>
                        </div>

                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" type="checkbox" id="isPcn" maxlength="15" name="isPcn"
                                formControlName="isPcn" (change)="manualValidate()">
                            <label class="custom-control-label font14" for="isPcn">I cannot find this
                                information</label>
                        </div>

                        <div class="form-group">
                            <label class="form-control-label" for="cardHolderId">CARDHOLDER ID</label><span
                                class="required">*</span>
                            <input type="text" class="form-control" autocomplete="off"
                                (keypress)="validateAlphanumeric($event)" maxlength="15" formControlName="cardHolderId">
                            <div
                                *ngIf="manualEntryForm.get('cardHolderId').invalid && (submitted || manualEntryForm.get('cardHolderId').dirty)">
                                <small class="form-text error-msg"
                                    *ngIf="manualEntryForm.get('cardHolderId').errors.required">
                                    This field is required.
                                </small>
                                <small class="form-text error-msg"
                                    *ngIf="manualEntryForm.get('cardHolderId').errors.pattern">
                                    No special characters allowed.
                                </small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="groupId">GROUP ID</label><span class="required"
                                *ngIf="!manualEntryForm.get('isGroupId').value">*</span>
                            <input type="text" class="form-control" autocomplete="off"
                                (keypress)="validateAlphanumeric($event)" maxlength="15" formControlName="groupId">
                            <div
                                *ngIf="manualEntryForm.get('groupId').invalid && (submitted || manualEntryForm.get('groupId').dirty)">
                                <small class="form-text error-msg"
                                    *ngIf="manualEntryForm.get('groupId').errors.required">
                                    This field is required.
                                </small>
                                <small class="form-text error-msg"
                                    *ngIf="manualEntryForm.get('groupId').errors.pattern">
                                    No special characters allowed.
                                </small>
                            </div>
                        </div>

                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" type="checkbox" id="isGroupId" name="isGroupId"
                                formControlName="isGroupId" (change)="manualValidate()">
                            <label class="custom-control-label font14" for="isGroupId">I cannot find this
                                information</label>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="drugName">DRUG NAME</label><span
                                class="required">*</span>
                            <input type="text" class="form-control" autocomplete="off"
                                (keypress)="validateAlphanumeric($event)" maxlength="20" formControlName="drugName">
                            <div
                                *ngIf="manualEntryForm.get('drugName').invalid && (submitted || manualEntryForm.get('drugName').dirty)">
                                <small class="form-text error-msg"
                                    *ngIf="manualEntryForm.get('drugName').errors.required">
                                    Please enter drug name.
                                </small>
                                <small class="form-text error-msg"
                                    *ngIf="manualEntryForm.get('drugName').errors.pattern">
                                    No special characters allowed.
                                </small>
                            </div>
                        </div>
                        <br />

                    </form>
                </ng-container>
            </div>
            <div class="col-lg-4 col-md-6 pt-4">
                <div class="row">
                    <div class="font12">
                        <fa-icon icon="info-circle" class="icon-custom icon-blue"></fa-icon>&nbsp;To submit a coupon, do
                        the following:
                    </div>
                    <ol class="pad-left17 font12 pt-2">
                        <li><small class="font12">Enter coupon information.</small></li>
                        <li><small class="font12">Submit coupon information.</small></li>
                        <li><small class="font12">Coupon must be processed prior to requesting a refill. We will send
                                you notification when you can submit a refill for this drug.</small></li>
                        <li><small class="font12">Please do not submit refill request here.</small></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button (click)="submitCoupon()" class="btn btn-enable btn-block"
                [disabled]="(isUnauthorized) || (imageLoaded && isImageCoupon)">SUBMIT</button>
        </div>
    </div>
</div>

<ng-container *ngIf="isSubmitSuccess">
    <div class="content">

        <div class="col-lg-4 col-md-6">
            <div class="success-message" *ngIf="isSubmitSuccess && !submittedData.isManual">
                Your coupon was submitted successfully. Do not submit a refill request. We will send you a notification
                then
                you can submit a refill.
            </div>
            <div class="mt-4 success-message" *ngIf="isSubmitSuccess && submittedData.isManual">
                Your coupon was submitted successfully. Do not submit a refill request for {{submittedData.drugName}}.
                We
                will send you a notification then you can submit a refill.
            </div>

            <div class="mt-2">
                <h4 class="subtitle mt-2">Coupon Request Submitted For</h4>
                <div>
                    <small>Patient Name: <span>
                            <strong> {{submittedData.firstName | titlecase }}
                                {{submittedData.lastName | titlecase }}</strong></span>
                    </small>
                </div>
                <div><small>Date of Birth: <strong>{{ submittedData.dateOfBirth | date: 'MM/dd/yyyy' }}</strong></small></div>
            </div>
        </div>
    </div>

    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button (click)="addAnotherCoupon()" class="btn btn-enable btn-block">ADD MORE</button>
        </div>
    </div>
</ng-container>

<div *ngIf="!showmessage && passwordForm && !apiCalled">
    <form name="form" role="form" (ngSubmit)="finishReset()" [formGroup]="passwordForm">
        <div class="content with-action">
            <div class="row">
                <a routerLink="/">
                    <img src="../../../../content/images/bjc-blue-logo.png" alt="bjc logo">
                </a>
            </div>
            <h2 class="subtitle">Reset Password</h2>
            <h2 class="heading">Please enter your details</h2>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="form-group">
                        <label class="form-control-label" for="newPassword">Password</label><span
                            class="required">*</span>
                        <input type="password" class="form-control" id="newPassword"
                            [ngClass]="{ 'is-invalid':passwordForm.get('newPassword').invalid && (submitted || passwordForm.get('newPassword').touched) }"
                            name="newPassword" placeholder="Password" formControlName="newPassword" required>
                        <div
                            *ngIf="passwordForm.get('newPassword').invalid && (submitted || passwordForm.get('newPassword').touched)">
                            <small class="form-text error-msg" *ngIf="passwordForm.get('newPassword').errors.required">
                                Please enter valid password.
                            </small>
                            <small class="form-text error-msg" *ngIf="passwordForm.get('newPassword').errors.pattern">
                                Please enter valid password.
                            </small>
                            <small class="form-text error-msg" *ngIf="passwordForm.get('newPassword').errors.minlength">
                                Password should be at least 14 characters.
                            </small>
                            <small class="form-text error-msg" *ngIf="passwordForm.get('newPassword').errors.error">
                                {{ passwordForm.get('newPassword').errors.error }}
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="passwordForm.get('newPassword').errors.containfnln">
                                Password should not contain first name or last name.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="passwordForm.get('newPassword').errors.containUsername">
                                Password should not contain username.
                            </small>

                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="confirmPassword">Confirm
                            Password</label><span class="required">*</span>
                        <input type="password" class="form-control" id="confirmPassword"
                            [ngClass]="{ 'is-invalid':passwordForm.get('confirmPassword').invalid && (submitted || passwordForm.get('confirmPassword').touched) }"
                            name="confirmPassword" placeholder="Confirm Password" formControlName="confirmPassword"
                            required>
                        <div
                            *ngIf="passwordForm.get('confirmPassword').invalid && (submitted || passwordForm.get('confirmPassword').touched)">
                            <small class="form-text error-msg"
                                *ngIf="passwordForm.get('confirmPassword').errors.required">
                                Please enter confirm password.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="passwordForm.get('confirmPassword').errors.equalValue">
                                Confirm password does not match with password.
                            </small>
                        </div>
                    </div>
                    <p class="font14 info-text">Your password must be at least 14 characters long, contain at least one
                        number and have a mixture of uppercase and lowercase letters. It should not contain your username, your email, or your first
                        or last name.</p>
                    <p class="font14 info-text">You cannot reuse any of your last 5 passwords.</p>

                </div>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="col-lg-3 col-md-6 p-0">
                <button type="submit" [disabled]="!passwordForm.touched"
                    class="btn btn-enable btn-block">CONTINUE</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="!showmessage && apiCalled">


    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-12 bjcrx-gif">
                <div class="gif-text">
                    <div class="bjc-text">
                        <img src="../../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="" />
                        <div class="laoder-text text-center">Processing...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showmessage">
    <div class="content">
        <div class="col-lg-4 col-md-6">
            <div [ngClass]="{'success-message':success, 'error-message':error}">
                {{message}}
            </div>
        </div>
    </div>
    <div class="row action m-0" *ngIf="errorType == 2" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
        </div>
    </div>
    <div class="row action m-0" *ngIf="showLoginBtn" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" (click)="login()" class="btn btn-enable btn-block">LOGIN</button>
        </div>
    </div>
</div>

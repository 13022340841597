import { Route } from '@angular/router';
import NavbarComponent from './navbar.component';

const navbarRoute: Route = {
  path: '',
  component: NavbarComponent,
  outlet: 'navbar'
};

export default navbarRoute;

import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import {
  Resolve, ActivatedRouteSnapshot, Routes
} from '@angular/router';
import {
  Observable, of
} from 'rxjs';
import {
  filter, map
} from 'rxjs/operators';
import {
  PharmacyLocationsRx, IPharmacyLocationsRx
} from 'app/shared/model/pharmacy-locations-rx.model';
import PharmacyLocationsRxService from './pharmacy-locations-rx.service';
import PharmacyLocationsRxComponent from './pharmacy-locations-rx.component';

@Injectable({ providedIn: 'root' })
export class PharmacyLocationsRxResolve implements Resolve<IPharmacyLocationsRx> {
  constructor(private service: PharmacyLocationsRxService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IPharmacyLocationsRx> {
    const { id } = route.params;
    if (id) {
      return this.service.find(id).pipe(
        filter((response: HttpResponse<PharmacyLocationsRx>) => response.ok),
        map((pharmacyLocations: HttpResponse<PharmacyLocationsRx>) => pharmacyLocations.body)
      );
    }
    return of(new PharmacyLocationsRx());
  }
}

export const pharmacyLocationsRoute: Routes = [
  {
    path: '',
    component: PharmacyLocationsRxComponent,
    data: {
      authorities: ['ROLE_USER'],
      defaultSort: 'id,asc',
      pageTitle: 'bjcrxApp.pharmacyLocations.home.title'
    }
  }
];

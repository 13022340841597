<div class="options-page content">
    <div class="row m-0 p-0 h-30 ">
        <div class="col-md-6 p-0">
            <div class="logo-wrapper">
                <img src="../../../content/images/bjclogo_navbar.png" alt=""/>
            </div>
            <div class="error-message mt-5" *ngIf="systemMsg">
                {{systemMsg ?.message }}
            </div>
        </div>
    </div>
</div>
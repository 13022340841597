import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import {
  FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors
} from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import {
  Router, ActivatedRoute
} from '@angular/router';
import AccountService from 'app/core/auth/account.service';
import { SessionStorageService } from 'ngx-webstorage';
import * as moment from 'moment';
import {
  DateValidator, CheckFutureDate
} from 'app/shared/util/request-util';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  isIEBrowser, prescriptionGlobalErrorMessage, refillReminderHelpText
} from 'app/app.constants';
import PrescriptionRxService from './prescription-rx.service';

@Component({
  selector: 'jhi-prescription-rx',
  templateUrl: './prescription-rx.component.html',
  styleUrls: ['prescription-rx.scss']
})
export default class PrescriptionRxComponent implements OnInit, OnDestroy {
  private prescriptionRxComponent$: Subject<void> = new Subject<void>();

  prescriptions;

  prescriptionForm: FormGroup;

  choosePharmacy: string[];

  dob: Date;

  last4DigitPhoneNumber: number;

  rxNumber1: any;

  titleAlert = 'Refill or Check status';

  responseData: any;

  isShow = false;

  rxData: any;

  isRxChecked = false;

  showSearchBox = false;

  isSingleItem;

  isSelected = true;

  step: string;

  rxItems: any;

  dependentList: any;

  isAuthenticated: boolean;

  currentPage: number;

  totalItems: number;

  pharmacyList: any;

  selectedPatient: any;

  selectedPatientId: any;

  showListview = true;

  isCheckedBox = false;

  rxNotFoundError = false;

  submitted = false;

  errormessages: string[] = [];

  successmessages: string[] = [];

  showErrorMessage = false;

  showSuccessMessage = false;

  peopleSoftId: any;

  selectedPharmacy = [];

  isDependents: boolean;

  isApiCalled = false;

  isUnauthorized: boolean;

  selectedStoreName: any;

  selectedStoreId: any;

  rxInfoListArr = {};

  exPatientId: any;

  dependentId: any;

  isSkeletonLoader: any = false;

  isCreditCardFound: boolean;

  accountInfo: any;

  dependentData: any;

  isDisabledSelectbox: boolean;

  searchText: string;

  setCallDoctorFlags = false;

  slideScreen;

  allPrescriptions: any;

  showLoader = true;

  birthDateFormat;

  nextFillDateFormat;

  orderBy: string;

  order: string;

  faInfoCircle = faInfoCircle;

  refillReminderHelpText = refillReminderHelpText;

  filterByStatus = false;

  filterByStatusArr = [];

  arr = [];

  editButton: any;

  hideButton: any;

  hide = false;

  cancel = true;

  apply = false;

  showAllRx = true;

  hideEdit = false;

  hideList = [];

  unhiddenRxList = [];

  noPrescription = false;

  disableRefillButton = false;

  allowTransfer = true;

  specialtyPharmacy = false;

  singlePharmacy: any;

  fullPharmacyList: any;

  nonAuthSelectedStoreId: any;

  nonAuthSelectedStoreName: any;

  nonAuthSelectedStoreAdultTransfer: any;

  constructor(
    private fb: FormBuilder,
    protected prescriptionService: PrescriptionRxService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private $sessionStorage: SessionStorageService,
    protected toasterMessage: ToastrService
  ) {
    this.prescriptionForm = this.fb.group({
      choosePharmacy: [null, Validators.required],
      dob: [null, [Validators.required, DateValidator(), CheckFutureDate()]],
      last4DigitPhoneNumber: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      rxNumberArr: this.addRxNumbersForm()
    });

    this.isDisabledSelectbox = true;
    this.activatedRoute.params.pipe(takeUntil(this.prescriptionRxComponent$)).subscribe((params) => {
      this.step = params?.step;

      if (this.step === 'search') {
        this.showListview = true;
      }

      if (this.step === 'confirm' || this.step === 'success') {
        if (!Array.isArray(this.rxData) || !this.rxData.length) {
          if (!this.isAuthenticated) {
            this.router.navigate(['prescription-rx/refill/search']);
          } else {
            this.router.navigate(['prescription-rx/refill/list']);
          }
        }
      }

      if (this.step === 'list' && !this.isAuthenticated) {
        if (!Array.isArray(this.responseData) || !this.responseData.length) {
          console.warn('empty blocks must be removed');
        }
      }
    });
    this.dependentList = [];
    this.currentPage = 0;
    this.prescriptions = [];
    this.responseData = [];
    this.allPrescriptions = [];
  }

  addRxNumbersForm() {
    const rxNumberFormArr = this.fb.array<FormGroup>([], { validators: this.validateForm });
    for (let i = 0; i < 6; i++) {
      const fg = this.fb.group({
        rxNumber: ['', []],
        storeId: ['', []]
      });
      rxNumberFormArr.push(fg);
    }
    return rxNumberFormArr;
  }

  public validateForm: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const { controls } = control;
    if (controls) {
      const theOne = Object.keys(controls).findIndex((key) => controls[key].value.rxNumber !== '' && controls[key].value.storeId !== '');

      if (theOne === -1) {
        return {
          atLeastOneRequired: {
            text: 'Please enter prescription number'
          }
        };
      }

      const secondOne = Object.keys(controls).findIndex((key) => controls[key].value.rxNumber !== '' && controls[key].value.storeId === '');

      if (secondOne >= 0) {
        return {
          atLeastOneRequired: {
            text: 'Please enter store id'
          }
        };
      }

      const thirdone = Object.keys(controls).findIndex((key) => controls[key].value.rxNumber === '' && controls[key].value.storeId !== '');

      if (thirdone >= 0) {
        return {
          atLeastOneRequired: {
            text: 'Please enter prescription number'
          }
        };
      }
    }
  };

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.prescriptionRxComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.accountService.identity().then((account) => {
      this.accountInfo = account;

      if (this.accountInfo && this.accountInfo !== null) {
        this.isAuthenticated = this.accountService.isAuthenticated();
        if (this.step === 'list' && this.isAuthenticated) {
          this.peopleSoftId = this.accountInfo.peopleSoftId;
          this.selectedPatientId = this.accountInfo.enterpriseRxPatientId;
          if (!this.accountInfo.peopleSoftId || this.accountInfo.peopleSoftId === 0) {
            this.getPrescriptionList(this.accountInfo.enterpriseRxPatientId);
            this.getPatientInfo(this.accountInfo.enterpriseRxPatientId);
          } else {
            this.getDependentList();
          }
        }
      } else if (this.responseData.length === 0) {
        this.router.navigate(['prescription-rx/refill/search']);
      }
    });

    this.prescriptionService
      .getPharmacyList()
      .pipe(takeUntil(this.prescriptionRxComponent$))
      .subscribe(
        (res: HttpResponse<[]>) => {
          this.fullPharmacyList = res.body;
          this.pharmacyList = this.fullPharmacyList;
        },
        () => {
          this.rxNotFoundError = true;
          this.errormessages = [];
          this.checkErrorMessages('Something went wrong!');
        }
      );

    if (this.step === 'search') {
      this.showListview = true;
    }
  }

  get f() {
    return this.prescriptionForm.controls;
  }

  getPatientInfo(patientId) {
    this.prescriptionService
      .getRxProfileData(patientId)
      .pipe(takeUntil(this.prescriptionRxComponent$))
      .subscribe((resp:any) => {
        if (resp) {
          this.dob = resp?.dateOfBirth;
          this.selectedStoreId = resp?.defaultPharmacyId;
          this.setSelectedPharmacy();
        }
      });
  }

  findPrescription(prescriptions: any) {
    this.submitted = true;
    if (this.prescriptionForm.invalid) {
      if (
        this.prescriptionForm.get('dob').errors &&
        !this.prescriptionForm.get('dob').errors?.required &&
        !this.prescriptionForm.get('dob').errors?.invalidDate &&
        !this.prescriptionForm.get('dob').errors?.futuredate
      ) {
        this.prescriptionForm.get('dob').setErrors(null);
      }
    }

    if (this.prescriptionForm.invalid) {
      return;
    }

    this.isSkeletonLoader = true;
    this.dob = prescriptions.dob;
    this.selectedStoreId = prescriptions.choosePharmacy;
    this.selectedStoreName = this.getPharmacyName(this.selectedStoreId);
    this.nonAuthSelectedStoreAdultTransfer = this.getIsAdultTransfer(this.selectedStoreId);
    this.nonAuthSelectedStoreId = this.selectedStoreId;
    this.nonAuthSelectedStoreName = this.selectedStoreName;
    const rxList = [];
    this.resetMessages();

    const rxArray = Object.keys(prescriptions.rxNumberArr).map((i) => prescriptions.rxNumberArr[i]);
    const list = rxArray.filter((res) => res !== '');

    const payload = {
      targetPharmacyId: prescriptions.choosePharmacy,
      lastFourDigitOfPhoneNumber: prescriptions.last4DigitPhoneNumber,
      patientDateOfBirth: moment(prescriptions.dob).format('YYYY-MM-DD'), // '1961-02-09'
      prescriptionDetailRequests: []
    };

    list.forEach((rxNumberStoreId) => {
      rxList.push({
        storeNumber: prescriptions.choosePharmacy,
        rxNumberWithStoreNumber: `${rxNumberStoreId.rxNumber}-${rxNumberStoreId.storeId}`,
        lastFourDigitPhoneNumber: prescriptions.last4DigitPhoneNumber,
        patientDob: moment(prescriptions.dob).format('YYYY-MM-DD'), // '1961-02-09'
        requestedBy: 'API-USER',
        page: '10',
        size: '20'
      });
      if (rxNumberStoreId.rxNumber !== '' && rxNumberStoreId.storeId !== '') {
        payload.prescriptionDetailRequests.push({ rxNumber: rxNumberStoreId.rxNumber, storeNumber: rxNumberStoreId.storeId });
      }
    });

    this.prescriptionService
      .checkRxRefillEligibility(payload)
      .pipe(takeUntil(this.prescriptionRxComponent$))
      .subscribe(
        (res) => {
          if (res) {
            if (res.content) {
              this.isSkeletonLoader = false;
              this.responseData = res.content;
              this.setPharmacyName();
              const haveActiveCreditCard = this.responseData.filter((response) => response.haveActiveCreditCard === false);

              if (haveActiveCreditCard.length > 0 && prescriptions.choosePharmacy === '54') {
                this.step = 'list';
                this.showListview = false;
              } else {
                this.step = 'list';
                this.showListview = true;
              }
            } else {
              if (res.phoneDobMatchFound === false) {
                this.responseData = res;
                this.isSkeletonLoader = false;
                this.checkErrorMessages("Information provided doesn't match with our records, please check again.");
              }
              this.rxNotFoundError = true;
            }
          }
        },
        (error) => {
          this.rxNotFoundError = true;
          console.log('error', error);
          if (error.status === 404) {
            this.isSkeletonLoader = false;
            this.checkErrorMessages('One or more of your prescription number(s) is incorrect, please try again.');
            this.showErrorMessage = true;
          } else if (error.status === 500) {
            this.checkErrorMessages(prescriptionGlobalErrorMessage);
            this.isSkeletonLoader = false;
          } else if (error.status === 422) {
            this.checkErrorMessages(error.error.detail);
            this.isSkeletonLoader = false;
          } else {
            this.checkErrorMessages(prescriptionGlobalErrorMessage);
            this.isSkeletonLoader = false;
          }
        }
      );

    this.titleAlert = 'Request items to Refill';
  }

  showSearchForm() {
    this.rxNotFoundError = false;
    if (this.prescriptionForm.value.dob) {
      this.prescriptionForm.get('dob').setValue(moment(this.prescriptionForm.value.dob));
    }
  }

  toggleDisplay(rxRefillData: any) {
    rxRefillData.isShow = !rxRefillData.isShow;
  }

  onChange(rxRefillData) {
    rxRefillData.isCheckedBox = !rxRefillData.isCheckedBox;
    this.rxData = this.responseData.filter((ele) => ele.isCheckedBox === true);

    if (this.rxData.length > 1) {
      this.isSingleItem = 'items';
    } else {
      this.isSingleItem = 'item';
    }
    this.titleAlert = `Request ${this.rxData.length} ${this.isSingleItem} to Refill`;

    if (this.rxData.length === 0) {
      this.titleAlert = 'Request items to Refill';
    }

    if (this.rxData.length > 0) {
      this.isRxChecked = true;
      if (this.rxData.length === 1) {
        this.singlePharmacy = this.getPharmacy(this.rxData[0].rxFacilityID);
        if (
          this.nonAuthSelectedStoreAdultTransfer !== undefined &&
          ((!this.nonAuthSelectedStoreAdultTransfer &&
            parseInt(this.nonAuthSelectedStoreId, 10) !== parseInt(this.rxData[0].rxFacilityID, 10)) ||
            (this.nonAuthSelectedStoreAdultTransfer && !this.singlePharmacy.isAdultTransfer))
        ) {
          this.pharmacyList = [];
          this.selectedStoreId = '';
          this.setSelectedPharmacy();
          this.allowTransfer = false;
          this.specialtyPharmacy = true;
        } else if (this.singlePharmacy.isAdultTransfer !== undefined && !this.singlePharmacy.isAdultTransfer) {
          this.pharmacyList = [];
          this.pharmacyList.push(this.singlePharmacy);
          this.selectedStoreId = this.singlePharmacy.pharmacyId;
          this.setSelectedPharmacy();
          this.specialtyPharmacy = true;
          this.allowTransfer = true;
        } else {
          this.getRxRefillPharmacyList();
          this.allowTransfer = true;
          this.disableRefillButton = false;
        }
      } else {
        for (let i = 0; i <= this.rxData.length - 1; i++) {
          const pharmacy1 = this.getPharmacy(this.rxData[0].rxFacilityID);
          const pharmacy2 = this.getPharmacy(this.rxData[i].rxFacilityID);
          if (this.rxData[0].rxFacilityID !== this.rxData[i].rxFacilityID && (!pharmacy1.isAdultTransfer || !pharmacy2.isAdultTransfer)) {
            // no transfer allowed
            this.pharmacyList = [];
            this.selectedStoreId = '';
            this.setSelectedPharmacy();
            this.allowTransfer = false;
          } else if (
            this.nonAuthSelectedStoreAdultTransfer !== undefined &&
              ((!this.nonAuthSelectedStoreAdultTransfer &&
                (parseInt(this.nonAuthSelectedStoreId, 10) !== parseInt(this.rxData[0].rxFacilityID, 10) ||
                  parseInt(this.nonAuthSelectedStoreId, 10) !== parseInt(this.rxData[i].rxFacilityID, 10))) ||
                (this.nonAuthSelectedStoreAdultTransfer && (!pharmacy1.isAdultTransfer || !pharmacy2.isAdultTransfer)))
          ) {
            this.pharmacyList = [];
            this.selectedStoreId = '';
            this.setSelectedPharmacy();
            this.allowTransfer = false;
          } else if (pharmacy1.isAdultTransfer !== undefined && !pharmacy1.isAdultTransfer) {
            this.pharmacyList = [];
            this.pharmacyList.push(pharmacy1);
            this.selectedStoreId = pharmacy1.pharmacyId;
            this.setSelectedPharmacy();
            this.specialtyPharmacy = true;
            this.allowTransfer = true;
          } else {
            this.getRxRefillPharmacyList();
            this.allowTransfer = true;
            this.disableRefillButton = false;
            if (!this.isAuthenticated) {
              this.selectedStoreId = this.nonAuthSelectedStoreId;
              this.selectedStoreName = this.nonAuthSelectedStoreName;
            }
          }
        }
      }
    } else {
      this.isRxChecked = false;
    }
  }

  getPharmacy(rxFacilityId) {
    let pharmacyData;
    this.fullPharmacyList.forEach((pharmacy) => {
      if (pharmacy.pharmacyId === parseInt(rxFacilityId, 10)) {
        pharmacyData = pharmacy;
      }
    });
    return pharmacyData;
  }

  getRxRefillPharmacyList() {
    this.pharmacyList = [];
    this.fullPharmacyList.forEach((pharmacy) => {
      if (pharmacy.isAdultTransfer) {
        this.pharmacyList.push(pharmacy);
      }
    });
  }

  requestRefillRx() {
    this.step = 'confirm';
    this.titleAlert = 'Confirm your order';
    this.setCallDoctorFlags = false;
    this.showListview = true;
    if (this.rxData.filter((ele) => parseInt(ele.refillsRemaining, 10) === 0).length > 0) {
      this.setCallDoctorFlags = true;
    }
  }

  checkCreditCard() {
    if (this.selectedStoreId === '54') {
      this.prescriptionService
        .getCreditCardStatus(this.selectedPatientId)
        .pipe(takeUntil(this.prescriptionRxComponent$))
        .subscribe((resp: any) => {
          if (resp?.resultsFound) {
            this.requestRefillRequestRx();
            this.isCreditCardFound = true;
            this.showListview = true;
          } else {
            this.showListview = false;
            this.isCreditCardFound = false;
          }
        });
    } else {
      this.requestRefillRequestRx();
      this.showListview = true;
    }
  }

  resetMessages() {
    this.successmessages = [];
    this.errormessages = [];
  }

  requestRefillRequestRx() {
    this.showLoader = false;
    this.resetMessages();
    const selectedRx = [];

    this.rxData.forEach((data) => {
      let isCallDoctorFlag = false;
      // isCallDoctorFlag = parseInt(data.refillsRemaining, 10) > 0 ? false : true;
      const currentDate = new Date(Date.now());

      const splitDate = data.rxExpiryDate.split('-', 3); // 'yyyy-mm-dd'
      const expirationDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2], 0, 0);
      const differenceInTime = currentDate.getTime() - expirationDate.getTime();

      if (parseInt(data.refillsRemaining, 10) <= 0 || differenceInTime > 0) {
        isCallDoctorFlag = true;
      }

      selectedRx.push({
        rxNumber: data.rxNumber,
        facilityId: data.rxFacilityID,
        isCallDoctor: isCallDoctorFlag,
        isUnacceptablePickupTime: false
      });
    });

    const requestPayload: any = {
      rxInfoList: selectedRx,
      pickupStore: this.selectedStoreId
    };

    this.prescriptionService
      .submitRefillRequest(requestPayload)
      .pipe(takeUntil(this.prescriptionRxComponent$))
      .subscribe(
        (res) => {
          if (res) {
            this.disableRefillButton = false;
            this.showLoader = true;
            this.step = 'success';
            res.forEach((result) => {
              /**
               * successMessage is require for Validation because API returns two different messages for HttpStatus 201.
               * Success Message with 201 HttpStatus = "Your request was successfully submitted."
               * Error Message with 201 HttpStatus = "One of your Rx(s) is neither fillable nor flagged as call doctor and cannot be refilled."
               */
              const successMessage = 'Your request was successfully submitted.';
              if (
                result.statusCode === 202 ||
                result.statusCode === 200 ||
                (result.statusCode === 201 && result.message === successMessage)
              ) {
                this.showSuccessMessage = true;
                this.checkSuccessMessages(result.message);
              }
              if (
                result.statusCode === 404 ||
                result.statusCode === 406 ||
                (result.statusCode === 201 && result.message !== successMessage)
              ) {
                this.showErrorMessage = true;
                this.checkErrorMessages(result.message);
              }
            });
            this.prescriptionForm.reset();
          }
        },
        (error) => {
          error.forEach((errorResponse) => {
            if (errorResponse.statusCode === 500 || errorResponse.statusCode === 404 || errorResponse.statusCode === 406) {
              this.showErrorMessage = true;
              this.checkErrorMessages(errorResponse.message);
            }
          });
          this.disableRefillButton = false;
          this.showLoader = true;
        }
      );
  }

  checkErrorMessages(newMessage: string) {
    if (this.errormessages.length > 0) {
      let messageReceived = false;
      this.errormessages.forEach((currentMessage) => {
        if (currentMessage === newMessage) {
          messageReceived = true;
        }
      });
      if (!messageReceived) {
        this.errormessages.push(newMessage);
      }
    } else {
      this.errormessages.push(newMessage);
    }
  }

  checkSuccessMessages(newMessage: string) {
    if (this.successmessages.length > 0) {
      let messageReceived = false;
      this.successmessages.forEach((currentMessage) => {
        if (currentMessage === newMessage) {
          messageReceived = true;
        }
      });
      if (!messageReceived) {
        this.successmessages.push(newMessage);
      }
    } else {
      this.successmessages.push(newMessage);
    }
  }

  backClicked() {
    this.showListview = false;
    this.isSkeletonLoader = false;
    if (this.step === 'confirm') {
      this.step = 'list';
      this.showListview = true;
      if (!this.isAuthenticated) {
        this.selectedStoreId = this.nonAuthSelectedStoreId;
        this.selectedStoreName = this.nonAuthSelectedStoreName;
      }
    } else if (this.step === 'list') {
      if (this.isAuthenticated) {
        window.history.back();
      } else {
        if (this.prescriptionForm.value.dob) {
          this.prescriptionForm.get('dob').setValue(moment(this.prescriptionForm.value.dob));
        }
        this.pharmacyList = this.fullPharmacyList;
        this.allowTransfer = true;
        this.specialtyPharmacy = false;
        this.step = 'search';
        this.responseData = [];
      }
      this.step = 'search';
      this.responseData = [];
    }
  }

  isShowSearchBox() {
    this.showSearchBox = !this.showSearchBox;
    if (this.showSearchBox === false) {
      this.searchText = '';
    }
  }

  toggleEditButton() {
    this.responseData = [];
    this.currentPage = 0;
    this.editButton = document.getElementById('editButton');
    this.hideList = [];
    this.hide = true;
    this.hideEdit = true;
    this.cancel = false;
    this.showAllRx = false;
    this.getPrescriptionList(this.accountInfo.enterpriseRxPatientId);
  }

  cancelButton() {
    this.responseData = [];
    this.currentPage = 0;
    this.cancel = true;
    this.hide = false;
    this.hideEdit = false;
    this.showAllRx = true;
    this.getPrescriptionList(this.accountInfo.enterpriseRxPatientId);
    window.location.reload();
  }

  toggleButton(hideRx) {
    hideRx.visible = !hideRx.visible;
    if (!this.hideList.includes(hideRx.rxNumber)) {
      this.hideList.push(hideRx.rxNumber);
    } else {
      this.hideList.splice(this.hideList.indexOf(hideRx.rxNumber), 1);
    }
  }

  toggleApplyButton() {
    this.responseData = [];
    this.currentPage = 0;
    this.hide = false;
    this.cancel = true;
    this.hideEdit = false;
    this.showAllRx = true;
    this.prescriptionService.sendHiddenRxList(this.selectedPatientId, this.hideList).subscribe((res) => {
      if (res === null) {
        this.getPrescriptionList(this.accountInfo.enterpriseRxPatientId);
      }
    });
  }

  getPrescriptionList(e) {
    if (!this.isSkeletonLoader) {
      this.resetMessages();
      this.isSkeletonLoader = true;
      if (this.selectedPatientId !== '' && this.selectedPatientId !== undefined && this.selectedPatientId !== null) {
        this.exPatientId = this.selectedPatientId;
      } else {
        this.exPatientId = e;
      }

      this.isApiCalled = false;
      if (e) {
        this.currentPage += 1;
        this.prescriptionService
          .getPrescriptionList(this.currentPage, this.exPatientId, this.orderBy, this.order, this.filterByStatus)
          .pipe(takeUntil(this.prescriptionRxComponent$))
          .subscribe(
            (resp) => {
              if (resp) {
                this.isDisabledSelectbox = false;
                if (resp.content !== 'Prescriptions Not Found For The Patient.') {
                  this.isApiCalled = true;
                  this.responseData = this.responseData.concat(resp.content);

                  this.responseData.forEach((element) => {
                    element.refillsRemaining = parseInt(element.refillsRemaining, 10);
                    if (this.showAllRx === true) {
                      if (element.visible === false) {
                        this.unhiddenRxList.push(element);
                        this.responseData = this.responseData.filter((val) => !this.unhiddenRxList.includes(val));
                      }
                    }
                  });
                  if (this.responseData.length === 0 || this.selectedPatientId === 0 || this.isUnauthorized) {
                    this.noPrescription = true;
                  } else {
                    this.noPrescription = false;
                  }
                  this.allPrescriptions = this.allPrescriptions.concat(resp.content);
                  this.totalItems = resp.totalRecords;
                  this.currentPage = resp.pageNumber;
                  if (this.responseData.length > 0) {
                    this.setPharmacyName();
                    this.sort(this.responseData);
                  } else {
                    this.checkErrorMessages(this.responseData);
                  }
                  this.isSkeletonLoader = false;
                } else {
                  this.isSkeletonLoader = false;
                  this.isApiCalled = true;
                }
              }
            },
            () => {
              this.isSkeletonLoader = false;
              this.isApiCalled = true;
              this.checkErrorMessages(prescriptionGlobalErrorMessage);
            }
          );
      }
    }
  }

  checkIsIEBrowser() {
    return isIEBrowser;
  }

  checkRxExpirationDifference(rxData) {
    const todayDate = new Date();
    const rxExpiryDate = new Date(rxData.rxExpiryDate);
    rxExpiryDate.setDate(rxExpiryDate.getDate());
    const differenceInTime = todayDate.getTime() - rxExpiryDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    if (differenceInDays <= 180) {
      // If the expired prescription lies between 180 days, checkbox should be enabled
      if (differenceInDays < 0 && rxData.eligibleForRefill === false && rxData.refillsRemaining > 0) {
        // differenceInDays < 0 is not expired
        return false;
      }
      return true;
    }
    return false;
  }

  subscribeForRefillReminder(event, prescription) {
    if (this.dependentList.length > 0) {
      this.birthDateFormat = this.dependentList.find((element) => element.patientId === parseInt(this.selectedPatientId, 10)).birthDate;
    } else {
      this.birthDateFormat = this.dob;
    }

    if (this.birthDateFormat !== null && this.birthDateFormat !== '') {
      this.birthDateFormat = moment(this.birthDateFormat).format('MM/DD/YYYY');
    } else {
      this.birthDateFormat = '';
    }

    if (prescription.nextFillDate !== null && prescription.nextFillDate !== '') {
      this.nextFillDateFormat = moment(prescription.nextFillDate).format('MM/DD/YYYY');
    } else {
      this.nextFillDateFormat = '';
    }

    const payload = {
      nextFillDate: this.nextFillDateFormat,
      reminderEnabled: event.target.checked,
      rxNumber: prescription.rxNumber,
      dateOfBirth: this.birthDateFormat,
      patientId: '',
      primaryUserPatientId: '',
      dependent: false,
      username: '',
      employeePharmacy: false
    };

    payload.patientId = this.exPatientId;
    payload.primaryUserPatientId = this.accountInfo.enterpriseRxPatientId;
    if (parseInt(this.exPatientId, 10) !== parseInt(this.accountInfo.enterpriseRxPatientId, 10)) {
      payload.dependent = true;
    }
    payload.username = this.accountInfo.username;
    payload.employeePharmacy = parseInt(prescription.rxFacilityID, 10) === 54;

    this.prescriptionService
      .subscribeForRefillReminder(payload)
      .pipe(takeUntil(this.prescriptionRxComponent$))
      .subscribe(
        () => {
          // event.target.checked = event.target.checked;
        },
        () => {
          event.target.checked = !event.target.checked;
        }
      );
  }

  calculateAge(event) {
    this.responseData = [];
    this.isUnauthorized = false;
    const targetElement = event.target;
    this.dependentId = targetElement.options[targetElement.selectedIndex].getAttribute('data-dependent-id');
    this.selectedPatient = this.dependentList.find((item) => parseInt(item.id, 10) === parseInt(this.dependentId, 10));

    this.selectedStoreId = this.dependentList.find((element) => element.id === parseInt(this.dependentId, 10)).defaultPharmacyId;
    this.setSelectedPharmacy();

    const selectedDependent = this.dependentList.find((element) => element.id === parseInt(this.dependentId, 10));
    const age = moment().diff(selectedDependent.birthDate, 'month');

    if (age / 12 >= 18 && selectedDependent.sequence !== null) {
      /* Users cannot manage accounts for dependents who are over 18 */
      this.isUnauthorized = true;
      return false;
    }
    if (age / 12 < 18 && selectedDependent.patientId === 0) {
      /* If a minor dependent is selected who is not in EntRx, show minor registration link */
      return true;
    }
    this.getPrescriptionList(this.selectedPatientId);
    this.dob = selectedDependent.birthDate;
    return true;
  }

  validateNumber(e) {
    if (e.key.match(/[0-9]/)) {
      return true;
    }
    return false;
  }

  getDependentList() {
    this.isSkeletonLoader = true;
    this.prescriptionService
      .getDependentList(this.peopleSoftId)
      .pipe(takeUntil(this.prescriptionRxComponent$))
      .subscribe((resp) => {
        if (resp) {
          this.isSkeletonLoader = false;
          this.dependentList = resp;
          if (this.dependentList.length > 0) {
            this.isDependents = true;
            this.selectedPatient = this.dependentList.find((element) => element.sequence === null);
            this.selectedStoreId = this.selectedPatient.defaultPharmacyId;
            this.setSelectedPharmacy();
            this.getPrescriptionList(this.selectedPatientId);
          } else {
            this.isDependents = false;
            this.getPatientInfo(this.selectedPatientId);
            this.getPrescriptionList(this.selectedPatientId);
          }
        }
      });
  }

  resetForm(loadPrescriptions) {
    this.rxNotFoundError = false;
    if (this.prescriptionForm.value.dob) {
      this.prescriptionForm.get('dob').setValue(moment(this.prescriptionForm.value.dob));
    }
    this.resetMessages();

    if (loadPrescriptions) {
      if (this.isAuthenticated) {
        this.step = 'list';
        this.showListview = true;
        this.currentPage = 0;
        this.responseData = [];
        this.getPrescriptionList(this.selectedPatientId);
      } else {
        this.step = 'search';
        this.responseData = [];
        this.pharmacyList = this.fullPharmacyList;
      }
    } else {
      this.step = 'list';
      this.showListview = true;
    }
  }

  sort(data) {
    const expiredPrescriptions = [];
    const activePrescriptions = [];
    const hiddenPrescriptions = [];
    const inactivePrescriptions = [];
    data.forEach((element) => {
      if (element.rxStatus === 'Transferred') {
        expiredPrescriptions.push(element);
      } else if (element.rxStatus === 'Cancelled') {
        hiddenPrescriptions.push(element);
      } else if (element.rxStatus === 'Profiled') {
        hiddenPrescriptions.push(element);
      } else if (element.rxStatus === 'Active-Expired') {
        expiredPrescriptions.push(element);
      } else if (element.rxStatus === 'Inactive') {
        inactivePrescriptions.push(element);
      } else {
        activePrescriptions.push(element);
      }
    });
    this.responseData = activePrescriptions.concat(expiredPrescriptions).concat(inactivePrescriptions);
  }

  getPharmacyName(pharmacyId: any) {
    for (let i = 0; i < this.pharmacyList.length; i++) {
      if (this.pharmacyList[i]?.pharmacyId === parseInt(pharmacyId, 10)) {
        return (this.selectedPharmacy === this.pharmacyList[i].name);
      }
    }
  }

  getIsAdultTransfer(pharmacyId: any) {
    for (let i = 0; i < this.fullPharmacyList.length; i++) {
      if (this.fullPharmacyList[i].pharmacyId === parseInt(pharmacyId, 10)) {
        return this.fullPharmacyList[i].isAdultTransfer;
      }
    }
  }

  setPharmacyName() {
    if (this.responseData.length > 0) {
      this.responseData.forEach((ele) => {
        ele.mgData = ele.productName.replace(/[^0-9]/g, '');
        const pharmacyName = this.pharmacyList.find((element) => parseInt(element.pharmacyId, 10) === parseInt(ele.rxFacilityID, 10));
        pharmacyName ? (ele.pharmacyName = pharmacyName.name) : (ele.pharmacyName = 'NA');
      });
    }
  }

  setSelectedPharmacy() {
    let pharmacy = {
      name: ''
    };
    pharmacy = this.pharmacyList.find((element) => parseInt(element.pharmacyId, 10) === parseInt(this.selectedStoreId, 10));
    if (pharmacy && pharmacy?.name) {
      this.selectedStoreName = pharmacy?.name;
    } else {
      this.selectedStoreName = '';
    }
  }

  sortBy(type) {
    this.responseData = [];
    this.currentPage = 0;
    if (this.isAuthenticated) {
      if (type === 'a-z') {
        this.orderBy = 'prescriptionName';
        this.order = 'asc';
      } else if (type === 'z-a') {
        this.orderBy = 'prescriptionName';
        this.order = 'desc';
      } else if (type === 'pnumber') {
        this.orderBy = 'prescriptionNumber';
        this.order = 'asc';
      }
      this.getPrescriptionList(this.accountInfo.enterpriseRxPatientId);
    } else if (type === 'a-z') {
      this.responseData.sort((a, b) => (a.productName > b.productName ? 1 : -1));
    } else if (type === 'z-a') {
      this.responseData.sort((a, b) => (a.productName > b.productName ? -1 : 1));
    } else if (type === 'pnumber') {
      this.responseData.sort((a, b) => (a.rxNumber > b.rxNumber ? 1 : -1));
    }
  }

  showByFilter(status) {
    this.responseData = [];
    this.currentPage = 0;
    if (this.isAuthenticated) {
      if (status === 'latest') this.filterByStatus = true;
      this.orderBy = '';
      this.order = '';
      console.log('DATA ===== ', this.allPrescriptions);
      this.arr = this.allPrescriptions.sort((a, b) => a.productName.localeCompare(b.productName));
      console.log('SORTED === ', this.arr);
      this.getPrescriptionList(this.accountInfo.enterpriseRxPatientId);
      this.filterByStatusArr.push(status);
    }
  }

  clearFilter(filterName) {
    this.responseData = [];
    this.currentPage = 0;
    this.filterByStatus = false;
    this.orderBy = '';
    this.order = '';
    const index: number = this.filterByStatusArr.indexOf(filterName);
    if (index !== -1) {
      this.filterByStatusArr.splice(index, 1);
    }
    this.getPrescriptionList(this.accountInfo.enterpriseRxPatientId);
  }

  public ngOnDestroy(): void {
    this.prescriptionRxComponent$.next();
    this.prescriptionRxComponent$.unsubscribe();
  }

  public registerForMailOrder() {
    this.$sessionStorage.store('minorDependent', JSON.stringify(this.selectedPatient));
    this.router.navigate(['/profile-rx/mailorder']);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import PasswordService from './password.service';

@Component({
  selector: 'jhi-password',
  templateUrl: './password.component.html'
})
export default class PasswordComponent implements OnInit, OnDestroy {
  private passwordComponent$: Subject<void> = new Subject<void>();

  doNotMatch: string;

  error: string;

  success: string;

  account: any;

  passwordForm = this.fb.group({
    currentPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]]
  });

  constructor(private passwordService: PasswordService, private accountService: AccountService, private fb: FormBuilder) {}

  ngOnInit() {
    this.accountService.identity().then((account) => {
      this.account = account;
    });
  }

  changePassword() {
    const newPassword = this.passwordForm.get(['newPassword']).value;
    if (newPassword !== this.passwordForm.get(['confirmPassword']).value) {
      this.error = null;
      this.success = null;
      this.doNotMatch = 'ERROR';
    } else {
      this.doNotMatch = null;
      this.passwordService
        .save(newPassword, this.passwordForm.get(['currentPassword']).value)
        .pipe(takeUntil(this.passwordComponent$))
        .subscribe(
          () => {
            this.error = null;
            this.success = 'OK';
          },
          () => {
            this.success = null;
            this.error = 'ERROR';
          }
        );
    }
  }

  public ngOnDestroy(): void {
    this.passwordComponent$.next();
    this.passwordComponent$.unsubscribe();
  }
}

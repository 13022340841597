import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DateValidator, CheckFutureDate } from 'app/shared/util/request-util';
import AccountService from 'app/core/auth/account.service';
import { SessionStorageService } from 'ngx-webstorage';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { isIEBrowser } from 'app/app.constants';
import PrescriptionTxReqRxService from '../prescription-tx-req-rx/prescription-tx-req-rx.service';
import PrescriptionRxService from '../prescription-rx/prescription-rx.service';
import CustomerStatementRxService from './customer-statement-rx.service';

@Component({
  selector: 'jhi-customer-statement-rx',
  templateUrl: './customer-statement-rx.component.html'
})
export default class CustomerStatementRxComponent implements OnInit, OnDestroy {
  private customerStatementRxComponentDestroyed$: Subject<void> = new Subject<void>();

  currentAccount: any;

  pharmacyList: any;

  isUnauthorized: boolean;

  rxPatientId: any;

  peopleSoftId: any;

  dependentList: any;

  dependentListFromApi: any;

  resPdf: any;

  resPdfName: any;

  showDependents: boolean;

  today: Date;

  todays: any;

  greaterEndDate: boolean;

  slideScreen;

  showLoader = false;

  customerStatementsForm = new FormGroup({
    patientName: new FormControl(''),
    pharmacyName: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required, DateValidator(), CheckFutureDate()]),
    endDate: new FormControl('', [Validators.required, DateValidator(), CheckFutureDate()])
  });

  constructor(
    protected customerStatementService: CustomerStatementRxService,
    protected accountService: AccountService,
    protected prescriptionService: PrescriptionRxService,
    protected prescriptionTxReqService: PrescriptionTxReqRxService,
    private sessionStorage: SessionStorageService
  ) {
    this.dependentListFromApi = [];
  }

  ngOnInit() {
    this.accountService.identity().then((account) => {
      this.currentAccount = account;

      this.peopleSoftId = this.currentAccount.peopleSoftId ? this.currentAccount.peopleSoftId : 0;
      this.rxPatientId = this.currentAccount.enterpriseRxPatientId;
      this.getDependentList();
      this.today = new Date();
      this.prescriptionTxReqService
        .getPharmacyList()
        .pipe(takeUntil(this.customerStatementRxComponentDestroyed$))
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.pharmacyList = res.body;
            this.pharmacyList.unshift({ pharmacyId: '0', name: 'All' });
          }
        );
    });
    this.accountService.slideScreen.pipe(takeUntil(this.customerStatementRxComponentDestroyed$)).subscribe((data) => {
      this.slideScreen = data;
    });
  }

  getDependentList() {
    this.prescriptionService
      .getDependentList(this.peopleSoftId)
      .pipe(takeUntil(this.customerStatementRxComponentDestroyed$))
      .subscribe((resp) => {
        this.dependentListFromApi = resp;
        this.customerStatementsForm.get('patientName').setValue(this.rxPatientId);
        if (this.dependentListFromApi.length > 0) {
          this.showDependents = true;
        }
      });
  }

  calculateAge(patientName) {
    this.isUnauthorized = false;
    this.dependentListFromApi.forEach((ele) => {
      let age = 0;
      if (parseInt(ele.patientId, 10) === parseInt(patientName.value.patientName, 10)) {
        age = moment().diff(ele.birthDate, 'month');
        if (age / 12 < 18 || ele.sequence === null) {
          return true;
        }
        this.isUnauthorized = true;
        return false;
      }
    });
  }

  customerStatement() {
    if (
      this.customerStatementsForm.get('startDate').errors &&
      !this.customerStatementsForm.get('startDate').errors?.required &&
      !this.customerStatementsForm.get('startDate').errors?.invalidDate &&
      !this.customerStatementsForm.get('startDate').errors?.futuredate
    ) {
      this.customerStatementsForm.get('startDate').setErrors(null);
    }
    if (
      this.customerStatementsForm.get('endDate').errors &&
      !this.customerStatementsForm.get('endDate').errors?.required &&
      !this.customerStatementsForm.get('endDate').errors?.invalidDate &&
      !this.customerStatementsForm.get('endDate').errors?.futuredate
    ) {
      this.customerStatementsForm.get('endDate').setErrors(null);
    }

    if (!this.customerStatementsForm.invalid) {
      this.showLoader = true;
      const payload = {
        startDate: this.customerStatementsForm.value.startDate,
        endDate: this.customerStatementsForm.value.endDate,
        patientId: this.customerStatementsForm.value.patientName
          ? parseInt(this.customerStatementsForm.value.patientName, 10)
          : this.rxPatientId,
        pharmacyId: parseInt(this.customerStatementsForm.value.pharmacyName, 10),
        idType: 'PID',
        requestedBy: 'API-USER'
      };
      this.customerStatementService
        .customersStatements(payload)
        .pipe(takeUntil(this.customerStatementRxComponentDestroyed$))
        .subscribe((resp) => {
          this.resPdf = resp.body;
          const nav = window.navigator as any;
          if (window.navigator && nav.msSaveOrOpenBlob) {
            // IE workaround
            const byteCharacters = atob(resp.body);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            nav.msSaveOrOpenBlob(blob, 'Customers_Statements.pdf');
          } else {
            const linkSource = `data:application/pdf;base64,${this.resPdf}`;
            const downloadLink = document.createElement('a');
            const fileName = 'Customers_Statements.pdf';
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            console.warn('in response ==> 2');
            downloadLink.click();
          }

          this.showLoader = false;
        });
    }
  }

  checkIsIEBrowser() {
    return isIEBrowser;
  }

  public ngOnDestroy(): void {
    this.customerStatementRxComponentDestroyed$.next();
    this.customerStatementRxComponentDestroyed$.unsubscribe();
  }
}

import {
  Component, OnInit, ChangeDetectorRef, OnDestroy
} from '@angular/core';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import PharmacyLocationsRxService from './pharmacy-locations-rx.service';
import { Location } from './location-model';
import { Distance } from './distance-model';

@Component({
  selector: 'jhi-pharmacy-locations-rx',
  templateUrl: './pharmacy-locations-rx.component.html',
  styleUrls: ['pharmacy-locations-rx.scss']
})
export default class PharmacyLocationsRxComponent implements OnInit, OnDestroy {
  private pharmacyLocationsRxComponent$: Subject<void> = new Subject<void>();

  latitude = 38.9087;

  longitude = -90.1568;

  previous: any;

  zoom: number;

  origin: any;

  destination: any;

  pharmacies: any;

  isPharmacyDetails: boolean;

  selectedPharmacy: any;

  imagesList: any;

  isGetDirections: boolean;

  mapStyle: any;

  markerOptions: any;

  renderOptions: any;

  markers: any;

  loader: boolean;

  slideScreen;

  constructor(
    private pharmacyLocationsService: PharmacyLocationsRxService,
    private accountService: AccountService,
    private ref: ChangeDetectorRef
  ) {
    this.zoom = 6;
    this.mapStyle = [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      }
    ];
    this.markers = [];
    this.pharmacies = [];
    this.renderOptions = {
      supressMarkers: true
    };

    this.markerOptions = {
      origin: {
        icon: '../../../content/images/pin.png'
      },
      destination: {
        icon: '../../../content/images/pin.png'
      }
    };
    this.isPharmacyDetails = false;
    if (navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.longitude = +pos.coords.longitude;
          this.latitude = +pos.coords.latitude;
        }
      );
    }

    this.imagesList = [];
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.pharmacyLocationsRxComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });

    this.getPharmacies();
  }

  setMarkers() {
    this.pharmacies.forEach((ele) => {
      this.getCoordinates(ele);
    });
    setTimeout(() => {
      this.loader = false;
    }, 5000);
  }

  getDistance(ele) {
    if (!(ele.latitude === 38.9087 && ele.longitude === -90.1568)) {
      const origin = {
        lat: this.latitude,
        lng: this.longitude
      };
      const destination = {
        lat: ele.latitude,
        lng: ele.longitude
      };
      this.pharmacyLocationsService
        .getDistance(origin, destination)
        .pipe(takeUntil(this.pharmacyLocationsRxComponent$))
        .subscribe((distance: Distance) => {
          if (distance) {
            ele.data.pharmacyDistanceFromLocation = distance;
          }
        });
    } else {
      ele.data.pharmacyDistanceFromLocation = null;
    }
  }

  getPharmacies() {
    this.loader = true;
    this.pharmacyLocationsService
      .getPharmacies(this.latitude, this.longitude)
      .pipe(takeUntil(this.pharmacyLocationsRxComponent$))
      .subscribe((resp) => {
        if (resp) {
          this.pharmacies = resp;
          this.pharmacies = this.pharmacies.sort(this.pharmacySort);
          this.pharmacies.forEach((element) => {
            const address = [];
            const addressInfo = [];
            element.images = element.imageUrl ? element.imageUrl.split(',') : '';
            if (element.pharmacyWeeklySchedule) {
              element.pharmacyWeeklySchedule = JSON.parse(element.pharmacyWeeklySchedule);
              element.hours = element.pharmacyWeeklySchedule;
            }
            if (element.streetAddress !== '') {
              address.push(element.streetAddress);
            }

            if (element.addressLocality !== '') {
              address.push(element.addressLocality);
            }

            if (element.addressRegion !== '') {
              address.push(`${element.addressRegion} ${element.postalCode}`);
            }

            if (element.landmark !== '') {
              addressInfo.push(element.landmark);
            }

            if (element.additionalLocationInfo !== null) {
              addressInfo.push(element.additionalLocationInfo);
            }

            element.address = address.join(', ');
            element.addressInfo = addressInfo.join(', ');
          });

          if (this.pharmacies.length > 0) {
            this.setMarkers();
          }
        }
      });
  }

  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  viewPharmacyDetails(data) {
    this.imagesList = [];
    this.selectedPharmacy = data;
    if (this.selectedPharmacy.imageUrl) {
      for (let i = 0; i < this.selectedPharmacy.images.length; i++) {
        this.pharmacyLocationsService
          .getPharmacyImage(
            this.selectedPharmacy.pharmacyId,
            this.selectedPharmacy.images[i] === '' ? 'no-image.jpg' : this.selectedPharmacy.images[i]
          )
          .pipe(takeUntil(this.pharmacyLocationsRxComponent$))
          .subscribe((resp) => {
            if (resp) {
              this.blobToImg(resp);
            }
          });
      }
    }

    setTimeout(() => {
      this.isPharmacyDetails = true;
    }, 200);
  }

  blobToImg(blob: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.imagesList = this.imagesList.concat(reader.result);
      },
      false
    );
    if (blob) {
      reader.readAsDataURL(blob);
    }
  }

  back() {
    this.isPharmacyDetails = false;
    this.isGetDirections = false;
    this.origin = {};
    this.destination = {};
  }

  getDirections() {
    this.origin = {
      lat: this.latitude,
      lng: this.longitude
    };
    this.destination = {
      lat: this.selectedPharmacy.latitude,
      lng: this.selectedPharmacy.longitude
    };
    this.isGetDirections = true;
  }

  getCoordinates(pharmacyData) {
    this.pharmacyLocationsService
      .geocodeAddress(`${pharmacyData?.name} , ${pharmacyData?.streetAddress}`)
      .pipe(takeUntil(this.pharmacyLocationsRxComponent$))
      .subscribe((location: Location) => {
        if (location) {
          const marker = {
            latitude: location?.lat,
            longitude: location?.lng,
            data: pharmacyData
          };
          this.markers.push(marker);
          if (this.markers.length === this.pharmacies.length) {
            this.markers.forEach((ele) => {
              this.getDistance(ele);
            });
            this.markers = this.markers.sort((a, b) => this.pharmacySort(a?.data, b?.data));
          }
        }
      });
  }

  private pharmacySort(a: any, b: any): number {
    // Compare on distance if you can
    if (a.pharmacyDistanceFromLocation !== null || b.pharmacyDistanceFromLocation !== null) {
      if (a.pharmacyDistanceFromLocation === b.pharmacyDistanceFromLocation) {
        return 0;
      }
      if (a.pharmacyDistanceFromLocation > b.pharmacyDistanceFromLocation) {
        return 1;
      }
      return -1;
    }
    // Otherwise sort by alpha on the name
    return a.name.localeCompare(b.name);
  }

  public ngOnDestroy(): void {
    this.pharmacyLocationsRxComponent$.next();
    this.pharmacyLocationsRxComponent$.unsubscribe();
  }
}

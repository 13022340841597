import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import AccountService from 'app/core/auth/account.service';
import ConfirmationDialogService from 'app/core/confirmation-dialog/confirmation-dialog.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionStorageService } from 'ngx-webstorage';
import PrescriptionRxService from '../prescription-rx/prescription-rx.service';
import CardInfoRxService from './card-info-rx.service';

@Component({
  selector: 'jhi-card-info-rx',
  templateUrl: './card-info-rx.component.html',
  styleUrls: ['./card-info-rx.scss']
})
export default class CardInfoRxComponent implements OnInit, OnDestroy {
  private cardInfoRxComponent$: Subject<void> = new Subject<void>();

  currentAccount: any;

  rxPatientId: any;

  show: any;

  maxCardLimit: any;

  allowCard: any;

  paymentCardList: any[];

  dependentListFromApi: any;

  showDependents = false;

  isUnauthorized = false;

  selectedPatient;

  selectedDependentId;

  isDisabledSelectbox = true;

  isEdit = false;

  errorForMinor = false;

  dependentData;

  constructor(
    protected cardInfoService: CardInfoRxService,
    protected accountService: AccountService,
    protected router: Router,
    private confirmationDialogService: ConfirmationDialogService,
    private $sessionStorage: SessionStorageService,
    private prescriptionService: PrescriptionRxService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.accountService.identity().then((account) => {
      this.currentAccount = account;
      this.rxPatientId = account.enterpriseRxPatientId;
      this.selectedPatient = account.enterpriseRxPatientId;
      this.isDisabledSelectbox = true;
      if (this.currentAccount.peopleSoftId && parseInt(this.currentAccount.peopleSoftId, 10) !== 0) {
        this.getDependentList();
      } else {
        this.getPaymentCardList(this.rxPatientId);
      }
    });
    this.show = false;
  }

  loadDependentCards(event) {
    const index = event.target.selectedIndex;
    this.selectedDependentId = event.target.options[event.target.selectedIndex].value;
    this.dependentData = this.dependentListFromApi[index - 1];
    this.calculateAge(this.selectedDependentId);
    if (this.isUnauthorized === false) {
      this.maxCardLimit = false;
      this.getPaymentCardList(this.selectedPatient);
    }
    return this.isUnauthorized;
  }

  calculateAge(dependentId) {
    this.show = false;
    this.isDisabledSelectbox = true;
    this.dependentListFromApi.forEach((ele) => {
      let age = 0;
      if (parseInt(ele.id, 10) === parseInt(dependentId, 10)) {
        age = moment().diff(ele.birthDate, 'month');
        if (age / 12 < 18 || ele.sequence === null) {
          this.selectedPatient = ele.patientId;
          this.rxPatientId = ele.patientId;
          this.isUnauthorized = false;
          return false;
        }
        this.isDisabledSelectbox = false;
        this.show = true;
        this.isUnauthorized = true;
        this.errorForMinor = false;
        return true;
      }
    });
    return false;
  }

  getPaymentCardList(patientId) {
    this.paymentCardList = [];
    this.cardInfoService
      .getCardList(patientId)
      .pipe(takeUntil(this.cardInfoRxComponent$))
      .subscribe(
        (resp) => {
          this.show = true;
          this.paymentCardList = resp.creditCards ? resp.creditCards : [];
          this.isDisabledSelectbox = false;
        },
        (error) => {
          this.show = true;
          this.isDisabledSelectbox = false;
          if (error) {
            if (error.error.text === 'Card not found.') {
              this.allowCard = true;
            } else if (error.error.message === 'Error getting Card information.') {
              this.allowCard = false;
              this.isUnauthorized = true;
              this.errorForMinor = true;
            }
          }
        }
      );
  }

  deleteCardPayment(i) {
    const popupmsg = 'Are you sure you want to remove this card ?';
    this.confirmationDialogService
      .confirm('Delete Card?', popupmsg, 'DELETE', 'CANCEL', 'lg')
      .then((confirmed) => {
        if (confirmed) {
          const deletePayload = {
            firstName: this.paymentCardList[i]?.firstName,
            cardType: this.paymentCardList[i]?.cardType,
            expirationDate: this.paymentCardList[i]?.expirationDate,
            last4Digits: this.paymentCardList[i]?.last4Digits,
            primaryUserPatientId: this.currentAccount.enterpriseRxPatientId
          };
          this.cardInfoService
            .deleteCardPayment(deletePayload, this.selectedPatient)
            .pipe(takeUntil(this.cardInfoRxComponent$))
            .subscribe(() => {
              this.paymentCardList.splice(i, 1);
              this.maxCardLimit = false;
            });
        }
      })
      .catch(() => console.warn('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  addPaymentMethod() {
    this.isEdit = false;
    this.$sessionStorage.store('isEdit', this.isEdit);
    if (this.allowCard) {
      this.router.navigate([`card-info-rx/new/${this.selectedPatient}`]);
    } else if (this.paymentCardList.length < 3) {
      this.maxCardLimit = false;
      this.router.navigate([`card-info-rx/new/${this.selectedPatient}`]);
    } else if (this.paymentCardList.length > 2) {
      this.maxCardLimit = true;
    }
  }

  editCardPayment(i) {
    const cardInfo = this.paymentCardList[i];
    this.isEdit = true;
    this.$sessionStorage.store('isEdit', this.isEdit);
    this.router.navigate([`card-info-rx/${this.selectedPatient}/update`], { queryParams: { data: btoa(JSON.stringify(cardInfo)) } });
  }

  getDependentList() {
    this.prescriptionService
      .getDependentList(this.currentAccount.peopleSoftId)
      .pipe(takeUntil(this.cardInfoRxComponent$))
      .subscribe((resp) => {
        this.dependentListFromApi = resp;
        if (this.dependentListFromApi.length > 0) {
          this.showDependents = true;
          this.dependentListFromApi.forEach((dep) => {
            if (dep.sequence === null) {
              this.selectedDependentId = dep.id;
            }
          });
        }
        this.getPaymentCardList(this.rxPatientId);
      });
  }

  public ngOnDestroy(): void {
    this.cardInfoRxComponent$.next();
    this.cardInfoRxComponent$.unsubscribe();
  }

  public registerForMailOrder() {
    this.$sessionStorage.store('minorDependent', JSON.stringify(this.dependentData));
    this.router.navigate(['/profile-rx/mailorder']);
  }
}

import { EventEmitter, Injectable, Output } from '@angular/core';
import { JhiLanguageService } from 'ng-jhipster';
import { SessionStorageService } from 'ngx-webstorage';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import Account from 'app/core/user/account.model';

@Injectable()
export default class AccountService {
  @Output() changeUserName: EventEmitter<any> = new EventEmitter<any>();

  private userIdentity: any;

  private authenticated = false;

  private authenticationState = new Subject<any>();

  public slideScreen = new BehaviorSubject<any>(false);

  public maintenanceScreen = new BehaviorSubject<any>(false);

  public isMaintenance = new BehaviorSubject<any>({});

  public userNameBySubject = new BehaviorSubject<any>('');

  constructor(private languageService: JhiLanguageService, private sessionStorage: SessionStorageService, private http: HttpClient) {}

  fetch(): Observable<HttpResponse<Account>> {
    return this.http.get<Account>(`${SERVER_API_URL}api/account`, { observe: 'response' });
  }

  save(account: any): Observable<HttpResponse<any>> {
    return this.http.post(`${SERVER_API_URL}api/account`, account, { observe: 'response' });
  }

  authenticate(identity) {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[]): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }

    return authorities.some((authority: string) => this.userIdentity.authorities.includes(authority));
  }

  hasAuthority(authority: string): Promise<boolean> {
    if (!this.authenticated) {
      return Promise.resolve(false);
    }

    return this.identity().then((id) => Promise.resolve(id.authorities && id.authorities.includes(authority)), () => Promise.resolve(false));
  }

  identity(force?: boolean): Promise<Account> {
    if (force) {
      this.userIdentity = undefined;
    }

    // check and see if we have retrieved the userIdentity data from the server.
    // if we have, reuse it by immediately resolving
    if (this.userIdentity) {
      return Promise.resolve(this.userIdentity);
    }

    if (this.sessionStorage.retrieve('authenticationToken') !== null) {
      // retrieve the userIdentity data from the server, update the identity object, and then resolve.
      return this.fetch()
        .toPromise()
        .then((response) => {
          const account: Account = response.body;
          if (account) {
            this.userIdentity = account;
            this.authenticated = true;
            // After retrieve the account info, the language will be changed to
            // the user's preferred language configured in the account setting
            if (this.userIdentity.langKey) {
              const langKey = this.sessionStorage.retrieve('locale') || this.userIdentity.langKey;
              this.languageService.changeLanguage(langKey);
            }
          } else {
            this.userIdentity = null;
            this.authenticated = false;
          }
          this.authenticationState.next(this.userIdentity);
          return this.userIdentity;
        })
        .catch(() => {
          this.userIdentity = null;
          this.authenticated = false;
          this.authenticationState.next(this.userIdentity);
          this.sessionStorage.clear('displayName');
          this.changeUserName.emit('');
          return null;
        });
    }
    this.userIdentity = null;
    this.authenticated = false;
    return Promise.resolve(this.userIdentity);
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return this.userIdentity !== undefined;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): string {
    return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
  }

  getUserInfo(): Observable<any> {
    return this.sessionStorage.retrieve('accountinfo');
  }

  assistForgotUsername(info: any): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/rx/rxProfile/forgotUserName`, info);
  }

  isSystemMaintenanceModeOn() {
    const maintenanceModeUrl = `${SERVER_API_URL}api/notification/wallnotification`;
    return this.http.get(maintenanceModeUrl);
  }

  getEmitter() {
    return this.changeUserName;
  }
}

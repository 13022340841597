import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DATE_FORMAT } from 'app/shared/constants/input.constants';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { IPrescriptionTxReqRx } from 'app/shared/model/prescription-tx-req-rx.model';

type EntityResponseType = HttpResponse<IPrescriptionTxReqRx>;
type EntityArrayResponseType = HttpResponse<IPrescriptionTxReqRx[]>;

@Injectable({ providedIn: 'root' })
export default class PrescriptionTxReqRxService {
  public resourceUrl = `${SERVER_API_URL}api/prescription-tx-reqs`;

  constructor(protected http: HttpClient) {}

  public getPharmacyList() {
    const pharmacyListUrl = `${SERVER_API_URL}api/rx/pharmacy`;
    return this.http.get(pharmacyListUrl, { observe: 'response' });
  }

  transferPrescriptions(transPresData: any): Observable<any> {
    const transferPrescriptionUrl = `${SERVER_API_URL}api/rx/prescription-tx-reqs`;
    return this.http.post(transferPrescriptionUrl, transPresData);
  }

  create(prescriptionTxReq: IPrescriptionTxReqRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(prescriptionTxReq);
    return this.http
      .post<IPrescriptionTxReqRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(prescriptionTxReq: IPrescriptionTxReqRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(prescriptionTxReq);
    return this.http
      .put<IPrescriptionTxReqRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IPrescriptionTxReqRx>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IPrescriptionTxReqRx[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(prescriptionTxReq: IPrescriptionTxReqRx): IPrescriptionTxReqRx {
    const copy: IPrescriptionTxReqRx = {
      ...prescriptionTxReq,
      dateOfBirth:
        prescriptionTxReq.dateOfBirth != null && prescriptionTxReq.dateOfBirth.isValid()
          ? moment(prescriptionTxReq.dateOfBirth.format(DATE_FORMAT))
          : null
    };
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateOfBirth = res.body.dateOfBirth != null ? moment(res.body.dateOfBirth) : null;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((prescriptionTxReq: IPrescriptionTxReqRx) => {
        prescriptionTxReq.dateOfBirth = prescriptionTxReq.dateOfBirth != null ? moment(prescriptionTxReq.dateOfBirth) : null;
      });
    }
    return res;
  }
}

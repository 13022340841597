import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import BjcrxSharedModule from 'app/shared/shared.module';
import INFORMATION_ROUTE from './information.route';
import InformationComponent from './information.component';

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild([INFORMATION_ROUTE])],
  declarations: [InformationComponent]
})
export default class BjcrxInfromationModule {}

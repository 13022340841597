import {
  Component, OnInit, AfterViewInit, Renderer2, ElementRef, OnDestroy
} from '@angular/core';
import {
  FormBuilder, Validators, ValidatorFn, FormGroup, ValidationErrors
} from '@angular/forms';
import {
  ActivatedRoute, Router
} from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import LoginModalService from 'app/core/login/login-modal.service';
import AccountService from 'app/core/auth/account.service';
import ProfileRxService from 'app/entities/profile-rx/profile-rx.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  passwordErrorCannotContainFirstOrLastName,
  passwordErrorCannotContainUsername,
  passwordErrorMinimum14Chars,
  passwordErrorMustContainLowercase,
  passwordErrorMustContainNumericOrSpecialChar,
  passwordErrorMustContainUppercase,
  passwordErrorNoSpace
} from 'app/app.constants';
import PasswordResetService from './password-reset.service';

@Component({
  selector: 'jhi-password-reset',
  templateUrl: './password-reset.component.html'
})
export default class PasswordResetComponent implements OnInit, OnDestroy, AfterViewInit {
  private passwordResetComponent$: Subject<void> = new Subject<void>();

  doNotMatch: string;

  error;

  keyMissing: boolean;

  success;

  modalRef: NgbModalRef;

  key: string;

  showmessage;

  passwordForm: any;

  errorType: number;

  message;

  submitted = false;

  showLoginBtn = false;

  currentAccount;

  profile;

  constructor(
    private passwordResetService: PasswordResetService,
    private loginModalService: LoginModalService,
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private accountService: AccountService,
    private profileService: ProfileRxService
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.passwordResetComponent$)).subscribe((params) => {
      this.key = params?.key;
      if (this.key) {
        this.key = this.key.replace(/ /g, '+');
      }
    });
    this.accountService.identity().then((account) => {
      if (account) {
        this.currentAccount = account;
        this.profileService
          .getProfile(this.currentAccount.enterpriseRxPatientId)
          .pipe(takeUntil(this.passwordResetComponent$))
          .subscribe((res) => {
            this.profile = res;
            const userdetails = {
              userName: this.currentAccount.username,
              firstName: this.profile.firstName,
              lastName: this.profile.lastName
            };
            this.passwordForm = this.fb.group(
              {
                resetPassword: ['', [Validators.required]],
                newPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
                confirmPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]]
              },
              { validators: this.validateForm(userdetails) }
            );
          });
      }
    });
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('#password') != null) {
      // this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#password'), 'focus', []);
      this.elementRef.nativeElement.querySelector('#password')?.focus.apply(this.elementRef.nativeElement.querySelector('#password'), []);
    }
  }

  validateForm(userDetails): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const password = group.controls?.newPassword;
      const confirmpass = group.controls?.confirmPassword;
      let message: string;

      if (password.value && confirmpass.value) {
        const isMatch = password.value === confirmpass.value;
        if (!isMatch && password.valid) {
          confirmpass.setErrors({ equalValue: 'error' });
          message = 'error';
        }
      }

      const passValue = password.value.toLocaleLowerCase();
      if (password.value) {
        if (!password.value.match('^(?=.*?[A-Z])')) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainUppercase });
        } else if (!password.value.match('^(?=.*?[a-z])')) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainLowercase });
        } else if (!(password.value.match('^(?=.*?[0-9])') || password.value.match('^(?=.*?[-$_!%*&])'))) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainNumericOrSpecialChar });
        }
        if (password.value.match('^[ ]*$')) {
          message = 'error';
          password.setErrors({ error: passwordErrorNoSpace });
        } else if (password.value.length < 14) {
          message = 'error';
          password.setErrors({ error: passwordErrorMinimum14Chars });
        }
      }
      if (passValue) {
        if (userDetails.userName) {
          if (passValue.match(new RegExp(userDetails.userName.toLocaleLowerCase()))) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainUsername });
          }
        }
        if (userDetails && userDetails.firstName) {
          if (passValue.match(userDetails.firstName.toLocaleLowerCase())) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainFirstOrLastName });
          }
        }

        if (userDetails && userDetails.lastName) {
          if (passValue.match(userDetails.lastName.toLocaleLowerCase())) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainFirstOrLastName });
          }
        }
      }

      if (message) {
        return { equalValue: message };
      }

      return null;
    };
  }

  finishReset() {
    this.submitted = true;
    if (!this.passwordForm.invalid) {
      const payload = {
        resetPassword: this.passwordForm.value.resetPassword,
        newPassword: this.passwordForm.value.newPassword,
        patientId: this.currentAccount.enterpriseRxPatientId
      };
      this.passwordResetService
        .updatePassword(payload)
        .pipe(takeUntil(this.passwordResetComponent$))
        .subscribe(
          (res) => {
            if (res.message === 'Password updated successfully') {
              this.success = true;
              this.message = res.message;
              this.showmessage = true;
              this.reloadUserAccount();
            } else {
              this.error = true;
              this.showmessage = true;
              this.message = res.message;
            }
          },
          (error) => {
            this.error = true;
            this.showmessage = true;
            this.message = error.error.detail ? error.error.detail : 'There was a problem resetting your password. Please try again';
          }
        );
    }
  }

  reloadUserAccount() {
    this.accountService.identity(true).then(() => {
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 2000);
    });
  }

  login() {
    this.router.navigate(['/account/login']);
  }

  tryagain() {
    this.showmessage = false;
    this.error = false;
    this.success = false;
    this.passwordForm.reset();
    this.submitted = false;
  }

  public ngOnDestroy(): void {
    this.passwordResetComponent$.next();
    this.passwordResetComponent$.unsubscribe();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export default class PrescriptionListFilterPipe implements PipeTransform {
  transform(lists: any[], filterText: any): any {
    if (filterText == null) return lists;

    return lists.filter((filter) => {
      const rxNumberWithStoreId = `${filter.rxNumber}-${filter.rxFacilityID}`;
      return filter.productName.toLowerCase().indexOf(filterText.toLowerCase()) > -1 || rxNumberWithStoreId.includes(filterText);
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import BjcrxSharedModule from 'app/shared/shared.module';
import MAINTENANCE_ROUTE from './system-maintenance.route';
import SystemMaintenanceComponent from './system-maintenance.component';

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild([MAINTENANCE_ROUTE])],
  declarations: [SystemMaintenanceComponent]
})
export default class BjcrxSystemMaintenanceModule {}

import { Moment } from 'moment';

export interface IPrescriptionRx {
  id?: number;
  pharmacyId?: number;
  prescriptionNumber?: string;
  lastFillDate?: Moment;
  prescriberName?: string;
  refillRemaining?: number;
  drugName?: string;
  prescriptionStatus?: string;
  refillStatus?: string;
}

export class PrescriptionRx implements IPrescriptionRx {
  constructor(
    public id?: number,
    public pharmacyId?: number,
    public prescriptionNumber?: string,
    public lastFillDate?: Moment,
    public prescriberName?: string,
    public refillRemaining?: number,
    public drugName?: string,
    public prescriptionStatus?: string,
    public refillStatus?: string
  ) {}
}

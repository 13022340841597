import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'jhi-profile-rx-question',
  templateUrl: './profile-questions.component.html',
  styleUrls: ['profile-questions.scss']
})
export default class ProfileRxQuestionComponent implements OnInit, OnDestroy {
  private profileRxQuestionComponent$: Subject<void> = new Subject<void>();

  questionId;

  slideScreen;

  profileQuestionForm = new FormGroup({
    answer: new FormControl('', [Validators.required])
  });

  constructor(private activatedRoute: ActivatedRoute, private route: Router, private accountService: AccountService) {
    this.activatedRoute.params.pipe(takeUntil(this.profileRxQuestionComponent$)).subscribe((params) => {
      this.questionId = params?.id;
    });
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.profileRxQuestionComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
  }

  onSubmit() {
    let routeTo;
    if (!this.profileQuestionForm.invalid) {
      if (this.questionId === '1') {
        if (this.profileQuestionForm.value.answer === 'yes') {
          routeTo = 'nextquestion';
        } else {
          routeTo = 'register';
        }
      } else if (this.questionId === '2') {
        if (this.profileQuestionForm.value.answer === 'yes') {
          routeTo = 'registerwithbjc';
        } else {
          routeTo = 'register';
        }
      }

      if (routeTo === 'register') {
        this.route.navigate(['profile-rx/create/guest']);
      }
      if (routeTo === 'nextquestion') {
        this.route.navigate(['/profile-rx/questions/2']);
      }
      if (routeTo === 'registerwithbjc') {
        this.route.navigate(['profile-rx/create/employee']);
      }
      this.profileQuestionForm.reset();
    }
  }

  previousState() {
    window.history.back();
  }

  public ngOnDestroy(): void {
    this.profileRxQuestionComponent$.next();
    this.profileRxQuestionComponent$.unsubscribe();
  }
}

<h1 class="pageTitle">
    Contact Us
</h1>
<div class="content m-0 ">
    <div class="row">
        <div class="col-md-6">   
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <h3 class="subtitle contact-font28">Get in touch<br/> if you need any help!</h3>                        
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 pt-3">                   
                    <h3 class="subtitle font18">CALL US AT</h3>                   
                </div>

                <div class="col-md-12 col-sm-12 pt-3">
                    <span class="icon-text">
                        <fa-icon icon='phone-alt'></fa-icon>
                        <h3 class="font16"><span class="lineheight30"><a href='tel:314-657-9000'>314-657-9000</a> OR</span><br/><span><a href='tel:1-855-525-0411'>1-855-525-0411</a></span></h3>
                    </span>
                </div>

                <div class="col-md-12 col-sm-12 pt-3">
                    <span class="icon-text">
                        <fa-icon icon='clock'></fa-icon>
                        <h3 class="font16">Monday-Friday, 8am-4:30pm</h3></span>
                </div>

                <div class="col-md-12 col-sm-12 pt-3" (click)="showDrugInformation()">
                    <span class="icon-text"> <fa-icon icon='external-link-alt'></fa-icon> <h3 class="font16"><a href="https://www.bjc.org/Services/Pharmacies" target="_blank" rel="noopener">BJC Pharmacy Services Home Page</a></h3></span>
                </div>
            </div>
        </div>
    </div>
</div>
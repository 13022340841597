import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import {
  Resolve, ActivatedRouteSnapshot, Routes
} from '@angular/router';
import { JhiResolvePagingParams } from 'ng-jhipster';
import UserRouteAccessService from 'app/core/auth/user-route-access-service';
import {
  Observable, of
} from 'rxjs';
import {
  filter, map
} from 'rxjs/operators';
import {
  NotificationMessageRx, INotificationMessageRx
} from 'app/shared/model/notification-message-rx.model';
import NotificationMessageRxService from './notification-message-rx.service';
import NotificationMessageRxComponent from './notification-message-rx.component';

@Injectable({ providedIn: 'root' })
export class NotificationMessageRxResolve implements Resolve<INotificationMessageRx> {
  constructor(private service: NotificationMessageRxService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<INotificationMessageRx> {
    const { id } = route.params;
    if (id) {
      return this.service.find(id).pipe(
        filter((response: HttpResponse<NotificationMessageRx>) => response.ok),
        map((notificationMessage: HttpResponse<NotificationMessageRx>) => notificationMessage.body)
      );
    }
    return of(new NotificationMessageRx());
  }
}

export const notificationMessageRoute: Routes = [
  {
    path: '',
    component: NotificationMessageRxComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams
    },
    data: {
      authorities: ['ROLE_USER'],
      defaultSort: 'id,asc',
      pageTitle: 'bjcrxApp.notificationMessage.home.title'
    },
    canActivate: [UserRouteAccessService]
  }
];

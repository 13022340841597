<h1 class="pageTitle" jhiTranslate="bjcrxApp.profile.create.title"></h1>

<div *ngIf="!showLoader">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-12 bjcrx-gif">
                <div class="gif-text">
                    <div class="bjc-text">
                        <img src="../../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="" />
                        <div class="laoder-text text-center">Processing...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="userType === 'guest' && !showmessage && showLoader">
    <form name="form" role="form" (ngSubmit)="validateGuest()" [formGroup]="registerForm">
        <div class="content with-action">
            <h2 class="subtitle">Let's create your account</h2>
            <h3 class="heading">Member Information</h3>
            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label class="form-control-label" for="firstName"
                        jhiTranslate="bjcrxApp.profile.create.form.firstName.label">First
                        Name</label><span class="required">*</span>
                    <input type="text" class="form-control" id="firstName" name="firstName" jhiTrimInput
                        placeholder="{{'bjcrxApp.profile.create.form.firstName.placeholder' | translate}}"
                        formControlName="firstName" autocomplete="off">
                    <div
                        *ngIf="registerForm.get('firstName').invalid && (submitted || registerForm.get('firstName').touched)">
                        <small class="form-text error-msg" *ngIf="registerForm.get('firstName').errors.required">
                            Please enter first name.
                        </small>
                        <small class="form-text error-msg" *ngIf="registerForm.get('firstName').errors.pattern">
                            Please enter only alphabetical letters. A space, hyphen, period, or apostrophe is also allowed.
                        </small>
                    </div>
                </div>  
                <div class="form-group">
                    <label class="form-control-label" for="middleInitial"
                        jhiTranslate="bjcrxApp.profile.create.form.middleInitial.label">Middle
                        Initial</label>
                    <input type="text" class="form-control" maxlength="1" id="middleInitial" name="middleInitial" jhiTrimInput
                        placeholder="{{'bjcrxApp.profile.create.form.middleInitial.placeholder' | translate}}"
                        formControlName="middleInitial" autocomplete="off">
                    <div
                        *ngIf="registerForm.get('middleInitial').invalid && (submitted || registerForm.get('middleInitial').touched)">
                        <small class="form-text error-msg" *ngIf="registerForm.get('middleInitial').errors.pattern">
                            Please enter only alphabetical letters.
                        </small>
                        <small class="form-text error-msg"
                            *ngIf="registerForm.get('middleInitial').invalid">
                            Please only enter middle initial.
                        </small>
                    </div>
                </div>             
                <div class="form-group">
                    <label class="form-control-label" for="lastName"
                        jhiTranslate="bjcrxApp.profile.create.form.lastName.label">Last
                        Name</label><span class="required">*</span>
                    <input type="text" class="form-control" id="lastName" name="lastName" jhiTrimInput
                        placeholder="{{'bjcrxApp.profile.create.form.lastName.placeholder' | translate}}"
                        formControlName="lastName" autocomplete="off">
                    <div
                        *ngIf="registerForm.get('lastName').invalid && (submitted || registerForm.get('lastName').touched)">
                        <small class="form-text error-msg" *ngIf="registerForm.get('lastName').errors.required">
                            Please enter last name.
                        </small>
                        <small class="form-text error-msg" *ngIf="registerForm.get('lastName').errors.pattern">
                            Please enter only alphabetical letters. A space, hyphen, period, or apostrophe is also allowed.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="suffix"
                        jhiTranslate="bjcrxApp.profile.create.form.suffix.label">None
                        </label>
                    <select class="form-control" id="suffix" name="suffix"
                    	formControlName="suffix" autocomplete="off">
                    		<option value="" disabled selected hidden>{{'bjcrxApp.profile.create.form.suffix.placeholder' | translate}}</option>
                        	<option value="junior">Jr.</option>
                        	<option value="senior">Sr.</option>
                        	<option value="second">II</option>
                        	<option value="third">III</option>
                    	</select>
                </div>      
                <div class="form-group date-group">
                    <label class="form-control-label" for="dateOfBirth"
                           jhiTranslate="bjcrxApp.profile.create.form.dateOfBirth.label">Date of
                        Birth</label><span class="required">*</span>
                    <div class="input-group" *ngIf="checkIsIEBrowser() === false">
                        <input type="date" class="form-control" id="dateOfBirth" name="dateOfBirth" max="9999-12-31"
                            placeholder="{{'bjcrxApp.profile.create.form.dateOfBirth.placeholder' | translate}}"
                            formControlName="dateOfBirth" ngbDatepicker #dobDp="ngbDatepicker" autocomplete="off">
                        <span class="input-group-append" (click)="dobDp.toggle()">
                            <fa-icon [icon]="'calendar-alt'"></fa-icon>
                        </span>
                    </div>

                    <div class="input-group" *ngIf="checkIsIEBrowser() === true">
                        <input type="text" tabindex="-1" class="form-control" id="dateOfBirth" name="dateOfBirth"
                               max="9999-12-31" placeholder="mm/dd/yyyy" formControlName="dateOfBirth" ngbDatepicker
						                   #dobDp="ngbDatepicker" autocomplete="off">
                        <span class="input-group-append">
                            <fa-icon [icon]="'calendar-alt'"></fa-icon>
                        </span>
                        <div class="date-pick-div" (click)="dobDp.toggle()"></div>
                    </div>

                    <div
                            *ngIf="registerForm.get('dateOfBirth').invalid && (submitted || (registerForm.get('dateOfBirth').touched && checkIsIEBrowser() === false))">
                        <small class="form-text error-msg" *ngIf="registerForm.get('dateOfBirth').errors.required">
                            Please enter date of birth.
                        </small>
                        <small class="form-text error-msg" *ngIf="registerForm.get('dateOfBirth').errors.invalidDate">
                            Please enter valid date.
                        </small>
                        <small class="form-text error-msg" *ngIf="registerForm.get('dateOfBirth').errors.futuredate">
                            Please enter valid date of birth.
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-control-label" for="last4ssn">Last 4 SSN</label><span
                        class="required">*</span>
                    <input type="text" class="form-control" maxlength="4" id="last4ssn"
                        name="last4ssn" placeholder="xxxx" formControlName="last4ssn"
                        autocomplete="off">
                    <div
                        *ngIf="registerForm.get('last4ssn').invalid && (submitted || registerForm.get('last4ssn').touched)">
                        <small class="form-text error-msg"
                            *ngIf="registerForm.get('last4ssn').invalid">
                            Please enter last 4 digits of SSN.
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-control-label" for="rxNumber"
                        jhiTranslate="bjcrxApp.profile.create.form.rxNumber.label">Prescription Number</label>
                    <small id="prescriptionInfo" class="form-text">Enter your prescription number
                        include all numbers before and after the dash </small>
                    <div class="input-group">
                        <input type="number" class="form-control" maxl="20" id="rxNumber" name="rxNumber"
                            jhi-appNumberMaxlength
                            formControlName="rxNumber" autocomplete="off">
                        <span class="input-group-addon">-</span>
                        <input type="number" class="form-control" maxl="2" jhi-appNumberMaxlength
                            formControlName="storeId" autocomplete="off" />
                    </div>
                </div>

                <div class="form-group m-0 p-0"
                    *ngIf="registerForm.get('rxNumber').invalid && (submitted || registerForm.get('rxNumber').touched)">
                    <small class="form-text error-msg" *ngIf="registerForm.get('rxNumber').errors.pattern">
                        Please enter valid rxnumber.
                    </small>
                    <small class="form-text error-msg" *ngIf="registerForm.get('rxNumber').errors.requiredStoreId">
                        {{ registerForm.get('rxNumber').errors.requiredStoreId }}
                    </small>

                </div>

                <p class="font14 info-text">If you don’t have an existing prescription number select continue and you will be prompted for more information</p>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="col-lg-3 col-md-6 p-0">
                <button type="submit" [disabled]="!registerForm.touched"
                    class="btn btn-enable btn-block">CONTINUE</button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="userType === 'employee' && !showmessage && showLoader">
    <form name="form" role="form" (ngSubmit)="registerWithBJCDetails()" [formGroup]="registerWithBJC">
        <div class="content  with-action">
            <h2 class="subtitle">Welcome. Let's verify your identity.</h2>
            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label class="form-control-label" for="bjcNtusername"
                        jhiTranslate="bjcrxApp.profile.create.form.bjcNtusername.label">BJC-NT Username</label><span
                        class="required">*</span>
                    <input type="text" class="form-control" id="bjcNtusername"
                        [ngClass]="{ 'is-invalid':registerWithBJC.get('bjcNtusername').invalid && (submitted || registerWithBJC.get('bjcNtusername').touched) }"
                        name="bjcNtusername" jhiTrimInput
                        placeholder="{{'bjcrxApp.profile.create.form.bjcNtusername.placeholder' | translate}}"
                        formControlName="bjcNtusername" required autocomplete="off">
                    <div
                        *ngIf="registerWithBJC.get('bjcNtusername').invalid && (submitted || registerWithBJC.get('bjcNtusername').touched)">
                        <small class="form-text error-msg" *ngIf="registerWithBJC.get('bjcNtusername').errors.required">
                            Please enter valid username.
                        </small>
                        <small class="form-text error-msg"
                            *ngIf="registerWithBJC.get('bjcNtusername').errors.minlength">
                            Please enter valid username.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="bjcNtpassword"
                        jhiTranslate="bjcrxApp.profile.create.form.bjcNtpassword.label">BJC-NT Username</label><span
                        class="required">*</span>
                    <input type="password" class="form-control" id="bjcNtpassword"
                        [ngClass]="{ 'is-invalid' : registerWithBJC.get('bjcNtpassword').invalid && (submitted || registerWithBJC.get('bjcNtpassword').touched) }"
                        name="bjcNtpassword" jhiTrimInput
                        placeholder="{{'bjcrxApp.profile.create.form.bjcNtpassword.placeholder' | translate}}"
                        formControlName="bjcNtpassword" required>
                    <div
                        *ngIf="registerWithBJC.get('bjcNtpassword').invalid && (submitted || registerWithBJC.get('bjcNtpassword').touched)">
                        <small class="form-text error-msg" *ngIf="registerWithBJC.get('bjcNtpassword').errors.required">
                            Please enter valid password.
                        </small>
                        <small class="form-text error-msg"
                            *ngIf="registerWithBJC.get('bjcNtpassword').errors.minlength">
                            Please enter valid password.
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="col-lg-3 col-md-6 p-0">
                <button type="submit" [disabled]="!registerWithBJC.valid"
                    class="btn btn-enable btn-block">CONTINUE</button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="showmessage && showLoader">
    <div class="content">
        <div class="col-md-4">
            <div class="error-message">
                {{message}}
            </div>
            <div class="or-message-link" (click)="redirectToLexusNexus()" *ngIf="showLexusNexusOption">
                Or, Identify by answering few questions
            </div>
        </div>
    </div>
    <div class="row action m-0" *ngIf="showTryAgain" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
        </div>
    </div>
    <div class="row action m-0" *ngIf="showRegisterForMailOrder" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" (click)="registerForMailOrder()" class="btn btn-enable btn-block">REGISTER YOUR
                ACCOUNT</button>
        </div>
    </div>
</div>

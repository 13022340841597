<div *ngIf="!showmessage && !apiCalled">
    <form name="form" role="form" (ngSubmit)="requestReset()" [formGroup]="resetRequestForm">
        <div class="content with-action">
            <div class="row">
                <a routerLink="/">
                    <img src="../../../../content/images/bjc-blue-logo.png" alt="blue logo">
                </a>
            </div>
            <h2 class="subtitle">Forgot Password</h2>
            <h2 class="heading">Please enter your details</h2>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="form-group">
                        <label class="form-control-label" for="username">Username</label><span class="required">*</span>
                        <input type="text" class="form-control" id="username" name="username" placeholder="username"
                            formControlName="username" autocomplete="off">
                        <div
                            *ngIf="resetRequestForm.get('username').invalid && (submitted || resetRequestForm.get('username').touched)">
                            <small class="form-text error-msg" *ngIf="resetRequestForm.get('username').errors.required">
                                Your username is required.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="dateOfBirth">Date of
                            Birth</label><span class="required">*</span>
                        <div class="input-group" *ngIf="checkIsIEBrowser() === false">
                            <input type="date" class="form-control" id="dob" name="dob" placeholder="Date of Birth"
                                formControlName="dob" max="9999-12-31" ngbDatepicker #dobDp="ngbDatepicker"
                                autocomplete="off">
                            <span class="input-group-append calendar" (click)="dobDp.toggle()">
                                <fa-icon [icon]="'calendar-alt'"></fa-icon>
                            </span>
                        </div>

                        <div class="input-group" *ngIf="checkIsIEBrowser() === true">
                            <input type="text" tabindex="-1" class="form-control" id="dob" name="dob" placeholder="mm/dd/yyyy"
                                formControlName="dob" max="9999-12-31" ngbDatepicker #dobDp="ngbDatepicker"
                                autocomplete="off">
                            <span class="input-group-append calendar">
                                <fa-icon [icon]="'calendar-alt'"></fa-icon>
                            </span>
                            <div class="date-pick-div" (click)="dobDp.toggle()"></div>
                        </div>

                        <div
                            *ngIf="resetRequestForm.get('dob').invalid && (submitted || (resetRequestForm.get('dob').touched && checkIsIEBrowser() === false))">
                            <small class="form-text error-msg" *ngIf="resetRequestForm.get('dob').errors.required">
                                Please enter date of birth.
                            </small>
                            <small class="form-text error-msg" *ngIf="resetRequestForm.get('dob').errors.invalidDate">
                                Please enter valid date.
                            </small>
                            <small class="form-text error-msg" *ngIf="resetRequestForm.get('dob').errors.futuredate">
                                Please enter valid date of birth.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="action-statement col-xl-12 col-lg-12 col-md-12">
                <p>If you have an email address on file, an email containing a password reset link will be sent to 
                   your email account.  If you do not remember any of this information, or you do not have a valid 
                   email address on file, you will have to contact the Pharmacy at 314-657-9000 OR 1-855-525-0411 
                   to help you regain access to your BJCRx account. It is important for you to understand that 
                   there is a potential risk that email messages containing your information may be intercepted 
                   by a third party.</p>
            </div>
            <div class="col-lg-3 col-md-6 p-0">
                <button type="submit" [disabled]="!resetRequestForm.touched" class="btn btn-enable btn-block">CONTINUE</button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="!showmessage && apiCalled">
    <div class="content with-action">
        <div class="col-lg-4 col-md-6 bjcrx-gif">
            <div class="bjc-text">
                <img src="../../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="">
                <div class="laoder-text text-center">Processing...</div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showmessage">
    <div class="content">
        <div class="col-lg-4 col-md-6">
            <div [ngClass]="{'success-message':success, 'error-message':error}">
                {{message}}
            </div>
        </div>
    </div>
    <div class="row action m-0" *ngIf="error" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
        </div>
    </div>
    <div class="row action m-0" *ngIf="success" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" (click)="login()" class="btn btn-enable btn-block">LOGIN</button>
        </div>
    </div>
</div>
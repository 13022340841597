import { Route } from '@angular/router';
import ActivateComponent from './activate.component';

const activateRoute: Route = {
  path: 'activate',
  component: ActivateComponent,
  data: {
    authorities: [],
    pageTitle: 'activate.title'
  }
};

export default activateRoute;

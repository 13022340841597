import { NgModule } from '@angular/core';
import PhoneMaskDirective from 'app/core/directive/phone-mask.directive';
import FormDirective from 'app/core/directive/form.directive';
import DigitOnlyDirective from 'app/core/directive/digit-only.directive';
import NumberMaxlengthDirective from 'app/core/directive/number-maxlength.directive';
import NotificationConsentComponent from 'app/account/notification-consent/notification-consent.component';
import PreventDoubleClickDirective from 'app/core/directive/prevent-double-click.directive';
import DebounceClickDirective from 'app/core/directive/debounce-click.directive';
import TrimInputDirective from 'app/core/directive/trim-input.directive';
import HasAnyAuthorityDirective from './auth/has-any-authority.directive';
import JhiLoginModalComponent from './login/login.component';
import JhiAlertErrorComponent from './alert/alert-error.component';
import JhiAlertComponent from './alert/alert.component';
import FindLanguageFromKeyPipe from './language/find-language-from-key.pipe';
import BjcrxSharedLibsModule from './shared-libs.module';

@NgModule({
  imports: [BjcrxSharedLibsModule],
  declarations: [
    FindLanguageFromKeyPipe,
    JhiAlertComponent,
    JhiAlertErrorComponent,
    JhiLoginModalComponent,
    HasAnyAuthorityDirective,
    PhoneMaskDirective,
    FormDirective,
    DigitOnlyDirective,
    NumberMaxlengthDirective,
    NotificationConsentComponent,
    PreventDoubleClickDirective,
    DebounceClickDirective,
    TrimInputDirective
  ],
  entryComponents: [JhiLoginModalComponent],
  exports: [
    BjcrxSharedLibsModule,
    FindLanguageFromKeyPipe,
    JhiAlertComponent,
    JhiAlertErrorComponent,
    JhiLoginModalComponent,
    HasAnyAuthorityDirective,
    PhoneMaskDirective,
    FormDirective,
    DigitOnlyDirective,
    NumberMaxlengthDirective,
    NotificationConsentComponent,
    PreventDoubleClickDirective,
    DebounceClickDirective,
    TrimInputDirective
  ]
})
export default class BjcrxSharedModule {}

import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import {
  FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors, AbstractControl
} from '@angular/forms';
import { SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  passwordErrorCannotContainFirstOrLastName,
  passwordErrorCannotContainUsername,
  passwordErrorMinimum14Chars,
  passwordErrorMustContainLowercase,
  passwordErrorMustContainNumericOrSpecialChar,
  passwordErrorMustContainUppercase,
  passwordErrorNoSpace
} from 'app/app.constants';
import ProfileCreateDTO from 'app/shared/model/profile-create.model';
import Register from './register.service';

@Component({
  selector: 'jhi-register',
  templateUrl: './register.component.html'
})
export default class RegisterComponent implements OnInit, OnDestroy {
  private registerComponent$: Subject<void> = new Subject<void>();

  doNotMatch: string;

  error: string;

  errorUserExists: string;

  success: boolean;

  failure: boolean;

  pending = false;

  message: string;

  errorEmailExists: string;

  showTermsConditions: boolean;

  submitted = false;

  userdetail: ProfileCreateDTO;

  registerProfileForm: FormGroup;

  showmessage = false;

  slideScreen;

  patientRegistrationInfo: any;

  constructor(
    private registerService: Register,
    private $sessionStorage: SessionStorageService,
    private router: Router,
    private accountService: AccountService
  ) {
    const { state } = this.router.getCurrentNavigation().extras;
    if (state) {
      this.patientRegistrationInfo = JSON.parse(state.payload);
      /**
       * We are deleting middleInitial and suffix field from payload because backend DTO does not contain these two fields.
       * To avoid the exception it is removed here before passing it to API call.
       *
       * For reference of this issue please check (BJCRX-93)
       */
      console.warn('Deleting middleInitial and suffix from payload to avoid exception as Backend does not need these two fields');
      delete this.patientRegistrationInfo?.middleInitial;
      delete this.patientRegistrationInfo?.suffix;
    }
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.registerComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.success = false;
    this.showmessage = false;
    this.failure = false;
    this.showTermsConditions = false;
    if (this.$sessionStorage.retrieve('acdetails')) {
      const sessionDetails = JSON.parse(this.$sessionStorage.retrieve('acdetails'));
      this.userdetail = sessionDetails;
      this.userdetail.patientId = sessionDetails.patientId;
    }
    this.registerProfileForm = new FormGroup(
      {
        accountUsername: new FormControl('', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(50),
          Validators.pattern('^[a-zA-Z0-9-_]+$')
        ]),
        accountPassword: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required]),
        primaryEmailAddress: new FormControl('', [Validators.required, Validators.email]),
        primaryPhoneNum: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)])
      },
      this.validateForm(this.userdetail)
    );
    let email;
    if (this.userdetail && this.userdetail.publicAuthenticationDTO.primaryEmailAddress) {
      email = this.userdetail.publicAuthenticationDTO.primaryEmailAddress;
    } else if (this.$sessionStorage.retrieve('primaryEmail')) {
      email = JSON.parse(this.$sessionStorage.retrieve('primaryEmail'));
    } else if (this.patientRegistrationInfo) {
      email = this.patientRegistrationInfo.primaryEmailAddress;
    } else {
      email = '';
    }
    this.registerProfileForm.get('primaryEmailAddress').setValue(email);

    let phoneNum;
    if (this.userdetail && this.userdetail.publicAuthenticationDTO.phoneNum) {
      phoneNum = this.userdetail.publicAuthenticationDTO.phoneNum;
    } else {
      phoneNum = '';
    }
    this.registerProfileForm.get('primaryPhoneNum').setValue(phoneNum);
  }

  validatePassword(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};
    if (control.value) {
      if (!control.value.match('^(?=.*?[A-Z])')) {
        errors.error = passwordErrorMustContainUppercase;
      } else if (!control.value.match('^(?=.*?[a-z])')) {
        errors.error = passwordErrorMustContainLowercase;
      } else if (!(control.value.match('^(?=.*?[0-9])') || control.value.match('^(?=.*?[-$_!%*&])'))) {
        errors.error = passwordErrorMustContainNumericOrSpecialChar;
      }
      if (control.value.match('^[ ]*$')) {
        errors.error = passwordErrorNoSpace;
      } else if (control.value.length < 14) {
        errors.error = passwordErrorMinimum14Chars;
      }
    }
    return errors;
  }

  validateForm(userDetails): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const password = group.controls?.accountPassword;
      const confirmpass = group.controls?.confirmPassword;
      const userName = group.controls?.accountUsername;
      let message: string;

      if (password.value && confirmpass.value) {
        const isMatch = password.value === confirmpass.value;
        if (!isMatch && password.valid) {
          confirmpass.setErrors({ equalValue: 'error' });
          message = 'error';
        } else {
          confirmpass.setErrors(null);
        }
      }

      const passValue = password.value.toLocaleLowerCase();
      if (password.value) {
        if (!password.value.match('^(?=.*?[A-Z])')) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainUppercase });
        } else if (!password.value.match('^(?=.*?[a-z])')) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainLowercase });
        } else if (!(password.value.match('^(?=.*?[0-9])') || password.value.match('^(?=.*?[-$_!%*&])'))) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainNumericOrSpecialChar });
        }
        if (password.value.match('^[ ]*$')) {
          message = 'error';
          password.setErrors({ error: passwordErrorNoSpace });
        } else if (password.value.length < 14) {
          message = 'error';
          password.setErrors({ error: passwordErrorMinimum14Chars });
        }
      }
      if (passValue) {
        if (userName.touched) {
          if (passValue.match(new RegExp(userName.value.toLocaleLowerCase())) && userName.valid) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainUsername });
          }
        }
        if (userDetails && userDetails.publicAuthenticationDTO && userDetails.publicAuthenticationDTO.firstName) {
          if (
            passValue.match(userDetails.publicAuthenticationDTO.firstName.toLocaleLowerCase()) ||
            passValue.match(userDetails.publicAuthenticationDTO.lastName.toLocaleLowerCase())
          ) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainFirstOrLastName });
          }
        }
      }

      if (message) {
        return { equalValue: message };
      }

      return null;
    };
  }

  register() {
    this.submitted = true;
    if (!this.registerProfileForm.invalid) {
      let publicAuthenticationDTO = null;
      if (this.userdetail && this.userdetail.publicAuthenticationDTO) {
        publicAuthenticationDTO = {
          firstName: this.userdetail.publicAuthenticationDTO.firstName,
          lastName: this.userdetail.publicAuthenticationDTO.lastName,
          dateOfBirth: this.userdetail.publicAuthenticationDTO.dateOfBirth,
          last4ssn: this.userdetail.publicAuthenticationDTO.last4ssn,
          primaryEmailAddress: this.userdetail.publicAuthenticationDTO.primaryEmailAddress,
          phoneNum: this.userdetail.publicAuthenticationDTO.phoneNum,
          rxNumberWithFacilityId: this.userdetail.publicAuthenticationDTO.rxNumberWithFacilityId
        };
      }
      this.userdetail = {
        publicAuthenticationDTO,
        username: this.registerProfileForm.get('accountUsername').value,
        password: this.registerProfileForm.get('accountPassword').value,
        personId: this.userdetail && this.userdetail.personId ? this.userdetail.personId : '',
        patientId: this.userdetail && this.userdetail.patientId ? this.userdetail.patientId : null
      };
      this.showTermsConditions = true;
    }
  }

  insertCharacterAtPosition(str: string, position: number, char: string): string {
    return str.slice(0, position) + char + str.slice(position);
  }

  submitRegisterForm(event) {
    const registerAccount = {
      patientId: this.userdetail.patientId,
      personId: this.userdetail.personId,
      username: this.userdetail.username,
      password: this.userdetail.password,
      userConsent: event === 'yes' ? 1 : 0,
      publicAuthenticationDTO: this.userdetail.publicAuthenticationDTO,
      patientRegistration: this.patientRegistrationInfo ? this.patientRegistrationInfo : null
    };
    this.registerService
      .registerUser(registerAccount)
      .pipe(takeUntil(this.registerComponent$))
      .subscribe(
        (res) => {
          if (res.username && res.patientId !== null && res.patientId !== 'null') {
            const payload2 = {
              firstName: this.userdetail.publicAuthenticationDTO.firstName,
              lastName: this.userdetail.publicAuthenticationDTO.lastName,
              primaryUserPatientId: this.userdetail.patientId,
              edits: [
                {
                  emails: [
                    {
                      address: this.registerProfileForm.get('primaryEmailAddress').value,
                      rank: 1,
                      useForNotifier: true
                    }
                  ],
                  phoneNumbers: [
                    {
                      phonenumber: this.insertCharacterAtPosition(this.registerProfileForm.get('primaryPhoneNum').value, 3, '-'),
                      usage: 'Cell',
                      type: ''
                    }
                  ]
                }
              ],
              patientId: this.userdetail.patientId,
              admin: false
            };
            this.registerService
              .updateUser(payload2)
              .pipe(takeUntil(this.registerComponent$))
              .subscribe((resp) => {
                if (resp) {
                  this.showmessage = true;
                  this.success = true;
                  this.failure = false;
                  this.message = res.message;
                  this.showTermsConditions = false;
                  this.$sessionStorage.clear('acdetails');
                }
              });
          } else if (res.username) {
            this.showmessage = true;
            this.success = true;
            this.failure = false;
            this.message = res.message;
            this.showTermsConditions = false;
            this.$sessionStorage.clear('acdetails');
          }
        },
        (response) => {
          this.showTermsConditions = false;
          if (response.detail && response.detail.indexOf('Username') > -1) {
            // THIS IS NOT WORKING
            this.registerProfileForm.get('accountUsername').setErrors({ alreadyexist: response.error.detail });
          } else if (response.detail && response.detail.indexOf('password') > -1) {
            // THIS IS ALSO NOT WORKING
            this.registerProfileForm.get('accountPassword').setErrors({ error: response.error.detail });
          } else if (response.error.detail && response.error.detail.indexOf('You have a pending registration') > -1) {
            this.pending = true;
            this.showmessage = true;
            this.failure = false;
            this.message = response.error.detail;
          } else {
            this.showmessage = true;
            this.failure = true;
            this.success = false;
            this.message = response.error.detail ? response.error.detail : 'There was a problem processing your registration.';
          }
        }
      );
  }

  routeToLogin() {
    this.router.navigate(['/account/login']);
  }

  routeToHome() {
    this.router.navigate(['/']);
  }

  tryagain() {
    this.showmessage = false;
    this.failure = false;
    this.success = false;
    this.submitted = false;
    this.registerProfileForm.reset();
    this.router.navigate(['/account/register']);
  }

  public ngOnDestroy(): void {
    this.registerComponent$.next();
    this.registerComponent$.unsubscribe();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import BjcrxSharedModule from 'app/shared/shared.module';
import CustomerStatementRxComponent from './customer-statement-rx.component';

import { customerStatementRoute } from './customer-statement-rx.route';

const ENTITY_STATES = [...customerStatementRoute];

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild(ENTITY_STATES)],
  declarations: [CustomerStatementRxComponent]
})
export default class BjcrxCustomerStatementRxModule {}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'jhi-app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export default class ConfirmationDialogComponent implements OnInit {
  @Input() title: string;

  @Input() message: string;

  @Input() btnOkText: string;

  @Input() btnCancelText: string;

  @Input() hideButtons: boolean;

  @Input() timeoutOnShow: boolean;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.timeoutOnShow) {
      setTimeout(() => {
        this.activeModal.close(true);
      }, 3000);
    }
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}

export default class PersonDTO {
  firstName?: string;

  lastName?: string;

  birthDate?: string;

  ssn?: string;

  driverLicense?: string;

  driverLicenseState?: string;
}

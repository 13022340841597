export interface IPharmacyLocationsRx {
  id?: number;
  pharmacyId?: number;
  name?: string;
  streetAddress?: string;
  addressLocality?: string;
  addressRegion?: string;
  postalCode?: string;
  addressCountry?: string;
  services?: string;
  phoneNumber?: string;
  emailId?: string;
  faxNumber?: string;
  website?: string;
  landmark?: string;
  latitude?: number;
  longitude?: number;
  closingHour?: string;
  openingHour?: string;
  dayOfWeek?: string;
}

export class PharmacyLocationsRx implements IPharmacyLocationsRx {
  constructor(
    public id?: number,
    public pharmacyId?: number,
    public name?: string,
    public streetAddress?: string,
    public addressLocality?: string,
    public addressRegion?: string,
    public postalCode?: string,
    public addressCountry?: string,
    public services?: string,
    public phoneNumber?: string,
    public emailId?: string,
    public faxNumber?: string,
    public website?: string,
    public landmark?: string,
    public latitude?: number,
    public longitude?: number,
    public closingHour?: string,
    public openingHour?: string,
    public dayOfWeek?: string
  ) {}
}

import { Moment } from 'moment';

export interface ICardInfoRx {
  id?: number;
  primary?: boolean;
  cardNo?: string;
  expiryDate?: Moment;
  type?: string;
  profileIdProfileId?: string;
  profileIdId?: number;
}

export class CardInfoRx implements ICardInfoRx {
  constructor(
    public id?: number,
    public primary?: boolean,
    public cardNo?: string,
    public expiryDate?: Moment,
    public type?: string,
    public profileIdProfileId?: string,
    public profileIdId?: number
  ) {
    this.primary = this.primary || false;
  }
}

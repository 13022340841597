<ng-container *ngIf="!showTermsConditions">
    <h1 class="pageTitle" jhiTranslate="register.title"></h1>
    <div *ngIf="!showmessage">
        <form name="form" role="form" (ngSubmit)="register()" [formGroup]="registerProfileForm"
            *ngIf="registerProfileForm">
            <div class="content with-action">
                <h3 class="heading">Choose a Username</h3>
                <div class="col-lg-4 col-md-6">
                    <div class="form-group">
                        <label class="form-control-label" for="accountUsername">Account Username</label><span
                            class="required">*</span>
                        <input type="text" class="form-control" id="accountUsername" jhiTrimInput
                            [ngClass]="{ 'is-invalid':registerProfileForm.get('accountUsername').invalid && (submitted || registerProfileForm.get('accountUsername').touched) }"
                            name="accountUsername" placeholder="Account Username" formControlName="accountUsername"
                            required autocomplete="off">
                        <div *ngIf="registerProfileForm.get('accountUsername').invalid && (submitted || registerProfileForm.get('accountUsername').touched)">
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountUsername').errors.required">
                                Please enter valid username.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountUsername').errors.pattern">
                                Username should contain alphanumeric characters (only - and _ are allowed).
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountUsername').errors.minlength">
                                Username should contain at least 4 characters.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountUsername').errors.maxlength">
                                Username should be less that 50 characters.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountUsername').errors?.alreadyexist">
                                Username already exists.
                            </small>
                        </div>
                    </div>
                    <h3 class="heading">Password</h3>
                    <div class="form-group">
                        <label class="form-control-label" for="accountPassword">Password</label><span
                            class="required">*</span>
                        <input type="password" class="form-control" id="accountPassword"
                            [ngClass]="{ 'is-invalid':registerProfileForm.get('accountPassword').invalid && (submitted || registerProfileForm.get('accountPassword').touched) }"
                            name="accountPassword" placeholder="Password" formControlName="accountPassword" required>
                        <div *ngIf="registerProfileForm.get('accountPassword').invalid && (submitted || registerProfileForm.get('accountPassword').touched)">
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountPassword').errors.required">
                                Please enter valid password.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountPassword').errors.pattern">
                                Please enter valid password.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountPassword').errors.minlength">
                                Password should be at least 14 characters.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountPassword').errors.error">
                                {{ registerProfileForm.get('accountPassword').errors.error }}
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountPassword').errors.containfnln">
                                Password should not contain first name or last name.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('accountPassword').errors.containUsername">
                                Password should not contain username.
                            </small>

                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="confirmPassword">Confirm
                            Password</label><span class="required">*</span>
                        <input type="password" class="form-control" id="confirmPassword"
                            [ngClass]="{ 'is-invalid':registerProfileForm.get('confirmPassword').invalid && (submitted || registerProfileForm.get('confirmPassword').touched) }"
                            name="confirmPassword" placeholder="Confirm Password" formControlName="confirmPassword"
                            required>
                        <div
                            *ngIf="registerProfileForm.get('confirmPassword').invalid && (submitted || registerProfileForm.get('confirmPassword').touched)">
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('confirmPassword').errors.required">
                                Please enter confirm password.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="registerProfileForm.get('confirmPassword').errors.equalValue">
                                Confirm password does not match with password.
                            </small>
                        </div>
                    </div>
                    <p class="font14 info-text">Your password must be at least 14 characters long, contain at least one
                        number and have a mixture of uppercase and lowercase letters. It should not contain your username, your email, or your first
                        or last name.</p>
                    <p class="font14 info-text">You cannot reuse any of your last 5 passwords.</p>
                    <label for="" class="form-heading-label">Contact Information</label>
                    <div class="form-group">
                        <label class="form-control-label" for="primaryEmailAddress">
                            Primary Email Address</label><span class="required">*</span>
                        <input type="text" class="form-control" id="primaryEmailAddress" 
                               formControlName="primaryEmailAddress" placeholder="Enter primary email address">
                        <div 
                            *ngIf="registerProfileForm.get('primaryEmailAddress').invalid && (submitted || registerProfileForm.get('primaryEmailAddress').dirty || registerProfileForm.get('primaryEmailAddress').touched)">
                            <small class="form-text error-msg">
                                    Please enter a valid primary email address.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="primaryPhoneNum">
                            Primary Phone Number</label><span class="required">*</span>
                        <input type="text" class="form-control" id="primaryPhoneNum" 
                               formControlName="primaryPhoneNum" placeholder="Enter primary phone number">
                        <div 
                            *ngIf="registerProfileForm.get('primaryPhoneNum').invalid && (submitted || registerProfileForm.get('primaryPhoneNum').dirty || registerProfileForm.get('primaryPhoneNum').touched)">
                            <small class="form-text error-msg">
                                    Please enter a 10 digit primary phone number.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
                <div class="col-lg-3 col-md-6 p-0">
                    <button type="submit" [disabled]="!registerProfileForm.touched || (submitted && registerProfileForm.invalid)"
                        class="btn btn-enable btn-block">CONTINUE</button>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="showmessage">
        <div class="content">
            <div class="col-md-4">
                <div *ngIf="success" class="success-message">
                    {{message}}
                </div>
		            <div *ngIf="!success" class="error-message">
			            {{message}}
		            </div>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="col-lg-3 col-md-6 p-0">
                <button type="button" *ngIf="failure" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
                <button type="button" *ngIf="success" (click)="routeToLogin()" class="btn btn-enable btn-block">LOGIN</button>
		            <button type="button" *ngIf="pending" (click)="routeToHome()" class="btn btn-enable btn-block">HOME</button>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="showTermsConditions">
            <jhi-notification-consent [fromPage]="register" (submitconsent)="submitRegisterForm($event)"></jhi-notification-consent>
</ng-container>

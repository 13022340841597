import { Route } from '@angular/router';
import SystemMaintenanceComponent from './system-maintenance.component';

const MAINTENANCE_ROUTE: Route = {
  path: '',
  component: SystemMaintenanceComponent,
  data: {
    authorities: [],
    pageTitle: 'home.title'
  }
};

export default MAINTENANCE_ROUTE;

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export default class CouponRxService {
  public resourceUrl = `${SERVER_API_URL}api/coupons`;

  constructor(protected http: HttpClient) {}

  getNonBJCData(patientId) {
    return this.http.get(`${SERVER_API_URL}api/rx/rxProfile/${patientId}`);
  }

  uploadImage(payload: FormData, patientId, imageType) {
    return this.http.post(`${SERVER_API_URL}api/coupons/uploadFile/${patientId}/imageType/${imageType}`, payload);
  }

  getImage(imageURL: string): Observable<Blob> {
    return this.http.get(imageURL, { responseType: 'blob' });
  }

  deleteImage(patientId, fileName) {
    return this.http.delete(`${SERVER_API_URL}api/coupons/${patientId}/${fileName}`);
  }

  submitCoupon(payload, patientId) {
    return this.http.post(`${SERVER_API_URL}api/coupons/${patientId}`, payload);
  }
}

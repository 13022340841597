import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import BjcrxSharedModule from 'app/shared/shared.module';
import { TextMaskModule } from 'angular2-text-mask';
import PrescriptionTxReqRxComponent from './prescription-tx-req-rx.component';
import { prescriptionTxReqRoute } from './prescription-tx-req-rx.route';

const ENTITY_STATES = [...prescriptionTxReqRoute];

@NgModule({
  imports: [BjcrxSharedModule, TextMaskModule, ReactiveFormsModule, RouterModule.forChild(ENTITY_STATES)],
  declarations: [PrescriptionTxReqRxComponent]
})
export default class BjcrxPrescriptionTxReqRxModule {}

import {
  Component, EventEmitter, OnInit, Output, Input
} from '@angular/core';
import {
  ActivatedRoute, Router
} from '@angular/router';
import AccountService from 'app/core/auth/account.service';
import { SessionStorageService } from 'ngx-webstorage';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import ConfirmationDialogService from 'app/core/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'jhi-notification-consent',
  templateUrl: './notification-consent.component.html'
})
export default class NotificationConsentComponent implements OnInit {
  @Output() submitconsent = new EventEmitter();

  @Input() fromPage;

  fromNavParam;

  optedForConsent;

  currentUser;

  showLoading;

  slideScreen;

  declineMessage = "By declining this consent you won't be able to get any kind of notifications such as emails and text messages.";

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private http: HttpClient,
    private router: Router,
    private $sessionStorage: SessionStorageService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.activatedRoute.params.pipe().subscribe((params) => {
      this.fromNavParam = params?.frompage;
    });
  }

  ngOnInit() {
    this.showLoading = false;
    this.accountService.slideScreen.subscribe((data) => {
      this.slideScreen = data;
    });
    this.accountService.identity().then((account) => {
      this.currentUser = account;
    });
  }

  submit() {
    if (this.optedForConsent === 'no') {
      this.confirmationDialogService.confirm('Are you sure?', this.declineMessage, 'CONFIRM', 'CANCEL', 'lg').then((confirmed) => {
        if (confirmed) {
          this.doAction();
        }
      });
    } else {
      this.doAction();
    }
  }

  doAction() {
    if (this.fromNavParam) {
      this.saveDetails();
    } else {
      this.submitconsent.emit(this.optedForConsent);
    }
  }

  saveDetails() {
    this.showLoading = true;
    const selectedConsent = this.optedForConsent === 'yes' ? 1 : 0;
    const payload = {
      consent: selectedConsent,
      primaryUserPatientId: this.currentUser.enterpriseRxPatientId,
      userId: this.currentUser.id
    };
    this.http.post(`${SERVER_API_URL}api/user/notification-consent`, payload).subscribe((res) => {
      console.warn(res);
      if (this.fromNavParam === 'login') {
        this.router.navigate(['']);
      } else if (this.fromNavParam === 'notification') {
        if (this.optedForConsent === 'yes') {
          this.$sessionStorage.store('showConsentScreen', false);
          this.router.navigate(['/notification-message-rx']);
        } else {
          this.$sessionStorage.store('showConsentScreen', true);
          this.router.navigate(['']);
        }
      }
    });
  }
}

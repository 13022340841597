<h1 class="pageTitle">
    <a (click)="previousState()" class="back-icon">
        <img src="../../../../content/images/_ionicons_svg_md-arrow-back@2x.png" alt="back" width="20px" height="20px" />
    </a>
    Edit Profile</h1>
<div *ngIf="!showLoader">
    <div class="container-fluid">
        <div class="row ">
        <div class="col-md-12 bjcrx-gif">
            <div class="gif-text">
            <div class="bjc-text">
                <img src="../../../../content/images/bjc-rx-animation.gif" alt="" class="gif-img" alt="" />
                <div class="laoder-text text-center">Processing...</div>
            </div>
            </div>
        </div>
        </div>
    </div>
</div>

<form jhiFocusInvalidInput [formGroup]="editForm" *ngIf="profile && !showmessage && showLoader && editForm" (submit)="save()">
    <div class="content with-action">
        <div class="col-lg-4 col-md-6">
            <div class="name-wraper">
                <span class="name">{{profile?.firstName}} {{profile?.lastName}}</span>
                <span class="account-type">{{profile?.accountType}}</span>
            </div>
            <div class="info-wrapper">
                <span class="info">{{profile?.dateOfBirth | date:'MMMM, dd yyyy'}}</span>
            </div>
            <div>
                <h3 class="heading">Contact Information</h3>
                <div class="form-group">
                    <label class="form-control-label" for="primaryEmailAddress">Email Address</label><span class="required">*</span>
                    <input type="email" class="form-control" id="primaryEmailAddress" [ngClass]="{ 'is-invalid':editForm.get('primaryEmailAddress').invalid && (submitted || editForm.get('primaryEmailAddress').touched) }" name="primaryEmailAddress" placeholder="Email" formControlName="primaryEmailAddress"
                        required autocomplete="off">
                    <div *ngIf="editForm.get('primaryEmailAddress').invalid && (submitted || editForm.get('primaryEmailAddress').touched)">
                        <small class="form-text error-msg" *ngIf="editForm.get('primaryEmailAddress').errors.required">
                            Please enter your email address.
                        </small>
                        <small class="form-text error-msg" *ngIf="editForm.get('primaryEmailAddress').invalid && !editForm.get('primaryEmailAddress').errors.required">
                            Please enter a valid email address.
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-control-label" for="primaryPhoneNumber">Primary Phone Number</label><span class="required">*</span>
                    <input type="tel" class="form-control" id="primaryPhoneNumber" maxlength="14" name="primaryPhoneNumber" jhi-appPhoneMask placeholder=""
                        [ngClass]="{ 'is-invalid':editForm.get('primaryPhoneNumber').invalid && (submitted || editForm.get('primaryPhoneNumber').touched) }"
                        formControlName="primaryPhoneNumber" required autocomplete="off">
                    <div *ngIf="editForm.get('primaryPhoneNumber').invalid && (submitted || editForm.get('primaryPhoneNumber').touched)">
                        <small class="form-text error-msg" *ngIf="editForm.get('primaryPhoneNumber').errors && editForm.get('primaryPhoneNumber').errors.required">
                            Please enter your phone number.
                        </small>
                        <small class="form-text error-msg" *ngIf="editForm.get('primaryPhoneNumber').errors
                            && (editForm.get('primaryPhoneNumber').errors.phone || editForm.get('primaryPhoneNumber').errors.minlength)">
                            Please enter a valid phone number.
                        </small>
                    </div>
                </div>

                <h3 class="heading">Shipping Address</h3>
                <div class="form-group">
                    <label class="form-control-label" for="addressLine">Address</label><span class="required">*</span>
                    <input type="text" class="form-control" id="addressLine" [ngClass]="{ 'is-invalid':editForm.get('addressLine').invalid && (submitted || editForm.get('addressLine').touched) }" name="addressLine" placeholder="Address" formControlName="addressLine" required
                        autocomplete="off">
                    <div *ngIf="editForm.get('addressLine').invalid && (submitted || editForm.get('addressLine').touched)">
                        <small class="form-text error-msg" *ngIf="editForm.get('addressLine').errors.required">
                            Please enter shipping address.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="addressCity">City</label><span class="required">*</span>
                    <input type="text" class="form-control" id="addressCity" [ngClass]="{ 'is-invalid':editForm.get('addressCity').invalid && (submitted || editForm.get('addressCity').touched) }" name="addressCity" placeholder="City" formControlName="addressCity" required
                        autocomplete="off">
                    <div *ngIf="editForm.get('addressCity').invalid && (submitted || editForm.get('addressCity').touched)">
                        <small class="form-text error-msg" *ngIf="editForm.get('addressCity').errors.required">
                            Please enter city.
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-7 pl-0">
                        <label class="form-control-label" for="addressState">State</label><span class="required">*</span>
                        <select class="form-control" id="addressState" name="addressState" [ngClass]="{ 'is-invalid': editForm.get('addressState').invalid && (submitted || editForm.get('addressState').touched) }" formControlName="addressState" required>
                            <option value="">State</option>
                            <option *ngFor="let state of stateList" [value]="state.code">{{state.state}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col pr-0">
                        <label class="form-control-label" for="addressZip">ZIPCODE</label><span class="required">*</span>
                        <input type="number" class="form-control" id="addressZip" [ngClass]="{ 'is-invalid':editForm.get('addressZip').invalid && (submitted || editForm.get('addressZip').touched) }" name="addressZip" placeholder="Zipcode" formControlName="addressZip" required
                            autocomplete="off">
                    </div>
                </div>
                <div class="form-group m-0 p-0" *ngIf="editForm.get('addressState').invalid && (submitted || editForm.get('addressState').touched)">
                    <small class="form-text error-msg" *ngIf="editForm.get('addressState').errors.required">
                        Please select state.
                    </small>
                </div>
                <div class="form-group m-0 p-0" *ngIf="editForm.get('addressZip').invalid && (submitted || editForm.get('addressZip').touched)">
                    <small class="form-text error-msg" *ngIf="editForm.get('addressZip').errors.required">
                        Please enter zipcode.
                    </small>
                </div>
                <div class="form-group m-0 p-0" *ngIf="isInvalidAddress">
                    <small class="form-text error-msg">
                        Please enter valid address.
                    </small>
                </div>

                <h3 class="heading">Allergies</h3>
                <div class="form-group pb-0">
                    <label class="form-control-label" for="accountUsername">Allergies</label>
                </div>
                <div class="allergies-box">
                    <span *ngFor="let allergy of profile.patientAllergyList">{{allergy.description}}</span>
                </div>
                <h3 class="heading">Set Default Pharmacy</h3>
                <div class="form-group">
                    <label class="form-control-label" for="accountUsername">Pharmacy</label><span class="required">*</span>
                    <select class="form-control" id="defaultPharmacy" name="defaultPharmacy" [ngClass]="{ 'is-invalid': editForm.get('primaryEmailAddress').invalid && (submitted || editForm.get('primaryEmailAddress').touched) }" formControlName="defaultPharmacy" required>
                        <option [ngValue]="null" disabled>Select dropdown item</option>
                        <option *ngFor="let pharmacy of pharmacyList" [value]="pharmacy.pharmacyId">{{pharmacy.name}}
                        </option>
                    </select>
                    <div *ngIf="editForm.get('defaultPharmacy').invalid && (submitted || editForm.get('defaultPharmacy').touched)">
                        <small class="form-text error-msg" *ngIf="editForm.get('defaultPharmacy').errors.required">
                            Please select pharmacy.
                        </small>
                    </div>
                </div>
                <div *ngIf="profile?.accountType === 'primary'">
                    <h3 class="heading">Password</h3>
                    <div class="form-group">
                        <label class="form-control-label" for="newpassword">Password</label>
                        <input type="password" class="form-control" id="newpassword" [ngClass]="{ 'is-invalid':editForm.get('newpassword').invalid && (submitted || editForm.get('newpassword').touched) }" name="newpassword" placeholder="Password" formControlName="newpassword"
                            autocomplete="off">
                        <div *ngIf="editForm.get('newpassword').invalid && (submitted || editForm.get('newpassword').touched)">
                            <small class="form-text error-msg" *ngIf="editForm.get('newpassword').errors.required">
                                Please enter valid password.
                            </small>
                            <small class="form-text error-msg" *ngIf="editForm.get('newpassword').errors.pattern">
                                Please enter valid password.
                            </small>
                            <small class="form-text error-msg" *ngIf="editForm.get('newpassword').errors.minlength">
                                Password should be at least 14 characters.
                            </small>
                            <small class="form-text error-msg" *ngIf="editForm.get('newpassword').errors.error">
                                {{ editForm.get('newpassword').errors.error }}
                            </small>
                            <small class="form-text error-msg" *ngIf="editForm.get('newpassword').errors.containfnln">
                                Password should not contain first name or last name.
                            </small>
                            <small class="form-text error-msg" *ngIf="editForm.get('newpassword').errors.containUsername">
                                Password should not contain username.
                            </small>

                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="confirmPassword">Confirm Password</label>
                        <input type="password" class="form-control" id="confirmPassword" [ngClass]="{ 'is-invalid':editForm.get('confirmPassword').invalid && (submitted || editForm.get('primaryEmailAddress').touched) }" name="confirmPassword" placeholder="Confirm Password" formControlName="confirmPassword"
                            autocomplete="off">
                        <div *ngIf="editForm.get('confirmPassword').invalid && (submitted || editForm.get('confirmPassword').touched)">
                            <small class="form-text error-msg" *ngIf="editForm.get('confirmPassword').errors.required">
                                Please enter confirm password.
                            </small>
                            <small class="form-text error-msg" *ngIf="editForm.get('confirmPassword').errors.equalValue">
                                Confirm password does not match with password.
                            </small>
                        </div>
                    </div>
                    <p class="font14 info-text">Your password must be at least 14 characters long, contain at least one
                        number and have a mixture of uppercase and lowercase letters. It should not contain your username, your email, or your first
                        or last name.</p>
                    <p class="font14 info-text">You cannot reuse any of your last 5 passwords.</p>
                </div>

            </div>
        </div>
    </div>
    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="submit" class="btn btn-enable btn-block">CONTINUE</button>
        </div>
    </div>
</form>

<div *ngIf="showmessage && showLoader">
    <div class="content">
        <div class="col-md-4">
            <div [ngClass]="{'success-message':success, 'error-message':error}">
                {{message}}
            </div>
        </div>
    </div>
    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" *ngIf="error" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
        </div>
    </div>
</div>

<h1 class="pageTitle" jhiTranslate="bjcrxApp.customerStatement.home.title">Prescription Statement</h1>

<div *ngIf="showLoader">
  <div class="container-fluid">
    <div class="row ">
      <div class="col-md-12 bjcrx-gif">
        <div class="gif-text">
          <div class="bjc-text">
            <img src="../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="" />
            <div class="laoder-text text-center">Processing...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
    <form name="form" role="form" (ngSubmit)="customerStatement();" #myNgForm="ngForm" [formGroup]="customerStatementsForm" *ngIf="!showLoader">
        <div class="content with-action">
            <h3 class="heading" *ngIf="showDependents">Patient Information</h3>
            <div class="col-lg-4 col-md-6">
                <div class="form-group" *ngIf="showDependents">
                    <label class="form-control-label" for="patientName">PATIENT </label><span class="required">*</span>
                    <select class="form-control" id="patientName" formControlName="patientName" (change)="calculateAge(customerStatementsForm)">
            <option disabled value="">Select dropdown item</option>
            <option *ngFor="let dependent of dependentListFromApi | orderBy: 'sequence'" [value]="dependent.patientId">
              {{ dependent.firstName + ' ' + dependent.lastName }}</option
            >
          </select>
                    <div *ngIf="isUnauthorized">
                        <small class="form-text error-msg">You cannot manage dependents who are older than 18 years</small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="pharmacyName">PHARMACY </label><span class="required">*</span>
                    <select class="form-control" id="pharmacyName" formControlName="pharmacyName">
            <option disabled value="" selected>Select Pharmacy Name</option>
            <option [value]="pharmacy.pharmacyId" *ngFor="let pharmacy of pharmacyList">{{ pharmacy.name }}</option>
          </select>
                    <div *ngIf="customerStatementsForm.get('pharmacyName').invalid && (customerStatementsForm.get('pharmacyName').touched)">
                        <small class="form-text error-msg" *ngIf="customerStatementsForm.get('pharmacyName').errors.required">
              Please select pharmacy name.
            </small>
                    </div>
                </div>
                <h3 class="heading">Date Range</h3>
                <div class="form-group date-group">
                    <label class="form-control-label" for="startDate">START DATE</label><span class="required">*</span>
                    <div class="input-group" *ngIf="checkIsIEBrowser() === false">
                        <input type="date" class="form-control" min="2000-01-01" [max]="today | date: 'yyyy-MM-dd'" id="startDate" name="startDate" ngbDatepicker #stDate="ngbDatepicker" formControlName="startDate" autocomplete="off" />
                        <span class="input-group-append" (click)="stDate.toggle()" id="startDateCal">
                          <fa-icon [icon]="'calendar-alt'"></fa-icon>
                      </span>
                    </div>
                    <div class="input-group"  *ngIf="checkIsIEBrowser() === true">
                        <input type="text" placeholder="mm/dd/yyyy" tabindex="-1"  class="form-control" min="2000-01-01" [max]="today | date: 'yyyy-MM-dd'" id="startDate" name="startDate" ngbDatepicker #stDate="ngbDatepicker" formControlName="startDate" autocomplete="off" />
                        <span class="input-group-append" id="startDateCal">
                          <fa-icon [icon]="'calendar-alt'"></fa-icon>
                      </span>
                      <div class="date-pick-div" (click)="stDate.toggle()"></div>
                    </div>
                    <div *ngIf="customerStatementsForm.get('startDate').invalid && ((customerStatementsForm.get('startDate').touched && checkIsIEBrowser() === false))">
                        <small class="form-text error-msg" *ngIf="customerStatementsForm.get('startDate').errors.required">
              Please enter start date.
            </small>
                        <small class="form-text error-msg" *ngIf="customerStatementsForm.get('startDate').errors.invalidDate">
              Please enter valid date.
            </small>
                        <small class="form-text error-msg" *ngIf="customerStatementsForm.get('startDate').errors.futuredate">
              Please enter valid date.
            </small>
                    </div>
                </div>
                <div class="form-group date-group">
                    <label class="form-control-label" for="endDate">END DATE</label><span class="required">*</span>
                    <div class="input-group" *ngIf="checkIsIEBrowser() === false">
                        <input type="date" class="form-control" min="2000-01-01" [max]="today | date: 'yyyy-MM-dd'" id="endDate" ngbDatepicker #edDate="ngbDatepicker" name="endDate" formControlName="endDate" autocomplete="off" />
                        <span class="input-group-append" (click)="edDate.toggle()" id="endDateCal">
                          <fa-icon [icon]="'calendar-alt'"></fa-icon>
                      </span>
                    </div>
                    <div class="input-group" *ngIf="checkIsIEBrowser() === true">
                        <input type="text" placeholder="mm/dd/yyyy" tabindex="-1" class="form-control" min="2000-01-01" [max]="today | date: 'yyyy-MM-dd'" id="endDate" ngbDatepicker #edDate="ngbDatepicker" name="endDate" formControlName="endDate" autocomplete="off" />
                        <span class="input-group-append" id="endDateCal">
                          <fa-icon [icon]="'calendar-alt'"></fa-icon>
                      </span>
                      <div class="date-pick-div" (click)="edDate.toggle()"></div>
                    </div>
                    <small class="form-text error-msg" *ngIf="
              (customerStatementsForm.get('endDate').touched && checkIsIEBrowser() === false) &&
              this.customerStatementsForm.value.startDate > this.customerStatementsForm.value.endDate
            ">
            Start date is greater than End date.
          </small>
                    <div *ngIf="customerStatementsForm.get('endDate').invalid && (customerStatementsForm.get('endDate').touched)">
                        <small class="form-text error-msg" *ngIf="customerStatementsForm.get('endDate').errors.required">
              Please enter end date.
            </small>
                        <small class="form-text error-msg" *ngIf="customerStatementsForm.get('endDate').errors.invalidDate">
              Please enter valid date.
            </small>
                        <small class="form-text error-msg" *ngIf="customerStatementsForm.get('endDate').errors.futuredate">
              Please enter valid date.
            </small>
                    </div>
                </div>
                <div class="col-6"></div>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="col-lg-3 col-md-6 p-0">
                <button type="submit" [disabled]="!customerStatementsForm.touched || ((this.customerStatementsForm.value.startDate > this.customerStatementsForm.value.endDate) ||
            isUnauthorized)" class="btn btn-enable btn-block">
          VIEW STATEMENT
        </button>
            </div>
        </div>
    </form>
</div>
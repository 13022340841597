import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import LoginModalService from 'app/core/login/login-modal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ActivateService from './activate.service';

@Component({
  selector: 'jhi-activate',
  templateUrl: './activate.component.html'
})
export default class ActivateComponent implements OnInit, OnDestroy {
  private activateComponent$: Subject<void> = new Subject<void>();

  error: string;

  success: string;

  modalRef: NgbModalRef;

  constructor(private activateService: ActivateService, private loginModalService: LoginModalService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.activateComponent$)).subscribe((params) => {
      this.activateService
        .get(params?.key)
        .pipe(takeUntil(this.activateComponent$))
        .subscribe(
          () => {
            this.error = null;
            this.success = 'OK';
          },
          () => {
            this.success = null;
            this.error = 'ERROR';
          }
        );
    });
  }

  login() {
    this.modalRef = this.loginModalService.open();
  }

  public ngOnDestroy(): void {
    this.activateComponent$.next();
    this.activateComponent$.unsubscribe();
  }
}

<div *ngIf="!showmessage && !apiCalled">
    <form name="form" role="form" (ngSubmit)="requestReset()" [formGroup]="forgotUsernameForm">
        <div class="content with-action">
            <div class="row">
                <a routerLink="/">
                    <img src="../../../content/images/bjc-blue-logo.png" alt="bjc logo">
                </a>
            </div>
            <h2 class="subtitle">Forgot Username</h2>
            <h2 class="heading">Please enter your details</h2>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="form-group">
                        <label class="form-control-label" for="firstName">First
                            Name</label><span class="required">*</span>
                        <input type="text" class="form-control" id="firstName" (keypress)="validateAlphabets($event)"
                            name="firstName" placeholder="First Name" formControlName="firstName" autocomplete="off">
                        <div
                            *ngIf="forgotUsernameForm.get('firstName').invalid && (submitted || forgotUsernameForm.get('firstName').touched)">
                            <small class="form-text error-msg"
                                *ngIf="forgotUsernameForm.get('firstName').errors.required">
                                Please enter first name.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="lastName">Last
                            Name</label><span class="required">*</span>
                        <input type="text" class="form-control" id="lastName" (keypress)="validateAlphabets($event)"
                            name="lastName" placeholder="Last Name" formControlName="lastName" autocomplete="off">
                        <div
                            *ngIf="forgotUsernameForm.get('lastName').invalid && (submitted || forgotUsernameForm.get('lastName').touched)">
                            <small class="form-text error-msg"
                                *ngIf="forgotUsernameForm.get('lastName').errors.required">
                                Please enter last name.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="email">Email Address</label><span
                            class="required">*</span>
                        <input type="text" class="form-control" id="email" name="email" placeholder="Email"
                            formControlName="email" autocomplete="off">
                        <div
                            *ngIf="forgotUsernameForm.get('email').invalid && (submitted || forgotUsernameForm.get('email').touched)">
                            <small class="form-text error-msg" *ngIf="forgotUsernameForm.get('email').errors.required">
                                Please enter your email address.
                            </small>
                            <small class="form-text error-msg" *ngIf="forgotUsernameForm.get('email').errors.email">
                                Please enter valid email address.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="dateOfBirth">Date of
                            Birth</label><span class="required">*</span>
                        <div class="input-group" *ngIf="checkIsIEBrowser() === false">
                            <input type="date" class="form-control" id="dateOfBirth" name="dateOfBirth"
                                placeholder="Date of Birth" formControlName="dateOfBirth" ngbDatepicker
                                #dobDp="ngbDatepicker" max="9999-12-31" autocomplete="off">
                            <span class="input-group-append calendar" (click)="dobDp.toggle()">
                                <fa-icon [icon]="'calendar-alt'"></fa-icon>
                            </span>
                        </div>

                        <div class="input-group" *ngIf="checkIsIEBrowser() === true">
                            <input type="text" tabindex="-1" class="form-control" id="dateOfBirth" name="dateOfBirth"
                                placeholder="mm/dd/yyyy" formControlName="dateOfBirth" ngbDatepicker
                                #dobDp="ngbDatepicker" max="9999-12-31" autocomplete="off">
                            <span class="input-group-append calendar">
                                <fa-icon [icon]="'calendar-alt'"></fa-icon>
                            </span>
                            <div class="date-pick-div" (click)="dobDp.toggle()"></div>
                        </div>

                        <div
                            *ngIf="forgotUsernameForm.get('dateOfBirth').invalid && (submitted || (forgotUsernameForm.get('dateOfBirth').touched && checkIsIEBrowser() === false))">
                            <small class="form-text error-msg"
                                *ngIf="forgotUsernameForm.get('dateOfBirth').errors.required">
                                Please enter date of birth.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="forgotUsernameForm.get('dateOfBirth').errors.invalidDate">
                                Please enter valid date.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="forgotUsernameForm.get('dateOfBirth').errors.futuredate">
                                Please enter valid date of birth.
                            </small>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="action-statement col-xl-12 col-lg-12 col-md-12">
                <p>If you have an email address on file, your BJCRx username will be sent to your email account.
                   If you do not remember any of this information, or you do not have a valid email address on file, 
                   you will have to contact the Pharmacy at 314-657-9000 OR 1-855-525-0411 to help you regain access 
                   to your BJCRx account.  It is important for you to understand that there is a potential risk that 
                   email messages containing your information may be intercepted by a third party.</p>
            </div>
            <div class="col-lg-3 col-md-6 p-0">
                <button type="submit" [disabled]="!forgotUsernameForm.touched"
                    class="btn btn-enable btn-block">CONTINUE</button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="!showmessage && apiCalled">
    <div class="content with-action">
        <div class="col-lg-4 col-md-6 bjcrx-gif">
            <div class="bjc-text">
                <img src="../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="">
                <div class="laoder-text text-center">Processing...</div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showmessage">
    <div class="content">
        <div class="col-lg-4 col-md-6">
            <div [ngClass]="{'success-message':success, 'error-message':error}">
                {{message}}
            </div>
        </div>
    </div>
    <div class="row action m-0" *ngIf="error" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
        </div>
    </div>

    <div class="row action m-0" *ngIf="success" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" (click)="login()" class="btn btn-enable btn-block">LOGIN</button>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { DATE_FORMAT } from 'app/shared/constants/input.constants';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { INotificationMessageRx } from 'app/shared/model/notification-message-rx.model';

type EntityResponseType = HttpResponse<INotificationMessageRx>;
type EntityArrayResponseType = HttpResponse<INotificationMessageRx[]>;

@Injectable({ providedIn: 'root' })
export default class NotificationMessageRxService {
  public resourceUrl = `${SERVER_API_URL}api/notification-messages`;

  constructor(protected http: HttpClient) {}

  create(notificationMessage: INotificationMessageRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(notificationMessage);
    return this.http
      .post<INotificationMessageRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(notificationMessage: INotificationMessageRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(notificationMessage);
    return this.http
      .put<INotificationMessageRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<INotificationMessageRx>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<INotificationMessageRx[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(notificationMessage: INotificationMessageRx): INotificationMessageRx {
    const copy: INotificationMessageRx = {
      ...notificationMessage,
      startDate:
        notificationMessage.startDate != null && notificationMessage.startDate.isValid()
          ? moment(notificationMessage.startDate.format(DATE_FORMAT))
          : null,
      endDate:
        notificationMessage.endDate != null && notificationMessage.endDate.isValid()
          ? moment(notificationMessage.endDate.format(DATE_FORMAT))
          : null
    };
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.startDate = res.body.startDate != null ? moment(res.body.startDate) : null;
      res.body.endDate = res.body.endDate != null ? moment(res.body.endDate) : null;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((notificationMessage: INotificationMessageRx) => {
        notificationMessage.startDate = notificationMessage.startDate != null ? moment(notificationMessage.startDate) : null;
        notificationMessage.endDate = notificationMessage.endDate != null ? moment(notificationMessage.endDate) : null;
      });
    }
    return res;
  }

  getUserNotificationSettings(userName: string): Observable<any> {
    return this.http.get(`${SERVER_API_URL}api/user/${userName}/details`, { observe: 'response' });
  }

  submitNotificationSettings(data: any, method: string): Observable<any> {
    const parameters = new HttpParams().set('method', method);
    return this.http.put(`${SERVER_API_URL}api/user/update`, data, { params: parameters });
  }
}

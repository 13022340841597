import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'jhi-app-address-validation-dialog',
  templateUrl: './address-validation-dialog.component.html'
})
export default class AddressValidationDialogComponent implements OnInit {
  @Input() title: string;

  @Input() uspsAddressLine: string;

  @Input() userAddressLine: string;

  @Input() uspsCity: string;

  @Input() userCity: string;

  @Input() uspsState: string;

  @Input() userState: string;

  @Input() uspsZip: string;

  @Input() userZip: string;

  // @Input() btnUspsText: string;
  // @Input() btnUserText: string;
  // @Input() btnEditText: string;

  @Input() hideButtons: boolean;

  @Input() timeoutOnShow: boolean;

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal) {}

  ngOnInit() {
    if (this.timeoutOnShow) {
      setTimeout(() => {
        this.activeModal.close('timeout');
      }, 3000);
    }
  }

  public keepUsps() {
    this.activeModal.close('usps');
  }

  public keepUser() {
    this.activeModal.close('user');
  }

  public continueEdit() {
    this.activeModal.close('edit');
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}

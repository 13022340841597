<ng-container *ngIf="!showLoading">
    <h1 class="pageTitle">Terms and Conditions</h1>
    <div class="content with-action terms-condition">
        <div class="col-xl-12 col-lg-12 col-md-12">
            <h3 class="heading">AUTHORIZATION TO UTILIZE UNSECURE COMMUNICATIONS FOR SHARING PROTECTED HEALTH
                INFORMATION</h3>
            <p>Electronic mail (email) and text messaging are very common and convenient forms of communication. These
                methods may be used to share information between you and your health care providers, including retail
                and mail order pharmacies. As a patient, you have the right to confidentiality of your Protected Health
                Information (PHI). It is important for you to understand that there is a potential risk that email and
                text messages containing your protected health information may be intercepted by a third party. </p>
            <p>Before BJC HealthCare and its affiliated hospital retail and mail order pharmacies (each, a “BJC Hospital
                Pharmacy”), can send you email or text messages, we are required to make you aware of these risks and to
                obtain your authorization. <strong>If you elect to receive communications in this manner, we will (when
                    available to the applicable health care provider) use the information you provided to send phone
                    messages, texts, and emails regarding your pharmacy orders and refills, including refill
                    reminders.</strong> We will limit information sent to you to the minimum deemed necessary to benefit
                your health.</p>
            <p>Email, phone, text and other messaging communications may become a part of your patient medical record
                and may be accessible to the clinical support staff responsible for your care. </p>
            <h3 class="heading">EMAIL AND TEXT MESSAGE COMMUNICATION SHOULD NEVER BE USED IN THE CASE OF AN EMERGENCY OR
                FOR URGENT REQUESTS FOR INFORMATION.</h3>
            <p>By supplying my home phone number, mobile phone number, or email address, and any other personal contact
                information, I authorize BJC HealthCare and BJC Hospital Pharmacies to employ a third-party automated
                outreach and messaging system to use my personal information, my pharmacy order and refill, including
                refill reminders, and other limited information associated with my prescription. I understand that
                notifications may use an autodialer and/or prerecorded or artificial voice and may be repeated multiple
                times per pharmacy order and refill and may exceed contacts more than three times per week. </p>
            <p>If you elect to communicate from a workplace computer, email account, or workplace phone, please be aware
                that your employer and workplace agents might have access to those email and text communications.</p>
            <p>This authorization may be revoked at any time, use the menu to go to the Notification settings and select DECLINE
                to receive unsecured email and text messages from BJC Hospital Pharmacies. It is understood that the
                revocation will not apply to information that has already been released.</p>
            <p>I agree to update you if my phone number or email address change. I understand this authorization is
                valid while I have an active prescription filled by a BJC Hospital retail or mail order pharmacy. </p>
            <p>I understand and accept terms and conditions outlined herein.</p>
            <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="agreeToyes" [(ngModel)]="optedForConsent"
                    name="agreeTo" value="yes" />
                <label class="custom-control-label form-check-label" for="agreeToyes">
                    <strong>I AUTHORIZE BJC HealthCare and BJC Hospital Pharmacies</strong> to send me unsecured email
                    and text messages to the phone number and email address I provide to any BJC Hospital Pharmacy. 
                </label>
            </div>

            <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="agreeTonon" [(ngModel)]="optedForConsent"
                    name="agreeTo" value="no" />
                <label class="custom-control-label form-check-label" for="agreeTonon">
                    <strong>I DECLINE</strong> to receive unsecured email and text messages from BJC Hospital
                    Pharmacies.
                </label>
            </div>
        </div>
    </div>
    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-xl-4 col-lg-6 col-md-6 p-0">
            <button type="button" (click)="optedForConsent ? submit() :''" [disabled]="!optedForConsent"
                class="btn btn-enable btn-block">CONTINUE</button>
        </div>
    </div>
</ng-container>

<div *ngIf="showLoading">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-12 bjcrx-gif">
                <div class="gif-text">
                    <div class="bjc-text">
                        <img src="../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="" />
                        <div class="laoder-text text-center">Processing...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
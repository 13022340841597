import {
  Component, Renderer2, ElementRef, OnInit, OnDestroy
} from '@angular/core';
import {
  FormBuilder, Validators
} from '@angular/forms';
import {
  Router, ActivatedRoute, NavigationEnd
} from '@angular/router';
import { JhiEventManager } from 'ng-jhipster';
import LoginService from 'app/account/login/login.service';
import StateStorageService from 'app/core/auth/state-storage.service';
import NotificationMessageRxService from 'app/entities/notification-message-rx/notification-message-rx.service';
import { SessionStorageService } from 'ngx-webstorage';
import IdleService from 'app/core/auth/idle.service';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'jhi-login',
  templateUrl: './login.component.html'
})
export default class LoginComponent implements OnInit, OnDestroy {
  private loginComponent$: Subject<void> = new Subject<void>();

  authenticationError: boolean;

  authenticationErrorMsg: string;

  defaultAuthenticationErrorMsg = 'Log in failed. Check your credentials and try again';

  returnUrl: string;

  submitted = false;

  slideScreen;

  showmessage = true;

  patientId: any;

  passwordExpired = false;

  private userNotificationPref: any;

  private isBackClicked = false;

  private routerSubscription: any;

  loginForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });

  constructor(
    private eventManager: JhiEventManager,
    private loginService: LoginService,
    private stateStorageService: StateStorageService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private $sessionStorage: SessionStorageService,
    private fb: FormBuilder,
    private idleService: IdleService,
    private accountService: AccountService,
    private notificationService: NotificationMessageRxService
  ) {
    window.addEventListener('popstate', () => {
      this.isBackClicked = true;
    });
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.isBackClicked) {
          this.loginService.logoutRedirect();
          this.isBackClicked = false;
          this.routerSubscription.unsubscribe();
        }
      }
    });
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.loginComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
  }

  cancel() {
    this.authenticationError = false;
    this.loginForm.patchValue({
      username: '',
      password: ''
    });
  }

  login() {
    this.submitted = true;
    this.showmessage = false;

    if (!this.loginForm.invalid) {
      this.loginService
        .login({
          username: this.loginForm.get('username').value,
          password: this.loginForm.get('password').value,
          rememberMe: false
        })
        .then(() => {
          this.authenticationError = false;
          this.accountService
            .identity(true)
            .then((account) => {
              if (account) {
                this.passwordExpired = false;
                this.idleService.checkUserIdle();
                this.patientId = account.enterpriseRxPatientId;
                this.getNotificationPreferences(this.patientId);
                if (account.username) {
                  this.accountService.userNameBySubject.next(account.username);
                } else {
                  this.accountService.userNameBySubject.next('');
                }
                if (account.passwordUpdateByAdmin) {
                  this.showmessage = true;
                  this.router.navigate(['/account/reset/password']);
                } else if (this.passwordIsExpired(account.passwordResetDate)) {
                  this.showmessage = true;
                  this.authenticationError = true;
                  this.passwordExpired = true;
                  this.authenticationErrorMsg =
                    'Your password has expired. Passwords must be reset every 120 days. Please click the Reset Password button to change your password.';
                } else if (account.userNotificationConsent === null || account.userNotificationConsent === 'Not Provided') {
                  this.router.navigate(['/account/communication-consent/login']);
                } else if (
                  this.router.url === '/account/login' ||
                  this.router.url.startsWith('/account/activate/') ||
                  this.router.url.startsWith('/account/reset/')
                ) {
                  this.showmessage = true;
                  this.router.navigate(['']);
                }
                this.$sessionStorage.store('isPasswordExpired', this.passwordExpired);
              } else {
                sessionStorage.clear();
                this.authenticationError = true;
                this.showmessage = true;
                this.authenticationErrorMsg = this.defaultAuthenticationErrorMsg;
              }
            })
            .catch(() => {
              sessionStorage.clear();
              this.authenticationError = true;
              this.showmessage = true;
              this.authenticationErrorMsg = this.defaultAuthenticationErrorMsg;
            });
        })
        .catch(() => {
          this.showmessage = true;
          this.authenticationError = true;
          this.authenticationErrorMsg = this.defaultAuthenticationErrorMsg;
        });
    }
  }

  getNotificationPreferences(userName: string) {
    this.notificationService.getUserNotificationSettings(userName).subscribe(
      (resp) => {
        if (resp.status === 200) {
          this.userNotificationPref = resp.body;
          if (this.userNotificationPref.optedForCallNotification) {
            this.$sessionStorage.store('showConsentScreen', true);
          } else {
            this.$sessionStorage.store('showConsentScreen', false);
          }
        }
      },
      (error) => {
        console.log('Error while setting consent', error);
      }
    );
  }

  passwordIsExpired(lastPasswordResetDate) {
    if (lastPasswordResetDate) {
      const expirationDate = moment(lastPasswordResetDate).add(120, 'days');
      const today = moment(new Date());
      const diff = expirationDate.diff(today, 'days');
      return diff <= 0;
    }
    return false;
  }

  register() {
    this.router.navigate(['/account/register']);
  }

  tryagain() {
    this.authenticationError = false;
    this.authenticationErrorMsg = '';
    this.loginForm.reset();
    this.submitted = false;
    this.showmessage = true;
  }

  userResetPassword() {
    this.router.navigate(['/account/reset/password']);
  }

  requestResetPassword() {
    this.router.navigate(['/account/reset/request']);
  }

  requestForUsername() {
    this.router.navigate(['/account/forgot-username']);
  }

  public ngOnDestroy(): void {
    this.loginComponent$.next();
    this.loginComponent$.unsubscribe();
  }
}

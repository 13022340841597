import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateValidator, CheckFutureDate } from 'app/shared/util/request-util';
import { Router } from '@angular/router';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isIEBrowser } from 'app/app.constants';
import * as moment from 'moment';
import PasswordResetInitService from './password-reset-init.service';

@Component({
  selector: 'jhi-password-reset-init',
  templateUrl: './password-reset-init.component.html',
  styleUrls: ['password-reset-init.scss']
})
export default class PasswordResetInitComponent implements OnInit, OnDestroy {
  private passwordResetInitComponent$: Subject<void> = new Subject<void>();

  showmessage = false;

  submitted = false;

  message;

  slideScreen;

  error = false;

  success = false;

  apiCalled = false;

  serverError = 'Oops!! Something went wrong, please try again.';

  errorEmailNotExists: string;

  resetRequestForm = this.fb.group({
    username: ['', [Validators.required]],
    dob: ['', [Validators.required, DateValidator(), CheckFutureDate()]]
  });

  constructor(
    private passwordResetInitService: PasswordResetInitService,
    private fb: FormBuilder,
    private router: Router,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.passwordResetInitComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
  }

  tryagain() {
    this.showmessage = false;
    this.error = false;
    this.success = false;
    this.message = '';
    this.submitted = false;
    this.resetRequestForm.get('dob').setValue(moment(this.resetRequestForm.value.dob).format('YYYY-MM-DD'));
  }

  login() {
    this.router.navigate(['/account/login']);
  }

  checkIsIEBrowser() {
    return isIEBrowser;
  }

  requestReset() {
    this.error = false;
    this.success = false;

    this.errorEmailNotExists = null;
    this.submitted = true;
    if (this.resetRequestForm.invalid) {
      if (
        this.resetRequestForm.get('dob').errors &&
        !this.resetRequestForm.get('dob').errors?.required &&
        !this.resetRequestForm.get('dob').errors?.invalidDate &&
        !this.resetRequestForm.get('dob').errors?.futuredate
      ) {
        this.resetRequestForm.get('dob').setErrors(null);
      }
    }

    if (!this.resetRequestForm.invalid) {
      this.apiCalled = true;
      const dataset = this.resetRequestForm.value;
      dataset.dob = moment(dataset.dob).format('YYYY-MM-DD');
      this.passwordResetInitService
        .save(JSON.stringify(dataset))
        .pipe(takeUntil(this.passwordResetInitComponent$))
        .subscribe(
          (res) => {
            if (res.hasError) {
              this.error = true;
            } else {
              this.success = true;
            }
            this.message = res.message;
            this.showmessage = true;
            this.apiCalled = false;
          },
          (error) => {
            this.success = false;
            this.message = error.error.detail ? error.error.detail : this.serverError;
            this.error = true;
            this.apiCalled = false;
            this.showmessage = true;
          }
        );
    }
  }

  public ngOnDestroy(): void {
    this.passwordResetInitComponent$.next();
    this.passwordResetInitComponent$.unsubscribe();
  }
}

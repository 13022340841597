import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SessionStorageService } from 'ngx-webstorage';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import PersonDTO from 'app/shared/model/person.model';
import ProfileCreateDTO from 'app/shared/model/profile-create.model';
import * as moment from 'moment';
import ProfileRxService from '../profile-rx.service';

@Component({
  selector: 'jhi-profile-rx-security-question',
  templateUrl: './profile-security-questions.component.html',
  styleUrls: ['profile-security-questions.scss']
})
export default class ProfileRxSecurityQuestionComponent implements OnInit, OnDestroy {
  private profileRxSecurityQuestionComponent$: Subject<void> = new Subject<void>();

  userdetail: ProfileCreateDTO;

  showmessage = false;

  message;

  questionSet;

  lexusNexusResponse;

  lexusNexusQuestionSet;

  form: FormGroup;

  errorstep;

  slideScreen;

  serverError = 'Oops!! Something went wrong, please try again.';

  showRegisterForMailOrder = false;

  constructor(
    private $sessionStorage: SessionStorageService,
    private route: Router,
    private profileRxService: ProfileRxService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.showmessage = false;
    this.message = '';
    this.accountService.slideScreen.pipe(takeUntil(this.profileRxSecurityQuestionComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });

    if (this.$sessionStorage.retrieve('acdetails')) {
      const sessionDetails = JSON.parse(this.$sessionStorage.retrieve('acdetails'));
      this.userdetail = sessionDetails;
      this.userdetail.patientId = sessionDetails.patientId;
      this.getSecurityQuestions();
    }
  }

  public registerForMailOrder() {
    this.route.navigate(['/profile-rx/mailorder']);
  }

  getSecurityQuestions() {
    const details = new PersonDTO();
    details.birthDate = moment(this.userdetail.publicAuthenticationDTO.dateOfBirth).format('YYYY-MM-DD');
    details.firstName = this.userdetail.publicAuthenticationDTO.firstName;
    details.lastName = this.userdetail.publicAuthenticationDTO.lastName;
    details.ssn = this.userdetail.publicAuthenticationDTO.last4ssn;
    details.driverLicense = this.userdetail.publicAuthenticationDTO.driverLicenseNumber
      ? this.userdetail.publicAuthenticationDTO.driverLicenseNumber.toString()
      : null;
    details.driverLicenseState = this.userdetail.publicAuthenticationDTO.driverLicenseState;
    this.profileRxService
      .getSecurityQuestionsPlus(details)
      .pipe(takeUntil(this.profileRxSecurityQuestionComponent$))
      .subscribe(
        (res) => {
          this.userdetail.patientId = res.patientId ? res.patientId : this.userdetail.patientId;
          this.userdetail.publicAuthenticationDTO.primaryEmailAddress = res.primaryEmailAddress
            ? res.primaryEmailAddress
            : this.userdetail.publicAuthenticationDTO.primaryEmailAddress;
          this.loadQuestions(res);
        },
        (error) => {
          this.showmessage = true;
          this.message = error.error.detail ? error.error.detail : this.serverError;
          this.errorstep = 1;
        }
      );
  }

  createform() {
    const group: any = {};
    this.questionSet.forEach((element) => {
      group[`question-${element.QuestionId}`] = new FormControl('', [Validators.required]);
    });
    return new FormGroup(group);
  }

  previousState() {
    window.history.back();
  }

  submitAnswers() {
    if (!this.form.invalid) {
      const selectedQuestionsAnswers = this.form.value;
      const requestObj = this.lexusNexusResponse;
      let iidqaIndex = 0;
      for (let i = 0; i < requestObj.Products.length; i++) {
        if (requestObj.Products[i].ProductType === 'IIDQA') {
          iidqaIndex = i;
          break;
        }
      }

      requestObj.Products[iidqaIndex].QuestionSet.Questions.forEach((element) => {
        const answers = element.Choices;
        if (element.Type === 'singlechoice') {
          const selected = answers.filter(
            (answer) => answer.ChoiceId.toString() === selectedQuestionsAnswers[`question-${element.QuestionId}`]
          );

          if (selected.length > 0) {
            element.Choices = [];
            element.Choices.push(selected[0]);
          }
        }
      });
      this.profileRxService
        .submitQuestions(requestObj)
        .pipe(takeUntil(this.profileRxSecurityQuestionComponent$))
        .subscribe(
          (res) => {
            console.log(res);
            if (res.TransactionStatus === 'pending') {
              this.loadQuestions(res);
            } else if (res.TransactionStatus === 'passed') {
              this.validateUser();
            }
          },
          (error) => {
            this.showmessage = true;
            this.message = error.error.detail;
            this.errorstep = 1;
            if (this.message.indexOf('We are not able to find your') >= 0) {
              this.showRegisterForMailOrder = true;
            }
          }
        );
    }
  }

  loadQuestions(res) {
    this.lexusNexusResponse = res;
    let iidqaIndex = 0;
    for (let i = 0; i < res.Products.length; i++) {
      if (res.Products[i].ProductType === 'IIDQA') {
        iidqaIndex = i;
        break;
      }
    }
    this.lexusNexusQuestionSet = res.Products[iidqaIndex].QuestionSet;
    this.questionSet = this.lexusNexusQuestionSet.Questions;
    this.form = this.createform();
  }

  validateUser() {
    this.$sessionStorage.store('acdetails', JSON.stringify(this.userdetail));
    if (this.userdetail.patientId && this.userdetail.patientId !== '') {
      this.route.navigate(['account/register']);
    } else {
      this.registerForMailOrder();
    }
  }

  tryagain() {
    this.showRegisterForMailOrder = false;
    if (this.errorstep === 1) {
      this.route.navigate(['profile-rx/create/guest']);
    } else if (this.errorstep === 2) {
      this.showmessage = false;
      this.message = '';
      this.getSecurityQuestions();
    }
  }

  public ngOnDestroy(): void {
    this.profileRxSecurityQuestionComponent$.next();
    this.profileRxSecurityQuestionComponent$.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import {
  Resolve, ActivatedRouteSnapshot, Routes
} from '@angular/router';
import { JhiResolvePagingParams } from 'ng-jhipster';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  PrescriptionTxReqRx, IPrescriptionTxReqRx
} from 'app/shared/model/prescription-tx-req-rx.model';
import PrescriptionTxReqRxService from './prescription-tx-req-rx.service';
import PrescriptionTxReqRxComponent from './prescription-tx-req-rx.component';

@Injectable({ providedIn: 'root' })
export class PrescriptionTxReqRxResolve implements Resolve<IPrescriptionTxReqRx> {
  constructor(private service: PrescriptionTxReqRxService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IPrescriptionTxReqRx> {
    const { id } = route.params;
    if (id) {
      return this.service.find(id).pipe(
        filter((response: HttpResponse<PrescriptionTxReqRx>) => response.ok),
        map((prescriptionTxReq: HttpResponse<PrescriptionTxReqRx>) => prescriptionTxReq.body)
      );
    }
    return of(new PrescriptionTxReqRx());
  }
}

export const prescriptionTxReqRoute: Routes = [
  {
    path: '',
    component: PrescriptionTxReqRxComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams
    },
    data: {
      authorities: ['ROLE_USER'],
      defaultSort: 'id,asc',
      pageTitle: 'bjcrxApp.prescriptionTxReq.home.title'
    }
  }
];

import { Route } from '@angular/router';
import RegisterComponent from './register.component';

const registerRoute: Route = {
  path: 'register',
  component: RegisterComponent,
  data: {
    authorities: [],
    pageTitle: 'register.title'
  }
};

export default registerRoute;

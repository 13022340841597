import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { JhiEventManager } from 'ng-jhipster';

import LoginModalService from 'app/core/login/login-modal.service';
import AccountService from 'app/core/auth/account.service';
import Account from 'app/core/user/account.model';
import { SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import LoginService from 'app/account/login/login.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'jhi-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.scss']
})
export default class HomeComponent implements OnInit, OnDestroy {
  account: Account;

  authSubscription: Subscription;

  modalRef: NgbModalRef;

  slideDrawer;

  message;

  show: any;

  constructor(
    private toastr: ToastrService,
    private accountService: AccountService,
    private loginModalService: LoginModalService,
    private eventManager: JhiEventManager,
    private sessionStorage: SessionStorageService,
    public router: Router,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    if (this.accountService.isAuthenticated()) {
      this.registerAuthenticationSuccess();
      this.accountService.identity().then((account) => {
        if (account) {
          this.account = account;
          this.sessionStorage.store('accountInfo', this.account);
          this.checkPasswordExpirationDate(this.account.passwordResetDate);
        }
      });
    }
  }

  registerAuthenticationSuccess() {
    this.authSubscription = this.eventManager.subscribe('authenticationSuccess', () => {});
  }

  checkPasswordExpirationDate(lastPasswordResetDate) {
    const expirationDate = moment(lastPasswordResetDate).add(120, 'days');
    const today = moment(new Date());
    const diff = expirationDate.diff(today, 'days');
    if (diff <= 10) {
      const toast = this.toastr.warning(`Your password will expire in ${diff} days.`, 'WARNING');
      toast.onTap.subscribe(() => {
        this.toastr.remove(toast.toastId);
      });
    }
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  login() {
    this.modalRef = this.loginModalService.open();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.eventManager.destroy(this.authSubscription);
    }
  }
}

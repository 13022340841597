<h1 *ngIf="!isEdit" class="pageTitle">
    <a (click)="previousState()">
        <img src="../../../content/images/_ionicons_svg_md-arrow-back@2x.png" alt="back" width="20px" height="20px" />
    </a>
    Add Payment Method
</h1>

<h1 *ngIf="isEdit" class="pageTitle">
    <a (click)="previousState()">
        <img src="../../../content/images/_ionicons_svg_md-arrow-back@2x.png" alt="back" width="20px" height="20px" />
    </a>
    Edit Payment Method
</h1>

<div *ngIf="!showLoader">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-12 bjcrx-gif">
                <div class="gif-text">
                    <div class="bjc-text">
                        <img src="../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="" />
                        <div class="laoder-text text-center">Processing...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content" *ngIf="showMessage && showLoader">
    <div class="col-md-4">
        <div class="error-message" *ngIf="maxAddCardLimit">
            Maximum number of Credit Cards per Patient exceeded.
        </div>
        <div class="error-message" *ngIf="showErrorMessage">
            {{message}}
        </div>
        <div class="success-message" *ngIf="showSuccessMessage">
            {{message}}
        </div>
    </div>
</div>

<ng-container *ngIf="!showMessage && showLoader">
    <form name="form" role="form" (ngSubmit)="savePaymentCard()" [formGroup]="addPaymentCardForm">
        <div class="content with-action">
            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label for="firstName">Patient</label><span class="required">*</span>
                    <input class="form-control" type="text" id="patientName" formControlName="patientName"
                           placeholder="First Name">
                </div>
                <h3 class="heading">Add Card</h3>
                <div class="form-group">
                    <label for="firstName">First Name</label><span class="required">*</span>
                    <input class="form-control" type="text"
                           maxlength="40" id="firstName" formControlName="firstName" placeholder="First Name">
                    <div
                        *ngIf="addPaymentCardForm.get('firstName').invalid && (addPaymentCardForm.get('firstName').touched)">
                        <small class="form-text error-msg">
                            Please enter first name.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="middleName">Middle Initial</label>
                    <input class="form-control" type="text"
                           maxlength="1" id="middleName" formControlName="middleName" placeholder="Middle Initial">
                    <div
                        *ngIf="addPaymentCardForm.get('middleName').invalid && (addPaymentCardForm.get('middleName').touched)">
                        <small class="form-text error-msg">
                            Please enter middle Initial.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lastName">Last Name</label><span class="required">*</span>
                    <input class="form-control" type="text"
                           maxlength="40" id="lastName" formControlName="lastName" placeholder="Last Name">
                    <div
                        *ngIf="addPaymentCardForm.get('lastName').invalid && (addPaymentCardForm.get('lastName').touched)">
                        <small class="form-text error-msg">
                            Please enter last name.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="cardNumber">Card number</label><span class="required">*</span>
                    <input class="form-control" type="text" id="cardNumber" maxlength="16"
                           (keypress)="validateAlphanumeric($event)" jhi-appDigitOnly inputmode="numeric" pattern="[0-9]*"
                           formControlName="cardNumber" placeholder="Card number">
                    <div
                        *ngIf="addPaymentCardForm.get('cardNumber').invalid && (addPaymentCardForm.get('cardNumber').touched)">
                        <small class="form-text error-msg" *ngIf="addPaymentCardForm.get('cardNumber').errors.required">
                            Please enter card number.
                        </small>
                        <small class="form-text error-msg"
                               *ngIf="addPaymentCardForm.get('cardNumber').errors.invalidCard">
                            Please enter a valid card number.
                        </small>
                    </div>
                </div>
                <div class="form-group exp-date">
                    <label for="expDate">Exp Date</label><span class="required">*</span>
                    <input type="text" class="form-control" id="expDate" autocomplete="off"
                           [textMask]="{mask: expDateMask}" maxlength="8" formControlName="expDate" placeholder="MM/YYYY">
                    <div
                        *ngIf="addPaymentCardForm.get('expDate').invalid && (addPaymentCardForm.get('expDate').touched)">
                        <small class="form-text error-msg">
                            Please enter valid Exp Date.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="expDate">Is this a FSA card?</label><span class="required">*</span>
                    <select class="form-control" id="isFSACard" name="isFSACard" formControlName="isFSACard">
                        <option value="">Select One</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                    <div
                        *ngIf="addPaymentCardForm.get('isFSACard').invalid && (addPaymentCardForm.get('isFSACard').touched)">
                        <small class="form-text error-msg" *ngIf="addPaymentCardForm.get('isFSACard').errors.required">
                            Please select FSA card value.
                        </small>
                    </div>
                </div>
                <div class="form-group align-inline pb-0">
                    <label for="billingAddress">Billing Address</label>
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" (change)="doCheck(ischecked)" #ischecked type="checkbox"
                               id="sameShipping" name="billingAddress">
                        <label class="custom-control-label font14 same-as-shipping-lbl" for="sameShipping">Same as
                            Shipping</label>
                    </div>
                </div>

                <div class="form-group">
                    <label for="addressLine">address</label><span class="required">*</span>
                    <input [readonly]="ischecked.checked" type="text" class="form-control" id="addressLine"
                           formControlName="addressLine" placeholder="1234 Street Name">
                    <div
                        *ngIf="addPaymentCardForm.get('addressLine').invalid && (addPaymentCardForm.get('addressLine').touched)">
                        <small class="form-text error-msg">
                            Please enter address.
                        </small>
                    </div>
                </div>
                <div class="form-group exp-date">
                    <label for="city">City</label><span class="required">*</span>
                    <input [readonly]="ischecked.checked" type="text" class="form-control" id="city"
                           formControlName="city" placeholder="City">
                    <div *ngIf="addPaymentCardForm.get('city').invalid && (addPaymentCardForm.get('city').touched)">
                        <small class="form-text error-msg">
                            Please enter City.
                        </small>
                    </div>
                </div>
                <div class="form-group align-inline">
                    <div class="mrgn-rt">
                        <label for="addState">State</label><span class="required">*</span>

                        <select class="form-control" id="addState" name="addState" formControlName="addState">
                            <option disabled value="" selected>Select State</option>
                            <option *ngFor="let state of stateList" [value]="state.code">{{state.state}}
                            </option>
                        </select>
                        <div
                            *ngIf="addPaymentCardForm.get('addState').invalid && (addPaymentCardForm.get('addState').touched)">
                            <small class="form-text error-msg">
                                Please select State.
                            </small>
                        </div>
                    </div>
                    <div><label for="zipcode">Zipcode</label><span class="required">*</span>
                        <input [readonly]="ischecked.checked" type="text" maxlength="10" jhi-appDigitOnly
                               inputmode="numeric" pattern="[0-9]*" class="form-control" id="zipcode"
                               formControlName="zipcode" placeholder="Zipcode">
                        <div
                            *ngIf="addPaymentCardForm.get('zipcode').invalid && (addPaymentCardForm.get('zipcode').touched)">
                            <small class="form-text error-msg">
                                Please enter zip-code.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row action m-0 pt-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="col-md-4 p-0">
                <div class="custom-control custom-checkbox set-deafult-wrapper">
                    <input class="custom-control-input" type="checkbox" formControlName="setAsDefault" id="setAsDefault"
                           name="setAsDefault">
                    <label class="custom-control-label font14 set-default-lbl" for="setAsDefault">Save as default
                        payment method</label>
                </div>
                <button type="submit"
                        [disabled]="addPaymentCardForm.invalid || addPaymentCardForm.get('expDate').invalid || isUnauthorized "
                        class="btn btn-enable btn-block cursor-pointer">SAVE</button>
            </div>
        </div>
    </form>
</ng-container>

import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import AccountService from '../../core/auth/account.service';

@Component({
  selector: 'jhi-system-maintenance',
  templateUrl: './system-maintenance.component.html',
  styleUrls: ['./system-maintenance.component.scss']
})
export default class SystemMaintenanceComponent implements OnDestroy {
  private systemMaintenanceComponent$: Subject<void> = new Subject<void>();

  maintainenanceMode: any;

  systemMode: any;

  errormessage: string;

  maintenanceMsg: any;

  systemMsg: any;

  constructor(private accountService: AccountService) {
    this.accountService.isMaintenance.pipe(takeUntil(this.systemMaintenanceComponent$)).subscribe((data) => {
      this.systemMsg = data;
      console.error(this.systemMsg);
    });
    console.error(this.systemMsg);
  }

  showSuccess() {
    this.accountService
      .isSystemMaintenanceModeOn()
      .pipe(takeUntil(this.systemMaintenanceComponent$))
      .subscribe((resp) => {
        if (resp) {
          const systemResponse = JSON.parse(JSON.stringify(resp));

          this.maintenanceMsg = systemResponse.filter((response) => response.notificationType === 'maintenance');
        }
      });
  }

  public ngOnDestroy(): void {
    this.systemMaintenanceComponent$.next();
    this.systemMaintenanceComponent$.unsubscribe();
  }
}

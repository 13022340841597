import { Moment } from 'moment';
import { ICardInfoRx } from 'app/shared/model/card-info-rx.model';

export interface IProfileRx {
  id?: number;
  profileId?: number;
  userId?: number;
  dateOfBirth?: Moment;
  homeAddressLine?: string;
  homeAddressCity?: string;
  homeAddressState?: string;
  homeAddressZip?: string;
  shippingAddressLine?: string;
  shippingAddressCity?: string;
  shippingAddressState?: string;
  shippingAddressZip?: string;
  medicalAllergies?: string;
  primaryContactDetails?: string;
  secondaryContactDetails?: string;
  otherContactDetails?: string;
  primaryEmailAddress?: string;
  secondaryEmailAddress?: string;
  cardInfos?: ICardInfoRx[];
}

export interface IProfileResponse {
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: Moment;
  profileId?: string;
}

export default class ProfileRx implements IProfileRx {
  constructor(
    public id?: number,
    public profileId?: number,
    public userId?: number,
    public dateOfBirth?: Moment,
    public homeAddressLine?: string,
    public homeAddressCity?: string,
    public homeAddressState?: string,
    public homeAddressZip?: string,
    public shippingAddressLine?: string,
    public shippingAddressCity?: string,
    public shippingAddressState?: string,
    public shippingAddressZip?: string,
    public medicalAllergies?: string,
    public primaryContactDetails?: string,
    public secondaryContactDetails?: string,
    public otherContactDetails?: string,
    public primaryEmailAddress?: string,
    public secondaryEmailAddress?: string,
    public cardInfos?: ICardInfoRx[]
  ) {}
}

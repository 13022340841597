<div>
    <div class="modal-header">
        <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
        <table class="table table-bordered blue-color">
            <thead>
                <tr><th id="suggestion">USPS Suggestion</th><th id="userinput">User Input</th></tr>
            </thead>
            <tbody>
                <tr><td>{{ uspsAddressLine }}<br></td><td>{{ userAddressLine }}</td></tr>
                <tr><td>{{ uspsCity }}<br></td><td>{{ userCity }}</td></tr>
                <tr><td>{{ uspsState }}<br></td><td>{{ userState }}</td></tr>
                <tr><td>{{ uspsZip }}<br></td><td>{{ userZip }}</td></tr>
            </tbody>
            <tfoot [hidden]="hideButtons">
                <tr>
                    <td><button type="button" class="btn btn-option" (click)="keepUsps()">Save USPS Address</button></td>
                    <td><button type="button" class="btn btn-option" (click)="keepUser()">Save User Input</button></td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div class="modal-footer" [hidden]="hideButtons">
        <button type="button" class="btn btn-option" (click)="continueEdit()">Return and Edit Address</button>
    </div>
</div>

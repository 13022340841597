import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export default class Register {
  constructor(private http: HttpClient, private router: Router) {}

  save(account: any): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/register`, account);
  }

  registerUser(account: any): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/rx/rxProfile`, account);
  }

  updateUser(account: any): Observable<any> {
    return this.http.put(`${SERVER_API_URL}api/rx/rxProfile`, account);
  }
}

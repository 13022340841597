import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import BjcrxProfileRxModule from 'app/entities/profile-rx/profile-rx.module';
import BjcrxCardInfoRxModule from 'app/entities/card-info-rx/card-info-rx.module';
import BjcrxPrescriptionTxReqRxModule from 'app/entities/prescription-tx-req-rx/prescription-tx-req-rx.module';
import BjcrxPrescriptionRxModule from 'app/entities/prescription-rx/prescription-rx.module';
import BjcrxCouponRxModule from 'app/entities/coupon-rx/coupon-rx.module';
import BjcrxPharmacyLocationsRxModule from 'app/entities/pharmacy-locations-rx/pharmacy-locations-rx.module';
import BjcrxCustomerStatementRxModule from 'app/entities/customer-statement-rx/customer-statement-rx.module';
import BjcrxNotificationMessageRxModule from 'app/entities/notification-message-rx/notification-message-rx.module';
import BjcrxInfromationModule from 'app/entities/information/information.module';
import BjcrxSystemMaintenanceModule from 'app/entities/system-maintenance/system-maintenance.module';
import BjcrxContactUsModule from 'app/entities/contact-us/contact-us.module';
import AuthGuardService from '../core/auth/auth-guard.service';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'profile-rx',
        loadChildren: () => import('./profile-rx/profile-rx.module').then(() => BjcrxProfileRxModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'card-info-rx',
        loadChildren: () => import('./card-info-rx/card-info-rx.module').then(() => BjcrxCardInfoRxModule)
      },
      {
        path: 'prescription-tx-req-rx',
        loadChildren: () => import('./prescription-tx-req-rx/prescription-tx-req-rx.module').then(() => BjcrxPrescriptionTxReqRxModule)
      },
      {
        path: 'prescription-rx',
        loadChildren: () => import('./prescription-rx/prescription-rx.module').then(() => BjcrxPrescriptionRxModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'coupon-rx',
        loadChildren: () => import('./coupon-rx/coupon-rx.module').then(() => BjcrxCouponRxModule)
      },
      {
        path: 'pharmacy-locations-rx',
        loadChildren: () => import('./pharmacy-locations-rx/pharmacy-locations-rx.module').then(() => BjcrxPharmacyLocationsRxModule)
      },
      {
        path: 'customer-statement-rx',
        loadChildren: () => import('./customer-statement-rx/customer-statement-rx.module').then(() => BjcrxCustomerStatementRxModule)
      },
      {
        path: 'notification-message-rx',
        loadChildren: () => import('./notification-message-rx/notification-message-rx.module').then(() => BjcrxNotificationMessageRxModule)
      },
      {
        path: 'information',
        loadChildren: () => import('./information/information.module').then(() => BjcrxInfromationModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./system-maintenance/system-maintenance.module').then(() => BjcrxSystemMaintenanceModule)
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./contact-us/contact-us.module').then(() => BjcrxContactUsModule)
      }
      /* jhipster-needle-add-entity-route - JHipster will add entity modules routes here */
    ]),
    ReactiveFormsModule
  ]
})
export default class BjcrxEntityModule {}

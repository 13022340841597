import { Moment } from 'moment';

export interface ICouponRx {
  id?: number;
  couponId?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  endDate?: Moment;
  primaryPhone?: string;
  emailId?: string;
  isManual?: boolean;
  frontOfCardPath?: string;
  backOfCardPath?: string;
  bin?: string;
  pcn?: string;
  groupId?: string;
  cardHolderId?: string;
  drugName?: string;
}

export class CouponRx implements ICouponRx {
  constructor(
    public id?: number,
    public couponId?: number,
    public firstName?: string,
    public middleName?: string,
    public lastName?: string,
    public endDate?: Moment,
    public primaryPhone?: string,
    public emailId?: string,
    public isManual?: boolean,
    public frontOfCardPath?: string,
    public backOfCardPath?: string,
    public bin?: string,
    public pcn?: string,
    public groupId?: string,
    public cardHolderId?: string,
    public drugName?: string
  ) {
    this.isManual = this.isManual || false;
  }
}

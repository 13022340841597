<ng-container>
    <h1 class="pageTitle">
        <a (click)="previousState()" class="back-icon" *ngIf="showMessage === false">
            <img src="../../../../content/images/_ionicons_svg_md-arrow-back@2x.png" alt="back" width="20px" height="20px" />
        </a>
        Create Account
    </h1>
    <ng-container *ngIf="showMessage === false && loading === false">
        <form [formGroup]="profileMailOrderForm" (ngSubmit)="onSubmit()">
            <div class="content with-action">
                <h3 class="heading">Personal Details</h3>
                <div class="col-lg-4 col-md-6">
                    <div class="form-group">
                        <label class="form-control-label" for="firstName">First Name</label>
                        <span class="required">*</span>
                        <input type="text" class="form-control" id="firstName" name="firstName" placeholder="First Name" jhiTrimInput
                            formControlName="firstName" autocomplete="off" [attr.readonly]="userDetails ?  'readonly' : null">
                        <div
                            *ngIf="profileMailOrderForm.get('firstName').invalid && (submitted || profileMailOrderForm.get('firstName').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('firstName').errors.required">
                                Please enter first name.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('firstName').errors.pattern">
                                Please enter only alphabetical letters.
                            </small>
                        </div>
                    </div>
                    
                    <div class="form-group">
                        <label class="form-control-label" for="lastName">Last Name</label>
                        <span class="required">*</span>
                        <input type="text" class="form-control" id="lastName" name="lastName" placeholder="Last Name" jhiTrimInput
                            formControlName="lastName" autocomplete="off" [attr.readonly]="userDetails ? 'readonly' : null">
                        <div
                            *ngIf="profileMailOrderForm.get('lastName').invalid && (submitted || profileMailOrderForm.get('lastName').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('lastName').errors.required">
                                Please enter last name.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('lastName').errors.pattern">
                                Please enter only alphabetical letters.
                            </small>
                        </div>
                    </div>
                	
                    <div class="form-group">
                        <label class="form-control-label" for="dateOfBirth">Date of Birth</label>
                        <span class="required">*</span>

                        <div class="input-group" *ngIf="userDetails && userDetails?.publicAuthenticationDTO.dateOfBirth">
                            <input type="text" class="form-control" id="dateOfBirth" name="dateOfBirth"
                                   placeholder="Date of Birth" formControlName="dateOfBirth"
                                   value="{{ userDetails.publicAuthenticationDTO.dateOfBirth | date: 'MM/dd/yyyy'}}" readonly />
                        </div>
                        <div class="input-group" *ngIf="(userDetails===null || userDetails?.publicAuthenticationDTO.dateOfBirth === null) && checkIsIEBrowser() === false">
                            <input type="date" class="form-control" id="dateOfBirth" name="dateOfBirth" max="9999-12-31"
                                   placeholder="Date of Birth" formControlName="dateOfBirth" ngbDatepicker
                                   #dobDp="ngbDatepicker" autocomplete="off" />
                            <span class="input-group-append" (click)="dobDp.toggle()">
                                <fa-icon [icon]="'calendar-alt'"></fa-icon>
                            </span>
                        </div>
                        <div class="input-group" *ngIf="(userDetails===null || userDetails?.publicAuthenticationDTO.dateOfBirth === null) && checkIsIEBrowser() === true">
                            <input type="text" tabindex="-1" class="form-control" id="dateOfBirth" name="dateOfBirth"
                                max="9999-12-31" placeholder="Date of Birth" formControlName="dateOfBirth" ngbDatepicker
                                #dobDp="ngbDatepicker" autocomplete="off" />
                            <span class="input-group-append">
                                <fa-icon [icon]="'calendar-alt'"></fa-icon>
                            </span>
                            <div class="date-pick-div" (click)="dobDp.toggle()"></div>
                        </div>
                        <div
                            *ngIf="profileMailOrderForm.get('dateOfBirth').invalid && (submitted || (profileMailOrderForm.get('dateOfBirth').touched && checkIsIEBrowser() === false))">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('dateOfBirth').errors.required">
                                Please enter date of birth.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('dateOfBirth').errors.invalidDate">
                                Please enter valid date.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('dateOfBirth').errors.futuredate">
                                Please enter valid date of birth.
                            </small>
                        </div>

                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="gender">Gender</label>
                        <span class="required">*</span>
                        <select class="form-control" id="gender" name="gender"
                            [ngClass]="{ 'is-invalid': profileMailOrderForm.get('gender').invalid && (submitted || profileMailOrderForm.get('gender').touched) }"
                            formControlName="gender" required>
                            <option value="">Select</option>
                            <option *ngFor="let g of genderList" [value]="g">{{g}}
                            </option>
                        </select>
                        <div
                            *ngIf="profileMailOrderForm.get('gender').invalid && (submitted || profileMailOrderForm.get('gender').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('gender').errors.required">
                                Please select gender.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="insuranceMemberId">Insurance Member ID#</label><span
                            class="required">*</span>
                        <input type="text" class="form-control" maxlength="17" id="insuranceMemberId"
                            name="insuranceMemberId" placeholder="from Cigna card" formControlName="insuranceMemberId"
                            autocomplete="off">
                        <div
                            *ngIf="profileMailOrderForm.get('insuranceMemberId').invalid && (submitted || profileMailOrderForm.get('insuranceMemberId').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('insuranceMemberId').errors.required">
                                Please enter insurance member id.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="medicationAllergies">Medication Allergies</label>
                        <span class="required">*</span>
                        <textarea class="form-control" id="medicationAllergies" formControlName="medicationAllergies"
                            autocomplete="off" name="medicationAllergies" placeholder=""></textarea>
                        <div
                            *ngIf="profileMailOrderForm.get('medicationAllergies').invalid && (submitted || profileMailOrderForm.get('medicationAllergies').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('medicationAllergies').errors.required">
                                Please enter medication allergies.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="otherAllergies">Other Allergies</label>
                        <span class="required">*</span>
                        <textarea class="form-control" id="otherAllergies" autocomplete="off" name="otherAllergies"
                            placeholder="" formControlName="otherAllergies"></textarea>
                        <div
                            *ngIf="profileMailOrderForm.get('otherAllergies').invalid && (submitted || profileMailOrderForm.get('otherAllergies').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('otherAllergies').errors.required">
                                Please enter other allergies.
                            </small>
                        </div>
                    </div>
                    <h3 class="heading">Contact Information</h3>
                    <div class="form-group">
                        <label class="form-control-label" for="primaryEmailAddress">Email Address</label>
                        <span class="required">*</span>
                        <input type="email" class="form-control" id="primaryEmailAddress" jhiTrimInput
                            [ngClass]="{ 'is-invalid':profileMailOrderForm.get('primaryEmailAddress').invalid && (submitted || profileMailOrderForm.get('primaryEmailAddress').touched) }"
                            name="primaryEmailAddress" placeholder="Email" formControlName="primaryEmailAddress"
                            required autocomplete="off">
                        <div
                            *ngIf="profileMailOrderForm.get('primaryEmailAddress').invalid && (submitted || profileMailOrderForm.get('primaryEmailAddress').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('primaryEmailAddress').errors.required">
                                Please enter your email address.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('primaryEmailAddress').errors.email">
                                Please enter valid email address.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="secondaryEmailAddress">Secondary Email Address</label>
                        <input type="email" class="form-control" id="secondaryEmailAddress"
                            [ngClass]="{ 'is-invalid':profileMailOrderForm.get('secondaryEmailAddress').invalid && (submitted || profileMailOrderForm.get('secondaryEmailAddress').touched) }"
                            name="secondaryEmailAddress" placeholder="Email" formControlName="secondaryEmailAddress"
                            autocomplete="off">
                        <div
                            *ngIf="profileMailOrderForm.get('secondaryEmailAddress').invalid && (submitted || profileMailOrderForm.get('secondaryEmailAddress').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('secondaryEmailAddress').errors.required">
                                Please enter your email address.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('secondaryEmailAddress').errors.email">
                                Please enter valid email address.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="primaryPhone">Primary Phone Number</label>
                        <span class="required">*</span>
                        <input type="tel" class="form-control" id="primaryPhone"
                            [ngClass]="{ 'is-invalid':profileMailOrderForm.get('primaryPhone').invalid && (submitted || profileMailOrderForm.get('primaryPhone').touched) }"
                            name="primaryPhone" jhi-appPhoneMask placeholder="" formControlName="primaryPhone" required autocomplete="off">
                        <div
                            *ngIf="profileMailOrderForm.get('primaryPhone').invalid && (submitted || profileMailOrderForm.get('primaryPhone').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('primaryPhone').errors && profileMailOrderForm.get('primaryPhone').errors.required">
                                Please enter your phone number.
                            </small>
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('primaryPhone').errors
                                    && (profileMailOrderForm.get('primaryPhone').errors.phone || profileMailOrderForm.get('primaryPhone').errors.minlength)">
                                Please enter a valid phone number.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="secondaryPhone">Secondary Phone Number</label>
                        <input type="tel" class="form-control" id="secondaryPhone" jhi-appPhoneMask placeholder=""
                            [ngClass]="{ 'is-invalid':profileMailOrderForm.get('secondaryPhone').invalid && (submitted || profileMailOrderForm.get('secondaryPhone').touched) }"
                            name="secondaryPhone"formControlName="secondaryPhone" autocomplete="off">
                        <div
                            *ngIf="profileMailOrderForm.get('secondaryPhone').invalid && (submitted || profileMailOrderForm.get('secondaryPhone').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('secondaryPhone').errors
                                    && (profileMailOrderForm.get('secondaryPhone').errors.phone || profileMailOrderForm.get('secondaryPhone').errors.minlength)">
                                Please enter a valid phone number.
                            </small>
                        </div>
                    </div>
                    <h3 class="heading">Billing Address</h3>
                    <div class="form-group">
                        <label class="form-control-label" for="billingAddress">Address</label>
                        <span class="required">*</span>
                        <input type="text" class="form-control" id="billingAddress"
                            [ngClass]="{ 'is-invalid':profileMailOrderForm.get('billingAddress').invalid && (submitted || profileMailOrderForm.get('billingAddress').touched) }"
                            name="billingAddress" placeholder="Address" formControlName="billingAddress" required
                            autocomplete="off">
                        <div
                            *ngIf="profileMailOrderForm.get('billingAddress').invalid && (submitted || profileMailOrderForm.get('billingAddress').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('billingAddress').errors.required">
                                Please enter billing address.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="billingCity">City</label><span class="required">*</span>
                        <input type="text" class="form-control" id="billingCity"
                            [ngClass]="{ 'is-invalid':profileMailOrderForm.get('billingCity').invalid && (submitted || profileMailOrderForm.get('billingCity').touched) }"
                            name="billingCity" placeholder="City" formControlName="billingCity" required
                            autocomplete="off">
                        <div
                            *ngIf="profileMailOrderForm.get('billingCity').invalid && (submitted || profileMailOrderForm.get('billingCity').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('billingCity').errors.required">
                                Please enter city.
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-7 pl-0">
                            <label class="form-control-label" for="billingState">State</label>
                            <span class="required">*</span>
                            <select class="form-control" id="billingState" name="billingState"
                                [ngClass]="{ 'is-invalid': profileMailOrderForm.get('billingState').invalid && (submitted || profileMailOrderForm.get('billingState').touched) }"
                                formControlName="billingState" required>
                                <option value="">State</option>
                                <option *ngFor="let state of stateList" [value]="state.code">{{state.state}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col pr-0 pt-0">
                            <label class="form-control-label" for="billingZip">ZIPCODE</label>
                            <span class="required">*</span>
                            <input type="number" class="form-control" id="billingZip"
                                [ngClass]="{ 'is-invalid':profileMailOrderForm.get('billingZip').invalid && (submitted || profileMailOrderForm.get('billingZip').touched) }"
                                name="billingZip" placeholder="Zipcode" formControlName="billingZip" required
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group m-0 p-0"
                        *ngIf="profileMailOrderForm.get('billingState').invalid && (submitted || profileMailOrderForm.get('billingState').touched)">
                        <small class="form-text error-msg"
                            *ngIf="profileMailOrderForm.get('billingState').errors.required">
                            Please select state.
                        </small>
                    </div>
                    <div class="form-group m-0 p-0"
                        *ngIf="profileMailOrderForm.get('billingZip').invalid && (submitted || profileMailOrderForm.get('billingZip').touched)">
                        <small class="form-text error-msg"
                            *ngIf="profileMailOrderForm.get('billingZip').errors.required">
                            Please enter zipcode.
                        </small>
                    </div>
                    <div class="form-group m-0 p-0" *ngIf="isInvalidBillingAddress === true">
                        <small class="form-text error-msg">
                            Please enter valid billing address.
                        </small>
                    </div>
                    <h3 class="heading">Shipping Address</h3>

                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" id="sameAddress" name="sameAddress"
                            formControlName="sameAddress">
                        <label class="custom-control-label font14 same-as-billing-lbl" for="sameAddress">Same as Billing Address</label>
                    </div>

                    <ng-container *ngIf="profileMailOrderForm.value.sameAddress === false">
                        <div class="form-group">
                            <label class="form-control-label" for="shippingAddress">Address</label><span
                                class="required">*</span>
                            <input type="text" class="form-control" id="shippingAddress"
                                [ngClass]="{ 'is-invalid':profileMailOrderForm.get('shippingAddress').invalid && (submitted || profileMailOrderForm.get('shippingAddress').touched) }"
                                name="shippingAddress" placeholder="Address" formControlName="shippingAddress" required
                                autocomplete="off">
                            <div
                                *ngIf="profileMailOrderForm.get('shippingAddress').invalid && (submitted || profileMailOrderForm.get('shippingAddress').touched)">
                                <small class="form-text error-msg"
                                    *ngIf="profileMailOrderForm.get('shippingAddress').errors.required">
                                    Please enter shipping address.
                                </small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="shippingCity">City</label><span
                                class="required">*</span>
                            <input type="text" class="form-control" id="shippingCity"
                                [ngClass]="{ 'is-invalid':profileMailOrderForm.get('shippingCity').invalid && (submitted || profileMailOrderForm.get('shippingCity').touched) }"
                                name="shippingCity" placeholder="City" formControlName="shippingCity" required
                                autocomplete="off">
                            <div
                                *ngIf="profileMailOrderForm.get('shippingCity').invalid && (submitted || profileMailOrderForm.get('shippingCity').touched)">
                                <small class="form-text error-msg"
                                    *ngIf="profileMailOrderForm.get('shippingCity').errors.required">
                                    Please enter city.
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-7 pl-0">
                                <label class="form-control-label" for="shippingState">State</label><span
                                    class="required">*</span>
                                <select class="form-control" id="shippingState" name="shippingState"
                                    [ngClass]="{ 'is-invalid': profileMailOrderForm.get('shippingState').invalid && (submitted || profileMailOrderForm.get('shippingState').touched) }"
                                    formControlName="shippingState" required>
                                    <option value="">State</option>
                                    <option *ngFor="let state of stateList" [value]="state.code">{{state.state}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col pr-0 pt-0">
                                <label class="form-control-label" for="shippingZip">ZIPCODE</label><span
                                    class="required">*</span>
                                <input type="number" class="form-control" id="shippingZip"
                                    [ngClass]="{ 'is-invalid':profileMailOrderForm.get('shippingZip').invalid && (submitted || profileMailOrderForm.get('shippingZip').touched) }"
                                    name="shippingZip" placeholder="Zipcode" formControlName="shippingZip" required
                                    autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group m-0 p-0"
                            *ngIf="profileMailOrderForm.get('shippingState').invalid && (submitted || profileMailOrderForm.get('shippingState').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('shippingState').errors.required">
                                Please select state.
                            </small>
                        </div>
                        <div class="form-group m-0 p-0"
                            *ngIf="profileMailOrderForm.get('shippingZip').invalid && (submitted || profileMailOrderForm.get('shippingZip').touched)">
                            <small class="form-text error-msg"
                                *ngIf="profileMailOrderForm.get('shippingZip').errors.required">
                                Please enter zipcode.
                            </small>
                        </div>
                        <div class="form-group m-0 p-0"
                            *ngIf="isInvalidShippingAddress === true">
                            <small class="form-text error-msg">Please enter valid shipping address. </small>
                        </div>
                    </ng-container>
                    <p class="info-text">
                        The pharmacy can only ship to addresses in Missouri, Illinois, and Kansas. Refrigerated medications
                        cannot be mailed to a P.O. Box.
                    </p>
                    <p class="info-text pt-1">
                        The pharmacy accepts all major credit and debit card types (MasterCard, VISA, Discover and
                        American
                        Express), Flexible Spending Account Card, pre-paid card, or cash card through BJC Payroll. Once
                        your
                        profile is created, you will receive a confirmation
                        email directing you to our secure site where you can provide your card information.
                    </p>
                </div>
            </div>
            <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
                <div class="col-lg-3 col-md-6 p-0">
                    <button type="submit" class="btn btn-enable btn-block"
                        [disabled]="!profileMailOrderForm.touched || (submitted && profileMailOrderForm.invalid)">CONTINUE</button>
                </div>
            </div>
        </form>
    </ng-container>
    <div *ngIf="loading === true && showMessage === false">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-md-12 bjcrx-gif">
                    <div class="gif-text">
                        <div class="bjc-text">
                            <img src="../../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="" />
                            <div class="laoder-text text-center">Processing...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="showMessage === true && loading === false">
        <div class="content">
            <div class="col-md-4">
                <div [ngClass]="{'success-message':success, 'error-message':error}">
                    {{message}}
                </div>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="col-lg-3 col-md-6 p-0">
                <button type="button" *ngIf="error" (click)="tryagain()" class="btn btn-enable btn-block">TRY
                    AGAIN</button>
                <button type="button" *ngIf="userDetails?.isMinor" (click)="previousState()" class="btn btn-enable btn-block">RETURN</button>
            </div>
        </div>
    </ng-container>

</ng-container>


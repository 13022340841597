import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import {
  FormBuilder, Validators, FormGroup, ValidatorFn, ValidationErrors
} from '@angular/forms';
import {
  ActivatedRoute, Router
} from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { JhiAlertService } from 'ng-jhipster';
import { IProfileRx } from 'app/shared/model/profile-rx.model';
import ProfileRxService from 'app/entities/profile-rx/profile-rx.service';
import AccountService from 'app/core/auth/account.service';
import { Location } from '@angular/common';
import { STATE_DATA } from 'app/app.constants';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { SessionStorageService } from 'ngx-webstorage';
import PrescriptionRxService from '../prescription-rx/prescription-rx.service';
import CardInfoRxService from './card-info-rx.service';

@Component({
  selector: 'jhi-card-info-rx-update',
  templateUrl: './card-info-rx-update.component.html',
  styleUrls: ['./card-info-rx-update.scss']
})
export default class CardInfoRxUpdateComponent implements OnInit, OnDestroy {
  private cardInfoRxUpdateComponent$: Subject<void> = new Subject<void>();

  profiles: IProfileRx[];

  expiryDateDp: any;

  rxPatientId: any;

  dependentListFromApi: any;

  sameAsShippingAddress: boolean;

  first6Digits: any;

  stateList;

  profile: any;

  isChecked: boolean;

  showMessage: boolean;

  setInput: boolean;

  showSuccessMessage: boolean;

  showErrorMessage: boolean;

  maxAddCardLimit: boolean;

  peopleSoftId: any;

  showDropDown: any;

  isUnauthorized: boolean;

  showDependents: boolean;

  public expDateMask = [/[0-1]/, /[0-9]/, '/', /[2-3]/, /\d/, /\d/, /\d/];

  nameValidationPattern = "^[a-zA-Z\\'\\-\\ \\.]+$";

  patientId;

  slideScreen;

  showLoader = true;

  currentAccount;

  isEdit = false;

  message = '';

  acceptedCreditCards = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
    amex: /^3[47][0-9]{13}$/,
    discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
    dinersclub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
  };

  editForm = this.fb.group({
    id: [],
    primary: [],
    cardNo: [null, [Validators.maxLength(40)]],
    expiryDate: [],
    type: [null, [Validators.maxLength(10)]],
    profileIdId: [null]
  });

  addPaymentCardForm;

  cardInfo: any;

  profileData;

  showPharmacyErrorMesage = false;

  constructor(
    protected jhiAlertService: JhiAlertService,
    protected cardInfoService: CardInfoRxService,
    protected profileService: ProfileRxService,
    protected activatedRoute: ActivatedRoute,
    protected accountService: AccountService,
    private fb: FormBuilder,
    protected router: Router,
    private _location: Location,
    private $sessionStorage: SessionStorageService,
    protected prescriptionService: PrescriptionRxService
  ) {
    this.dependentListFromApi = [];
    this.activatedRoute.params.pipe(takeUntil(this.cardInfoRxUpdateComponent$)).subscribe((params) => {
      this.patientId = params?.id;
    });
    this.activatedRoute.queryParams.pipe(takeUntil(this.cardInfoRxUpdateComponent$)).subscribe((queryParams) => {
      this.cardInfo = JSON.parse(atob(queryParams.data));
    });
    this.isEdit = this.$sessionStorage.retrieve('isEdit');
    this.addPaymentCardForm = this.fb.group(
      {
        patientName: [{ value: '', disabled: true }],
        firstName: [{ value: '', disabled: this.isEdit }, [Validators.required, Validators.pattern(this.nameValidationPattern)]],
        middleName: [{ value: '', disabled: this.isEdit }, Validators.pattern('^[a-zA-Z ]*$')],
        lastName: [{ value: '', disabled: this.isEdit }, [Validators.required, Validators.pattern(this.nameValidationPattern)]],
        cardNumber: [{ value: '', disabled: this.isEdit }, [Validators.required]],
        expDate: ['', [Validators.pattern(/^((0[1-9])|(1[0-2]))\/((2018)|(2[0-9][0-9][0-9]))$/), Validators.required]],
        isBillingAddress: [{ value: '', disabled: this.isEdit }],
        primaryPerson: [{ value: '', disabled: this.isEdit }],
        dependentPerson: [{ value: '', disabled: this.isEdit }],
        setAsDefault: [false],
        addressLine: [{ value: '', disabled: this.isEdit }, Validators.required],
        city: [{ value: '', disabled: this.isEdit }, Validators.required],
        addState: [{ value: 'MO', disabled: this.isEdit }, Validators.required],
        zipcode: [{ value: '', disabled: this.isEdit }, Validators.required],
        isFSACard: ['', Validators.required]
      },
      { validators: this.validateForm(this.acceptedCreditCards) }
    );
  }

  setCardInfo() {
    this.addPaymentCardForm.get('firstName').setValue(this.cardInfo.firstName);
    this.addPaymentCardForm.get('middleName').setValue(this.cardInfo.middleName);
    this.addPaymentCardForm.get('lastName').setValue(this.cardInfo.lastName);
    this.addPaymentCardForm.get('cardNumber').setValue(`**** **** **** ${this.cardInfo.last4Digits}`);
    this.addPaymentCardForm.get('setAsDefault').setValue(this.cardInfo.isDefaultCard);
    this.addPaymentCardForm.get('expDate').setValue(this.cardInfo.expirationDate);
    this.addPaymentCardForm.get('addressLine').setValue(this.cardInfo.billingAddress.line1);
    this.addPaymentCardForm.get('city').setValue(this.cardInfo.billingAddress.city);
    this.addPaymentCardForm.get('addState').setValue(this.cardInfo.billingAddress.state);
    this.addPaymentCardForm.get('zipcode').setValue(this.cardInfo.billingAddress.zip);
    this.addPaymentCardForm.get('isFSACard').setValue(this.cardInfo.isFSA === true ? 'yes' : 'no');
  }

  getPayloadForUpdate() {
    // this.addline2 = this.cardInfo?.line2
    const payload = {
      billingAddress: {
        city: this.addPaymentCardForm.get('city').value,
        line1: this.addPaymentCardForm.get('addressLine').value,
        // line2: "",
        state: this.addPaymentCardForm.get('addState').value,
        zip: this.addPaymentCardForm.get('zipcode').value
      },
      last4Digits: this.cardInfo.last4Digits,
      first6Digits: this.cardInfo.first6Digits,
      cardType: this.cardInfo.cardType,
      newExpirationDate: this.addPaymentCardForm.value.expDate.toString(),
      expirationDate: this.cardInfo.expirationDate,
      status: this.cardInfo.status,
      isDefaultCard: this.addPaymentCardForm.value.setAsDefault,
      isFSA: this.addPaymentCardForm.get('isFSACard').value === 'yes',
      isAutoRefillCard: this.cardInfo.isAutoRefillCard,
      isOneTimeCard: this.cardInfo.isOneTimeCard,
      firstName: this.addPaymentCardForm.get('firstName').value,
      middleName: this.addPaymentCardForm.get('middleName').value,
      lastName: this.addPaymentCardForm.get('lastName').value,
      fullCardNumber: this.cardInfo.fullCardNumber, // this.addPaymentCardForm.get('cardNumber').value,
      ccToken: this.cardInfo.ccToken,
      primaryUserPatientId: this.currentAccount.enterpriseRxPatientId
    };
    return payload;
  }

  validateForm(cardInfo): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      let accepted = false;
      const enteredCardNumber = group.get('cardNumber').value;
      Object.keys(cardInfo).forEach((key) => {
        const regex = cardInfo[key];
        if (regex.test(enteredCardNumber)) {
          accepted = true;
        }
      });
      if (!accepted) {
        group.get('cardNumber').setErrors({ invalidCard: true });
      } else {
        group.get('cardNumber').setErrors(null);
      }

      if (group.get('expDate').valid) {
        const cardExpiry = group.get('expDate').value;
        if (moment(cardExpiry, 'MM/YYYYY') < moment()) {
          group.get('expDate').setErrors({ invalidExpiry: true });
        } else {
          group.get('expDate').setErrors(null);
        }
      }

      return null;
    };
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.cardInfoRxUpdateComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.showErrorMessage = false;
    this.showSuccessMessage = false;
    this.showMessage = false;
    this.message = '';
    this.setInput = false;
    this.maxAddCardLimit = false;
    this.stateList = STATE_DATA;
    this.getProfileDetails(this.patientId);
    this.accountService.identity().then((account) => {
      this.currentAccount = account;
    });
    if (this.cardInfo) {
      this.setCardInfo();
    }
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  doCheck(ischecked) {
    if (ischecked.checked) {
      this.setInput = true;
      this.addPaymentCardForm.get('addressLine').setValue(this.profile.patientAddressDTO?.line1);
      this.addPaymentCardForm.get('city').setValue(this.profile.patientAddressDTO?.city);
      this.addPaymentCardForm.get('addState').setValue(this.profile.patientAddressDTO?.state);
      this.addPaymentCardForm.get('zipcode').setValue(this.profile.patientAddressDTO?.zip);
      this.addPaymentCardForm.get('addState').disable();
    } else {
      this.addPaymentCardForm.get('addState').enable();
      this.addPaymentCardForm.patchValue({
        addressLine: '',
        city: '',
        addState: '',
        zipcode: ''
      });
    }
  }

  getProfileDetails(patientId: number) {
    this.profileService
      .getProfile(patientId)
      .pipe(takeUntil(this.cardInfoRxUpdateComponent$))
      .subscribe(
        (res) => {
          this.profile = res;
          this.addPaymentCardForm.get('patientName').setValue(`${this.profile.firstName} ${this.profile.lastName}`);
        },
        (error) => {
          this.onError(error.error.detail ? error.error.detail : 'Oops!! Something went wrong, please try again.');
        }
      );
  }

  savePaymentCard() {
    this.showLoader = false;
    if (this.isEdit) {
      this.updatePaymentCard();
    } else {
      const payload = {
        billingAddress: {
          city: this.addPaymentCardForm.value.city,
          line1: this.addPaymentCardForm.value.addressLine,
          line2: '',
          state: this.addPaymentCardForm.value.addState ? this.addPaymentCardForm.value.addState : this.profile.patientAddressDTO?.state,
          zip: this.addPaymentCardForm.value.zipcode.toString()
        },
        expirationDate: this.addPaymentCardForm.value.expDate.toString(),
        fullCardNumber: this.addPaymentCardForm.value.cardNumber,
        isDefaultCard: this.addPaymentCardForm.value.setAsDefault ? this.addPaymentCardForm.value.setAsDefault : false,
        isOneTimeCard: false,
        firstName: this.addPaymentCardForm.value.firstName,
        middleName: this.addPaymentCardForm.value.middleName ? this.addPaymentCardForm.value.middleName : ' ',
        lastName: this.addPaymentCardForm.value.lastName,
        primaryUserPatientId: this.currentAccount.enterpriseRxPatientId,
        isFSA: this.addPaymentCardForm.value.isFSACard === 'yes'
      };

      this.cardInfoService
        .addCardPayment(payload, this.patientId)
        .pipe(takeUntil(this.cardInfoRxUpdateComponent$))
        .subscribe(
          () => {
            this.showLoader = true;
            this.showSuccessMessage = true;
            this.showMessage = true;
            this.message = 'Card Added Successfully';
          },
          (error) => {
            if (error) {
              this.showLoader = true;
              this.showMessage = true;
              if (error.status === 500 || error.status === 400 || error.status === 401) {
                if (error.error === 'Maximum number of Credit Cards per Patient exceeded') {
                  this.maxAddCardLimit = true;
                  this.showErrorMessage = false;
                } else {
                  this.showErrorMessage = true;
                  this.message = 'Encountered an error while adding the credit card. Please try again after some time.';
                }
              } else {
                this.showSuccessMessage = true;
                this.message = 'Card Added Successfully';
              }
            }
          }
        );
    }
  }

  updatePaymentCard() {
    if (this.profile.defaultPharmacyId) {
      const payload = this.getPayloadForUpdate();
      this.cardInfoService.updateCardInfo(payload, this.patientId, this.profile.defaultPharmacyId).subscribe(
        () => {
          this.showLoader = true;
          this.showSuccessMessage = true;
          this.showMessage = true;
          this.message = 'Card Updated Successfully';
        },
        () => {
          this.showLoader = true;
          this.showErrorMessage = true;
          this.showMessage = true;
          this.showSuccessMessage = false;
          this.message = 'Failed to update card.';
        }
      );
    } else {
      this.showErrorMessage = true;
      this.showMessage = true;
      this.showLoader = true;
      this.message =
        'This Patient does not have Default Pharmacy. <a (click)="editProfile()">Click here to set Default Pharmacy first in Profile.</a>';
    }
  }

  editProfile() {
    this.router.navigate(['/profile-rx', this.currentAccount.enterpriseRxPatientId, 'edit']);
  }

  validateAlphanumeric(e) {
    if (e.key.match(/[0-9a-zA-Z ]/)) {
      return true;
    }
    return false;
  }

  protected onError(errorMessage: string) {
    this.jhiAlertService.error(errorMessage, null, null);
  }

  public ngOnDestroy(): void {
    this.cardInfoRxUpdateComponent$.next();
    this.cardInfoRxUpdateComponent$.unsubscribe();
  }

  previousState() {
    if (this.showMessage && this.showErrorMessage) {
      this.showMessage = false;
      this.showErrorMessage = false;
      this.showSuccessMessage = false;
      this.maxAddCardLimit = false;
    } else {
      this.router.navigate(['/card-info-rx']);
    }
  }
}

// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

// eslint-disable-next-line prefer-destructuring
export const VERSION = process.env.VERSION;
export const DEBUG_INFO_ENABLED = !!process.env.DEBUG_INFO_ENABLED;
// eslint-disable-next-line prefer-destructuring
export const SERVER_API_URL = process.env.SERVER_API_URL;
// eslint-disable-next-line prefer-destructuring
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;
export const STATE_DATA = [
  { code: 'AL', state: 'Alabama' },
  { code: 'AK', state: 'Alaska' },
  { code: 'AZ', state: 'Arizona' },
  { code: 'AR', state: 'Arkansas' },
  { code: 'CA', state: 'California' },
  { code: 'CO', state: 'Colorado' },
  { code: 'CT', state: 'Connecticut' },
  { code: 'DE', state: 'Delaware' },
  { code: 'FL', state: 'Florida' },
  { code: 'GA', state: 'Georgia' },
  { code: 'HI', state: 'Hawaii' },
  { code: 'ID', state: 'Idaho' },
  { code: 'IL', state: 'Illinois' },
  { code: 'IN', state: 'Indiana' },
  { code: 'IA', state: 'Iowa' },
  { code: 'KS', state: 'Kansas' },
  { code: 'KY', state: 'Kentucky' },
  { code: 'LA', state: 'Louisiana' },
  { code: 'ME', state: 'Maine' },
  { code: 'MD', state: 'Maryland' },
  { code: 'MA', state: 'Massachusetts' },
  { code: 'MI', state: 'Michigan' },
  { code: 'MN', state: 'Minnesota' },
  { code: 'MS', state: 'Mississippi' },
  { code: 'MO', state: 'Missouri' },
  { code: 'MT', state: 'Montana' },
  { code: 'NE', state: 'Nebraska' },
  { code: 'NV', state: 'Nevada' },
  { code: 'NH', state: 'New Hampshire' },
  { code: 'NJ', state: 'New Jersey' },
  { code: 'NM', state: 'New Mexico' },
  { code: 'NY', state: 'New York' },
  { code: 'NC', state: 'North Carolina' },
  { code: 'ND', state: 'North Dakota' },
  { code: 'OH', state: 'Ohio' },
  { code: 'OK', state: 'Oklahoma' },
  { code: 'OR', state: 'Oregon' },
  { code: 'PA', state: 'Pennsylvania' },
  { code: 'RI', state: 'Rhode Island' },
  { code: 'SC', state: 'South Carolina' },
  { code: 'SD', state: 'South Dakota' },
  { code: 'TN', state: 'Tennessee' },
  { code: 'TX', state: 'Texas' },
  { code: 'UT', state: 'Utah' },
  { code: 'VT', state: 'Vermont' },
  { code: 'VA', state: 'Virginia' },
  { code: 'WA', state: 'Washington' },
  { code: 'WV', state: 'West Virginia' },
  { code: 'WI', state: 'Wisconsin' },
  { code: 'WY', state: 'Wyoming' }
];
export const DFLT_PRMCY_MODULE_CODE = 'DFLT_PRMCY';
export const USPS_ID = '484BJCHE2206';
export const environments = {
  firebase: {
    apiKey: 'AIzaSyDvmr2KzTvl1WDSOeLm0ZB8QcR-p83K7EY',
    authDomain: 'fleet-day-269619.firebaseapp.com',
    databaseURL: 'https://fleet-day-269619.firebaseio.com',
    projectId: 'fleet-day-269619',
    storageBucket: 'fleet-day-269619.appspot.com',
    messagingSenderId: '982084337230',
    appId: '1:982084337230:web:1e149a69c3d11d6c974d1f',
    measurementId: 'G-HZ3JYR482L'
  },
  versionCheckURL: '/version.json'
};

const ua = window.navigator.userAgent;

export const isIEBrowser = ua.indexOf('Trident/') > 0 || ua.indexOf('Edge/') > 0 || ua.indexOf('MSIE ') > 0;

export const prescriptionGlobalErrorMessage =
  'An unexpected error has occurred. Please click here to go back to the home page and try again. If the problem persists contact our help desk at (314) 362-4700';

export const refillReminderHelpText =
  'Refill reminders is a service that will notify you by text or email on prescriptions selected within BJCRx when it is time to reorder, once you setup your Notifications.';

export const passwordErrorMinimum14Chars = 'Password must contain at least 14 characters.';

export const passwordErrorMustContainUppercase = 'Password must contain at least one uppercase character.';

export const passwordErrorMustContainLowercase = 'Password must contain at least one lowercase character.';

export const passwordErrorMustContainNumericOrSpecialChar =
  'Password must contain at least one number or special character (-$_!%*& or 0123456789).';

export const passwordErrorCannotContainUsername = 'Password cannot contain your username.';

export const passwordErrorCannotContainFirstOrLastName = 'Password cannot contain your first or last name.';

export const passwordErrorCannotContainEmail = 'Password cannot contain your email.';

export const passwordErrorNoSpace = 'Password cannot contain a space.';

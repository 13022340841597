import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DEBUG_INFO_ENABLED } from 'app/app.constants';
import BjcrxAccountModule from 'app/account/account.module';
import errorRoute from './layouts/error/error.route';
import navbarRoute from './layouts/navbar/navbar.route';
import AuthGuardService from './core/auth/auth-guard.service';

const LAYOUT_ROUTES = [navbarRoute, ...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'account',
          loadChildren: () => import('./account/account.module').then(() => BjcrxAccountModule),
          canActivate: [AuthGuardService]
        },
        ...LAYOUT_ROUTES
      ],
      { enableTracing: DEBUG_INFO_ENABLED }
    )
  ],
  exports: [RouterModule]
})
export default class BjcrxAppRoutingModule {}

import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import {
  Resolve, ActivatedRouteSnapshot, Routes
} from '@angular/router';
import { JhiResolvePagingParams } from 'ng-jhipster';
import UserRouteAccessService from 'app/core/auth/user-route-access-service';
import {
  Observable, of
} from 'rxjs';
import {
  filter, map
} from 'rxjs/operators';
import {
  PrescriptionRx, IPrescriptionRx
} from 'app/shared/model/prescription-rx.model';
import PrescriptionRxService from './prescription-rx.service';
import PrescriptionRxComponent from './prescription-rx.component';

@Injectable({ providedIn: 'root' })
export class PrescriptionRxResolve implements Resolve<IPrescriptionRx> {
  constructor(private service: PrescriptionRxService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IPrescriptionRx> {
    const { id } = route.params;
    if (id) {
      return this.service.find(id).pipe(
        filter((response: HttpResponse<PrescriptionRx>) => response.ok),
        map((prescription: HttpResponse<PrescriptionRx>) => prescription.body)
      );
    }
    return of(new PrescriptionRx());
  }
}

export const prescriptionRoute: Routes = [
  {
    path: '',
    redirectTo: 'refill/search',
    component: PrescriptionRxComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams
    },
    data: {
      pageTitle: 'bjcrxApp.prescription.home.title'
    }
  },
  {
    path: '',
    redirectTo: 'refill/list',
    component: PrescriptionRxComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams
    },
    data: {
      pageTitle: 'bjcrxApp.prescription.home.title'
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'refill/:step',
    component: PrescriptionRxComponent,
    resolve: {
      prescription: PrescriptionRxResolve
    },
    data: {
      authorities: ['ROLE_USER'],
      pageTitle: 'bjcrxApp.prescription.home.title'
    }
  }
];

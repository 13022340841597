import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import BjcrxSharedModule from 'app/shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import PharmacyActiveFilterPipe from 'app/entities/pharmacy-locations-rx/pharmacy-active-filter.pipe';
import { pharmacyLocationsRoute } from './pharmacy-locations-rx.route';
import PharmacyLocationsRxComponent from './pharmacy-locations-rx.component';

const ENTITY_STATES = [...pharmacyLocationsRoute];

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild(ENTITY_STATES), AgmCoreModule, AgmDirectionModule],
  declarations: [PharmacyLocationsRxComponent, PharmacyActiveFilterPipe]
})
export default class BjcrxPharmacyLocationsRxModule {}

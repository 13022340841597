import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DateValidator, CheckFutureDate } from 'app/shared/util/request-util';
import AccountService from 'app/core/auth/account.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isIEBrowser } from 'app/app.constants';
import * as moment from 'moment';

@Component({
  selector: 'jhi-password-reset-init',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['forgot-username.scss']
})
export default class ForgotUsernameComponent implements OnInit, OnDestroy {
  private forgotUsernameComponent$: Subject<void> = new Subject<void>();

  showmessage = false;

  submitted = false;

  apiCalled = false;

  message;

  serverError = 'Oops!! Something went wrong, please try again.';

  error = false;

  success = false;

  slideScreen;

  errorEmailNotExists: string;

  forgotUsernameForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    dateOfBirth: new FormControl<string | moment.Moment>('', [Validators.required, DateValidator(), CheckFutureDate()]),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private accountService: AccountService, private router: Router) {}

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.forgotUsernameComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
  }

  tryagain() {
    this.showmessage = false;
    this.error = false;
    this.success = false;
    this.submitted = false;
    this.message = '';
    this.forgotUsernameForm.get('dateOfBirth').setValue(moment(this.forgotUsernameForm.value.dateOfBirth));
  }

  checkIsIEBrowser() {
    return isIEBrowser;
  }

  login() {
    this.router.navigate(['/account/login']);
  }

  requestReset() {
    this.error = false;
    this.success = false;

    this.submitted = true;
    if (this.forgotUsernameForm.invalid) {
      if (
        this.forgotUsernameForm.get('dateOfBirth').errors &&
        !this.forgotUsernameForm.get('dateOfBirth').errors?.required &&
        !this.forgotUsernameForm.get('dateOfBirth').errors?.invalidDate &&
        !this.forgotUsernameForm.get('dateOfBirth').errors?.futuredate
      ) {
        this.forgotUsernameForm.get('dateOfBirth').setErrors(null);
      }
    }
    if (!this.forgotUsernameForm.invalid) {
      this.apiCalled = true;
      const dataset = this.forgotUsernameForm.value;
      dataset.dateOfBirth = moment(dataset.dateOfBirth).format('YYYY-MM-DD');
      this.accountService
        .assistForgotUsername(this.forgotUsernameForm.value)
        .pipe(takeUntil(this.forgotUsernameComponent$))
        .subscribe(
          (res) => {
            if (res.hasError) {
              this.error = true;
            } else {
              this.success = true;
            }
            this.message = res.message;
            this.showmessage = true;
            this.apiCalled = false;
          },
          (error) => {
            this.success = false;
            this.apiCalled = false;
            this.message = error.error.detail ? error.error.detail : this.serverError;
            this.error = true;
            this.showmessage = true;
          }
        );
    }
  }

  validateAlphabets(e) {
    if (e.key.match(/^[a-zA-Z\\'\\-\\ \\.]+$/)) {
      return true;
    }
    return false;
  }

  public ngOnDestroy(): void {
    this.forgotUsernameComponent$.next();
    this.forgotUsernameComponent$.unsubscribe();
  }
}

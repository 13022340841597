import { Route } from '@angular/router';
import ContactUsComponent from './contact-us.component';

const CONTACTUS_ROUTE: Route = {
  path: '',
  component: ContactUsComponent,
  data: {
    authorities: [],
    pageTitle: 'home.title'
  }
};

export default CONTACTUS_ROUTE;

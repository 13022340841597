import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import BjcrxSharedModule from 'app/shared/shared.module';
import CONTACTUS_ROUTE from './contact-us.route';
import ContactUsComponent from './contact-us.component';

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild([CONTACTUS_ROUTE])],
  declarations: [ContactUsComponent]
})
export default class BjcrxContactUsModule {}

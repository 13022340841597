import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'jhi-error',
  templateUrl: './error.component.html'
})
export default class ErrorComponent implements OnInit, OnDestroy {
  private errorComponent$: Subject<void> = new Subject<void>();

  errorMessage: string;

  error403: boolean;

  error404: boolean;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.pipe(takeUntil(this.errorComponent$)).subscribe((routeData) => {
      if (routeData.error403) {
        this.error403 = routeData.error403;
      }
      if (routeData.error404) {
        this.error404 = routeData.error404;
      }
      if (routeData.errorMessage) {
        this.errorMessage = routeData.errorMessage;
      }
    });
  }

  public ngOnDestroy(): void {
    this.errorComponent$.next();
    this.errorComponent$.unsubscribe();
  }
}

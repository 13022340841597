import { Route } from '@angular/router';
import LoginComponent from './login.component';

const loginRoute: Route = {
  path: 'login',
  component: LoginComponent,
  data: {
    authorities: [],
    pageTitle: 'login.title'
  }
};

export default loginRoute;

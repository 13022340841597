import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][jhi-appNumberMaxlength]'
})
export default class NumberMaxlengthDirective {
  @Input() maxl: string;

  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value);
  }

  onInputChange(event) {
    const newVal = event ? event.toString().replace(/\D/g, '') : event;
    if (newVal !== undefined && newVal !== null) {
      if (newVal !== '') {
        this.ngControl.valueAccessor.writeValue(parseInt(newVal.substring(0, parseInt(this.maxl, 10)), 10));
      } else {
        this.ngControl.valueAccessor.writeValue('');
      }
    }
  }
}

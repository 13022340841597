import { Injectable } from '@angular/core';
import {
  Resolve, Routes
} from '@angular/router';
import { JhiResolvePagingParams } from 'ng-jhipster';
import UserRouteAccessService from 'app/core/auth/user-route-access-service';
import {
  Observable, of
} from 'rxjs';
import {
  CouponRx, ICouponRx
} from 'app/shared/model/coupon-rx.model';
import CouponRxService from './coupon-rx.service';
import CouponRxComponent from './coupon-rx.component';

@Injectable({ providedIn: 'root' })
export default class CouponRxResolve implements Resolve<ICouponRx> {
  constructor(private service: CouponRxService) {}

  resolve(): Observable<ICouponRx> {
    return of(new CouponRx());
  }
}

export const couponRoute: Routes = [
  {
    path: '',
    component: CouponRxComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams
    },
    data: {
      authorities: ['ROLE_USER'],
      defaultSort: 'id,asc',
      pageTitle: 'bjcrxApp.coupon.home.title'
    },
    canActivate: [UserRouteAccessService]
  }
];

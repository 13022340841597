import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JhiLanguageService } from 'ng-jhipster';
import AccountService from 'app/core/auth/account.service';
import JhiLanguageHelper from 'app/core/language/language.helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'jhi-settings',
  templateUrl: './settings.component.html'
})
export default class SettingsComponent implements OnInit, OnDestroy {
  private settingsComponent$: Subject<void> = new Subject<void>();

  error: string;

  success: string;

  languages: any[];

  settingsForm = this.fb.group({
    firstName: [undefined, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
    lastName: [undefined, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
    email: [undefined, [Validators.required, Validators.minLength(5), Validators.maxLength(254), Validators.email]],
    activated: [false],
    authorities: [[]],
    langKey: ['en'],
    login: [],
    imageUrl: []
  });

  constructor(
    private accountService: AccountService,
    private fb: FormBuilder,
    private languageService: JhiLanguageService,
    private languageHelper: JhiLanguageHelper
  ) {}

  ngOnInit() {
    this.accountService.identity().then((account) => {
      this.updateForm(account);
    });
    this.languageHelper.getAll().then((languages) => {
      this.languages = languages;
    });
  }

  save() {
    const settingsAccount = this.accountFromForm();
    this.accountService
      .save(settingsAccount)
      .pipe(takeUntil(this.settingsComponent$))
      .subscribe(
        () => {
          this.error = null;
          this.success = 'OK';
          this.accountService.identity(true).then((account) => {
            this.updateForm(account);
          });
          this.languageService.getCurrent().then((current) => {
            if (settingsAccount.langKey !== current) {
              this.languageService.changeLanguage(settingsAccount.langKey);
            }
          });
        },
        () => {
          this.success = null;
          this.error = 'ERROR';
        }
      );
  }

  private accountFromForm(): any {
    const account = {};
    return {
      ...account,
      firstName: this.settingsForm.get('firstName').value,
      lastName: this.settingsForm.get('lastName').value,
      email: this.settingsForm.get('email').value,
      activated: this.settingsForm.get('activated').value,
      authorities: this.settingsForm.get('authorities').value,
      langKey: this.settingsForm.get('langKey').value,
      login: this.settingsForm.get('login').value,
      imageUrl: this.settingsForm.get('imageUrl').value
    };
  }

  updateForm(account: any): void {
    this.settingsForm.patchValue({
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      activated: account.activated,
      authorities: account.authorities,
      langKey: account.langKey,
      login: account.login,
      imageUrl: account.imageUrl
    });
  }

  public ngOnDestroy(): void {
    this.settingsComponent$.next();
    this.settingsComponent$.unsubscribe();
  }
}

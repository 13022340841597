import { Moment } from 'moment';

export interface INotificationMessageRx {
  id?: number;
  messageId?: number;
  message?: string;
  startDate?: Moment;
  endDate?: Moment;
  status?: string;
  notificationType?: string;
  userId?: number;
}

export class NotificationMessageRx implements INotificationMessageRx {
  constructor(
    public id?: number,
    public messageId?: number,
    public message?: string,
    public startDate?: Moment,
    public endDate?: Moment,
    public status?: string,
    public notificationType?: string,
    public userId?: number
  ) {}
}

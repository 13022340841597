import { Moment } from 'moment';

export interface IPrescriptionTxReqRx {
  id?: number;
  requestId?: number;
  prescriptionNumber?: string;
  sourcePharmacy?: string;
  sourcePharmacyPhoneNumber?: string;
  destinationPharmacyId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dateOfBirth?: Moment;
  emailId?: string;
  phoneNumber?: string;
}

export class PrescriptionTxReqRx implements IPrescriptionTxReqRx {
  constructor(
    public id?: number,
    public requestId?: number,
    public prescriptionNumber?: string,
    public sourcePharmacy?: string,
    public sourcePharmacyPhoneNumber?: string,
    public destinationPharmacyId?: string,
    public firstName?: string,
    public middleName?: string,
    public lastName?: string,
    public dateOfBirth?: Moment,
    public emailId?: string,
    public phoneNumber?: string
  ) {}
}

/**
 * Angular bootstrap Date adapter
 */
import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from 'moment';
import * as moment from 'moment';
import { isIEBrowser } from 'app/app.constants';

@Injectable()
export default class NgbDateMomentAdapter extends NgbDateAdapter<Moment> {
  fromModel(date: Moment): NgbDateStruct {
    if (date != null && moment.isMoment(date) && date.isValid()) {
      return { year: date.year(), month: date.month() + 1, day: date.date() };
    }
    return null;
  }

  toModel(date: NgbDateStruct): Moment {
    if (isIEBrowser) {
      return date ? moment(`${date.month}/${date.day}/${date.year}`, 'MM/DD/YYYY') : null;
    }
    return date ? moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD') : null;
  }
}

<div class="main-container">
    <div class="offline-mode" [hidden]="isConnected !== false">
        <span><img src="../../../content/images/offline_icon.png" alt=""></span> You are offline
    </div>
    <div class="nav-header">
        <router-outlet name="navbar"></router-outlet>
    </div>
    <div *ngIf="show === true" class="main-container-body" [ngClass]="{'slide-content-screen':messages}">
        <router-outlet></router-outlet>
        <router-outlet name="popup"></router-outlet>
    </div>
</div>

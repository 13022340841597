import { Routes } from '@angular/router';
import activateRoute from './activate/activate.route';
import passwordRoute from './password/password.route';
import passwordResetFinishRoute from './password-reset/finish/password-reset-finish.route';
import passwordResetInitRoute from './password-reset/init/password-reset-init.route';
import registerRoute from './register/register.route';
import loginRoute from './login/login.route';
import settingsRoute from './settings/settings.route';
import ForgotUsernameComponent from './forgot-username/forgot-username.component';
import passwordResetRoute from './password-reset/reset/password-reset.route';
import NotificationConsentComponent from './notification-consent/notification-consent.component';

export const ACCOUNT_ROUTES = [
  activateRoute,
  passwordRoute,
  passwordResetFinishRoute,
  passwordResetInitRoute,
  passwordResetRoute,
  registerRoute,
  settingsRoute,
  loginRoute
];

export const accountState: Routes = [
  {
    path: '',
    children: ACCOUNT_ROUTES
  },
  {
    path: 'forgot-username',
    component: ForgotUsernameComponent,
    data: {
      pageTitle: 'global.menu.account.forgotusername'
    }
  },
  {
    path: 'communication-consent/:frompage',
    component: NotificationConsentComponent,
    data: {
      pageTitle: 'global.menu.account.communicationConsent'
    }
  }
];

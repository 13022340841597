import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[jhiTrimInput]'
})
export default class TrimInputDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('blur') onBlur() {
    this.control.control.setValue(this.el.nativeElement.value.trim());
  }
}

<div *ngIf="!showmessage && submitted">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-12 bjcrx-gif">
                <div class="gif-text">
                    <div class="bjc-text">
                        <img src="../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="" />
                        <div class="laoder-text text-center">Processing...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm" *ngIf="!authenticationError && showmessage">
    <div class="content with-action">
        <div class="row">
            <a routerLink="/">
                <img src="../../../content/images/bjc-blue-logo.png" alt="bjc logo"/>
            </a>
        </div>
        <h2 class="subtitle">Log In</h2>
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="username-label" for="username">Username</label><span class="required">*</span>
                    <input type="text" class="form-control" name="username" id="username" placeholder="" autocomplete="off" formControlName="username" />
                    <div *ngIf="loginForm.get('username').invalid && (submitted || loginForm.get('username').touched)">
                        <small class="form-text error-msg" *ngIf="loginForm.get('username').errors.required">
						              Please enter username.
						            </small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label><span class="required">*</span>
                    <input type="password" class="form-control" name="password" id="password" autocomplete="off" formControlName="password" />
                    <div *ngIf="loginForm.get('password').invalid && (submitted || loginForm.get('password').touched)">
                        <small class="form-text error-msg" *ngIf="loginForm.get('password').errors.required">
						              Please enter password.
						            </small>
                    </div>
                </div>
                <div class="login-action">
                  <a (click)="requestResetPassword()" class="info-text">Forgot password?</a>&nbsp;&nbsp;&nbsp;<a (click)="requestForUsername()" class="info-text">Forgot username?</a>
                </div>
            </div>
        </div>
  </div>
  <div class="row action m-0" [ngClass]="{ 'slide-left-action': slideScreen }">
    <div class="col-xl-4 col-lg-6 col-md-6 p-0"  >
      <button type="submit" [disabled]="loginForm.invalid" class="btn btn-enable btn-block">LOG IN</button>
      </div>
  </div>
</form>

<div *ngIf="authenticationError && showmessage">
  <div class="content">
    <div class="col-xl-4 col-lg-6 col-md-6">
      <div class="error-message">
        {{ authenticationErrorMsg }}
      </div>
    </div>
  </div>
  <div class="row action m-0" [ngClass]="{ 'slide-left-action': slideScreen }">
    <div class="col-xl-4 col-lg-6 col-md-6 p-0">
	    <button *ngIf="passwordExpired" type="button" (click)="userResetPassword()" class="btn btn-enable btn-block">RESET PASSWORD</button>
	    <button *ngIf="!passwordExpired" type="button" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
    </div>
  </div>
</div>

import { Route } from '@angular/router';
import InformationComponent from './information.component';

const INFORMATION_ROUTE: Route = {
  path: '',
  component: InformationComponent,
  data: {
    authorities: [],
    pageTitle: 'home.title'
  }
};

export default INFORMATION_ROUTE;

import {
  Component, OnInit, OnDestroy, Input
} from '@angular/core';
import {
  ActivatedRoute, Router
} from '@angular/router';
import {
  FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn
} from '@angular/forms';
import PrescriptionRxService from 'app/entities/prescription-rx/prescription-rx.service';
import {
  STATE_DATA, isIEBrowser
} from 'app/app.constants';
import {
  DateValidator, CheckFutureDate
} from 'app/shared/util/request-util';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionStorageService } from 'ngx-webstorage';
import * as moment from 'moment';
import AddressValidationDialogService from 'app/core/address-validation-dialog/address-validation-dialog.service';
import ProfileCreateDTO from 'app/shared/model/profile-create.model';
import ProfileRxService from '../profile-rx.service';

@Component({
  selector: 'jhi-profile-rx-mailorder',
  templateUrl: './profile-rx-mailorder.component.html',
  styleUrls: ['profile-rx-mailorder.scss']
})
export default class ProfileRxMailOrderComponent implements OnInit, OnDestroy {
  private profileRxMailOrderComponent$: Subject<void> = new Subject<void>();

  @Input() userDetails: ProfileCreateDTO;

  genderList = ['Female', 'Male'];

  nameValidationPattern = "^[a-zA-Z\\'\\-\\ \\.]+$";

  pharmacyList;

  submitted = false;

  stateList;

  profileMailOrderForm;

  showMessage = false;

  message;

  success;

  error;

  slideScreen;

  showConsent = false;

  loading = false;

  isInvalidBillingAddress = false;

  isInvalidShippingAddress = false;

  isSubmitDetails = false;

  account;

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private prescriptionService: PrescriptionRxService,
    private profileRxService: ProfileRxService,
    private accountService: AccountService,
    private $sessionStorage: SessionStorageService,
    private addressValidationDialogService: AddressValidationDialogService
  ) {
    this.stateList = STATE_DATA;
    this.profileMailOrderForm = new FormGroup(
      {
        firstName: new FormControl('', [Validators.required, Validators.pattern(this.nameValidationPattern)]),
        middleInitial: new FormControl('', [Validators.pattern(this.nameValidationPattern)]),
        lastName: new FormControl('', [Validators.required, Validators.pattern(this.nameValidationPattern)]),
        suffix: new FormControl('', [Validators.pattern(this.nameValidationPattern)]),
        dateOfBirth: new FormControl('', [Validators.required, DateValidator(), CheckFutureDate()]),
        gender: new FormControl('', [Validators.required]),
        insuranceMemberId: new FormControl('', [Validators.required, Validators.maxLength(17)]),
        medicationAllergies: new FormControl('', [Validators.required]),
        otherAllergies: new FormControl('', [Validators.required]),
        primaryEmailAddress: new FormControl('', [Validators.required, Validators.email]),
        secondaryEmailAddress: new FormControl('', [Validators.email]),
        primaryPhone: new FormControl('', [Validators.required, Validators.minLength(14)]),
        secondaryPhone: new FormControl('', [Validators.minLength(14)]),
        billingAddress: new FormControl('', [Validators.required]),
        billingCity: new FormControl('', [Validators.required]),
        billingState: new FormControl('', [Validators.required]),
        billingZip: new FormControl('', [Validators.required]),
        sameAddress: new FormControl(true, []),
        shippingAddress: new FormControl('', []),
        shippingCity: new FormControl('', []),
        shippingState: new FormControl('', []),
        shippingZip: new FormControl('', []),
        pharmacyId: new FormControl('', []),
        pharmacyName: new FormControl('', [])
      },
      { validators: this.validateForm() }
    );
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.profileRxMailOrderComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.accountService.identity().then((account) => {
      this.account = account;
    });

    if (this.$sessionStorage.retrieve('minorDependent')) {
      /* This session object is an instance of PeoplesoftImportDTO */
      const userData = JSON.parse(this.$sessionStorage.retrieve('minorDependent'));
      this.userDetails = {
        publicAuthenticationDTO: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          dateOfBirth: userData.birthDate,
          last4ssn: '',
          driverLicenseNumber: '',
          driverLicenseState: '',
          primaryEmailAddress: '',
          phoneNum: ''
        },
        patientId: userData.patientId,
        personId: userData.importId,
        isMinor: true
      };
    } else if (this.$sessionStorage.retrieve('acdetails')) {
      /* This session object is an instance of ProfileCreateDTO  */
      const sessionDetails = JSON.parse(this.$sessionStorage.retrieve('acdetails'));
      this.userDetails = sessionDetails;
      this.userDetails.patientId = sessionDetails.patientId;
    } else {
      this.userDetails = null;
    }
    if (this.userDetails) {
      this.profileMailOrderForm.get('firstName').setValue(this.userDetails.publicAuthenticationDTO.firstName);
      this.profileMailOrderForm.get('lastName').setValue(this.userDetails.publicAuthenticationDTO.lastName);
      this.profileMailOrderForm.get('dateOfBirth').setValue(this.userDetails.publicAuthenticationDTO.dateOfBirth);
    }
    this.getPharmacyList();
  }

  validateForm(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      if (group.value.sameAddress === false) {
        if (!group.get('shippingAddress').value) {
          group.get('shippingAddress').setErrors({ required: true });
        }
        if (!group.get('shippingCity').value) {
          group.get('shippingCity').setErrors({ required: true });
        }
        if (!group.get('shippingState').value) {
          group.get('shippingState').setErrors({ required: true });
        }
        if (!group.get('shippingZip').value) {
          group.get('shippingZip').setErrors({ required: true });
        }
      } else {
        group.get('shippingAddress').setErrors(null);
        group.get('shippingCity').setErrors(null);
        group.get('shippingState').setErrors(null);
        group.get('shippingZip').setErrors(null);
      }
      return null;
    };
  }

  getPharmacyList() {
    this.prescriptionService
      .getPharmacyList()
      .pipe(takeUntil(this.profileRxMailOrderComponent$))
      .subscribe((res) => {
        this.pharmacyList = res.body;
      });
  }

  onChange(event) {
    console.warn(event);
  }

  fixPhoneNumber(phoneString) {
    const trimPhone = phoneString.substring(0, 14);
    this.profileMailOrderForm.get('primaryPhone').setValue(trimPhone);
  }

  onSubmit() {
    this.submitted = true;
    this.isInvalidBillingAddress = false;
    this.isInvalidShippingAddress = false;
    this.isSubmitDetails = false;
    if (this.profileMailOrderForm.invalid) {
      if (
        this.profileMailOrderForm.get('dateOfBirth').errors &&
        !this.profileMailOrderForm.get('dateOfBirth').errors?.required &&
        !this.profileMailOrderForm.get('dateOfBirth').errors?.invalidDate &&
        !this.profileMailOrderForm.get('dateOfBirth').errors?.futuredate
      ) {
        this.profileMailOrderForm.get('dateOfBirth').setErrors(null);
      }
    }
    if (!this.profileMailOrderForm.invalid) {
      if (this.profileMailOrderForm.get('primaryPhone').value.length > 14) {
        this.fixPhoneNumber(this.profileMailOrderForm.get('primaryPhone').value);
      }
      this.profileMailOrderForm.get('pharmacyName').setValue('');

      const billingAddressDetails = {
        firstName: this.profileMailOrderForm.value.firstName,
        lastName: this.profileMailOrderForm.value.lastName,
        addressLine: this.profileMailOrderForm.value.billingAddress,
        addressState: this.profileMailOrderForm.value.billingState,
        addressZip: this.profileMailOrderForm.value.billingZip
      };
      this.loading = true;
      this.profileRxService
        .validateAddress(billingAddressDetails)
        .pipe(takeUntil(this.profileRxMailOrderComponent$))
        .subscribe((usps) => {
          if (usps?.AddressValidateResponse?.Address?.Error) {
            this.isInvalidBillingAddress = true;
            this.loading = false;
          } else if (
            // Check to see if usps matched what user entered.
            this.profileMailOrderForm.get('billingAddress').value.toUpperCase() !==
              usps?.AddressValidateResponse?.Address?.Address2 ||
            this.profileMailOrderForm.get('billingCity').value.toUpperCase() !== usps?.AddressValidateResponse?.Address?.City ||
            this.profileMailOrderForm
              .get('billingZip')
              .value.toString()
              .toUpperCase() !== usps?.AddressValidateResponse?.Address?.Zip5
          ) {
            this.loading = false;
            this.addressValidationDialogService
              .confirm(
                'USPS Address Validation Response',
                usps?.AddressValidateResponse?.Address?.Address2,
                this.profileMailOrderForm.value.billingAddress,
                usps?.AddressValidateResponse?.Address?.City,
                this.profileMailOrderForm.value.billingCity,
                usps?.AddressValidateResponse?.Address?.State,
                this.profileMailOrderForm.value.billingState,
                usps?.AddressValidateResponse?.Address?.Zip5,
                this.profileMailOrderForm.value.billingZip,
                'lg'
              )
              .then((confirmed) => {
                if (confirmed === 'user') {
                  console.warn('in keep own');
                  // indicate good to submit for billing address
                  this.isSubmitDetails = true;
                } else if (confirmed === 'usps') {
                  console.warn('in keep usps');
                  // overwrite user with usps
                  this.profileMailOrderForm.get('billingAddress').setValue(usps?.AddressValidateResponse?.Address?.Address2);
                  this.profileMailOrderForm.get('billingZip').setValue(usps?.AddressValidateResponse?.Address?.Zip5);
                  this.profileMailOrderForm.get('billingCity').setValue(usps?.AddressValidateResponse?.Address?.City);
                  this.profileMailOrderForm.get('billingState').setValue(usps?.AddressValidateResponse?.Address?.State);
                  this.isSubmitDetails = true;
                } else if (confirmed === 'edit') {
                  console.warn('in stay and edit');
                  this.loading = false;
                  this.isSubmitDetails = false;
                } else if (confirmed === 'timeout') {
                  console.warn('modal timed out');
                  this.loading = false;
                  this.isSubmitDetails = false;
                }
                // check on shipping ONLY if we are NOT going to stay on page!
                if (this.isSubmitDetails) {
                  if (this.profileMailOrderForm.value.sameAddress === false) {
                    this.validateShippingAddress();
                    // We should not be doing anything here - timing will be off!
                  } else {
                    // we are here for valid billing address (same shipping)
                    // so we need to save and we did not prompt for anything
                    this.showConsent = true;
                    this.loading = false;
                    this.submitDetails('yes');
                  }
                } else {
                  this.loading = false;
                }
              });
            // We do NOT want to be doing anything here - timing will be off!
          } else if (this.profileMailOrderForm.value.sameAddress === false) {
            // USPS for billing matched we did not prompt
            this.validateShippingAddress();
            // We do not want to be doing anything here - timing will be off!
          } else {
            // we are here for valid billing address (same shipping)
            // so we need to save and we did not prompt for anything
            this.showConsent = true;
            this.loading = false;
            this.submitDetails('yes');
          }
        });
    }
  }

  validateShippingAddress() {
    const shippingAddressDetails = {
      firstName: this.profileMailOrderForm.value.firstName,
      lastName: this.profileMailOrderForm.value.lastName,
      addressLine: this.profileMailOrderForm.value.shippingAddress,
      addressState: this.profileMailOrderForm.value.shippingState,
      addressZip: this.profileMailOrderForm.value.shippingZip
    };
    this.profileRxService
      .validateAddress(shippingAddressDetails)
      .pipe(takeUntil(this.profileRxMailOrderComponent$))
      .subscribe((usps) => {
        if (usps?.AddressValidateResponse?.Address?.Error) {
          this.isInvalidShippingAddress = true;
          this.loading = false;
        } else if (
          // See if shipping address matches USPS
          this.profileMailOrderForm.get('shippingAddress').value.toUpperCase() !== usps?.AddressValidateResponse?.Address?.Address2 ||
          this.profileMailOrderForm.get('shippingCity').value.toUpperCase() !== usps?.AddressValidateResponse?.Address?.City ||
          this.profileMailOrderForm
            .get('shippingZip')
            .value.toString()
            .toUpperCase() !== usps?.AddressValidateResponse?.Address?.Zip5
        ) {
          this.addressValidationDialogService
            .confirm(
              'USPS Address Validation Response',
              usps?.AddressValidateResponse?.Address?.Address2,
              this.profileMailOrderForm.value.shippingAddress,
              usps?.AddressValidateResponse?.Address?.City,
              this.profileMailOrderForm.value.shippingCity,
              usps?.AddressValidateResponse?.Address?.State,
              this.profileMailOrderForm.value.shippingState,
              usps?.AddressValidateResponse?.Address?.Zip5,
              this.profileMailOrderForm.value.shippingZip,
              'lg'
            )
            .then((confirmed) => {
              if (confirmed === 'user') {
                console.warn('in keep own');
                this.isSubmitDetails = true;
              } else if (confirmed === 'usps') {
                console.warn('in keep usps');
                // overwrite user with usps
                this.profileMailOrderForm.get('shippingAddress').setValue(usps?.AddressValidateResponse?.Address?.Address2);
                this.profileMailOrderForm.get('shippingZip').setValue(usps?.AddressValidateResponse?.Address?.Zip5);
                this.profileMailOrderForm.get('shippingCity').setValue(usps?.AddressValidateResponse?.Address?.City);
                this.profileMailOrderForm.get('shippingState').setValue(usps?.AddressValidateResponse?.Address?.State);
                this.isSubmitDetails = true;
              } else if (confirmed === 'edit') {
                console.warn('in stay and edit');
                this.loading = false;
                this.isSubmitDetails = false;
              } else if (confirmed === 'timeout') {
                console.warn('modal timed out');
                this.loading = false;
                this.isSubmitDetails = false;
              }
              if (this.isSubmitDetails) {
                this.showConsent = true;
                this.loading = false;
                this.submitDetails('yes');
              } else {
                this.loading = false;
              }
            });
        } else {
          this.showConsent = true;
          this.loading = false;
          this.submitDetails('yes');
        }
      });
  }

  submitDetails(event) {
    this.loading = true;
    this.showConsent = false;
    const payload = this.profileMailOrderForm.value;
    payload.userConsent = event === 'yes' ? 1 : 0;
    const errorMessage = 'There was a problem processing your dependent.';

    if (this.userDetails && this.userDetails.isMinor) {
      this.profileRxService
        .getPrimaryPlanMember(this.account.peopleSoftId)
        .pipe(takeUntil(this.profileRxMailOrderComponent$))
        .subscribe((primaryPlanMember) => {
          if (primaryPlanMember && primaryPlanMember.body) {
            const queueItem = this.buildAdminQueueItem(payload, primaryPlanMember.body);
            this.loading = false;
            this.showMessage = true;
            this.profileRxService
              .sendToAdminQueue(queueItem)
              .pipe(takeUntil(this.profileRxMailOrderComponent$))
              .subscribe(
                (pendingQueueItem) => {
                  if (pendingQueueItem) {
                    this.success = true;
                    this.message =
                      "Thank you, your dependent's information has been sent to the Pharmacy Team. " +
                      'You will receive an email within 1-3 business days when their information is ready to access. \n' +
                      'If you have an urgent prescription need in the meantime, please contact the Family Care Central Pharmacy at 314-657-9000.';
                  } else {
                    this.success = false;
                    this.message = errorMessage;
                  }
                },
                () => {
                  this.success = false;
                  this.message = errorMessage;
                }
              );
          } else {
            this.success = false;
            this.message = errorMessage;
          }
        });
    } else {
      this.route.navigate(['/account/register'], { state: { payload: JSON.stringify(payload) } });
    }
  }

  buildAdminQueueItem(payload, primaryPlanMember) {
    let shippingAddress = '';
    if (payload.shippingAddress && payload.shippingAddress.length > 1) {
      shippingAddress = `${payload.shippingAddress} ${payload.shippingCity} ${payload.shippingState} ${payload.shippingZip}`;
    } else {
      shippingAddress = `${payload.billingAddress} ${payload.billingCity} ${payload.billingState} ${payload.billingZip}`;
    }

    return {
      patientId: null,
      primaryUserPatientId: this.account.enterpriseRxPatientId,
      firstName: payload.firstName,
      lastName: payload.lastName,
      dateOfBirth: moment(payload.dateOfBirth).format('MM/DD/YYYY'),
      updateType: 'registerminor',
      changeSet: {
        RxTransferRequest: {
          userId: null,
          peopleSoftId: primaryPlanMember.importId,
          PrimaryPlanMemberName: `${primaryPlanMember.firstName} ${primaryPlanMember.lastName}`,
          PharmacyName: payload.pharmacyName,
          PharmacyId: payload.pharmacyId,
          BillingAddress: `${payload.billingAddress} ${payload.billingCity} ${payload.billingState} ${payload.billingZip}`,
          ShippingAddress: shippingAddress,
          PrimaryPhone: payload.primaryPhone,
          SecondaryPhone: payload.secondaryPhone,
          PrimaryEmail: payload.primaryEmailAddress,
          SecondaryEmail: payload.secondaryEmailAddress,
          Gender: payload.gender,
          MemberInsurance: payload.insuranceMemberId,
          MedicationAllergies: payload.medicationAllergies,
          OtherAllergies: payload.otherAllergies,
          UserNotificationConsent: this.account.userNotificationConsent === 'Approved' ? '1' : '0'
        }
      }
    };
  }

  previousState() {
    window.history.back();
  }

  tryagain() {
    this.showMessage = false;
    this.success = false;
    this.error = false;
  }

  checkIsIEBrowser() {
    return isIEBrowser;
  }

  public ngOnDestroy(): void {
    this.profileRxMailOrderComponent$.next();
    this.profileRxMailOrderComponent$.unsubscribe();
  }
}

<h1 class="pageTitle">Profile</h1>
<div class="content with-action" *ngIf="profile && !showMessage && isPageLoaded === true">
    <div class="row">
        <div class="col-md-4">
            <div class="profile-card">
                <div class="content">
                    <div class="row name-wraper">

                        <span class="name">{{profile?.firstName}} {{profile?.lastName}}</span>
                        <span class="account-type">Primary</span>
                    </div>
                    <div class="row">
                        <div class="col p-0">
                            <div class="info-wrapper">
                                <fa-icon icon='calendar-day'></fa-icon><span class="info">{{profile?.dateOfBirth | date:'MMMM, dd yyyy'}}</span>
                            </div>
                            <div class="info-wrapper" *ngIf="profile?.primaryEmailAddress">
                                <fa-icon icon='envelope'></fa-icon><span class="info">{{profile?.primaryEmailAddress}}</span>
                            </div>
                            <div class="info-wrapper" *ngIf="profile?.primaryContactDetails">
                                <fa-icon icon='phone-alt'></fa-icon><span class="info">{{formatPhoneNumber(profile?.primaryContactDetails)}}</span>
                            </div>
                            <div class="info-wrapper">
                                <fa-icon icon='map-marker-alt'></fa-icon><span class="info">{{profile?.patientAddressDTO?.line1}} <br>
                                    {{profile?.patientAddressDTO?.city}}, {{profile?.patientAddressDTO?.state}}
                                    {{profile?.patientAddressDTO?.zip}}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="action">
                    <button type="button" (click)="editprofile(profile,'primary', profile.enterpriseRxId)" class="btn btn-block">EDIT</button>
                </div>
            </div>

        </div>

        <div class="col-md-4" *ngFor="let dependent of dependentList">
            <div class="profile-card">
                <div class="content">
                    <div class="row name-wraper">
                        <span class="name">{{dependent?.firstName}} {{dependent?.lastName}}</span>
                        <span class="account-type">Dependent</span>
                    </div>
                    <div class="row">
                        <div class="col p-0">
                            <div class="info-wrapper">
                                <fa-icon icon='calendar-day'></fa-icon><span class="info">{{dependent?.birthDate | date:'MMMM, dd yyyy'}}</span>
                            </div>
                            <div class="info-wrapper" *ngIf="dependent?.primaryEmailAddress">
                                <fa-icon icon='envelope'></fa-icon><span class="info">{{dependent?.primaryEmailAddress | lowercase}}</span>
                            </div>
                            <div class="info-wrapper" *ngIf="dependent?.primaryContactDetails">
                                <fa-icon icon='phone-alt'></fa-icon><span class="info">{{formatPhoneNumber(dependent?.primaryContactDetails)}}</span>
                            </div>
                            <div class="info-wrapper" *ngIf="dependent?.patientAddressDTO?.line1">
                                <fa-icon icon='map-marker-alt'></fa-icon><span class="info">{{dependent?.patientAddressDTO?.line1}} <br>
                                    {{dependent?.patientAddressDTO?.city}}, {{dependent?.patientAddressDTO?.state}}
                                    {{dependent?.patientAddressDTO?.zip}}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="action" *ngIf="isMinor(dependent?.birthDate)">
                    <button type="button" (click)="editprofile(dependent, 'dependent', dependent.patientId)" class="btn btn-block">EDIT</button>
                </div>
            </div>

        </div>

    </div>
    <div class="row pt-3">
        <div class="delete-account" (click)="deleteAccount()" id="deleteAccount">Delete Account</div>
    </div>
</div>
<div *ngIf="showMessage">
    <div class="content">
        <div class="col-md-4">
            <div *ngIf="!errorForMinor" [ngClass]="{'success-message':success, 'error-message':error}">
                {{message}}
            </div>
            <div *ngIf="errorForMinor" class="error-message">
                The minor dependent you selected cannot be found in the BJC Pharmacy system.<br>
                Next steps require the minor dependent to have an account created for them and added to the pharmacy system.
                To create an account for a minor dependent, complete and submit the form  <a class="mail-order-link" (click)="registerForMailOrder()">HERE</a>
                or contact the Family Care Central Pharmacy at 314-657-9000.<br><br>
                Once the  account information has been entered into the pharmacy system, you will receive a confirmation e-mail and will be able to login and select the minor dependent.
            </div>
        </div>
    </div>
    <div class="row action m-0" *ngIf="showTryAgain" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" *ngIf="error && showTryAgain" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
        </div>
    </div>
</div>

<div class="skeleton-container" id="skeletonLoader" *ngIf="isPageLoaded === false">
    <div class="skeleton-body">
        <div class="">
            <div class="row">
                <div class="col-md-4">
                    <div class="profile-card skeleton-box">

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="profile-card skeleton-box">

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="profile-card skeleton-box">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import { ISystemConfigRx } from 'app/shared/model/system-config-rx.model';
import { JhiAlertService } from 'ng-jhipster';
import { Router } from '@angular/router';
import SystemConfigRxService from '../system-config-rx/system-config-rx.service';

@Component({
  selector: 'jhi-home',
  templateUrl: './information.component.html',
  styleUrls: ['information.scss']
})
export default class InformationComponent {
  systemConfigs: ISystemConfigRx[] = [];

  constructor(private systemConfigService: SystemConfigRxService, private jhiAlertService: JhiAlertService, private route: Router) {}

  protected onError(errorMessage: string) {
    this.jhiAlertService.error(errorMessage, null, null);
  }

  showDrugInformation() {
    const drugInfoURL = this.systemConfigService.getConfigValueFromLocalStorage('DrugInfo');
    if (drugInfoURL) {
      window.open(drugInfoURL);
    }
  }

  showHealthTopics() {
    const helthTopicURL = this.systemConfigService.getConfigValueFromLocalStorage('HltTopic');
    if (helthTopicURL) {
      window.open(helthTopicURL);
    }
  }

  showRegisterForMailOrder() {
    this.route.navigate(['/profile-rx/mailorder']);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import BjcrxSharedModule from 'app/shared/shared.module';
import CouponRxComponent from './coupon-rx.component';
import { couponRoute } from './coupon-rx.route';

const ENTITY_STATES = [...couponRoute];

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild(ENTITY_STATES)],
  declarations: [CouponRxComponent]
})
export default class BjcrxCouponRxModule {}

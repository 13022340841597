<h1 class="pageTitle" jhiTranslate="bjcrxApp.cardInfo.home.title">Manage Payment</h1>
<div class="content">
    <h3 class="heading">A payment method is only required if you are using the Family Care Central Pharmacy.</h3>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group" *ngIf="showDependents">
                <label class="form-control-label" for="patientName">PATIENT </label>
                <select class="form-control" id="patientName" [(ngModel)]="selectedDependentId" (change)="loadDependentCards($event)">
                    <option *ngFor="let dependent of dependentListFromApi | orderBy: 'sequence'" [value]="dependent.id">
                        {{ dependent.firstName + ' ' + dependent.lastName }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <p style="color:#0059A7" *ngIf="paymentCardList?.length > 0">Payment Information</p>
        </div>
    </div>
    <div class="row card-list">
        <ng-container *ngIf="!isUnauthorized && show === true">
            <div class="col-md-4" *ngFor="let cardList of  paymentCardList;let i = index">
                <div class="card">
                    <div class="card-header">
                        <div style="color:#0059A7;font-weight: bold">CARD</div>
                        <div>**** **** **** {{cardList.last4Digits}}</div>
                    </div>
                    <div class="card-body">
                        <div>
                            <fa-icon icon="user-alt" class="icon-custom icon-blue"></fa-icon>
                            <span>Name on card <span
                                    class="text-capitalize p-0">{{cardList.firstName+' '}}{{cardList?.middleName ? cardList?.middleName+' ':''}}{{cardList.lastName}}</span></span>
                        </div>
                        <div class="ml-3 p-0">
                            <span>Valid Thru:- {{cardList.expirationDate}}</span>
                        </div>
                        <div *ngIf="cardList.isDefaultCard">
                            <fa-icon icon="check" class="icon-custom icon-blue"></fa-icon>
                            <span>This is your default payment method.</span>
                        </div>
                        <div *ngIf="cardList.isFSA">
                            <fa-icon icon="check" class="icon-custom icon-blue"></fa-icon>
                            <span>This is your FSA card.</span>
                        </div>
                    </div>
                    <div class="card-footer card-actions" *ngIf="!isUnauthorized">
                        <div></div>
                        <div >
                            <fa-icon icon="trash" (click)="deleteCardPayment(i)" class="icon-custom icon-blue"></fa-icon>
                        </div>
                         
                    </div>
                    <div class="card-footer card-actions" *ngIf="!isUnauthorized">
                        <div></div>
                        <div>
                            <button style="float: right !important; height:40px; border:1px solid #0059A7;color:#0059A7;cursor: pointer;font-weight: lighter"
                             type="button" class="btn btn-default" (click)="editCardPayment(i)">EDIT</button>
                        </div>
                         
                    </div>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="show === false">
            <div class="col-md-4 pl-0">
                <div class="skeleton-box">

                </div>
            </div>
        </ng-container>
        <div *ngIf="isUnauthorized" class="col-md-4">
            <div class="error-message" *ngIf="!errorForMinor">You cannot manage dependents who are older than 18 years</div>
            <div class="error-message" *ngIf="errorForMinor">
                The minor dependent you selected cannot be found in the BJC Pharmacy system.<br>
                Next steps require the minor dependent to have an account created for them and added to the pharmacy system.
                To create an account for a minor dependent, complete and submit the form  <a class="mail-order-link" (click)="registerForMailOrder()">HERE</a>
                or contact the Family Care Central Pharmacy at 314-657-9000.<br><br>
                Once the  account information has been entered into the pharmacy system, you will receive a confirmation e-mail and will be able to login and select the minor dependent.
                </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4" *ngIf="maxCardLimit">
            <small class="error-msg">
                Max card limit is 3,To add another card for payment delete one.
            </small>
        </div>
    </div>
    <div class="row" *ngIf="show === true">
        <div class="col-md-4">
            <button type="button" [disabled]="(maxCardLimit || isUnauthorized || isDisabledSelectbox)" (click)="addPaymentMethod()"
                style="width:100%;border:1px solid #0059A7;margin-top: 20px;color:#0059A7;cursor: pointer;font-weight: bold"
                class="btn btn-default">+ ADD NEW PAYMENT METHOD</button>
        </div>
    </div>

</div>


import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DATE_FORMAT } from 'app/shared/constants/input.constants';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { IPrescriptionRx } from 'app/shared/model/prescription-rx.model';

type EntityResponseType = HttpResponse<IPrescriptionRx>;
type EntityArrayResponseType = HttpResponse<IPrescriptionRx[]>;

@Injectable({ providedIn: 'root' })
export default class PrescriptionRxService {
  public resourceUrl = `${SERVER_API_URL}api/prescriptions`;

  selectedPatient: any;

  constructor(protected http: HttpClient) {}

  create(prescription: IPrescriptionRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(prescription);
    return this.http
      .post<IPrescriptionRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(prescription: IPrescriptionRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(prescription);
    return this.http
      .put<IPrescriptionRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IPrescriptionRx>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IPrescriptionRx[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(prescription: IPrescriptionRx): IPrescriptionRx {
    const copy: IPrescriptionRx = {
      ...prescription,
      lastFillDate:
        prescription.lastFillDate != null && prescription.lastFillDate.isValid() ? moment(prescription.lastFillDate.format(DATE_FORMAT)) : null
    };
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.lastFillDate = res.body.lastFillDate != null ? moment(res.body.lastFillDate) : null;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((prescription: IPrescriptionRx) => {
        prescription.lastFillDate = prescription.lastFillDate != null ? moment(prescription.lastFillDate) : null;
      });
    }
    return res;
  }

  // NexGen custom functions starts here
  getPrescriptionList(page, patientId, orderBy, order, filterByStatus): Observable<any> {
    let url = `${SERVER_API_URL}api/rx/rxrefillrequest/${patientId}?page=${page}&requestedBy=API-USER&size=15`;
    if (orderBy) {
      url += `&sortby=${orderBy}`;
    } else {
      url += '&sortby=';
    }

    if (order) {
      url += `&order=${order}`;
    } else {
      url += '&order=';
    }

    url += `&filter=${filterByStatus}`;

    return this.http.get(url);
  }

  getDependentList(peopleSoftId): Observable<any> {
    return this.http.get(`${SERVER_API_URL}api/peoplesoft-imports/getDependents/${peopleSoftId}`);
  }

  public getPharmacyList() {
    const pharmacyListUrl = `${SERVER_API_URL}api/rx/pharmacy`;
    return this.http.get(pharmacyListUrl, { observe: 'response' });
  }

  checkRxRefillEligibility(rxDetails: any): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/rx/prescriptions?page=1&size=10`, rxDetails);
  }

  setSelectedPatient(data) {
    this.selectedPatient = data;
  }

  getSelectedPatient() {
    return this.selectedPatient;
  }

  resetSelectedPatient() {
    this.selectedPatient = false;
  }

  submitRefillRequest(rxData: any): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/rx/rxRefillRequest`, rxData);
  }

  getRxProfileData(patientId) {
    return this.http.get(`${SERVER_API_URL}api/rx/rxProfile/${patientId}`);
  }

  getCreditCardStatus(patientId) {
    return this.http.get(`${SERVER_API_URL}api/rx/rxProfile/isCreditcardOnFile/${patientId}?requestedBy=API-USER`);
  }

  subscribeForRefillReminder(rxData: any): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/refill/reminder/optIn`, rxData);
  }

  sendHiddenRxList(patientId, hideList: any): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/rx/rxrefillrequest/${patientId}/filterRxList`, hideList);
  }
}

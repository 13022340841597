import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { IPharmacyLocationsRx } from 'app/shared/model/pharmacy-locations-rx.model';
import { MapsAPILoader } from '@agm/core';
import { Observable, of, from } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { Location } from './location-model';
import { Distance } from './distance-model';

type EntityResponseType = HttpResponse<IPharmacyLocationsRx>;
type EntityArrayResponseType = HttpResponse<IPharmacyLocationsRx[]>;

declare let google: any;

@Injectable({ providedIn: 'root' })
export default class PharmacyLocationsRxService {
  public resourceUrl = `${SERVER_API_URL}api/pharmacy-locations`;

  geocoder: any;

  distanceMatrix: any;

  constructor(protected http: HttpClient, private mapLoader: MapsAPILoader) {}

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IPharmacyLocationsRx>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IPharmacyLocationsRx[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  getPharmacies(latitude, longitude) {
    if (latitude !== 38.9087 && longitude !== -90.1568) {
      return this.http.get(`${SERVER_API_URL}api/rx/pharmacy/${latitude}%2C${longitude}`, {
        headers: new HttpHeaders().set('Accept', '*/*')
      });
    }
    return this.http.get(`${SERVER_API_URL}api/rx/pharmacy/`, {
      headers: new HttpHeaders().set('Accept', '*/*')
    });
  }

  getPharmacyImage(id, imageName) {
    return this.http.get(`${SERVER_API_URL}api/pharmacy/downloadimage/${id}/${imageName}`, { responseType: 'blob' });
  }

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return from(this.mapLoader.load()).pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }

  geocodeAddress(location: string): Observable<Location> {
    return this.waitForMapsToLoad().pipe(
      switchMap(
        () => new Observable<Location>((observer) => {
          this.geocoder.geocode({ address: location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              });
            } else {
              observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        })
      )
    );
  }

  public initDistanceMatrix() {
    this.distanceMatrix = new google.maps.DistanceMatrixService();
  }

  private waitForDistanceToLoad(): Observable<boolean> {
    if (!this.distanceMatrix) {
      return from(this.mapLoader.load()).pipe(
        tap(() => this.initDistanceMatrix()),
        map(() => true)
      );
    }
    return of(true);
  }

  getDistance(origin, destination): Observable<Distance> {
    return this.waitForDistanceToLoad().pipe(
      switchMap(
        () => new Observable<Distance>((observer) => {
          const originLatLng = new google.maps.LatLng(origin.lat, origin.lng);
          const destinationLatLng = new google.maps.LatLng(destination.lat, destination.lng);
          this.distanceMatrix.getDistanceMatrix(
            {
              origins: [originLatLng],
              destinations: [destinationLatLng],
              travelMode: 'DRIVING',
              unitSystem: google.maps.UnitSystem.IMPERIAL
            },
            (results, status) => {
              if (status) {
                observer.next({
                  distance: results
                });
              } else {
                observer.next({ distance: '' });
              }
              observer.complete();
            }
          );
        })
      )
    );
  }
}

<div class="options-page content withou-header">
  <div class="row m-0 p-0 h-30 " *ngIf="!isAuthenticated()">
    <div class="col-md-6 p-0">
      <div class="logo-wrapper">
        <img src="../../content/images/bjclogo_navbar.png" alt=""/>
      </div>
      <h4>
          Create an account to get an instant view of prescriptions, refill reminders, and more.
      </h4>
    </div>
  </div>

  <div class="row m-0 h-70">
    <div class="col-md-4" *ngIf="!isAuthenticated()">
      <img src="../../content/images/mobile-app.png" alt=""/>
    </div>
    <div class="col-md-6 options" [ngClass]="{ 'guest-user': !isAuthenticated() }">
      <div class="row" *ngIf="!isAuthenticated()">
        <a [routerLink]="['profile-rx/questions', '1']" class="col-md-10 col-sm-12 cursor-pointer blue-box">
          <div>
            <span class="icon-text"
              ><fa-icon icon="user-alt"></fa-icon>
              <h5>CREATE AN ACCOUNT</h5></span
            >
            <span>It’s fast and easy</span>
          </div>
        </a>
      </div>

      <div class="row">
        <a class="col-md-10 col-sm-12 cursor-pointer blue-box" routerLink="/prescription-rx/refill/list" *ngIf="isAuthenticated()">
          <div>
            <span class="icon-text"
              ><fa-icon icon="prescription"></fa-icon>
              <h5>PRESCRIPTIONS</h5></span
            >
            <span>Refill prescriptions and check status</span>
          </div>
        </a>

        <a class="col-md-10 col-sm-12 cursor-pointer blue-box" routerLink="/prescription-rx" *ngIf="!isAuthenticated()">
          <div>
            <span class="icon-text"
              ><fa-icon icon="prescription"></fa-icon>
              <h5>PRESCRIPTIONS</h5></span
            >
            <span>Refill prescriptions and check status</span>
          </div>
        </a>
      </div>
      <div class="row">
        <a class="col-md-10 col-sm-12 cursor-pointer blue-box" routerLink="/prescription-tx-req-rx">
          <div>
            <span class="icon-text">
              <fa-icon icon="random"></fa-icon>
              <h5>TRANSFER PRESCRIPTIONS</h5></span
            >
            <span>Transfer prescriptions from another pharmacy</span>
          </div>
        </a>
      </div>
      <div class="row" *ngIf="isAuthenticated()">
        <a class="col-md-10 col-sm-12 cursor-pointer blue-box" routerLink="/card-info-rx">
          <div>
            <span class="icon-text">
              <fa-icon icon="dollar-sign"></fa-icon>
              <h5>MANAGE PAYMENTS</h5></span
            >
            <span>Manage your payment methods</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

<h1 class="pageTitle">
    <span *ngIf="isPharmacyDetails">
        <a (click)="back()">
            <img src="../../../content/images/_ionicons_svg_md-arrow-back@2x.png" alt="back" width="20px" height="20px" id="backArrowImg"/>
        </a> 
        {{selectedPharmacy ?.name}}
    </span>
    <span *ngIf="!isPharmacyDetails">
        Find a pharmacy
    </span>
</h1>
<ng-container *ngIf="!isPharmacyDetails && !loader">
    <div class="content justify-content-center" *ngIf="!loader">
        <div class="card-deck">
            <ng-container *ngFor="let pharmacy of markers">
                <div class="card-div col-md-4">
                    <div class="card mt-4">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-10" (click)="viewPharmacyDetails(pharmacy.data)">
                                    <label class="card-title">{{ pharmacy ?.data. name}}</label>
                                </div>
                                <div class="col-2 text-right">
                                    <label
                                        class="distance-label">{{ (pharmacy.data.pharmacyDistanceFromLocation ?.distance.rows[0].elements[0].status === 'ZERO_RESULTS') ? '' : (pharmacy.data.pharmacyDistanceFromLocation ?.distance.rows[0].elements[0].distance.text) }}
                                    </label>
                                </div>
                            </div>
                            <div class="pharmacy-address">
                                <span><img src="../../../content/images/find_pharmacy.png" alt="" width="17px" />&nbsp;</span>
                                <p>{{ pharmacy ?.data. address }}</p>
                            </div>
                            <div>
                                <span>
                                    <img src="../../../content/images/phone_icon_white.png" alt="" width="20px" height="20px" />&nbsp;
                                </span>
                                <a href='tel:{{ pharmacy ?.data. phoneNumber }}'><span class="phone-num">{{ pharmacy ?.data. phoneNumber }}</span></a>
                            </div>
                        </div>
                        <div class="card-footer" (click)="viewPharmacyDetails(pharmacy.data)">
                            <div class="row">
                                <div class="col-11">
                                    <span><em>More </em></span>
                                </div>

                                <div class="col-1 text-right">
                                    <fa-icon class="blue-text" icon="caret-right"></fa-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isPharmacyDetails && !loader">

    <div class="content justify-content-center">
        <div>
            <div class="img-list row">
                <ng-container *ngFor="let img of selectedPharmacy.images">
                    <div class="col-5 skeleton-box" *ngIf="imagesList.length === 0">
                    </div>
                </ng-container>

                <ng-container *ngFor="let img of imagesList">
                    <img [src]="img" class="col-5 img-box" alt="">
                </ng-container>
            </div>
            <div class="pharmacy-info-row col-5">
				<div class="addressicon"><img src="../../../content/images/find_pharmacy.png" alt="" width="17px" /></div>
				<div class="addressline1">{{ selectedPharmacy ?. address }}<br>{{ selectedPharmacy ?. addressInfo }}</div>
				<div class="phoneicon"><img src="../../../content/images/phone_icon_white.png" alt="" width="20px" height="20px" /></div>
				<div class="phonenum"><a href='tel:{{ selectedPharmacy ?. phoneNumber }}'>{{ selectedPharmacy ?. phoneNumber }}</a></div>
            </div>

            <div *ngIf="selectedPharmacy?.hours">
                <h4 class="subtitle mt-2">Hours</h4>
                <ng-container *ngFor="let item of selectedPharmacy ?.hours">
                    <div class="hourc-content">
                        <ng-container *ngIf="!item.isClosed">
                            <span class="bold-text">{{item?.day}}:</span><span>{{item?.openingTime}} -
                            {{item.closingTime}}</span>
                        </ng-container>
                        <ng-container *ngIf="item.isClosed">
                            <span class="bold-text">{{item?.day}}: </span><span>Closed</span>
                        </ng-container>
                    </div>
                </ng-container>

            </div>
            <span *ngIf="selectedPharmacy?.lunchHours">
            <h4 class="subtitle mt-2">Lunch Hours </h4> {{selectedPharmacy?.lunchHours}}
        </span>
            <span *ngIf="selectedPharmacy?.holidayHours">
            <h4 class="subtitle mt-2">Special Hours </h4> {{selectedPharmacy?.holidayHours}}
        </span>
        </div>
    </div>
</ng-container>

<div class="skeleton-container" id="skeletonLoader" *ngIf="loader">
    <div class="skeleton-body">
        <div class="container-fluid">
            <div class="row ">
                <div class="container p-0 mx-auto">
                    <div class="row">
                        <div class="col-md-4 top-20">
                            <div class="card skeleton-box">

                            </div>
                        </div>
                        <div class="col-md-4 top-20">
                            <div class="card skeleton-box">

                            </div>
                        </div>
                        <div class="col-md-4 top-20">
                            <div class="card skeleton-box">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import BjcrxSharedModule from 'app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import PrescriptionRxComponent from './prescription-rx.component';
import { prescriptionRoute } from './prescription-rx.route';
import PrescriptionListFilterPipe from './prescription-list-filter.pipe';

const ENTITY_STATES = [...prescriptionRoute];

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild(ENTITY_STATES), TranslateModule],
  declarations: [PrescriptionRxComponent, PrescriptionListFilterPipe]
})
export default class BjcrxPrescriptionRxModule {}

import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import {
  ActivatedRoute, Router
} from '@angular/router';
import {
  FormGroup, FormArray, FormBuilder, Validators, ValidatorFn, ValidationErrors, FormControl
} from '@angular/forms';
import {
  JhiEventManager, JhiParseLinks, JhiAlertService
} from 'ng-jhipster';
import { IPrescriptionTxReqRx } from 'app/shared/model/prescription-tx-req-rx.model';
import AccountService from 'app/core/auth/account.service';
import { HttpResponse } from '@angular/common/http';
import * as moment from 'moment';
import {
  DateValidator, CheckFutureDate
} from 'app/shared/util/request-util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isIEBrowser } from 'app/app.constants';
import ProfileRxService from '../profile-rx/profile-rx.service';
import PrescriptionRxService from '../prescription-rx/prescription-rx.service';
import PrescriptionTxReqRxService from './prescription-tx-req-rx.service';

@Component({
  selector: 'jhi-prescription-tx-req-rx',
  templateUrl: './prescription-tx-req-rx.component.html',
  styleUrls: ['prescription-tx-req-rx.component.scss']
})
export default class PrescriptionTxReqRxComponent implements OnInit, OnDestroy {
  prescriptionTxReqs: IPrescriptionTxReqRx[];

  transPrescriptionForm: FormGroup;

  error;

  success;

  message;

  pharmacyListData: any;

  isSubmitted: any;

  showMessage: any;

  showErrorMessage: any;

  pharmacyList: any;

  transferPrescriptionData: any;

  dependentList: any;

  dependentListFromApi: any;

  showCount: any;

  pharmacyName: any;

  transferPrescriptionPayload: any;

  peopleSoftId: any;

  planholderId: any;

  showDropDown: any;

  targetPharmacy: any;

  isUnauthorized: boolean;

  maxDt: any;

  now: Date;

  hidePatientInfoTitle: any;

  rxPatientId: any;

  patientName: any;

  hideAddAnotherPatient: boolean;

  strDrugNameArray: any;

  detailObj = [];

  patientList = [];

  firstName: any;

  lastName: any;

  primaryPhoneNumber: any;

  inputPhone: any;

  today: Date;

  defaultPharmacyId;

  primaryPatientId;

  primaryEmailAddress;

  shippingAddress;

  slideScreen;

  isApicalled = false;

  strDrugNamePrescription: any = [];

  showLoader = true;

  successResponse = 'Success';

  showLoggedInPhone = false;

  nameValidationPattern = "^[a-zA-Z\\'\\-]+\\s+[a-zA-Z\\'\\-\\s]+$";

  private componentDestroyed: Subject<void> = new Subject<void>();

  get addPatient() {
    return this.transPrescriptionForm.get('addPatient') as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    protected prescriptionTxReqService: PrescriptionTxReqRxService,
    protected prescriptionService: PrescriptionRxService,
    protected parseLinks: JhiParseLinks,
    protected jhiAlertService: JhiAlertService,
    protected accountService: AccountService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected eventManager: JhiEventManager,
    private profileService: ProfileRxService
  ) {
    this.dependentListFromApi = [];
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.componentDestroyed)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.now = new Date();
    this.maxDt = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
    this.isSubmitted = false;
    this.showCount = false;
    this.showMessage = false;
    this.showErrorMessage = false;
    this.today = new Date();
    if (this.isAuthenticated()) {
      this.accountService.identity().then((accountInfo) => {
        this.peopleSoftId = accountInfo.peopleSoftId;
        this.rxPatientId = accountInfo.enterpriseRxPatientId;
        this.firstName = accountInfo.firstName;
        this.lastName = accountInfo.lastName;
        this.profileService
          .getProfile(parseInt(accountInfo.enterpriseRxPatientId, 10))
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe((res:any) => {
            this.firstName = res?.firstName;
            this.lastName = res?.lastName;
            this.primaryPhoneNumber = res?.primaryContactDetails;
            this.shippingAddress = res?.patientAddressDTO;
            this.defaultPharmacyId = res?.defaultPharmacyId;
            this.primaryPatientId = res?.patientId;
            this.primaryEmailAddress = res?.primaryEmailAddress;
          });
        this.getDependentList();
        this.showLoggedInPhone = true;
      });
    }

    this.transPrescriptionForm = this.formBuilder.group({
      sourcePharmacyName: ['', Validators.required],
      sourcePharmacyPhoneNumber: ['', [Validators.required, Validators.minLength(14)]],
      selectPharmacy: ['', Validators.required],
      addPatient: this.formBuilder.array(this.isAuthenticated() ? [this.addLoggedPatientFormGroup()] : [this.addPatientFormGroup()])
    });

    this.prescriptionTxReqService
      .getPharmacyList()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (res: HttpResponse<[]>) => {
          if (res.body) {
            this.pharmacyList = (res.body as { [key: string]: any }[]).filter((pharmacy) => pharmacy.isAdultTransfer === true);
          }
        }
      );
  }

  getDependentList() {
    this.prescriptionService
      .getDependentList(this.peopleSoftId)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((resp) => {
        this.dependentListFromApi = resp;
        this.dependentListFromApi.forEach((element) => {
          if (element.sequence === null) {
            this.planholderId = element.id;
          }
        });
        this.addPatient.controls[0].get('dependentId').setValue(this.planholderId);
        if (this.dependentListFromApi.length > 0) {
          this.showDropDown = true;
        }
      });
  }

  checkIsIEBrowser() {
    return isIEBrowser;
  }

  addAnotherPatient(): void {
    this.showCount = true;
    this.isSubmitted = false;
    if (this.isAuthenticated()) {
      this.addPatient.push(this.addLoggedPatientFormGroup());
    } else {
      this.addPatient.push(this.addPatientFormGroup());
    }
  }

  public validateForm: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const { controls } = control;
    if (controls) {
      const theOne = Object.keys(controls).findIndex((key) => controls[key].value.rxNumber !== '');

      if (theOne === -1) {
        return {
          atLeastOneRequired: {
            text: 'Please enter prescription number.'
          }
        };
      }

      const drugEntered = Object.keys(controls).findIndex(
        (key) => controls[key].value.rxNumber !== '' && controls[key].value.drugName === ''
      );

      if (drugEntered >= 0) {
        return {
          atLeastOneRequired: {
            text: 'Please enter drug name.'
          }
        };
      }
    }
  };

  public validatePhoneNumber: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    if (control.value) {
      const phoneNumber = control.value;
      if (phoneNumber.length !== 14 && control.dirty) {
        return {
          lengthRequired: {
            text: 'Please enter valid phone number'
          }
        };
      }
    }
  };

  addRxNumbersForm() {
    const rxNumberFormArr = this.formBuilder.array<FormGroup>([], { validators: this.validateForm });
    for (let i = 0; i < 5; i++) {
      const fg = this.formBuilder.group({ rxNumber: ['', Validators.maxLength(20)], drugName: [''] });
      rxNumberFormArr.push(fg);
    }
    return rxNumberFormArr;
  }

  addMorePrescriptions(addPatient) {
    const start = addPatient.controls.rxNumberArr.controls.length;
    const end = addPatient.controls.rxNumberArr.controls.length + 5;
    for (let i = start; i < end; i++) {
      const fg = this.formBuilder.group({ rxNumber: [''], drugName: [''] });
      addPatient.controls.rxNumberArr.push(fg);
    }
  }

  addPatientFormGroup(): FormGroup {
    return this.formBuilder.group({
      /* Pattern requires at least one space, allows hyphens and apostrophes */
      guestPatientName: [
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern(this.nameValidationPattern)]
      ],
      guestPatientDob: ['', [Validators.required, DateValidator(), CheckFutureDate()]],
      guestPrimaryPhoneNumber: ['', [Validators.required, Validators.minLength(14)]],
      rxNumberArr: this.addRxNumbersForm()
    });
  }

  addLoggedPatientFormGroup(): FormGroup {
    return this.formBuilder.group({
      dependentId: [''],
      rxNumberArr: this.addRxNumbersForm(),
      primaryPhoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(14), this.validatePhoneNumber]]
    });
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  transferPrescription() {
    this.isSubmitted = true;
    this.showLoader = false;
    if (this.transPrescriptionForm.invalid) {
      if (!this.isAuthenticated()) {
        this.addPatient.controls.forEach((control) => {
          if (
            control.get('guestPatientDob').errors &&
            !control.get('guestPatientDob').errors?.required &&
            !control.get('guestPatientDob').errors?.invalidDate &&
            !control.get('guestPatientDob').errors?.futuredate
          ) {
            control.get('guestPatientDob').setErrors(null);
          }
        });
      }
      // removes error for hidden phone number field when more than one patient
      // is added to transfer request while user is logged in
      if (this.isAuthenticated()) {
        this.addPatient.controls.forEach((control) => {
          if (control.get('primaryPhoneNumber').pristine) {
            control.get('primaryPhoneNumber').setErrors(null);
          }
        });
      }
    }
    if (!this.transPrescriptionForm.invalid) {
      this.transferPrescriptionPayload = this.transPrescriptionForm.value;
      this.transferPrescriptionData = this.transPrescriptionForm.value;

      for (let i = 0; i < this.pharmacyList.length; i++) {
        if (this.pharmacyList[i]?.pharmacyId === parseInt(this.transferPrescriptionData?.selectPharmacy, 10)) {
          this.targetPharmacy = this.pharmacyList[i].name;
        }
      }
      const patientData = this.transPrescriptionForm.value.addPatient;

      patientData.forEach((element) => {
        const strArray = [];
        const drugArray = [];
        const rxStrArray = [];
        element.userType = this.isAuthenticated() ? 'loggedInUser' : 'guestUser';
        element.sourcePharmacyName = this.transferPrescriptionPayload?.sourcePharmacyName;
        element.sourcePharmacyPhoneNumber = this.transferPrescriptionPayload?.sourcePharmacyPhoneNumber;
        element.targetPharmacyId = this.transferPrescriptionPayload?.selectPharmacy;
        element.createdBy = 'string';
        element.emailSentFlag = true;
        element.sourcePharmacyId = 'string';
        element.requestId = 0;
        element.transferRequestDate = moment(new Date()).format('YYYY-MM-DD');
        for (const key of element) {
          if (key === 'rxNumberArr') {
            for (const k of element[key]) {
              if (element[key][k].rxNumber !== '' && element[key][k].drugName !== '') {
                strArray.push(element[key][k].rxNumber);
                if (element[key][k].drugName !== '') {
                  drugArray.push(element[key][k].drugName);
                }
                rxStrArray.push(`${element[key][k].rxNumber} - ${element[key][k].drugName}`);
              }
            }
          } else if (key === 'guestPatientDob') {
            element.dateOfBirth = element[key];
            delete element.guestPatientDob;
          } else if (key === 'guestPatientName') {
            element.patientName = element[key];
            delete element.guestPatientName;
          } else if (key === 'guestPrimaryPhoneNumber' || key === 'primaryPhoneNumber') {
            element.patientPhoneNumber = element[key];
            delete element.guestPrimaryPhoneNumber;
          }
        }
        this.strDrugNameArray = strArray;

        element.strDrugNamePrescription = [];
        element.strDrugNamePrescription = drugArray.reduce((result, field, index) => {
          result[strArray[index]] = field;
          return result;
        }, {});

        element.prescriptionNumberWithStoreId = strArray.join();
        element.drugName = drugArray.join();
        delete element.rxNumberArr;

        if (this.isApicalled === false) {
          if (this.isAuthenticated()) {
            // THERE IS NO DOB INPUT FOR AUTHENTICATED USERS
            if (this.dependentListFromApi.length > 0) {
              const patientSequence = this.dependentListFromApi.filter((dependent) => parseInt(element.dependentId, 10) === dependent.id);

              element.sequence = patientSequence[0].sequence;
              element.patientId = patientSequence[0].patientId;
              element.patientName = `${patientSequence[0].firstName} ${patientSequence[0].lastName}`;
              element.dateOfBirth = moment(patientSequence[0].birthDate).format('YYYY-MM-DD');
            } else {
              element.sequence = null;
              element.patientId = this.rxPatientId;
              element.patientName = `${this.firstName} ${this.lastName}`;
            }
          }
          element.peoplesoftId = this.peopleSoftId;
        }
      });
      this.detailObj = JSON.parse(JSON.stringify(patientData));
      if (this.isApicalled === false) {
        this.isApicalled = true;
        this.prescriptionTxReqService
          .transferPrescriptions(this.transPrescriptionForm.value.addPatient)
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe(
            () => {
              this.isApicalled = false;
              this.showMessage = true;
              this.showErrorMessage = false;
              this.showLoader = true;
            },
            () => {
              this.isApicalled = false;
              this.showMessage = false;
              this.showErrorMessage = true;
              this.showLoader = true;
            }
          );
        this.updateProfile();
      }
    }
  }

  createPayload() {
    const payload = {
      admin: false,
      firstName: this.firstName,
      lastName: this.lastName,
      defaultPharmacy: this.defaultPharmacyId,
      primaryUserPatientId: this.primaryPatientId,
      deletes: [],
      edits: [
        {
          emails: [
            {
              address: this.primaryEmailAddress,
              rank: '1',
              useForNotifier: true
            }
          ],
          shppingAddress: {
            address: this.shippingAddress.line1,
            city: this.shippingAddress.city,
            phoneNumbers: [
              {
                phonenumber: this.getValidPhoneNumber(this.primaryPhoneNumber),
                type: 'Primary',
                usage: 'Primary'
              }
            ],
            state: this.shippingAddress.state,
            zipCode: this.shippingAddress.zip
          }
        }
      ],
      patientId: this.rxPatientId
    };
    return payload;
  }

  private updateProfile() {
    this.profileService
      .update(this.createPayload())
      // .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (res) => {
          this.isApicalled = false;
          this.showMessage = true;
          this.showLoader = true;
          if (
            (res.body.updatePasswordResponse === null || res.body.updatePasswordResponse === this.successResponse) &&
            (res.body.setDefaultPharmacyResponse === null || res.body.setDefaultPharmacyResponse === this.successResponse) &&
            (res.body.updatePatientDetailsResponse === null || res.body.updatePatientDetailsResponse === this.successResponse)
          ) {
            this.success = true;
            this.error = false;
            this.message = 'profile updated successfully';
          } else {
            this.success = false;
            this.error = true;
            if (res.body.updatePatientDetailsResponse !== this.successResponse) {
              this.message = res.body.updatePatientDetailsResponse;
            }
          }
        },
        (error) => {
          this.showMessage = true;
          this.isApicalled = false;
          this.success = false;
          this.error = true;
          this.showLoader = true;
          this.message = error.error.detail ? error.error.detail : 'There ws a problem updating your profile';
        }
      );
  }

  changeDateFormat(date) {
    return moment(date).format('L');
  }

  calculateAge(addPatient) {
    this.isUnauthorized = false;
    this.dependentListFromApi.forEach((ele) => {
      let age = 0;
      if (parseInt(ele.dependentId, 10) !== this.planholderId && ele.id === parseInt(addPatient.value.dependentId, 10)) {
        age = moment().diff(ele.birthDate, 'month');
        if (age / 12 < 18) {
          return true;
        }
        addPatient.get('dependentId').setErrors({ dependentNotMinor: 'error' });
        return false;
      }
    });
  }

  getValidPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber
      .replace('(', '')
      .replace(') ', '')
      .replace('-', '')
      .replace(' ', '');
    return `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, phoneNumber.length - 1)}`;
  }

  removePatient(i) {
    this.addPatient.removeAt(i);
  }

  resetForm(failed) {
    this.showErrorMessage = false;
    this.showLoader = false;
    this.showErrorMessage = false;
    this.showMessage = false;
    this.isSubmitted = false;
    if (!failed) {
      this.transPrescriptionForm.reset();
    }
    if (this.isAuthenticated()) {
      this.addPatient.controls[0].get('dependentId').setValue(this.planholderId);
    }
    this.transPrescriptionForm.get('selectPharmacy').setValue('');
  }

  public ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import BjcrxSharedModule from 'app/shared/shared.module';
import PasswordStrengthBarComponent from './password/password-strength-bar.component';
import RegisterComponent from './register/register.component';
import LoginComponent from './login/login.component';
import ActivateComponent from './activate/activate.component';
import PasswordComponent from './password/password.component';
import PasswordResetInitComponent from './password-reset/init/password-reset-init.component';
import PasswordResetFinishComponent from './password-reset/finish/password-reset-finish.component';
import SettingsComponent from './settings/settings.component';
import { accountState } from './account.route';
import ForgotUsernameComponent from './forgot-username/forgot-username.component';
import PasswordResetComponent from './password-reset/reset/password-reset.component';

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild(accountState)],
  declarations: [
    ActivateComponent,
    RegisterComponent,
    LoginComponent,
    PasswordComponent,
    PasswordStrengthBarComponent,
    PasswordResetInitComponent,
    PasswordResetFinishComponent,
    SettingsComponent,
    ForgotUsernameComponent,
    PasswordResetComponent
  ]
})
export default class BjcrxAccountModule {}

import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import {
  Router, ActivatedRouteSnapshot, NavigationEnd, NavigationError
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import JhiLanguageHelper from 'app/core/language/language.helper';
import IdleService from 'app/core/auth/idle.service';
import AccountService from 'app/core/auth/account.service';
import SystemConfigRxService from 'app/entities/system-config-rx/system-config-rx.service';
import { HttpResponse } from '@angular/common/http';
import { ISystemConfigRx } from 'app/shared/model/system-config-rx.model';
import { map, takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { ConnectionService } from 'ng-connection-service';
import {
  environments, SERVER_API_URL, isIEBrowser
} from 'app/app.constants';
import LoginService from 'app/account/login/login.service';
import MessagingService from 'app/shared/messaging.service';
import { Subject } from 'rxjs';
import {
  NativeEventSource, EventSourcePolyfill
} from 'event-source-polyfill';
import VersionCheckService from 'app/version-check.service';

const EventSource = NativeEventSource || EventSourcePolyfill;

@Component({
  selector: 'jhi-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export default class JhiMainComponent implements OnInit, OnDestroy {
  private mainComponent$: Subject<void> = new Subject<void>();

  show: any;

  messages;

  currentMessage;

  isConnected;

  hideSidebar;

  slideScreen;

  systemResponse: any;

  maintenanceMsg: any;

  systemMsg: any;

  isinMaintenanceMode: boolean;

  myData: any;

  currentRoute: any;

  constructor(
    private jhiLanguageHelper: JhiLanguageHelper,
    private router: Router,
    private idleService: IdleService,
    private accountService: AccountService,
    private systemConfigService: SystemConfigRxService,
    private $localStorage: LocalStorageService,
    private connectionService: ConnectionService,
    private toastr: ToastrService,
    private logoutService: LoginService,
    private messageService: MessagingService,
    private versionCheckService: VersionCheckService
  ) {
    this.connect();
    this.messageService.receiveMessage();
    console.warn(isIEBrowser);
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: string = routeSnapshot.data && routeSnapshot.data?.pageTitle ? routeSnapshot.data?.pageTitle : 'bjcrxApp';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  ngOnInit() {
    this.versionCheckService.initVersionCheck(environments.versionCheckURL);
    this.isinMaintenanceMode = false;
    this.accountService.slideScreen.pipe(takeUntil(this.mainComponent$)).subscribe((data) => {
      this.messages = data;
    });

    this.connectionService
      .monitor()
      .pipe(takeUntil(this.mainComponent$))
      .subscribe((isConnected) => {
        this.isConnected = isConnected;
      });

    this.show = false;
    this.loadSystemConfig();
    setTimeout(() => {
      this.show = true;
    }, 3000);
    this.router.events.pipe(takeUntil(this.mainComponent$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.accountService.isAuthenticated()) {
          this.idleService.checkUserIdle();
        }
        this.jhiLanguageHelper.updateTitle(this.getPageTitle(this.router.routerState.snapshot.root));
      }
      if (event instanceof NavigationError && event.error.status === 404) {
        this.router.navigate(['/404']);
      }
    });
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  loadSystemConfig() {
    this.systemConfigService
      .getConfigDetail('RgMailDel,JwtExpryTm,HltTopic,DrugInfo')
      .pipe(map((res: HttpResponse<ISystemConfigRx[]>) => res.body))
      .subscribe(
        (res: ISystemConfigRx[]) => {
          this.$localStorage.store('sysconfig', JSON.stringify(res));
        }
      );
  }

  connect(): void {
    const source = new EventSource(`${SERVER_API_URL}api/systemnotification/latest`);
    source.addEventListener('message', (message) => {
      if (message) {
        this.myData = JSON.parse(message.data);
        if (this.myData.length > 0) {
          for (let i = 0; i < this.myData.length; i++) {
            if (this.myData[i].notificationType === 'system') {
              let showToaster = false;
              let closedMessages = this.$localStorage.retrieve('closed-messages');
              if (closedMessages) {
                closedMessages = closedMessages.split(',');
                if (closedMessages.indexOf(this.myData[i].id.toString()) === -1) {
                  showToaster = true;
                }
              } else {
                showToaster = true;
              }
              if (showToaster) {
                const toast = this.toastr.success(this.myData[i].message);
                toast.onTap.subscribe(() => {
                  this.closeMessage(this.myData[i].id);
                });
                toast.onAction.subscribe(() => {
                  this.closeMessage(this.myData[i].id);
                });
              }
            }

            if (this.myData[i].notificationType === 'maintenance') {
              this.accountService.maintenanceScreen.next((this.isinMaintenanceMode = true));
              this.currentRoute = this.router.url;
              if (
                this.currentRoute !== '/pharmacy-locations-rx' &&
                this.currentRoute !== '/prescription-tx-req-rx' &&
                this.currentRoute !== '/information' &&
                this.currentRoute !== '/contact-us'
              ) {
                this.logoutService.logout(false);
                this.router.navigate(['/maintenance']);
                this.accountService.isMaintenance.next(this.myData[i]);
              }
            } else {
              this.accountService.maintenanceScreen.next((this.isinMaintenanceMode = false));
              if (this.router.url === '/maintenance') {
                this.router.navigate(['/']);
              }
            }
          }
        } else {
          if (this.router.url === '/maintenance') {
            this.router.navigate(['/']);
          }
          if (this.isinMaintenanceMode) {
            this.accountService.maintenanceScreen.next((this.isinMaintenanceMode = false));
          }
        }
      }
    });
  }

  closeMessage(messageId) {
    let messages = this.$localStorage.retrieve('closed-messages');
    if (messages) {
      messages = messages.split(',');
      messages.push(messageId);
      messages = messages.join(',');
    } else {
      messages = messageId.toString();
    }
    this.$localStorage.store('closed-messages', messages);
  }

  showSuccess() {
    this.accountService.isSystemMaintenanceModeOn().subscribe((resp) => {
      if (resp) {
        const systemResponse = JSON.parse(JSON.stringify(resp));

        this.maintenanceMsg = systemResponse.filter((response) => response.notificationType === 'maintenance');

        this.systemMsg = systemResponse.filter((response) => response.notificationType === 'system');

        if (this.maintenanceMsg[0]) {
          this.accountService.maintenanceScreen.next((this.isinMaintenanceMode = true));
          this.router.navigate(['/maintenance']);
        }
        if (this.systemMsg[0]) {
          if (this.systemMsg[0]?.message) {
            this.toastr.success(this.systemMsg[0].message);
          }
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.mainComponent$.next();
    this.mainComponent$.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import {
  Resolve, ActivatedRouteSnapshot, Routes
} from '@angular/router';
import { JhiResolvePagingParams } from 'ng-jhipster';
import UserRouteAccessService from 'app/core/auth/user-route-access-service';
import { Observable, of } from 'rxjs';
import {
  filter, map
} from 'rxjs/operators';
import ProfileRx, { IProfileRx } from 'app/shared/model/profile-rx.model';
import ProfileRxService from './profile-rx.service';
import ProfileRxComponent from './profile-rx/profile-rx.component';
import ProfileRxUpdateComponent from './profile-rx-update/profile-rx-update.component';
import ProfileRxQuestionComponent from './profile-questions/profile-questions.component';
import ProfileRxCreateComponent from './profile-rx-create/profile-rx-create.component';
import ProfileRxSecurityQuestionComponent from './profile-security-questions/profile-security-questions.component';
import ProfileRxMailOrderComponent from './profile-rx-mailorder/profile-rx-mailorder.component';

@Injectable({ providedIn: 'root' })
export class ProfileRxResolve implements Resolve<IProfileRx> {
  constructor(private service: ProfileRxService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IProfileRx> {
    const { id } = route.params;
    if (id) {
      return this.service.find(id).pipe(
        filter((response: HttpResponse<ProfileRx>) => response.ok),
        map((profile: HttpResponse<ProfileRx>) => profile.body)
      );
    }
    return of(new ProfileRx());
  }
}

export const profileRoute: Routes = [
  {
    path: '',
    component: ProfileRxComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams
    },
    data: {
      authorities: ['ROLE_USER'],
      defaultSort: 'id,asc',
      pageTitle: 'bjcrxApp.profile.home.title'
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: ':id/edit',
    component: ProfileRxUpdateComponent,
    data: {
      authorities: ['ROLE_USER'],
      pageTitle: 'bjcrxApp.profile.home.title'
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'questions/:id',
    component: ProfileRxQuestionComponent,
    data: {
      pageTitle: 'bjcrxApp.profile.question.title'
    }
  },
  {
    path: 'create/:type',
    component: ProfileRxCreateComponent,
    data: {
      pageTitle: 'bjcrxApp.profile.create.title'
    }
  },
  {
    path: 'security-questions',
    component: ProfileRxSecurityQuestionComponent,
    data: {
      pageTitle: 'bjcrxApp.profile.create.title'
    }
  },
  {
    path: 'mailorder',
    component: ProfileRxMailOrderComponent,
    data: {
      pageTitle: 'bjcrxApp.profile.create.title'
    }
  }
];

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DATE_FORMAT } from 'app/shared/constants/input.constants';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { ICardInfoRx } from 'app/shared/model/card-info-rx.model';

type EntityResponseType = HttpResponse<ICardInfoRx>;
type EntityArrayResponseType = HttpResponse<ICardInfoRx[]>;

@Injectable({ providedIn: 'root' })
export default class CardInfoRxService {
  public resourceUrl = `${SERVER_API_URL}api/card-infos`;

  constructor(protected http: HttpClient) {}

  getCardList(data): Observable<any> {
    const transferPrescriptionUrl = `${SERVER_API_URL}api/rx/rxProfile/${data}/card`;
    return this.http.get(transferPrescriptionUrl);
  }

  addCardPayment(transPresData: any, data): Observable<any> {
    const addCardPaymentUrl = `${SERVER_API_URL}api/rx/rxProfile/${data}/addCard`;
    return this.http.post(addCardPaymentUrl, transPresData);
  }

  deleteCardPayment(tranData: any, idData): Observable<any> {
    const deleteCardPaymentUrl = `${SERVER_API_URL}api/rx/rxProfile/${idData}/deactivateCard`;
    return this.http.post(deleteCardPaymentUrl, tranData, { responseType: 'text' });
  }

  updateCardInfo(transferData: any, patientIdData, facilityIdData): Observable<any> {
    const updateCardPaymentUrl = `${SERVER_API_URL}api/rx/rxProfile/${patientIdData}/update`;
    const param = new HttpParams().set('facilityId', facilityIdData);
    return this.http.put(updateCardPaymentUrl, transferData, { params: param, responseType: 'text' });
  }

  create(cardInfo: ICardInfoRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cardInfo);
    return this.http
      .post<ICardInfoRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cardInfo: ICardInfoRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cardInfo);
    return this.http
      .put<ICardInfoRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICardInfoRx>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICardInfoRx[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(cardInfo: ICardInfoRx): ICardInfoRx {
    const copy: ICardInfoRx = { ...cardInfo, expiryDate: cardInfo.expiryDate != null && cardInfo.expiryDate.isValid() ? moment(cardInfo.expiryDate.format(DATE_FORMAT)) : null };
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.expiryDate = res.body.expiryDate != null ? moment(res.body.expiryDate) : null;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cardInfo: ICardInfoRx) => {
        cardInfo.expiryDate = cardInfo.expiryDate != null ? moment(cardInfo.expiryDate) : null;
      });
    }
    return res;
  }
}

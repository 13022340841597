import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import BjcrxSharedModule from 'app/shared/shared.module';
import { profileRoute } from './profile-rx.route';
import ProfileRxComponent from './profile-rx/profile-rx.component';
import ProfileRxDetailComponent from './profile-rx-detail/profile-rx-detail.component';
import ProfileRxUpdateComponent from './profile-rx-update/profile-rx-update.component';
import ProfileRxQuestionComponent from './profile-questions/profile-questions.component';
import ProfileRxCreateComponent from './profile-rx-create/profile-rx-create.component';
import ProfileRxSecurityQuestionComponent from './profile-security-questions/profile-security-questions.component';
import ProfileRxMailOrderComponent from './profile-rx-mailorder/profile-rx-mailorder.component';

const ENTITY_STATES = [...profileRoute];

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild(ENTITY_STATES)],
  declarations: [
    ProfileRxComponent,
    ProfileRxDetailComponent,
    ProfileRxUpdateComponent,
    ProfileRxQuestionComponent,
    ProfileRxCreateComponent,
    ProfileRxSecurityQuestionComponent,
    ProfileRxMailOrderComponent
  ],
  entryComponents: []
})
export default class BjcrxProfileRxModule {}

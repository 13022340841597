import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import {
  ActivatedRoute, Router
} from '@angular/router';
import {
  Validators, FormGroup, FormControl, ValidatorFn, ValidationErrors
} from '@angular/forms';
import AccountService from 'app/core/auth/account.service';
import MessagingService from 'app/shared/messaging.service';
import UserService from 'app/core/user/user.service';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import ConfirmationDialogService from 'app/core/confirmation-dialog/confirmation-dialog.service';
import { FormatPhoneNumber } from 'app/shared/util/request-util';
import ProfileRxService from '../profile-rx/profile-rx.service';
import NotificationMessageRxService from './notification-message-rx.service';

@Component({
  selector: 'jhi-notification-message-rx',
  templateUrl: './notification-message-rx.component.html',
  styleUrls: ['notification-message-rx.scss']
})
export default class NotificationMessageRxComponent implements OnInit, OnDestroy {
  private NotificationMessageRxComponent$: Subject<void> = new Subject<void>();

  notificationForm: FormGroup;

  submitted = false;

  userId: any;

  username: any;

  patientId: any;

  showError = false;

  showSuccecss = false;

  getNotificationsList: any;

  currentUser: any;

  rxPayload = {};

  phPayload = {};

  dndPayload = {};

  notificationList: any;

  userNotification: any;

  pcsPref: any;

  rxPref: any;

  dndPref: any;

  rxE: any;

  rxECheckbox: boolean;

  rxS: any;

  rxSCheckbox: boolean;

  pcsE: any;

  pcsECheckbox: boolean;

  pcsS: any;

  pcsSCheckbox: boolean;

  pcsC: any;

  pcsCCheckbox: boolean;

  showmessage = false;

  success = false;

  error = false;

  message;

  isSubscribed: boolean;

  showSubscribe: boolean;

  userPrimaryEmail;

  userPrimaryPhone;

  isUpdateProfile = false;

  shippingAddress;

  slideScreen;

  optedForPushNotification = false;

  profile;

  showLoader = false;

  agent: any;

  showPushButton: any;

  optedForCallNotificationVal = false;

  optedForEmailNotificationVal = false;

  optedForEmailPushNotificationVal = false;

  optedForNotificationVal = false;

  optedForSMSNotificationVal = false;

  optedForSMSPushNotificationVal = false;

  optedForPushNotificationVal = false;

  declineMessage = "By declining this consent you won't be able to get any kind of notifications such as emails and text messages.";

  communicationMethod;

  communicationValue;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private notificationService: NotificationMessageRxService,
    private accountService: AccountService,
    private profileService: ProfileRxService,
    private requestToSubscribeService: MessagingService,
    private $sessionStorage: SessionStorageService,
    private requestToUnSubscribeService: UserService,
    private http: HttpClient,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit() {
    this.agent = navigator.appVersion;

    if (this.notificationsAreAllowed() === true) {
      this.showPushButton = true;
    } else {
      this.showPushButton = false;
    }
    this.showLoader = true;
    this.accountService.slideScreen.pipe(takeUntil(this.NotificationMessageRxComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.notificationForm = new FormGroup(
      {
        phContactType: new FormControl('dnd', [Validators.required]),
        // rxContactType: new FormControl('email', [Validators.required]),
        userEmail: new FormControl('', [Validators.email]),
        userPhone: new FormControl('', [Validators.minLength(14)]),
        subscribeToPush: new FormControl('')
      },
      { validators: this.validateForm() }
    );

    this.accountService.identity().then((account) => {
      this.currentUser = account;

      if (this.currentUser) {
        this.userId = this.currentUser.id;
        this.username = this.currentUser.username;
        this.patientId = this.currentUser.enterpriseRxPatientId;

        this.optedForPushNotification = this.currentUser.optedForPushNotification;
        this.getNotificationsList = this.getUserNotificationsSettings();
        this.getProfile();
      }
    });

    this.accountService
      .fetch()
      .toPromise()
      .then((response) => {
        this.showLoader = false;
        this.currentUser = response.body;
        this.optedForPushNotification = this.currentUser.optedForPushNotification;
        this.notificationForm.get('subscribeToPush').setValue(this.optedForPushNotification);
      });
  }

  getProfile() {
    this.profileService
      .getProfile(this.currentUser.enterpriseRxPatientId)
      .pipe(takeUntil(this.NotificationMessageRxComponent$))
      .subscribe((res:any) => {
        this.profile = res;
        this.shippingAddress = res?.patientAddressDTO;
        if (res?.primaryEmailAddress) {
          this.userPrimaryEmail = res?.primaryEmailAddress;
          this.notificationForm.get('userEmail').setValue(res?.primaryEmailAddress);
          this.notificationForm.get('userEmail').disable();
        }
        if (res?.primaryContactDetails) {
          this.userPrimaryPhone = res?.primaryContactDetails;
          this.notificationForm.get('userPhone').setValue(FormatPhoneNumber(res?.primaryContactDetails));
          this.notificationForm.get('userPhone').disable();
        }
      });
  }

  validateForm(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      if (group.get('phContactType').value === 'email' && !group.get('userEmail').value) {
        group.get('userEmail').setErrors({ required: true });
      } else if (group.get('userEmail').errors && !group.get('userEmail').errors.email) {
        group.get('userEmail').setErrors(null);
      }

      if ((group.get('phContactType').value === 'sms' || group.get('phContactType').value === 'call') && !group.get('userPhone').value) {
        group.get('userPhone').setErrors({ required: true });
      } else {
        group.get('userPhone').setErrors(null);
      }
      return null;
    };
  }

  subscribeNotifications(event) {
    if (event.target.checked) {
      this.showSubscribe = true;
      this.requestToSubscribeService.requestPermission(this.userId);
      this.currentUser.optedForPushNotification = true;
    } else {
      this.unSubscribeNotifications();
      this.currentUser.optedForPushNotification = false;
    }
  }

  unSubscribeNotifications() {
    this.requestToUnSubscribeService
      .rejectSerivce(this.userId)
      .pipe(takeUntil(this.NotificationMessageRxComponent$))
      .subscribe(() => {});
    this.showSubscribe = false;
  }

  submitNotification() {
    this.submitted = true;
    this.showmessage = true;
    this.showLoader = true;
    const f = this.notificationForm.value;
    this.rxPayload = {};
    this.phPayload = {};

    if (!this.notificationForm.invalid) {
      this.isUpdateProfile = false;

      if (
        this.userPrimaryEmail !== this.notificationForm.value.userEmail ||
        this.notificationForm.value.userPhone !== FormatPhoneNumber(this.userPrimaryPhone)
      ) {
        this.isUpdateProfile = true;
      }

      this.optedForCallNotificationVal = false;
      this.optedForEmailNotificationVal = false;
      this.optedForEmailPushNotificationVal = false;
      this.optedForNotificationVal = false;
      this.optedForSMSNotificationVal = false;
      this.optedForSMSPushNotificationVal = false;
      this.optedForPushNotificationVal = false;

      if (f.phContactType === 'dnd') {
        // DND
        this.optedForNotificationVal = true;
        this.optedForCallNotificationVal = true;
        this.$sessionStorage.store('showConsentScreen', true);
        this.communicationMethod = 'PHONE CALL';
        this.communicationValue = this.userPrimaryPhone;
      }

      if (f.phContactType === 'email') {
        this.optedForEmailNotificationVal = true;
        this.$sessionStorage.store('showConsentScreen', false);
        this.communicationMethod = 'EMAIL';
        this.communicationValue = this.userPrimaryEmail;
      }

      if (f.phContactType === 'sms') {
        this.optedForSMSNotificationVal = true;
        this.$sessionStorage.store('showConsentScreen', false);
        this.communicationMethod = 'TEXT MESSAGE';
        this.communicationValue = this.userPrimaryPhone;
      }

      this.optedForPushNotificationVal = f.subscribeToPush === true; // Toggle

      const jsonPayloadStr = {
        patientId: this.patientId,
        optedForNoNotification: this.optedForNotificationVal,
        optedForEmailNotification: this.optedForEmailNotificationVal,
        optedForSMSNotification: this.optedForSMSNotificationVal,
        optedForCallNotification: this.optedForCallNotificationVal,
        optedForPushNotification: this.optedForPushNotificationVal,
        optedForEmailPushNotification: this.optedForEmailPushNotificationVal,
        optedForSMSPushNotification: this.optedForSMSPushNotificationVal,
        communicationMethod: this.communicationMethod,
        communicationValue: this.communicationValue
      };

      const method = 'UPDATE';
      /* if (this.optedForNotificationVal) {
        // Save consent choice
        method = 'DELETE';
        this.submitDecline(jsonPayloadStr, method);
      } */
      // Save other notifications
      if (jsonPayloadStr) {
        this.saveNotification(jsonPayloadStr, method);
      }
    }
  }

  saveNotification(payload: any, method: string) {
    this.notificationService
      .submitNotificationSettings(payload, method)
      .pipe(takeUntil(this.NotificationMessageRxComponent$))
      .subscribe(
        (res) => {
          if (res) {
            this.showmessage = true;
            this.success = true;
            this.error = false;
            this.message = 'Notification Preferences saved successfully.';
            if (this.isUpdateProfile) {
              this.updateProfile();
            }
            this.showLoader = false;
          }
        },
        () => {
          this.success = false;
          this.error = true;
          this.message = 'Notification Preferences not saved.';
          this.showLoader = false;
        }
      );
  }

  updateProfile() {
    const payload = {
      edits: [],
      patientId: this.currentUser.enterpriseRxPatientId,
      admin: false,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName
    };
    let submitUpdateForm = false;
    if (this.userPrimaryEmail !== this.notificationForm.value.userEmail && this.notificationForm.value.userEmail) {
      submitUpdateForm = true;
      if (!payload?.edits[0]) {
        payload.edits[0] = { emails: [] };
      }
      payload.edits[0].emails = [
        {
          address: this.notificationForm.value.userEmail,
          rank: '1',
          useForNotifier: true
        }
      ];
    }

    if (this.notificationForm.value.userPhone !== FormatPhoneNumber(this.userPrimaryPhone) && this.notificationForm.value.userPhone) {
      submitUpdateForm = true;
      if (!payload?.edits[0]) {
        payload.edits[0] = { shippingAddress: {} };
      }

      payload.edits[0].shippingAddress = {
        address: this.shippingAddress.line1,
        city: this.shippingAddress.city,
        phoneNumbers: [
          {
            phonenumber: this.getValidPhoneNumber(this.notificationForm.value.userPhone),
            type: 'Primary',
            usage: 'Primary'
          }
        ],
        state: this.shippingAddress.state,
        zipCode: this.shippingAddress.zip
      };
    }
    if (submitUpdateForm) {
      this.profileService
        .update(payload)
        .pipe(takeUntil(this.NotificationMessageRxComponent$))
        .subscribe(() => {}, () => {});
    }
  }

  getValidPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber
      .replace('(', '')
      .replace(') ', '')
      .replace('-', '')
      .replace(' ', '');
    return `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, phoneNumber.length - 1)}`;
  }

  getUserNotificationsSettings() {
    this.userNotification = [];

    this.notificationService
      .getUserNotificationSettings(this.patientId)
      .pipe(takeUntil(this.NotificationMessageRxComponent$))
      .subscribe((resp) => {
        if (resp.status === 200) {
          this.userNotification = resp.body;

          if (this.userNotification.optedForEmailNotification) {
            this.notificationForm.get('phContactType').setValue('email');
          } else if (this.userNotification.optedForSMSNotification) {
            this.notificationForm.get('phContactType').setValue('sms');
          } else if (this.userNotification.optedForCallNotification) {
            this.notificationForm.get('phContactType').setValue('dnd');
          } else {
            this.showmessage = true;
            this.success = false;
            this.error = true;
            this.message = 'Please call Pharmacy to enroll in Notifications.';
            this.showLoader = false;
          }

          // if (this.userNotification.optedForEmailPushNotification) {
          //   this.notificationForm.get('rxContactType').setValue('email');
          // }

          // if (this.userNotification.optedForSMSPushNotification) {
          //   this.notificationForm.get('rxContactType').setValue('sms');
          // }

          // if (this.userNotification.optedForNotification) {
          //   this.notificationForm.get('phContactType').setValue('dnd');
          // }
        }
      });
  }

  allowPushMsg() {
    this.requestToSubscribeService.requestPermission(this.userId);
  }

  notificationsAreAllowed(): boolean {
    let result = true;
    // verify browser supports notifications
    if (result && !('Notification' in window)) {
      result = false;
    }
    // verify notifications are not already blocked
    if (result && Notification.permission === 'denied') {
      result = false;
    }
    return result;
  }

  submitDecline(payload: any, method: string) {
    this.confirmationDialogService.confirm('Are you sure?', this.declineMessage, 'CONFIRM', 'CANCEL', 'lg').then((confirmed) => {
      if (confirmed) {
        this.saveDetails();
        this.saveNotification(payload, method);
      } else {
        window.location.reload();
      }
    });
  }

  saveDetails() {
    const declinedConsent = 0;
    const payload = {
      consent: declinedConsent,
      primaryUserPatientId: this.currentUser.enterpriseRxPatientId,
      userId: this.currentUser.id
    };
    this.http.post(`${SERVER_API_URL}api/user/notification-consent`, payload).subscribe((res) => {
      console.warn(res);
      this.router.navigate(['']);
    });
  }

  tryagain() {
    this.error = false;
    this.success = false;
    this.showmessage = false;
    this.showLoader = false;
  }

  public ngOnDestroy(): void {
    this.NotificationMessageRxComponent$.next();
    this.NotificationMessageRxComponent$.unsubscribe();
  }
}

<h1 class="pageTitle">Helpful Links</h1>
<div class="content options-page m-0 ">
    <div class="row">
        <div class="col-md-6 options">
            <div class="row">
                <div class="col-md-10 col-sm-12" (click)="showDrugInformation()">
                     <h5 class="info-icons"><fa-icon icon='prescription' class="font-size24"></fa-icon> DRUG INFORMATION</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 col-sm-12" (click)="showHealthTopics()">
                    <span class="icon-text"><h5 class="info-icons"><fa-icon icon='tasks' class="font-size24"></fa-icon> HEALTH TOPICS</h5></span>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'jhi-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['contact-us.scss']
})
export default class ContactUsComponent {
  previousState() {
    window.history.back();
  }

  showDrugInformation() {}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { DATE_FORMAT } from 'app/shared/constants/input.constants';
import { map } from 'rxjs/operators';

import { SERVER_API_URL, USPS_ID } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { IProfileRx } from 'app/shared/model/profile-rx.model';
import { NgxXml2jsonService } from 'ngx-xml2json';
import PersonDTO from 'app/shared/model/person.model';
import AdminQueueDTO from 'app/shared/model/admin-queue.model';

type EntityResponseType = HttpResponse<IProfileRx>;
type EntityArrayResponseType = HttpResponse<IProfileRx[]>;

@Injectable({ providedIn: 'root' })
export default class ProfileRxService {
  public resourceUrl = `${SERVER_API_URL}api/profiles`;

  constructor(protected http: HttpClient, private xml2json: NgxXml2jsonService) {}

  create(profile: IProfileRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(profile);
    return this.http
      .post<IProfileRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(profile: any): Observable<any> {
    return this.http.put(`${SERVER_API_URL}api/rx/rxProfile`, profile, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IProfileRx>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IProfileRx[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  validateBJCUser(req?: any): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/rx/rxProfile/validateId`, req).pipe(map((res: any) => res));
  }

  validateUser(req?: any, attempt?: number): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/rx/rxProfile/validation?attempt=${attempt}`, req).pipe(map((res: any) => res));
  }

  getSecurityQuestionsPlus(req: PersonDTO): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/rx/rxProfile/securityQuestionPlus`, req).pipe(map((res: any) => res));
  }

  submitQuestions(req?: any): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/rx/rxProfile/securityQuestion`, req).pipe(map((res: any) => res));
  }

  getProfile(patientId: number) {
    return this.http.get(`${SERVER_API_URL}api/rx/rxProfile/${patientId}`);
  }

  getDependentListWithoutPrimary(peopleSoftId): Observable<any> {
    return this.http.get(`${SERVER_API_URL}api/peoplesoft-imports/dependentsWithoutPrimary/${peopleSoftId}`);
  }

  registerForMailorder(req): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/registermailorder`, req, { responseType: 'text' });
  }

  sendToAdminQueue(queueItem: AdminQueueDTO): Observable<any> {
    return this.http.post(`${SERVER_API_URL}api/pending-queues`, queueItem, { observe: 'response' });
  }

  getPrimaryPlanMember(peopleSoftId): Observable<any> {
    return this.http.get(`${SERVER_API_URL}api/peoplesoft-imports/${peopleSoftId}/-1/1`, { observe: 'response' });
  }

  protected convertDateFromClient(profile: IProfileRx): IProfileRx {
    const copy: IProfileRx = { ...profile, dateOfBirth: profile.dateOfBirth != null && profile.dateOfBirth.isValid() ? moment(profile.dateOfBirth.format(DATE_FORMAT)) : null };
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateOfBirth = res.body.dateOfBirth != null ? moment(res.body.dateOfBirth) : null;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((profile: IProfileRx) => {
        profile.dateOfBirth = profile.dateOfBirth != null ? moment(profile.dateOfBirth) : null;
      });
    }
    return res;
  }

  validateAddress(detail): Observable<any> {
    const shippingAddress =
      `<AddressValidateRequest USERID="${
        USPS_ID
      }"><Revision>1</Revision><Address ID="0"><Address1>${
        detail.firstName
      } ${
        detail.lastName
      }</Address1><Address2>${
        detail.addressLine
      }</Address2><City/><State>${
        detail.addressState
      }</State><Zip5>${
        detail.addressZip
      }</Zip5><Zip4/></Address></AddressValidateRequest>`;

    return this.http
      .get(`${SERVER_API_URL}api/rx/rxProfile/validateaddress?api=Verify&xml=${encodeURIComponent(shippingAddress)}`, {
        responseType: 'text'
      })
      .pipe(
        map((res: any) => {
          const xml = new DOMParser().parseFromString(res, 'text/xml');
          res = this.xml2json.xmlToJson(xml);
          return res;
        })
      );
  }

  deleteAccount(id: number): Observable<any> {
    const obj = {};
    return this.http.put(`${SERVER_API_URL}api/users/${id}`, obj, { observe: 'response' });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DATE_FORMAT } from 'app/shared/constants/input.constants';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { ICustomerStatementRx } from 'app/shared/model/customer-statement-rx.model';

type EntityResponseType = HttpResponse<ICustomerStatementRx>;
type EntityArrayResponseType = HttpResponse<ICustomerStatementRx[]>;

@Injectable({ providedIn: 'root' })
export default class CustomerStatementRxService {
  public resourceUrl = `${SERVER_API_URL}api/rx/customer-statements`;

  public customerUrl = `${SERVER_API_URL}api/rx/customer-statement`;

  constructor(protected http: HttpClient) {}

  customersStatements(data): Observable<any> {
    const transferPrescriptionUrl = `${SERVER_API_URL}api/rx/customer-statement`;
    return this.http.post(transferPrescriptionUrl, data);
  }

  // customersStatements(date, type): Observable<any> {
  //   const transferPrescriptionUrl = SERVER_API_URL + 'api/rx/customer-statement?date=' + date + '&type=' + type;
  //   return this.http.get(transferPrescriptionUrl);
  // }

  create(customerStatement: ICustomerStatementRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(customerStatement);
    return this.http
      .post<ICustomerStatementRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(customerStatement: ICustomerStatementRx): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(customerStatement);
    return this.http
      .put<ICustomerStatementRx>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICustomerStatementRx>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICustomerStatementRx[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(customerStatement: ICustomerStatementRx): ICustomerStatementRx {
    const copy: ICustomerStatementRx = {
      ...customerStatement,
      statementDate:
        customerStatement.statementDate != null && customerStatement.statementDate.isValid()
          ? moment(customerStatement.statementDate.format(DATE_FORMAT))
          : null,
      fileDate:
        customerStatement.fileDate != null && customerStatement.fileDate.isValid() ? moment(customerStatement.fileDate.format(DATE_FORMAT)) : null,
      soldDate:
        customerStatement.soldDate != null && customerStatement.soldDate.isValid() ? moment(customerStatement.soldDate.format(DATE_FORMAT)) : null
    };
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.statementDate = res.body.statementDate != null ? moment(res.body.statementDate) : null;
      res.body.fileDate = res.body.fileDate != null ? moment(res.body.fileDate) : null;
      res.body.soldDate = res.body.soldDate != null ? moment(res.body.soldDate) : null;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((customerStatement: ICustomerStatementRx) => {
        customerStatement.statementDate = customerStatement.statementDate != null ? moment(customerStatement.statementDate) : null;
        customerStatement.fileDate = customerStatement.fileDate != null ? moment(customerStatement.fileDate) : null;
        customerStatement.soldDate = customerStatement.soldDate != null ? moment(customerStatement.soldDate) : null;
      });
    }
    return res;
  }
}

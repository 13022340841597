import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import BjcrxSharedModule from 'app/shared/shared.module';
import NotificationMessageRxComponent from './notification-message-rx.component';
import { notificationMessageRoute } from './notification-message-rx.route';

const ENTITY_STATES = [...notificationMessageRoute];

@NgModule({
  imports: [BjcrxSharedModule, RouterModule.forChild(ENTITY_STATES)],
  declarations: [NotificationMessageRxComponent]
})
export default class BjcrxNotificationMessageRxModule {}

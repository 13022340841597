<h1 class="pageTitle">
    <a (click)="tryagain()" class="back-icon" *ngIf="showmessage === true">
        <img src="../../../content/images/_ionicons_svg_md-arrow-back@2x.png" alt="" width="20px" height="20px" />
    </a>
    Notifications
</h1>

<div *ngIf="showLoader">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-12 bjcrx-gif">
                <div class="gif-text">
                    <div class="bjc-text">
                        <img src="../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="" />
                        <div class="laoder-text text-center">Processing...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="notification-class" *ngIf="showmessage === false && showLoader === false;">
    <form name="form" role="form" (ngSubmit)="submitNotification()" [formGroup]="notificationForm">
        <div class="content with-action">
            <h2 class="subtitle">Choose how you would like the pharmacy to contact you.</h2>

            <div class="col-lg-4 col-md-6">
                <h3 class="heading" *ngIf="showPushButton">Push Notifications</h3>
                <div class="form-group" *ngIf="showPushButton">
                    <div class="custom-control custom-switch">
                        <input class="custom-control-input" formControlName="subscribeToPush" (change)="subscribeNotifications($event)" name="subscribeToPush" type="checkbox" id="pushnotify">
                        <label class="custom-control-label push-notification-lbl" for="pushnotify" id="subscribePushLabel">Subscribe for push
                            notification</label>
                    </div>
                    <p class="info-text pt-2 pb-0 mb-0">Get push notifications for timely refill reminders & coupon updates
                    </p>
                </div>

                <div class="custom-control custom-radio pt-2">
                    <input type="radio" class="custom-control-input" name="phContactType" formControlName="phContactType" value="email" id="email-radio">
                    <label class="custom-control-label font14" for="email-radio" id="sendEmailLabel">Send me an email</label>
                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/profile-rx">
                            <span class="pad-l-3">(update email)</span>
                    </a>
                </div>

                <div class="form-group">
                    <input type="email" class="form-control" id="phEmail" name="userEmail" placeholder="name@email.com" formControlName="userEmail" autocomplete="off" />
                    <div *ngIf="notificationForm.get('userEmail').invalid && (submitted || notificationForm.get('userEmail').touched)">
                        <small class="form-text error-msg" *ngIf="notificationForm.get('userEmail').errors.required">
                            Please enter email address.
                        </small>
                        <small class="form-text error-msg" *ngIf="notificationForm.get('userEmail').errors.email">
                            Please enter a valid email address.
                        </small>
                    </div>
                </div>

                <div class="options">
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" name="phContactType" formControlName="phContactType" value="sms" id="sms-radio">
                        <label class="custom-control-label font14" for="sms-radio" id="sendTextLabel">Send me a text message</label>
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/profile-rx">
                                <span class="pad-l-3">(update phone number)</span>
                        </a>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" name="userPhone" placeholder="" formControlName="userPhone" autocomplete="off" jhi-appPhoneMask maxlength="14" />
                    <div *ngIf="notificationForm.get('userPhone').invalid && (submitted || notificationForm.get('userPhone').touched)">
                        <small class="form-text error-msg" *ngIf="notificationForm.get('userPhone').errors.required">
                            Please enter phone number.
                        </small>
                        <small class="form-text error-msg" *ngIf="notificationForm.get('userPhone').errors.minlength">
                            Please enter a valid phone number.
                        </small>
                    </div>
                </div>
            </div>
            <h2 class="subtitle">Option to revoke the authorization to receive unsecured email and text messages.</h2>

            <div class="col-lg-4 col-md-6">
                <div class="pt-2">
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" name="phContactType" formControlName="phContactType" value="dnd" id="doNotSend">
                        <label class="custom-control-label font14" for="doNotSend" id="declineLabel">
                            <strong>I DECLINE</strong> to receive unsecured email and text messages from BJC Hospital Pharmacies.
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="col-lg-3 col-md-6 p-0">
                <button type="submit" class="btn btn-enable btn-block" [disabled]="!notificationForm.valid">SAVE</button>
            </div>
        </div>
    </form>

</div>

<div *ngIf="showmessage">
    <div class="content ">
        <div class="col-md-4">
            <div [ngClass]="{ 'success-message': success, 'error-message': error }">
                {{ message }}
            </div>
        </div>
    </div>
    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" *ngIf="error" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
        </div>
    </div>
</div>

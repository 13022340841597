import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import BjcrxSharedModule from 'app/shared/shared.module';
import { TextMaskModule } from 'angular2-text-mask';
import CardInfoRxComponent from './card-info-rx.component';
import CardInfoRxUpdateComponent from './card-info-rx-update.component';
import { cardInfoRoute } from './card-info-rx.route';

const ENTITY_STATES = [...cardInfoRoute];

@NgModule({
  imports: [BjcrxSharedModule, TextMaskModule, RouterModule.forChild(ENTITY_STATES)],
  declarations: [CardInfoRxComponent, CardInfoRxUpdateComponent]
})
export default class BjcrxCardInfoRxModule {}

<h1 class="pageTitle" jhiTranslate="bjcrxApp.profile.question.title"></h1>
<form [formGroup]="profileQuestionForm" (ngSubmit)="onSubmit()">
    <div class="content with-action">
        <div class="row" *ngIf="questionId == '1'">
            <div class="alert alert-warning" role="alert">
                <h4 jhiTranslate="bjcrxApp.profile.alert.title"></h4>
                <p jhiTranslate="bjcrxApp.profile.alert.subtitle"></p>
            </div>
            <div class="col-md-12">
                <h2 class="subtitle" jhiTranslate="bjcrxApp.profile.question.subtitle1"></h2>
                <div class="options">
                    <!-- Default unchecked -->
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" formControlName="answer" value="yes" id="foryes" name="answer">
                        <label class="custom-control-label font14" for="foryes">Yes</label>
                    </div>

                    <!-- Default checked -->
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" formControlName="answer" value="no" id="forno" name="answer">
                        <label class="custom-control-label font14" for="forno">No</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="questionId == '2'">
            <div class="col-md-12">
                <h2 class="subtitle" jhiTranslate="bjcrxApp.profile.question.subtitle2"></h2>
                <div class="options">
                    <!-- Default unchecked -->
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" formControlName="answer" value="yes" id="foryes" name="answer">
                        <label class="custom-control-label font14" for="foryes">Yes</label>
                    </div>
                    <!-- Default checked -->
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" formControlName="answer" value="no" id="forno" name="answer">
                        <label class="custom-control-label font14" for="forno">No</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="submit" class="btn btn-enable btn-block" [disabled]="!profileQuestionForm.valid">CONTINUE</button>
        </div>
    </div>
</form>
<h1 class="pageTitle" jhiTranslate="bjcrxApp.profile.question.title"></h1>
<div *ngIf="!showmessage">
    <form *ngIf="form" [formGroup]="form" (submit)="submitAnswers()">
        <div class="content with-action">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="subtitle">Please answer the following questions.</h2>
                    <div *ngFor="let question of questionSet; let i = index">
                        <h3 class="heading">Question {{i+1}}:</h3>
                        <div class="form-group">
                            <label>{{question.Text['Statement']}}</label>
                        </div>
                        <div *ngIf="question.Type === 'singlechoice'">
                            <div class="options" *ngFor="let answer of question.Choices; let j = index">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" name="question-{{question.QuestionId}}" formControlName="question-{{question.QuestionId}}" value="{{answer.ChoiceId}}" id="question{{question.QuestionId}}-answer{{j}}">
                                    <label class="custom-control-label font14" for="question{{question.QuestionId}}-answer{{j}}">{{answer.Text.Statement}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
            <div class="col-lg-3 col-md-6 p-0">
                <button type="submit" class="btn btn-enable btn-block">CONTINUE</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="showmessage">
    <div class="content">
        <div class="col-md-8">
            <div class="error-message">
                {{message}}
            </div>
        </div>
    </div>
    <div class="row action m-0" *ngIf="!showRegisterForMailOrder" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-md-4 p-0">
            <button type="button" (click)="tryagain()" class="btn btn-enable btn-block">TRY AGAIN</button>
        </div>
    </div>

    <div class="row action m-0" *ngIf="showRegisterForMailOrder" [ngClass]="{'slide-left-action':slideScreen}">
        <div class="col-lg-3 col-md-6 p-0">
            <button type="button" (click)="registerForMailOrder()" class="btn btn-enable btn-block">REGISTER YOUR ACCOUNT</button>
        </div>
    </div>
</div>
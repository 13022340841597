import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import {
  FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors, FormControl
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { IProfileRx } from 'app/shared/model/profile-rx.model';
import PrescriptionRxService from 'app/entities/prescription-rx/prescription-rx.service';
import { SessionStorageService } from 'ngx-webstorage';
import {
  STATE_DATA,
  DFLT_PRMCY_MODULE_CODE,
  passwordErrorMustContainLowercase,
  passwordErrorMustContainUppercase,
  passwordErrorMustContainNumericOrSpecialChar,
  passwordErrorNoSpace,
  passwordErrorMinimum14Chars,
  passwordErrorCannotContainUsername,
  passwordErrorCannotContainFirstOrLastName
} from 'app/app.constants';
import NotificationMessageRxService from 'app/entities/notification-message-rx/notification-message-rx.service';
import AccountService from 'app/core/auth/account.service';
import { takeUntil } from 'rxjs/operators';
import AddressValidationDialogService from 'app/core/address-validation-dialog/address-validation-dialog.service';
import { FormatPhoneNumber } from 'app/shared/util/request-util';
import ProfileRxService from '../profile-rx.service';

@Component({
  selector: 'jhi-profile-rx-update',
  templateUrl: './profile-rx-update.component.html',
  styleUrls: ['./profile-rx-update.scss']
})
export default class ProfileRxUpdateComponent implements OnInit, OnDestroy {
  private profileRxUpdateComponent$: Subject<void> = new Subject<void>();

  isSaving: boolean;

  dateOfBirthDp: any;

  showmessage;

  error;

  success;

  message;

  submitted = false;

  profile;

  emailCount = 1;

  emailAddress;

  primaryPhoneNumberObj;

  phoneNumber1;

  phoneNumber2;

  phoneNumber3;

  pharmacyList;

  stateList;

  currentAccount;

  editForm: any;

  slideScreen;

  isApicalled = false;

  showLoader = true;

  primaryPatientId;

  isInvalidAddress = false;

  successResponse = 'Success';

  addressOld;

  constructor(
    protected profileService: ProfileRxService,
    protected activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private prescriptionService: PrescriptionRxService,
    private sessionStorage: SessionStorageService,
    private notificationMessageRxService: NotificationMessageRxService,
    private accountService: AccountService,
    private router: Router,
    private addressValidationDialogService: AddressValidationDialogService
  ) {}

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.profileRxUpdateComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.isSaving = false;
    this.getPharmacyList();
    this.stateList = STATE_DATA;
    this.profile = JSON.parse(this.sessionStorage.retrieve('profile-details'));
    this.primaryPatientId = JSON.parse(this.sessionStorage.retrieve('primarypatientid'));
    this.accountService.identity().then((account) => {
      this.currentAccount = account;
      if (this.profile) {
        this.editForm = new FormGroup(
          {
            primaryEmailAddress: new FormControl(null, [
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}$')
            ]),
            primaryPhoneNumber: new FormControl(null, [Validators.required, Validators.minLength(14)]),
            defaultPharmacy: new FormControl(null, [Validators.required]),
            addressLine: new FormControl(null, [Validators.maxLength(50)]),
            addressCity: new FormControl(null, [Validators.maxLength(50)]),
            addressState: new FormControl(null, [Validators.maxLength(50)]),
            addressZip: new FormControl(null, [Validators.maxLength(10)]),
            newpassword: new FormControl(''),
            confirmPassword: new FormControl('')
          },
          this.validateForm(this.currentAccount)
        );
        if (this.currentAccount.enterpriseRxPatientId === this.profile.patientId) {
          this.currentAccount.firstName = this.profile.firstName;
          this.currentAccount.lastName = this.profile.lastName;
        }

        this.updateForm();
      }
    });
  }

  updateForm() {
    if (this.profile.patientEmailList) {
      this.profile.patientEmailList = this.profile.patientEmailList.sort((a, b) => a.emailRank - b.emailRank);
      this.emailCount = this.profile.patientEmailList.length;
      this.emailAddress = this.profile.patientEmailList[0] ? this.profile.patientEmailList[0].emailAddress : '';
    }
    this.primaryPhoneNumberObj =
      this.profile.patientAddressDTO && this.profile.patientAddressDTO.primaryPhone ? this.profile.patientAddressDTO : '';

    this.editForm.get('primaryEmailAddress').setValue(this.emailAddress);

    if (this.profile.patientAddressDTO) {
      this.editForm.get('addressLine').setValue(this.profile.patientAddressDTO.line1);
      this.editForm.get('addressCity').setValue(this.profile.patientAddressDTO.city);
      this.editForm.get('addressState').setValue(this.profile.patientAddressDTO.state);
      this.editForm.get('addressZip').setValue(this.profile.patientAddressDTO.zip);
      this.addressOld = {
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        addressLine: this.profile.patientAddressDTO.line1,
        addressState: this.profile.patientAddressDTO.state,
        addressZip: this.profile.patientAddressDTO.zip
      };
    }
    this.editForm
      .get('primaryPhoneNumber')
      .setValue(
        this.primaryPhoneNumberObj.primaryPhone.phoneNumber ? FormatPhoneNumber(this.primaryPhoneNumberObj.primaryPhone.phoneNumber) : ''
      );
  }

  getUserNotificationSettings() {
    this.notificationMessageRxService
      .getUserNotificationSettings(this.profile.enterpriseRxId)
      .pipe(takeUntil(this.profileRxUpdateComponent$))
      .subscribe((res) => {
        if (res.body) {
          const defaultPharmacy = res.body.filter((preference) => preference.moduleCode === DFLT_PRMCY_MODULE_CODE);

          if (defaultPharmacy.length > 0) {
            this.profile.defaultPharmacy = defaultPharmacy[0].preferenceValue;
            this.editForm.get('defaultPharmacy').setValue(this.profile.defaultPharmacy);
          }
        }
      });
  }

  getValidPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber
      .replace('(', '')
      .replace(') ', '')
      .replace('-', '');
    return `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, phoneNumber.length - 1)}`;
  }

  createPayload() {
    const payload = {
      admin: false,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      username: '',
      password: '',
      defaultPharmacy: this.editForm.value.defaultPharmacy,
      primaryUserPatientId: this.primaryPatientId,
      deletes: [],
      edits: [
        {
          emails: [
            {
              address: this.editForm.value.primaryEmailAddress,
              rank: '1',
              useForNotifier: true
            }
          ],
          phoneNumbers: [],
          shppingAddress: {
            address: this.editForm.value.addressLine,
            city: this.editForm.value.addressCity,
            phoneNumbers: [
              {
                phonenumber: this.getValidPhoneNumber(this.editForm.value.primaryPhoneNumber),
                type: 'Primary',
                usage: 'Primary'
              }
            ],
            state: this.editForm.value.addressState,
            zipCode: this.editForm.value.addressZip
          }
        }
      ],
      patientId: this.profile.enterpriseRxId
    };

    if (this.currentAccount.username) {
      payload.username = this.currentAccount.username;
    }
    if (this.editForm.value.newpassword) {
      payload.password = this.editForm.value.newpassword;
    }
    return payload;
  }

  previousState() {
    this.router.navigate(['/profile-rx']);
  }

  save() {
    this.submitted = true;
    this.showLoader = false;
    this.isInvalidAddress = false;
    const addressData = {
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      addressLine: this.editForm.value.addressLine,
      addressState: this.editForm.value.addressState,
      addressZip: this.editForm.value.addressZip
    };
    if (this.isApicalled === false) {
      this.isApicalled = true;
      if (this.addressOld && JSON.stringify(this.addressOld) !== JSON.stringify(addressData)) {
        this.profileService
          .validateAddress(addressData)
          .pipe(takeUntil(this.profileRxUpdateComponent$))
          .subscribe((uspsRes) => {
            const usps = uspsRes;

            if (usps?.AddressValidateResponse?.Address?.Error) {
              this.isApicalled = false;
              this.showLoader = true;
              this.isInvalidAddress = true;
              this.editForm.setErrors({ address: 'error' });
            } else if (
              // see if usps matched what the user entered.
              this.editForm.get('addressLine').value.toUpperCase() !== usps?.AddressValidateResponse?.Address?.Address2 ||
              this.editForm.get('addressCity').value.toUpperCase() !== usps?.AddressValidateResponse?.Address?.City ||
              this.editForm
                .get('addressZip')
                .value.toString()
                .toUpperCase() !== usps?.AddressValidateResponse?.Address?.Zip5
            ) {
              this.showLoader = false;
              this.addressValidationDialogService
                .confirm(
                  'USPS Address Validation Response',
                  usps?.AddressValidateResponse?.Address?.Address2,
                  this.editForm.value.addressLine,
                  usps?.AddressValidateResponse?.Address?.City,
                  this.editForm.value.addressCity,
                  usps?.AddressValidateResponse?.Address?.State,
                  this.editForm.value.addressState,
                  usps?.AddressValidateResponse?.Address?.Zip5,
                  this.editForm.value.addressZip,
                  // 'Keep USPS Suggestion',
                  // 'Keep Own',
                  // 'Keep Editing',
                  'lg'
                )
                .then((confirmed) => {
                  if (confirmed === 'user') {
                    // User choose to keep their own - update
                    if (!this.editForm.invalid) {
                      this.updateProfile();
                    } else {
                      this.showLoader = true;
                      this.isApicalled = false;
                    }
                  } else if (confirmed === 'usps') {
                    // user choose to use USPS data
                    // overwrite with usps and update
                    this.editForm.get('addressLine').setValue(usps?.AddressValidateResponse?.Address?.Address2);
                    this.editForm.get('addressCity').setValue(usps?.AddressValidateResponse?.Address?.City);
                    this.editForm.get('addressZip').setValue(usps?.AddressValidateResponse?.Address?.Zip5);
                    this.editForm.get('addressState').setValue(usps?.AddressValidateResponse?.Address?.State);
                    if (!this.editForm.invalid) {
                      this.updateProfile();
                    } else {
                      this.showLoader = true;
                      this.isApicalled = false;
                    }
                  } else if (confirmed === 'edit') {
                    console.warn('in stay and edit');
                    // stay on this page
                    this.showLoader = true;
                    this.isApicalled = false;
                  } else if (confirmed === 'timeout') {
                    console.warn('modal timed out');
                    // stay on this page
                    this.showLoader = true;
                    this.isApicalled = false;
                  }
                });
            } else if (!this.editForm.invalid) {
              // we are here because usps matched the user's entry
              this.updateProfile();
            } else {
              this.showLoader = true;
              this.isApicalled = false;
            }
          });
      } else {
        this.updateProfile();
      }
    }
    this.isApicalled = true;
  }

  private updateProfile() {
    this.profileService
      .update(this.createPayload())
      .pipe(takeUntil(this.profileRxUpdateComponent$))
      .subscribe(
        (res) => {
          this.isApicalled = false;
          this.showmessage = true;
          this.showLoader = true;
          if (
            (res.body.updatePasswordResponse === null || res.body.updatePasswordResponse === this.successResponse) &&
            (res.body.setDefaultPharmacyResponse === null || res.body.setDefaultPharmacyResponse === this.successResponse) &&
            (res.body.updatePatientDetailsResponse === null || res.body.updatePatientDetailsResponse === this.successResponse)
          ) {
            this.success = true;
            this.error = false;
            this.message = 'Profile updated successfully';
          } else {
            this.success = false;
            this.error = true;
            if (res.body.updatePasswordResponse !== this.successResponse) {
              this.message = res.body.updatePasswordResponse;
            } else if (res.body.setDefaultPharmacyResponse !== this.successResponse) {
              this.message = res.body.setDefaultPharmacyResponse;
            } else if (res.body.updatePatientDetailsResponse !== this.successResponse) {
              this.message = res.body.updatePatientDetailsResponse;
            }
          }
        },
        (error) => {
          this.showmessage = true;
          this.isApicalled = false;
          this.success = false;
          this.error = true;
          this.showLoader = true;
          this.message = error.error.detail ? error.error.detail : 'There was a problem updating your profile, please try again.';
        }
      );
  }

  private createFromForm() {}

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IProfileRx>>) {
    result.pipe(takeUntil(this.profileRxUpdateComponent$)).subscribe(() => this.onSaveSuccess(), () => this.onSaveError());
  }

  protected onSaveSuccess() {
    this.isSaving = false;
    this.previousState();
  }

  protected onSaveError() {
    this.isSaving = false;
  }

  getPharmacyList() {
    this.prescriptionService
      .getPharmacyList()
      .pipe(takeUntil(this.profileRxUpdateComponent$))
      .subscribe((res) => {
        this.pharmacyList = res.body;
        if (this.profile.defaultPharmacyId) {
          const tempPharmacyList = this.pharmacyList.filter(
            (pharmacy) => parseInt(pharmacy.pharmacyId, 10) === parseInt(this.profile.defaultPharmacyId, 10)
          );

          if (tempPharmacyList.length > 0) {
            this.editForm.get('defaultPharmacy').setValue(this.profile.defaultPharmacyId);
          }
        }
      });
  }

  showEmailAddressInput() {
    this.emailCount++;
  }

  tryagain() {
    this.showmessage = false;
    this.success = false;
    this.error = false;
    this.isApicalled = false;
  }

  validateForm(userDetails): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const password = group.controls?.newpassword;
      const confirmpass = group.controls?.confirmPassword;
      let message: string;

      if (password.value && confirmpass.value) {
        const isMatch = password.value === confirmpass.value;
        if (!isMatch && password.valid) {
          confirmpass.setErrors({ equalValue: 'error' });
          message = 'error';
        }
      }

      const passValue = password.value.toLocaleLowerCase();
      if (password.value) {
        if (!password.value.match('^(?=.*?[A-Z])')) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainUppercase });
        } else if (!password.value.match('^(?=.*?[a-z])')) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainLowercase });
        } else if (!(password.value.match('^(?=.*?[0-9])') || password.value.match('^(?=.*?[-$_!%*&])'))) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainNumericOrSpecialChar });
        }
        if (password.value.match('^[ ]*$')) {
          message = 'error';
          password.setErrors({ error: passwordErrorNoSpace });
        } else if (password.value.length < 14) {
          message = 'error';
          password.setErrors({ error: passwordErrorMinimum14Chars });
        }
      }
      if (passValue) {
        if (userDetails.username) {
          if (passValue.match(new RegExp(userDetails.username.toLocaleLowerCase()))) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainUsername });
          }
        }
        if (userDetails && userDetails.firstName) {
          if (passValue.match(userDetails.firstName.toLocaleLowerCase())) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainFirstOrLastName });
          }
        }

        if (userDetails && userDetails.lastName) {
          if (passValue.match(userDetails.lastName.toLocaleLowerCase())) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainFirstOrLastName });
          }
        }
      }

      if (message) {
        return { equalValue: message };
      }

      return null;
    };
  }

  public ngOnDestroy(): void {
    this.profileRxUpdateComponent$.next();
    this.profileRxUpdateComponent$.unsubscribe();
  }
}

<h1 class="pageTitle">
    <span
        *ngIf="(!isAuthenticated && ( step !== 'search' && step !== 'success')) || (isAuthenticated && step !== 'list' && step !== 'success' ) || step === 'confirm'">
        <a (click)="showLoader && backClicked()">
            <img src="../../../content/images/_ionicons_svg_md-arrow-back@2x.png" alt="" width="20px" height="20px" id="backArrowImg"/>
        </a>
    </span> <span jhiTranslate="bjcrxApp.prescription.home.title"> Prescriptions</span>
</h1>
<div class="member-selection-box" *ngIf="isAuthenticated && step === 'list' && isDependents">
    <div class="form-group member-selection-row">
        <label for="patientName" jhiTranslate="bjcrxApp.prescription.patient" class="mr-3 mt-1">Patient</label>
        <select class="form-control col-md-4" id="patientName" [disabled]="isDisabledSelectbox"
            [(ngModel)]="selectedPatientId" (change)="currentPage=0;responseData=[];calculateAge($event);">
            <option *ngFor="let dependent of dependentList | orderBy: 'sequence'" [value]="dependent.patientId" [attr.data-dependent-id]="dependent.id">
                {{dependent.firstName +' '+dependent.lastName}}</option>
        </select>
    </div>
</div>

<jhi-alert></jhi-alert>

<div *ngIf="!showLoader">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-12 bjcrx-gif">
                <div class="gif-text">
                    <div class="bjc-text">
                        <img src="../../../content/images/bjc-rx-animation.gif" class="gif-img" alt="" />
                        <div class="laoder-text text-center">Processing...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content with-action" *ngIf="step === 'search' && !isAuthenticated && isSkeletonLoader === false">
    <div class="rxRefillSection col-md-4" id="rxRefillSection">
        <div *ngIf="!rxNotFoundError">
            <div class="row">
                <h3 class="subtitle">{{ titleAlert }}</h3>
            </div>
            <div class="justify-content-center">
                <form name="form" role="form" (ngSubmit)="findPrescription(prescriptionForm.value)"
                    [formGroup]="prescriptionForm">
                    <div class="form-group">
                        <label for="choosePharmacy" jhiTranslate="bjcrxApp.prescription.ChoosePharmacy">Choose
                            Pharmacy</label><span class="required">*</span>
                        <select class="form-control" id="choosePharmacy" name="choosePharmacy"
                            [ngClass]="{ 'is-invalid': f.choosePharmacy.touched && f.choosePharmacy.errors }"
                            formControlName="choosePharmacy">
                            <option [ngValue]="null" disabled>Select drop-down item</option>
                            <option *ngFor="let pharmacy of pharmacyList" [value]="pharmacy.pharmacyId">
                                {{pharmacy.name}}
                            </option>
                        </select>

                        <div *ngIf="f.choosePharmacy.touched && f.choosePharmacy.errors" class="invalid-feedback">
                            <div *ngIf="f.choosePharmacy.errors.required">Please choose any pharmacy.</div>
                        </div>
                    </div>

                    <div class="form-group date-group">
                        <label for="dob">Date of Birth</label><span class="required">*</span>
                        <div class="input-group" *ngIf="checkIsIEBrowser() === false">
                            <input type="date" class="form-control" autocomplete="off" max="9999-12-31" name="dob"
                                id="dob" formControlName="dob" ngbDatepicker #dobDp="ngbDatepicker">
                            <span class="input-group-append" (click)="dobDp.toggle()">
                                <fa-icon [icon]="'calendar-alt'"></fa-icon>
                            </span>
                        </div>
                        <div class="input-group" *ngIf="checkIsIEBrowser() === true">
                            <input type="text" tabindex="-1" placeholder="mm/dd/yyyy" class="form-control"
                                autocomplete="off" max="9999-12-31" name="dob" id="dob" formControlName="dob"
                                ngbDatepicker #dobDp="ngbDatepicker">
                            <span class="input-group-append">
                                <fa-icon [icon]="'calendar-alt'"></fa-icon>
                            </span>
                            <div class="date-pick-div" (click)="dobDp.toggle()"></div>
                        </div>
                        <div
                            *ngIf="(prescriptionForm.get('dob').touched && checkIsIEBrowser() === false) && prescriptionForm.get('dob').invalid">
                            <small class="form-text error-msg"
                                *ngIf="prescriptionForm.get('dob').errors.required">Please enter Date of Birth.</small>
                            <small class="form-text error-msg"
                                *ngIf="prescriptionForm.get('dob').errors.invalidDate">Please enter valid date.</small>
                            <small class="form-text error-msg"
                                *ngIf="prescriptionForm.get('dob').errors.futuredate">Please enter valid date of
                                birth.</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="last4DigitPhoneNumber">Last 4 Digits of Phone Number</label><span
                            class="required">*</span>
                        <input type="text" class="form-control" maxlength="4" jhi-appDigitOnly
                            (keypress)="validateNumber($event)" inputmode="numeric" pattern="[0-9]*" autocomplete="off"
                            name="last4DigitPhoneNumber" id="last4DigitPhoneNumber"
                            placeholder="Last 4 Digits of Phone Number"
                            [ngClass]="{ 'is-invalid': f.last4DigitPhoneNumber.touched && f.last4DigitPhoneNumber.errors }"
                            formControlName="last4DigitPhoneNumber">

                        <div *ngIf="f.last4DigitPhoneNumber.touched && f.last4DigitPhoneNumber.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.last4DigitPhoneNumber.errors.required">Last 4 Digits Phone Number is required
                            </div>
                            <div *ngIf="f.last4DigitPhoneNumber.errors.minlength">4 digits are required</div>
                            <div *ngIf="f.last4DigitPhoneNumber.errors.pattern">Please enter numbers only.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="prescriptionInfo">Prescription Information</label>
                        <small id="prescriptionInfo" class="form-text">Enter your prescription number(s)
                            include all numbers before and after the dash </small>
                        <label for="rxNumber">Prescription Number</label><span class="required">*</span>
                        <div *ngIf="f.rxNumberArr.touched">
                            <small class="form-text error-msg margin-bottm0"
                                *ngIf="f.rxNumberArr.errors?.atLeastOneRequired">
                                {{f.rxNumberArr.errors.atLeastOneRequired.text}}
                            </small>
                            <small class="form-text error-msg margin-bottm0" *ngIf="f.rxNumberArr.errors?.pattern">
                                Please enter numbers only
                            </small>
                        </div>
                        <div class="row" formArrayName="rxNumberArr">
                            <div class="col p-0">
                                <div *ngFor="let rxNum of f.rxNumberArr['controls']; let k = index" [formGroupName]="k">
                                    <div class="">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                    maxlength="20" jhi-appDigitOnly
                                                    inputmode="numeric" pattern="[0-9]*" formControlName="rxNumber"
                                                    name="rxNumber">
                                                <span class="input-group-addon">-</span>
                                                <input type="text" class="form-control drg-name" maxlength="2"
                                                    jhi-appDigitOnly inputmode="numeric"
                                                    [readonly]="!rxNum.value.rxNumber && !rxNum.value.storeId"
                                                    name="storeId"
                                                    (keypress)="validateNumber($event)" formControlName="storeId"
                                                    id="storeId">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row action m-0" [ngClass]="{'slide-left-action':slideScreen}">
                        <div class="col-lg-3 col-md-6 p-0">
                            <button type="submit" jhiTranslate="bjcrxApp.prescription.Continue"
                                [disabled]="!prescriptionForm.touched"
                                class="btn btn-enable btn-block">CONTINUE</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-md-12 col-sm-12" *ngIf="rxNotFoundError">
		        <div class="error-message" *ngIf="showErrorMessage">
			        <ul *ngIf="errormessages.length > 0">
				        <li *ngFor=" let errorMessage of errormessages">{{ errorMessage }}</li>
			        </ul>
		        </div>

            <div class="action">
                <div class="col-md-4">
                    <button class="btn btn-enable btn-block" type="submit" (click)="showSearchForm()">TRY AGAIN</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content with-action" *ngIf="responseData.length > 0"
    [ngClass]="{'with-dependent': isDependents && step !== 'confirm' && step !== 'success'}">
    <div class="col-md-12 col-sm-12 col-lg-12 rxRefillSectionList" id="rxRefillSectionList">
        <div *ngIf="showListview && step === 'list'">
            <div>
                <h3 *ngIf="!isAuthenticated" class="subtitle">{{ titleAlert }}</h3>
            </div>
            <div *ngIf="isAuthenticated">
                <div class="rxWidth pb-1 search-container">
                    <div id="wrapper">
                        <div id="first">
                            <button type="button" class="btn btn-blue dropdown-toggle pl-0" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" [hidden]="hideEdit">
                                Sort By
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" (click)="sortBy('a-z')">A-Z PRESCRIPTION NAME</a>
                                <a class="dropdown-item" (click)="sortBy('z-a')">Z-A PRESCRIPTION NAME</a>
                                <a class="dropdown-item" (click)="sortBy('pnumber')">PRESCRIPTION NUMBER</a>
                            </div>
                        </div>
                        <div id="second">
                            <button id="editButton" type="button" class="btn-check" (click)="toggleEditButton()" [hidden]="hideEdit">Edit</button>
                        </div>
                        <div id="third" *ngIf="filterByStatusArr">
                            <button type="button" class="btn-check" *ngFor="let state of filterByStatusArr"
                              (click)="clearFilter(state)">
                              x {{ state | uppercase }}
                            </button>
                        </div>
                    </div>
                    <div>
                        <img (click)="isShowSearchBox()" *ngIf="!showSearchBox" src="../../../content/images/search.png"
                            alt="" width="20px" height="20px" [hidden]="hideEdit" />
                        <img (click)="isShowSearchBox()" *ngIf="showSearchBox" src="../../../content/images/close.png"
                            alt="" width="20px" height="20px" />
                    </div>
                </div>
                <div class="justify-content-center rxWidth" *ngIf="showSearchBox">
                    <div class="col-md-8 col-sm-12">
                        <label for="searchText">SEARCH BY NAME OR PRESCRIPTION #</label>
                        <input class="form-control" type="text" name="searchText" autocomplete="off"
                            [(ngModel)]="searchText" placeholder="Search">
                    </div>
                </div>
            </div>
            <div class="justify-content-center prescription-list-box" infiniteScroll [infiniteScrollDistance]="1"
                [infiniteScrollThrottle]="1000"
                (scrolled)="(isAuthenticated && totalItems === allPrescriptions.length) ? '' : getPrescriptionList(selectedPatientId)"
                [scrollWindow]="true">
                <div class="card-deck" *ngIf="responseData">
                    <ng-container *ngIf="(responseData | filter: searchText) as filteredResult">
                        <div class="col-md-12 col-sm-12 col-lg-12" *ngIf="filteredResult.length == 0">
                            <div class="error-message no-result">
                                No Result found. Please Try Again.
                            </div>
                        </div>
                        
                        <ng-container *ngFor="let rxRefillData of filteredResult">
                            <div class="card-div col-md-4" *ngIf="isAuthenticated && rxRefillData.rxStatus !== 'Inactive'">
                                <ng-container *ngTemplateOutlet="prescriptionCard; context:{rxRefillData: rxRefillData, showExpandCard: true, showRefillCheckbox: true}">
                                </ng-container>
                            </div>
							<div class="card-div col-md-4" *ngIf="isAuthenticated && rxRefillData.rxStatus === 'Inactive'">
                            	<ng-container *ngTemplateOutlet="prescriptionCard; context:{rxRefillData: rxRefillData, showExpandCard: true, showRefillCheckbox: false}">
                            	</ng-container>
                        	</div>
                            <div class="card-div col-md-4" *ngIf="!isAuthenticated && rxRefillData.rxStatus !== 'Inactive'">
                                <ng-container *ngTemplateOutlet="guestPrescriptionCard; context:{rxRefillData: rxRefillData, showExpandCard: true, showRefillCheckbox: true}">
                                </ng-container> 
                            </div>
                            <div class="card-div col-md-4" *ngIf="!isAuthenticated && rxRefillData.rxStatus === 'Inactive'">
                            	<ng-container *ngTemplateOutlet="guestPrescriptionCard; context:{rxRefillData: rxRefillData, showExpandCard: true, showRefillCheckbox: false}">
                            	</ng-container>
                        	</div>
                           
                        </ng-container> 
                    </ng-container>
                </div>
                <div class="spinner-section" *ngIf="isSkeletonLoader &&  responseData.length > 0 && isAuthenticated">
                    <img src="../../../content/images/bjc-rx-animation.gif" alt="">
                </div>
            </div>

            <div class="action m-0" [ngClass]="{'slide-left-action':slideScreen}" *ngIf="isRxChecked">
                <div class="col-md-4 p-0">
                    <button jhiAppDebounceClick type="button" (debounceClick)="requestRefillRx()" 
                            class="btn btn-enable btn-block">REQUEST REFILL</button>
                </div>
            </div>
        </div>
    </div>

    <div class="rxRefillSection col-md-4 padd-bottom100" id="rxRefillSection"
         *ngIf="step === 'confirm' && showListview && showLoader">
        <h4 class="subtitle">{{ titleAlert }}</h4>
        <div class="justify-content-center">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <div class="col-md-12 col-sm-12 col-lg-12  mt-2">
                    <div *ngIf="rxData[0].pfName && rxData[0].plName">
                        <small>Patient Name: <span>
                                <strong> {{rxData[0].pfName | titlecase }} {{rxData[0].plName | titlecase }}</strong></span>
                        </small>
                    </div>
                    <div *ngIf="dob"><small>Date of Birth: <strong>{{ dob | date: 'MM/dd/yyyy' }}</strong></small></div>
                    <div *ngIf="selectedStoreName"><small>Pharmacy: <strong> {{ selectedStoreName }} </strong></small> </div>
                </div>

                <h4 class="refillheading mt-2">Refills</h4>
                <small *ngIf="setCallDoctorFlags  && allowTransfer">
                    <em *ngIf="rxData.length === 1">Note: Your request had no refills remaining or expired prescription. The pharmacy will
                        contact your prescriber for refill authorization.</em>
                    <em *ngIf="rxData.length > 1">Note: One request had no refills remaining or expired prescription. The pharmacy will contact
                        your prescriber for refill authorization.</em>
                </small>
                <p *ngIf="!allowTransfer" class="error-message">
                    <em *ngIf="(specialtyPharmacy && rxData.length === 1) || rxData.length > 1">One or more prescription cannot be refilled at selected location -- specialty pharmacy </em>
                </p>
                <small *ngIf="specialtyPharmacy && allowTransfer && !isAuthenticated">
                    <em *ngIf="rxData.length >= 1">Note: Please check the location of your refill. Some prescriptions can only be refilled at select locations.'.</em>
                    
                </small>

                <div *ngFor="let rxRefillData of rxData">
                    <div *ngIf="isAuthenticated">
                        <ng-container *ngTemplateOutlet="prescriptionCard; context:{rxRefillData: rxRefillData, showExpandCard: false, showRefillCheckbox: false}">
                        </ng-container>
                    </div>
                    <div *ngIf="!isAuthenticated">
                        <ng-container *ngTemplateOutlet="guestPrescriptionCard; context:{rxRefillData: rxRefillData, showExpandCard: false, showRefillCheckbox: false}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="action m-0" [ngClass]="{'slide-left-action':slideScreen}"
                *ngIf="isRxChecked && showLoader === true">

                <div class="col-md-4 p-0">
                    <div class="form-group pharmacy-selection-row" *ngIf="isAuthenticated">
                        <label for="choosePharmacy" class="mt-1">Pharmacy</label><span class="required mr-3">*</span>
                        <select class="form-control" [(ngModel)]="selectedStoreId" (change)="setSelectedPharmacy()" name="choosePharmacy">
                            <option disabled value="">Select drop down item</option>
                            <option *ngFor="let pharmacy of pharmacyList" [value]="pharmacy.pharmacyId">
                                {{pharmacy.name}}
                            </option>
                        </select>
                    </div>
                    <button jhiAppDebounceClick type="button" (click)="isAuthenticated ? checkCreditCard() : requestRefillRequestRx(); disableRefillButton = true;"
                        class="btn btn-enable btn-block" *ngIf="allowTransfer" [disabled]="!selectedStoreId || disableRefillButton || !allowTransfer">CONFIRM REFILL</button>
					<button type="button" (click)="resetForm(true)" *ngIf="!allowTransfer" class="btn btn-enable btn-block">TRY AGAIN</button>
                </div>
            </div>
        </div>
    </div>

    <div class="rxRefillSection col-md-4" id="rxRefillSectionSuccess" *ngIf="step === 'success'">
        <div class="justify-content-center" *ngIf="showLoader">
            <div class="col-md-12 col-sm-12 col-lg-12">
	            <div class="error-message" *ngIf="showErrorMessage">
                <ul *ngIf="errormessages.length > 0">
	                <li *ngFor=" let errorMessage of errormessages">{{ errorMessage }}</li>
                </ul>
              </div>

              <div class="success-message" *ngIf="showSuccessMessage">
                <ul *ngIf="successmessages.length > 0">
	                <li *ngFor=" let message of successmessages">{{ message }}</li>
                </ul>
              </div>


                <div class="col-md-12 col-sm-12 col-lg-12  mt-2">
                    <div *ngIf="rxData[0].pfName && rxData[0].plName">
                        <small>Patient Name: <span>
                                <strong> {{rxData[0].pfName | titlecase }} {{rxData[0].plName | titlecase }}</strong></span>
                        </small>
                    </div>
                    <div *ngIf="dob"><small>Date of Birth: <strong>{{ dob | date: 'MM/dd/yyyy' }}</strong></small></div>
                    <div *ngIf="selectedStoreName"><small>Pharmacy: <strong> {{ selectedStoreName }} </strong></small> </div>
                </div>

                <h4 class="refillheading mt-2">Refills</h4>

                <div *ngFor="let rxRefillData of rxData">
                    <div *ngIf="isAuthenticated">
                        <ng-container *ngTemplateOutlet="prescriptionCard; context:{rxRefillData: rxRefillData, showExpandCard: false, showRefillCheckbox: false}">
                        </ng-container>
                    </div>
                    <div *ngIf="!isAuthenticated">
                        <ng-container *ngTemplateOutlet="guestPrescriptionCard; context:{rxRefillData: rxRefillData, showExpandCard: false, showRefillCheckbox: false}">
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="action m-0" [ngClass]="{'slide-left-action':slideScreen}"
                *ngIf="showSuccessMessage || showErrorMessage">

                <div class="col-md-4 p-0">
                    <button type="button" (click)="resetForm(true)" *ngIf="showSuccessMessage" class="btn btn-enable btn-block">ADD MORE</button>
                        <button type="button" (click)="resetForm(false)" *ngIf="showErrorMessage" class="btn btn-enable btn-block">TRY AGAIN</button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-4" *ngIf="!isAuthenticated && !showListview">
        <div class="error-message">
            You have chosen the Family Care Central Pharmacy, and our system shows that your payment information is missing or
            expired. Please create an account or log in and add this information before proceeding.
        </div>
        <p class="pt-1"><em>Or, choose another pharmacy.</em></p>

        <div class="col-md-12 col-sm-12 whiteBox pt-5 text-center">
            <h3>OR</h3>
        </div>

        <div class="row blueBox pt-4 mt-1">
            <div class="col-md-12 col-sm-12 border-top">&nbsp;</div>
            <button class="btn btn-enable btn-block" type="submit">
                <a [routerLink]="['/profile-rx/questions/1']" routerLinkActive="router-link-active">CREATE ACCOUNT</a>
            </button>
        </div>

        <div class="col-md-12 col-sm-12 whiteBox pt-3 text-center">
            <div>
                <button class="btn btn-enable btn-block" type="submit">
                    <a [routerLink]="['/account/login']" routerLinkActive="router-link-active">LOG IN</a>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="isAuthenticated && !isCreditCardFound && !showListview">
        <div class="error-message">
            You have chosen the Family Care Central Pharmacy, and our system shows that your payment information is missing or
            expired. Please add this information before proceeding.
        </div>
        <p class="pt-1"><em>Or, select a different pharmacy.</em></p>
        <div class="col-md-12 col-sm-12 whiteBox pt-5 text-center">
            <h3>OR</h3>
        </div>


        <div class="row blueBox pt-4 mt-1">
            <div class="col-md-12 col-sm-12 border-top">&nbsp;</div>
            <button class="btn btn-enable btn-block">
                <a [routerLink]="['/card-info-rx']" routerLinkActive="router-link-active">Add Payment Information</a>
            </button>
        </div>

        <div class="col-md-12 col-sm-12 whiteBox pt-3 text-center">
            <div>
                <button class="btn btn-enable btn-block">
                    <a (click)="showListview = true">Select Different Pharmacy </a>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="content with-action" *ngIf="responseData.length == 0 && isSkeletonLoader === false">
    <button id="editButton" type="button" class="btn-check" (click)="toggleEditButton()" *ngIf="responseData.length == 0 && isApiCalled && !isUnauthorized && selectedPatientId !== '0' && noPrescription">Edit</button>
    <br><br>
    <div *ngIf="responseData.length == 0 && isApiCalled && !isUnauthorized && selectedPatientId !== '0' && !noPrescription"
        class="error-message col-md-4">
        Prescriptions not found!
    </div>
    <div *ngIf="responseData.length == 0 && isApiCalled && !isUnauthorized && selectedPatientId !== '0' && noPrescription"
        class="hiddenRx-message col-md-4">
        All Prescriptions are hidden!!
    </div>
    <div *ngIf="responseData.length == 0 && isUnauthorized" class="error-message col-md-6">
        You cannot manage dependents who are older than 18 years
    </div>
    <div *ngIf="selectedPatientId === '0' && !isUnauthorized" class="error-message col-md-6">
        The minor dependent you selected cannot be found in the BJC Pharmacy system.<br>
        Next steps require the minor dependent to have an account created for them and added to the pharmacy system.
        To create an account for a minor dependent, complete and submit the form  <a class="mail-order-link" (click)="registerForMailOrder()">HERE</a>
        or contact the Family Care Central Pharmacy at 314-657-9000.<br><br>
        Once the  account information has been entered into the pharmacy system, you will receive a confirmation e-mail and will be able to login and select the minor dependent.
    </div>
</div>

<div class="skeleton-container" id="skeletonLoader" *ngIf="isSkeletonLoader === true && responseData.length === 0">
    <div class="skeleton-body">
        <div class="container p-0 m-0">
            <div class="row">
                <div class="col-md-4 top-20 pl-0">
                    <div class="card skeleton-box">

                    </div>
                </div>
                <div class="col-md-4 top-20 pl-0">
                    <div class="card skeleton-box">

                    </div>
                </div>
                <div class="col-md-4 top-20 pl-0">
                    <div class="card skeleton-box">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template let-rxRefillData="rxRefillData" let-showExpandCard="showExpandCard" let-showRefillCheckbox="showRefillCheckbox" #prescriptionCard>
    <div class="card mt-4 prescriptionCards">
        <div class="card-body">
            <div class="row">
                <div class="col-11">
                    <small>Rx #{{ rxRefillData.rxNumber }}-{{rxRefillData.rxFacilityID}}</small>
                </div>

                <div class="col-1 text-right"
                     *ngIf="showRefillCheckbox && checkRxExpirationDifference(rxRefillData)" [hidden] = "hide">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox"
                               [checked]="rxRefillData.isCheckedBox"
                               (change)="onChange(rxRefillData)" id="{{rxRefillData.rxNumber}}">
                        <label class="custom-control-label font14"
                               for="{{rxRefillData.rxNumber}}" id="{{rxRefillData.rxNumber}}Label"></label>
                    </div>
                </div>
            </div>

            <div class="row prescription-name">
                <h5 class="card-title">{{ rxRefillData.productName | uppercase }}</h5>
                <small>Refills Remaining: {{rxRefillData ?.refillsRemaining | number:'1.0-0'}}</small><br>
                <small>Expiration Date: {{rxRefillData?.rxExpiryDate}}</small>
            </div>
        </div>

        <div class="card-footer">
            <div class="row">
                <div class="col-11">
                    <span *ngIf="rxRefillData.lastFillStatus && rxRefillData.lastFillStatus !== 'Sold'"><em>Refill
                        {{ rxRefillData.lastFillStatus }}</em></span>
                </div>

                <div class="col-1 text-right" *ngIf="showExpandCard">
                    <fa-icon (click)=toggleDisplay(rxRefillData) *ngIf="!rxRefillData.isShow"
                             icon="caret-down"></fa-icon>
                    <fa-icon (click)=toggleDisplay(rxRefillData) *ngIf="rxRefillData.isShow"
                             icon="caret-up"></fa-icon>
                </div>
            </div>
        </div>

        <div class="card-body" *ngIf="rxRefillData.isShow && showExpandCard">
            <p *ngIf="rxRefillData.lastFillDate">
                <span>
                    <fa-icon icon='calendar-check' class="font-size24 blue-color"></fa-icon>&nbsp;
                </span> Last filled {{ rxRefillData.lastFillDate | date:'MM/dd/yyyy' }}
            </p>
            <p><span>
                    <fa-icon icon='briefcase-medical' class="font-size24 blue-color"></fa-icon>
                </span>
                {{ (rxRefillData.drfName.indexOf('Dr.') >=0 ? rxRefillData.drfName : 'Dr. '+rxRefillData.drfName) | titlecase }}
                {{ rxRefillData.drlName.replace(',','') }}
            </p>
            <p><span class="pad-right-5">
                    <fa-icon icon='map-marker-alt' class="font-size24 blue-color"></fa-icon>
                </span> {{rxRefillData?.pharmacyName}}
            </p>
        </div>

        <div class="card-footer subscription-wrapper" *ngIf="showExpandCard" [hidden] = "hide">
            <div class="form-group row py-2 px-0">
                <div class="col-1 text-center">
                    <fa-icon [icon]="faInfoCircle" class="font-size24 blue-color cursor-pointer"
                             tooltipClass="icon-tooltip" triggers="click" ngbTooltip="{{refillReminderHelpText}}" container="body">
                    </fa-icon>
                </div>
                <div class="col custom-control custom-switch text-left">
                    <input class="custom-control-input"
                           [(ngModel)]="rxRefillData.refillReminderEnabled"
                           (change)="subscribeForRefillReminder($event, rxRefillData)"
                           type="checkbox" id="pushnotify{{rxRefillData.rxNumber}}"/>
                    <label class="custom-control-label push-notification-lbl"
                           for="pushnotify{{rxRefillData.rxNumber}}" id="{{rxRefillData.rxNumber}}ReminderLabel">REFILL
                        REMINDER
                    </label>
                </div>
            </div>
        </div>
        <div class="card-body-hide" [hidden] = "!hide">
            <button class="btn-hide" (click)="toggleButton(rxRefillData)" id="hideButton">
                <span *ngIf="rxRefillData.visible">Hide</span>
                <span *ngIf="!rxRefillData.visible">Unhide</span>
            </button>
        </div>
        <div class="action m-0" [ngClass]="{'slide-left-action':slideScreen}" [hidden]="cancel">
            <div class="col-md-4 p-0">
                <button jhiAppDebounceClick type="button" 
                        class="btn btn-block1" (click)="toggleApplyButton()" [disabled]="!hideList.length">Apply</button>
                <button jhiAppDebounceClick type="button" 
                        class="btn btn-block2" (click)="cancelButton()">Cancel</button>
            </div>
        </div>
    </div>

</ng-template>

<ng-template let-rxRefillData="rxRefillData" let-showExpandCard="showExpandCard" let-showRefillCheckbox="showRefillCheckbox" #guestPrescriptionCard>
    <div class="card mt-4 prescriptionCards">
        <div class="card-body">
            <div class="row">
                <div class="col-11">
                    <h5 class="card-title">Rx #{{ rxRefillData.rxNumber }}-{{rxRefillData.rxFacilityID}}</h5>
                    <small>Refills Remaining: {{rxRefillData ?.refillsRemaining | number:'1.0-0'}}</small><br>
                    <small>Expiration Date: {{rxRefillData?.rxExpiryDate}}</small>
                </div>

                <div class="col-1 text-right"
                     *ngIf="showRefillCheckbox && checkRxExpirationDifference(rxRefillData)">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox"
                               [checked]="rxRefillData.isCheckedBox"
                               (change)="onChange(rxRefillData)" id="{{rxRefillData.rxNumber}}">
                        <label class="custom-control-label font14"
                               for="{{rxRefillData.rxNumber}}" id="{{rxRefillData.rxNumber}}Label"></label>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <div class="row">
                <div class="col-11">
                    <span *ngIf="rxRefillData.lastFillStatus && rxRefillData.lastFillStatus !== 'Sold'"><em>Refill
                        {{ rxRefillData.lastFillStatus }}</em></span>
                </div>

                <div class="col-1 text-right" *ngIf="showExpandCard">
                    <fa-icon (click)=toggleDisplay(rxRefillData) *ngIf="!rxRefillData.isShow"
                             icon="caret-down"></fa-icon>
                    <fa-icon (click)=toggleDisplay(rxRefillData) *ngIf="rxRefillData.isShow"
                             icon="caret-up"></fa-icon>
                </div>
            </div>
        </div>

        <div class="card-body" *ngIf="rxRefillData.isShow && showExpandCard">
            <p *ngIf="rxRefillData.lastFillDate">
                <span>
                    <fa-icon icon='calendar-check' class="font-size24 blue-color"></fa-icon>&nbsp;
                </span> Last filled {{ rxRefillData.lastFillDate | date:'MM/dd/yyyy' }}
            </p>
            <p><span class="pad-right-5">
                    <fa-icon icon='map-marker-alt' class="font-size24 blue-color"></fa-icon>
                </span> {{rxRefillData?.pharmacyName}}
            </p>
        </div>
    </div>
</ng-template>

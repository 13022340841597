import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { JhiEventManager, JhiParseLinks, JhiAlertService } from 'ng-jhipster';
import { IProfileRx } from 'app/shared/model/profile-rx.model';
import AccountService from 'app/core/auth/account.service';

import PaginationConstants from 'app/shared/constants/pagination.constants';
import { SessionStorageService } from 'ngx-webstorage';
import LoginService from 'app/account/login/login.service';
import ConfirmationDialogService from 'app/core/confirmation-dialog/confirmation-dialog.service';
import { takeUntil } from 'rxjs/operators';
import { FormatPhoneNumber } from 'app/shared/util/request-util';
import ProfileRxService from '../profile-rx.service';

@Component({
  selector: 'jhi-profile-rx',
  templateUrl: './profile-rx.component.html',
  styleUrls: ['./profile-rx.scss']
})
export default class ProfileRxComponent implements OnInit, OnDestroy {
  private profileRxComponent$: Subject<void> = new Subject<void>();

  currentAccount: any;

  profiles: IProfileRx[];

  error: any;

  success: any;

  eventSubscriber: Subscription;

  links: any;

  totalItems: any;

  itemsPerPage: any;

  page: any;

  predicate: any;

  previousPage: any;

  reverse: any;

  showMessage;

  profile: any;

  dependentList: any = [];

  showTryAgain;

  message;

  isPageLoaded = true;

  slideScreen;

  primaryPatientId;

  errorForMinor: boolean = false;

  selectedMinorPatient: any;

  constructor(
    protected profileService: ProfileRxService,
    protected parseLinks: JhiParseLinks,
    protected jhiAlertService: JhiAlertService,
    protected accountService: AccountService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected eventManager: JhiEventManager,
    private sessionStorage: SessionStorageService,
    private loginService: LoginService,
    private confirmationDialogService: ConfirmationDialogService,
    private $sessionStorage: SessionStorageService
  ) {
    this.itemsPerPage = PaginationConstants.ITEMS_PER_PAGE;
  }

  loadAll() {}

  formatPhoneNumber(phoneNumber) {
    return FormatPhoneNumber(phoneNumber);
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }

  transition() {
    this.router.navigate(['/profile-rx'], {
      queryParams: {
        page: this.page,
        size: this.itemsPerPage,
        sort: `${this.predicate},${this.reverse ? 'asc' : 'desc'}`
      }
    });
    this.loadAll();
  }

  clear() {
    this.page = 0;
    this.router.navigate([
      '/profile-rx',
      {
        page: this.page,
        sort: `${this.predicate},${this.reverse ? 'asc' : 'desc'}`
      }
    ]);
    this.loadAll();
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.profileRxComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.accountService.identity().then((account) => {
      this.currentAccount = account;
      this.loadProfile();
    });
  }

  loadProfile() {
    if (this.currentAccount.enterpriseRxPatientId) {
      this.getProfileDetails(this.currentAccount.enterpriseRxPatientId);
      if (this.currentAccount.peopleSoftId) {
        this.getDependentList(this.currentAccount.peopleSoftId);
      }
    } else {
      this.profile = [];
      this.onError('No profile data found');
      this.showTryAgain = false;
    }
  }

  isMinor(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age < 18) {
      return true;
    }
    return false;
  }

  getDependentList(personId: number) {
    this.isPageLoaded = false;
    this.profileService
      .getDependentListWithoutPrimary(personId)
      .pipe(takeUntil(this.profileRxComponent$))
      .subscribe(
        (res) => {
          this.dependentList = res;
          this.isPageLoaded = true;
          console.log('dependentList:-', this.dependentList);
        },
        () => {
          this.isPageLoaded = true;
        }
      );
  }

  getProfileDetails(patientId: number) {
    this.profileService
      .getProfile(patientId)
      .pipe(takeUntil(this.profileRxComponent$))
      .subscribe(
        (res) => {
          this.profile = res;
        },
        (error) => {
          this.onError(error.error.detail ? error.error.detail : 'Oops!! Something went wrong, please try again.');
          this.showTryAgain = true;
        }
      );
  }

  editprofile(profile, type, primaryPatientId) {
    profile.accountType = type;
    if (!profile.enterpriseRxId) {
      this.selectedMinorPatient = profile;
      console.log('Profile Data from PeopleSoft:-', profile);
      this.profileService.getProfile(profile.patientId).subscribe(
        (res) => {
          profile = res;
          console.log('Profile Data from Rx:-', profile);
          this.sessionStorage.store('profile-details', JSON.stringify(profile));
          this.sessionStorage.store('primaryPatientId', JSON.stringify(primaryPatientId));
          this.router.navigate(['/profile-rx', profile.enterpriseRxId, 'edit']);
        },
        (error) => {
          this.errorForMinor = true;
          this.onError(error.error.detail ? error.error.detail : 'Dependent not found in BJC Pharmacy system');
          this.showTryAgain = true;
        }
      );
    } else {
      this.sessionStorage.store('profile-details', JSON.stringify(profile));
      this.sessionStorage.store('primaryPatientId', JSON.stringify(primaryPatientId));
      this.router.navigate(['/profile-rx', profile.enterpriseRxId, 'edit']);
    }
  }

  public registerForMailOrder() {
    this.$sessionStorage.store('minorDependent', JSON.stringify(this.selectedMinorPatient));
    this.router.navigate(['/profile-rx/mailorder']);
  }

  trackId(index: number, item: IProfileRx) {
    return item.id;
  }

  registerChangeInProfiles() {
    this.eventSubscriber = this.eventManager.subscribe('profileListModification', () => this.loadAll());
  }

  sort() {
    const result = [`${this.predicate},${this.reverse ? 'asc' : 'desc'}`];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  deleteAccount() {
    const popupmsg = "Deleting your account is permanent, this action can't be undone. You’ll have to create account again from scratch.";
    this.confirmationDialogService
      .confirm('Delete Account?', popupmsg, 'DELETE', 'CANCEL', 'lg')
      .then((confirmed) => {
        if (confirmed) {
          this.profileService
            .deleteAccount(this.currentAccount.id)
            .pipe(takeUntil(this.profileRxComponent$))
            .subscribe(
              (res) => {
                if (res.status === 200) {
                  this.onSuccess(
                    'Your account was deleted successfully. You can still leverage all the benefits of the app as a guest user. Or, can create an account again for the exclusive features.'
                  );
                  this.showTryAgain = false;
                  setTimeout(() => {
                    this.loginService.logoutRedirect();
                  }, 2000);
                } else {
                  this.showTryAgain = true;
                  this.onError('Oops!! Something went wrong, please try again.');
                }
              },
              (error) => {
                this.showTryAgain = true;
                this.onError(error.error.detail ? error.error.detail : 'Oops!! Something went wrong, please try again.');
              }
            );
        }
      })
      .catch(() => console.warn('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  protected paginateProfiles(data: IProfileRx[], headers: HttpHeaders) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
    this.profiles = data;
  }

  protected onError(errorMessage: string) {
    this.showMessage = true;
    this.error = true;
    this.success = false;
    this.message = errorMessage;
  }

  protected onSuccess(errorMessage: string) {
    this.showMessage = true;
    this.error = false;
    this.success = true;
    this.message = errorMessage;
  }

  tryagain() {
    this.loadProfile();
    this.showMessage = false;
    this.error = false;
    this.success = false;
    this.message = '';
  }

  public ngOnDestroy(): void {
    this.profileRxComponent$.next();
    this.profileRxComponent$.unsubscribe();
  }
}

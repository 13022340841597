import {
  Injectable, isDevMode
} from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot
} from '@angular/router';
import AccountService from 'app/core/auth/account.service';
import LoginService from 'app/account/login/login.service';
import StateStorageService from './state-storage.service';

@Injectable({ providedIn: 'root' })
export default class UserRouteAccessService implements CanActivate {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private accountService: AccountService,
    private stateStorageService: StateStorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    const { authorities } = route.data;
    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.checkLogin(authorities, state.url);
  }

  checkLogin(authorities: string[], url: string): Promise<boolean> {
    return this.accountService.identity().then((account) => {
      if (!authorities || authorities.length === 0) {
        return true;
      }

      if (account) {
        const hasAnyAuthority = this.accountService.hasAnyAuthority(authorities);
        if (hasAnyAuthority) {
          return true;
        }
        if (isDevMode()) {
          console.warn('User has not any of required authorities: ', authorities);
        }
        this.router.navigate(['accessdenied']);
        return false;
      }

      this.stateStorageService.storeUrl(url);
      this.loginService.logoutRedirect();
      return false;
    });
  }
}

import { Route } from '@angular/router';
import PasswordResetComponent from './password-reset.component';

const passwordResetRoute: Route = {
  path: 'reset/password',
  component: PasswordResetComponent,
  data: {
    authorities: [],
    pageTitle: 'global.menu.account.resetPassword'
  }
};

export default passwordResetRoute;

import { Moment } from 'moment';

export interface ICustomerStatementRx {
  id?: number;
  enterpriseRxPatientId?: string;
  idType?: string;
  partnerCode?: string;
  statementDate?: Moment;
  fileDate?: Moment;
  soldDate?: Moment;
  rxNumber?: number;
  storeNumber?: number;
  phoneNumber?: string;
  faxNumber?: string;
  priorAuth?: string;
  refillNumber?: string;
  productName?: string;
  productNdc?: string;
  productPackageUnits?: string;
  dispensedQuality?: string;
  daysSupply?: string;
  presLastName?: string;
  presFirstName?: string;
  mostRecentPrice?: string;
  facilityNabpNum?: string;
  productDespencingUnits?: string;
  isLof?: string;
}

export class CustomerStatementRx implements ICustomerStatementRx {
  constructor(
    public id?: number,
    public enterpriseRxPatientId?: string,
    public idType?: string,
    public partnerCode?: string,
    public statementDate?: Moment,
    public fileDate?: Moment,
    public soldDate?: Moment,
    public rxNumber?: number,
    public storeNumber?: number,
    public phoneNumber?: string,
    public faxNumber?: string,
    public priorAuth?: string,
    public refillNumber?: string,
    public productName?: string,
    public productNdc?: string,
    public productPackageUnits?: string,
    public dispensedQuality?: string,
    public daysSupply?: string,
    public presLastName?: string,
    public presFirstName?: string,
    public mostRecentPrice?: string,
    public facilityNabpNum?: string,
    public productDespencingUnits?: string,
    public isLof?: string
  ) {}
}

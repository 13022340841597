import {
  Component, OnInit, AfterViewInit, Renderer2, ElementRef, OnDestroy
} from '@angular/core';
import {
  FormBuilder, Validators, ValidatorFn, FormGroup, ValidationErrors
} from '@angular/forms';
import {
  ActivatedRoute, Router
} from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  passwordErrorCannotContainFirstOrLastName,
  passwordErrorCannotContainUsername,
  passwordErrorMinimum14Chars,
  passwordErrorMustContainLowercase,
  passwordErrorMustContainNumericOrSpecialChar,
  passwordErrorMustContainUppercase,
  passwordErrorNoSpace
} from 'app/app.constants';
import PasswordResetFinishService from './password-reset-finish.service';

@Component({
  selector: 'jhi-password-reset-finish',
  templateUrl: './password-reset-finish.component.html'
})
export default class PasswordResetFinishComponent implements OnInit, OnDestroy, AfterViewInit {
  private passwordResetFinishComponent$: Subject<void> = new Subject<void>();

  doNotMatch: string;

  error;

  keyMissing: boolean;

  success;

  modalRef: NgbModalRef;

  key: string;

  showmessage;

  passwordForm: any;

  errorType: number;

  message;

  submitted = false;

  showLoginBtn = false;

  slideScreen;

  apiCalled = false;

  constructor(
    private passwordResetFinishService: PasswordResetFinishService,
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.passwordResetFinishComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.route.queryParams.pipe(takeUntil(this.passwordResetFinishComponent$)).subscribe((params) => {
      this.key = params?.key;
      if (this.key) {
        this.key = this.key.replace(/ /g, '+');
      }
    });
    this.validateKey();
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('#password') != null) {
      // this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#password'), 'focus', []);
      this.elementRef.nativeElement.querySelector('#password')?.focus.apply(this.elementRef.nativeElement.querySelector('#password'), []);
    }
  }

  validateForm(userDetails): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const password = group.controls?.newPassword;
      const confirmpass = group.controls?.confirmPassword;
      let message: string;

      if (password.value && confirmpass.value) {
        const isMatch = password.value === confirmpass.value;
        if (!isMatch && password.valid) {
          confirmpass.setErrors({ equalValue: 'error' });
          message = 'error';
        } else {
          confirmpass.setErrors(null);
        }
      }

      const passValue = password.value.toLocaleLowerCase();
      if (password.value) {
        if (!password.value.match('^(?=.*?[A-Z])')) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainUppercase });
        } else if (!password.value.match('^(?=.*?[a-z])')) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainLowercase });
        } else if (!(password.value.match('^(?=.*?[0-9])') || password.value.match('^(?=.*?[-$_!%*&])'))) {
          message = 'error';
          password.setErrors({ error: passwordErrorMustContainNumericOrSpecialChar });
        }
        if (password.value.match('^[ ]*$')) {
          message = 'error';
          password.setErrors({ error: passwordErrorNoSpace });
        } else if (password.value.length < 14) {
          message = 'error';
          password.setErrors({ error: passwordErrorMinimum14Chars });
        }
      }
      if (passValue) {
        if (userDetails.userName) {
          if (passValue.match(new RegExp(userDetails.userName.toLocaleLowerCase()))) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainUsername });
          }
        }
        if (userDetails && userDetails.firstName) {
          if (passValue.match(userDetails.firstName.toLocaleLowerCase())) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainFirstOrLastName });
          }
        }

        if (userDetails && userDetails.lastName) {
          if (passValue.match(userDetails.lastName.toLocaleLowerCase())) {
            message = 'error';
            password.setErrors({ error: passwordErrorCannotContainFirstOrLastName });
          }
        }
      }

      if (message) {
        return { equalValue: message };
      }

      return null;
    };
  }

  validateKey() {
    if (this.key) {
      this.apiCalled = true;
      this.passwordResetFinishService
        .validateKey(this.key)
        .pipe(takeUntil(this.passwordResetFinishComponent$))
        .subscribe(
          (res) => {
            this.apiCalled = false;
            if (!res.hasError) {
              this.passwordForm = this.fb.group(
                {
                  newPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
                  confirmPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]]
                },
                { validators: this.validateForm(res) }
              );
            } else {
              this.showmessage = true;
              this.errorType = 1;
              this.error = true;
              this.message = res.message;
            }
          },
          (error) => {
            this.apiCalled = false;
            this.error = true;
            this.message = error.error.detail ? error.error.detail : 'Oops!! Something went wrong.';
          }
        );
    }
  }

  finishReset() {
    this.submitted = true;
    const newPassword = this.passwordForm.get(['newPassword']).value;
    if (!this.passwordForm.invalid) {
      this.apiCalled = true;
      this.passwordResetFinishService
        .save({ forgotPasswordKey: this.key, password: newPassword })
        .pipe(takeUntil(this.passwordResetFinishComponent$))
        .subscribe(
          (res) => {
            this.apiCalled = false;
            if (!res.hasError) {
              this.success = true;
              this.message = res.message;
              this.showmessage = true;
              this.showLoginBtn = true;
            } else {
              this.errorType = 2;
              this.error = true;
              this.message = res.message;
              this.showmessage = true;
            }
          },
          (error) => {
            this.apiCalled = false;
            this.error = true;
            this.message = error.error.detail ? error.error.detail : 'There was a problem changing your password. Please try again.';
          }
        );
    }
  }

  login() {
    this.router.navigate(['/account/login']);
  }

  tryagain() {
    this.showmessage = false;
    this.error = false;
    this.passwordForm = true;
  }

  public ngOnDestroy(): void {
    this.passwordResetFinishComponent$.next();
    this.passwordResetFinishComponent$.unsubscribe();
  }
}

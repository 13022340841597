import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import AccountService from 'app/core/auth/account.service';
import {
  FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors
} from '@angular/forms';
import { SessionStorageService } from 'ngx-webstorage';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import CouponRxService from './coupon-rx.service';
import PrescriptionRxService from '../prescription-rx/prescription-rx.service';

@Component({
  selector: 'jhi-coupon-rx',
  templateUrl: './coupon-rx.component.html',
  styleUrls: ['coupon-rx.scss']
})
export default class CouponRxComponent implements OnInit, OnDestroy {
  private couponRxComponent$: Subject<void> = new Subject<void>();

  manualEntryForm: FormGroup;

  imageEntryForm: FormGroup;

  dependentList: any;

  isImageCoupon: boolean;

  selectedPatient: any;

  isDependents: any;

  isPatientSelected: boolean;

  peopleSoftId: any;

  imageType: any;

  imageUrl: any;

  frontImageDataURL: any;

  backImageDataURL: any;

  frontImageUrl: any;

  backImageUrl: any;

  frontFileName: any;

  backFileName: any;

  selectedFile = null;

  isImageValid: boolean;

  imageLoaded: boolean;

  errorMessage: any;

  isNonBJC: boolean;

  nonBJCData: any;

  isSubmitSuccess: boolean;

  submittedData: any;

  submitted;

  dependentForm;

  images = [
    {
      image: null,
      imageType: 'Image Front',
      fileType: null,
      isLoading: false
    },
    {
      image: null,
      imageType: 'Image Back',
      fileType: null,
      isLoading: false
    }
  ];

  isUnauthorized: boolean;

  nonDependentData: any;

  slideScreen;

  accountInfo;

  imgDrugName;

  isApiCalled = false;

  constructor(
    protected couponService: CouponRxService,
    protected accountService: AccountService,
    private sessionStorage: SessionStorageService,
    protected fb: FormBuilder,
    protected prescriptionService: PrescriptionRxService,
    protected toasterMessage: ToastrService
  ) {
    this.imageLoaded = false;
    this.isImageCoupon = true;
    this.isPatientSelected = true;
    this.imageType = '';
    this.dependentList = [];
    this.manualEntryForm = this.fb.group(
      {
        bin: [null, [Validators.required, Validators.pattern(/^[0-9]{0,15}$/)]],
        pcn: [null, [Validators.pattern(/^[a-zA-Z0-9]{0,15}$/)]],
        isPcn: [null],
        cardHolderId: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{0,15}$/)]],
        groupId: [null, [Validators.pattern(/^[a-zA-Z0-9]{0,15}$/)]],
        isGroupId: [null],
        drugName: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]*$/), Validators.maxLength(20)]]
      },
      { validators: this.validateForm() }
    );

    this.imageEntryForm = this.fb.group({
      imgDrugName: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]*$/), Validators.maxLength(20)]]
    });
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.couponRxComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });

    this.accountService.identity().then((account) => {
      this.accountInfo = account;
      if (account) {
        this.peopleSoftId = this.accountInfo.peopleSoftId;
        this.couponService
          .getNonBJCData(this.accountInfo.enterpriseRxPatientId)
          .pipe(takeUntil(this.couponRxComponent$))
          .subscribe((resp: any) => {
            this.nonBJCData = resp;
            this.selectedPatient = resp?.enterpriseRxId;
          });

        if (parseInt(this.accountInfo.peopleSoftId, 10) === 0) {
          this.isNonBJC = true;
        } else {
          this.getDependentList();
        }
      }
    });

    this.dependentForm = this.fb.group({
      patientName: [null, Validators.required]
    });
  }

  downloadPDF() {}

  validateForm(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const isGroupIdChecked = group.get('isGroupId');
      const isPcnChecked = group.get('isPcn');
      const groupId = group.get('groupId');
      const pcnVal = group.get('pcn');
      let isError;
      if (!isGroupIdChecked.value && !groupId.value) {
        isError = true;
        groupId.setErrors({ required: 'error' });
      } else if (!isGroupIdChecked.value && groupId.value && !groupId.value.match(/^[a-zA-Z0-9]{0,15}$/g)) {
        groupId.setErrors({ pattern: 'error' });
      } else {
        groupId.setErrors(null);
      }

      if (!isPcnChecked.value && !pcnVal.value) {
        isError = true;
        pcnVal.setErrors({ required: 'error' });
      } else if (!isPcnChecked.value && pcnVal.value && !pcnVal.value.match(/^[a-zA-Z0-9]{0,15}$/g)) {
        groupId.setErrors({ pattern: 'error' });
      } else {
        pcnVal.setErrors(null);
      }

      if (isError) {
        return { error: true };
      }
      return null;
    };
  }

  couponToggle(type) {
    this.submitted = false;
    if (type === 'manualEntry') {
      this.isImageCoupon = false;
    } else {
      this.isImageCoupon = true;
    }
  }

  getDependentList() {
    if (this.peopleSoftId) {
      this.prescriptionService
        .getDependentList(this.peopleSoftId)
        .pipe(takeUntil(this.couponRxComponent$))
        .subscribe((resp) => {
          if (resp) {
            this.dependentList = resp;
            if (this.dependentList.length > 0) {
              this.selectedPatient = this.dependentList.find((element) => element.sequence === null).patientId;
              this.isDependents = true;
              if (this.selectedPatient) {
                this.dependentForm.controls?.patientName.setValue(this.selectedPatient);
              }
            } else {
              this.isDependents = false;
            }
          }
        });
    }
  }

  addAnotherCoupon() {
    this.manualEntryForm.reset();
    this.imageEntryForm.reset();
    this.frontImageUrl = '';
    this.backImageUrl = '';
    this.frontImageDataURL = '';
    this.backImageDataURL = '';
    this.submitted = false;
    this.imageType = '';
    this.images = [
      {
        image: null,
        imageType: 'Image Front',
        fileType: null,
        isLoading: false
      },
      {
        image: null,
        imageType: 'Image Back',
        fileType: null,
        isLoading: false
      }
    ];
    this.isSubmitSuccess = false;
    this.submitted = false;
  }

  uploadImage(event) {
    console.warn(event.target.files[0]);
    if (
      event.target.files[0].size < 10000000 &&
      (event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpeg' ||
        event.target.files[0].type === 'image/gif' ||
        event.target.files[0].type === 'application/pdf')
    ) {
      this.imageType === '' ? (this.imageType = 'Image Front') : (this.imageType = 'Image Back');
      if (this.imageType === 'Image Front') {
        this.errorMessage = '';
        this.isImageValid = true;
        [this.selectedFile] = event.target.files;
        if (this.selectedFile) {
          this.renameFile();
        }
      } else if (this.imageType === 'Image Back') {
        this.errorMessage = '';
        this.isImageValid = true;
        [this.selectedFile] = event.target.files;
        if (this.selectedFile) {
          this.renameFile();
        }
      }
    } else {
      this.errorMessage = 'File size exceeds (more than 10MB) or File format not supported (other than png, jpeg, gif or pdf).';
      this.isImageValid = false;
    }
  }

  renameFile() {
    if (this.selectedFile) {
      this.submitUpload();
    }
  }

  getImageType(type) {
    if (type === 'image/png') {
      return 'png';
    }
    if (type === 'image/jpeg') {
      return 'jpg';
    }
    if (type === 'image/gif') {
      return 'gif';
    }
    if (type === 'application/pdf') {
      return 'pdf';
    }
  }

  submitUpload() {
    this.imageLoaded = true;
    if (!this.dependentForm.invalid || this.isNonBJC || !this.isDependents) {
      const imageList = this.images.filter((response) => response.imageType === this.imageType);
      imageList[0].isLoading = true;

      const formData = new FormData();
      formData.append('file', this.selectedFile);
      if (this.isDependents) {
        this.selectedPatient = this.dependentForm.value.patientName ? this.dependentForm.value.patientName : this.selectedPatient;
      }
      this.couponService
        .uploadImage(formData, this.selectedPatient, this.imageType.replace('_'))
        .pipe(takeUntil(this.couponRxComponent$))
        .subscribe(
          (resp: any) => {
            if (resp) {
              if (this.imageType === 'Image Front') {
                this.frontImageUrl = resp?.fileDownloadURI;
                this.frontFileName = resp?.fileName;
              }
              if (this.imageType === 'Image Back') {
                this.backImageUrl = resp?.fileDownloadURI;
                this.backFileName = resp?.fileName;
              }
              this.couponService
                .getImage(resp?.fileDownloadURI)
                .pipe(takeUntil(this.couponRxComponent$))
                .subscribe(
                  (res) => {
                    if (res) {
                      this.imageLoaded = false;
                    }
                    if (resp?.fileType === 'application/pdf') {
                      imageList[0].fileType = 'pdf';
                    } else {
                      imageList[0].fileType = 'img';
                    }
                    this.blobToImg(res);
                  },
                  (error) => {
                    console.log(error);
                  }
                );
            }
          },
          (error) => {
            if (error.error.message === 'error.validation' && error.error.violations) {
              this.errorMessage = error.error.violations[0].message;
            }
            imageList[0].isLoading = false;
            this.imageLoaded = false;
          }
        );
    } else {
      this.errorMessage = 'Please select patient';
      return false;
    }
  }

  downloadPdf(blob, fileName) {
    const linkurl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = linkurl;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  blobToImg(blob: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.imageUrl = reader.result;
        if (this.imageType === 'Image Front') {
          const imageList = this.images.filter((res) => res.imageType === 'Image Front');
          imageList[0].image = reader.result;
          imageList[0].isLoading = false;

          this.frontImageDataURL = blob;
        } else {
          const imageList = this.images.filter((res) => res.imageType === 'Image Back');
          imageList[0].image = reader.result;
          imageList[0].isLoading = false;
          this.backImageDataURL = blob;
        }
      },
      false
    );

    if (blob) {
      reader.readAsDataURL(blob);
    }
  }

  calculateAge() {
    this.frontImageUrl = '';
    this.backImageUrl = '';
    this.frontImageDataURL = '';
    this.backImageDataURL = '';
    this.imageType = '';
    this.images = [
      {
        image: null,
        imageType: 'Image Front',
        fileType: null,
        isLoading: false
      },
      {
        image: null,
        imageType: 'Image Back',
        fileType: null,
        isLoading: false
      }
    ];
    this.isUnauthorized = false;
    this.dependentList.forEach((ele) => {
      let age = 0;
      if (ele.patientId === JSON.parse(this.dependentForm.value.patientName)) {
        age = moment().diff(ele.birthDate, 'month');
        if (age / 12 < 18 || ele.sequence === null) {
          this.isUnauthorized = false;
          this.errorMessage = '';
        } else {
          this.errorMessage = 'You cannot manage dependents who are older than 18 years';
          this.isUnauthorized = true;
        }
      }
    });
  }

  submitCoupon() {
    if (this.imageLoaded === false) {
      let payload:any = {};
      let patient: any;
      this.submitted = true;
      if (this.isNonBJC || !this.isDependents) {
        patient = this.nonBJCData;
      } else if (!this.dependentForm.invalid) {
        this.selectedPatient = this.dependentForm.value.patientName ? this.dependentForm.value.patientName : 0;
        if (this.isDependents) {
          patient = this.dependentList.find((element) => element.patientId === JSON.parse(this.selectedPatient));
        }
      } else {
        this.errorMessage = 'Please select patient';
      }

      if (!this.frontImageUrl) {
        this.errorMessage = 'Please upload front image';
      }
      if (patient) {
        this.isPatientSelected = true;
        if (this.isImageCoupon && this.frontImageUrl && !this.imageEntryForm.invalid) {
          payload = {
            frontImageUrl: this.frontImageUrl,
            backImageUrl: this.backImageUrl ? this.backImageUrl : null,
            isManual: false,
            bin: null,
            isPcn: false,
            pcn: null,
            cardHolderId: null,
            isGroupId: false,
            drugName: this.imageEntryForm.value.imgDrugName,
            firstName: patient.firstName,
            lastName: patient.lastName,
            dateOfBirth: this.isNonBJC || !this.isDependents ? patient.dateOfBirth : patient.birthDate,
            primaryUserPatientId: this.accountInfo.enterpriseRxPatientId,
          };
        } else {
          this.submitted = true;
          if (!this.manualEntryForm.invalid) {
            payload = {
              frontImageUrl: null,
              backImageUrl: null,
              isManual: true,
              firstName: patient.firstName,
              lastName: patient.lastName,
              dateOfBirth: this.isNonBJC || !this.isDependents ? patient.dateOfBirth : patient.birthDate,
              primaryUserPatientId: this.accountInfo.enterpriseRxPatientId
            };
            this.manualEntryForm.value.isPcn = !this.manualEntryForm.value.isPcn;
            this.manualEntryForm.value.isGroupId = !this.manualEntryForm.value.isGroupId;

            payload = { ...payload, ...this.manualEntryForm.value };
          }
        }

        if (payload?.firstName) {
          if (!this.isApiCalled) {
            this.isApiCalled = true;
            this.couponService
              .submitCoupon(payload, this.selectedPatient)
              .pipe(takeUntil(this.couponRxComponent$))
              .subscribe(
                (resp) => {
                  this.isApiCalled = false;
                  if (resp) {
                    this.isSubmitSuccess = true;
                    this.submittedData = resp;
                  }
                },
                () => {
                  this.isApiCalled = false;
                  this.toasterMessage.error('Something went wrong !');
                }
              );
          }
        }
      } else {
        this.isPatientSelected = false;
      }
    }
  }

  deleteImage(deleteImageType) {
    if (deleteImageType === 'Image Back') {
      this.backImageUrl = '';
    }

    if (deleteImageType === 'Image Front') {
      this.frontImageUrl = '';
    }

    this.couponService
      .deleteImage(this.selectedPatient, deleteImageType === 'Image Back' ? this.backFileName : this.frontFileName)
      .pipe(takeUntil(this.couponRxComponent$))
      .subscribe(() => {
        const imageList = this.images.filter((res) => res.imageType === deleteImageType);
        imageList[0].image = null;
        this.imageType = deleteImageType === 'Image Front' ? '' : 'Image Front';

        if (this.images.filter((res) => res.image !== null).length === 0) {
          this.imageType = '';
        }
      });
  }

  validateNumber(e) {
    if (e.key.match(/[0-9]/)) {
      return true;
    }
    return false;
  }

  validateAlphanumeric(e) {
    if (e.key.match(/[0-9a-zA-Z ]/)) {
      return true;
    }
    return false;
  }

  manualValidate() {
    if (this.manualEntryForm.get('isPcn').value) {
      this.manualEntryForm.get('pcn').disable();
      this.manualEntryForm.get('pcn').setValue('');
    } else {
      this.manualEntryForm.get('pcn').enable();
    }

    if (this.manualEntryForm.get('isGroupId').value) {
      this.manualEntryForm.get('groupId').disable();
      this.manualEntryForm.get('groupId').setValue('');
    } else {
      this.manualEntryForm.get('groupId').enable();
    }
  }

  public ngOnDestroy(): void {
    this.couponRxComponent$.next();
    this.couponRxComponent$.unsubscribe();
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { DateValidator, FormatDate, CheckFutureDate } from 'app/shared/util/request-util';
import AccountService from 'app/core/auth/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isIEBrowser, STATE_DATA } from 'app/app.constants';
import ProfileRxService from '../profile-rx.service';

@Component({
  selector: 'jhi-profile-rx-create',
  templateUrl: './profile-rx-create.component.html',
  styleUrls: ['./profile-rx-create.scss']
})
export default class ProfileRxCreateComponent implements OnInit, OnDestroy {
  private profileRxCreateComponent$: Subject<void> = new Subject<void>();

  slideScreen;

  userType;

  submitted = false;

  showmessage = false;

  message;

  dobDp: any;

  attempt = 1;

  showTryAgain;

  showLexusNexusOption;

  showRegisterForMailOrder = false;

  showLoader = true;

  stateList;

  nameValidationPattern = "^[a-zA-Z\\'\\-\\ \\.]+$";

  registerForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.pattern(this.nameValidationPattern)]),
    middleInitial: new FormControl('', [Validators.pattern(this.nameValidationPattern), Validators.maxLength(1)]),
    lastName: new FormControl('', [Validators.required, Validators.pattern(this.nameValidationPattern)]),
    suffix: new FormControl('', [Validators.pattern(this.nameValidationPattern)]),
    dateOfBirth: new FormControl('', [Validators.required, DateValidator(), CheckFutureDate()]),
    last4ssn: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')]),
    // driverLicenseNum: new FormControl('', [Validators.maxLength(19)]),
    // driverLicenseState: new FormControl('', []),
    rxNumber: new FormControl('', []),
    storeId: new FormControl('', [])
  });

  registerWithBJC = new FormGroup({
    bjcNtusername: new FormControl('', [Validators.required]),
    bjcNtpassword: new FormControl('', [Validators.required])
  });

  constructor(
    private profileRxService: ProfileRxService,
    private activatedRoute: ActivatedRoute,
    private $sessionStorage: SessionStorageService,
    private route: Router,
    private accountService: AccountService
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.profileRxCreateComponent$)).subscribe((params) => {
      this.userType = params?.type;
    });
  }

  ngOnInit() {
    this.accountService.slideScreen.pipe(takeUntil(this.profileRxCreateComponent$)).subscribe((data) => {
      this.slideScreen = data;
    });
    this.showmessage = false;
    this.message = '';
    this.$sessionStorage.clear('acdetails');
    this.stateList = STATE_DATA;
  }

  registerWithBJCDetails() {
    this.submitted = true;
    if (!this.registerWithBJC.invalid) {
      this.showLoader = false;
      this.profileRxService
        .validateBJCUser({ username: this.registerWithBJC.value.bjcNtusername, password: this.registerWithBJC.value.bjcNtpassword })
        .pipe(takeUntil(this.profileRxCreateComponent$))
        .subscribe(
          (res) => {
            const userProfile = this.buildProfileCreateDTO(res);
            this.$sessionStorage.store('acdetails', JSON.stringify(userProfile));
            this.showLoader = true;
            if (res.patientId) {
              this.route.navigate(['account/register']);
            } else {
              this.registerForMailOrder();
            }
          },
          (error) => {
            this.handleError(error, false);
          }
        );
    }
  }

  validateGuest() {
    this.submitted = true;

    if (
      (this.registerForm.get('rxNumber').value !== '' && this.registerForm.get('storeId').value === '') ||
      (this.registerForm.get('rxNumber').value === '' && this.registerForm.get('storeId').value !== '')
    ) {
      this.registerForm.get('rxNumber').setErrors({ requiredStoreId: 'Please enter prescription number and store Id' });
    } else {
      this.registerForm.get('rxNumber').setErrors(null);
    }

    if (this.registerForm.invalid) {
      console.log(`guest flow NOT valid ${this.registerForm.invalid}`);
      if (
        this.registerForm.get('dateOfBirth').errors &&
        !this.registerForm.get('dateOfBirth').errors?.required &&
        !this.registerForm.get('dateOfBirth').errors?.invalidDate &&
        !this.registerForm.get('dateOfBirth').errors?.futuredate
      ) {
        this.registerForm.get('dateOfBirth').setErrors(null);
      }
    }
    if (!this.registerForm.invalid) {
      this.showLoader = false;
      let rxNumberPlusFacilityId: string;
      if (this.registerForm.value.rxNumber && this.registerForm.value.storeId) {
        rxNumberPlusFacilityId = `${this.registerForm.value.rxNumber}-${this.registerForm.value.storeId}`;
      }
      this.profileRxService
        .validateUser(
          {
            firstName: this.registerForm.value.firstName,
            lastName: this.registerForm.value.lastName,
            dateOfBirth: FormatDate(this.registerForm.value.dateOfBirth),
            rxNumberWithFacilityId: rxNumberPlusFacilityId
          },
          this.attempt
        )
        .pipe(takeUntil(this.profileRxCreateComponent$))
        .subscribe(
          (res) => {
            this.showLoader = true;
            const userProfile = this.buildProfileCreateDTO(res);
            this.$sessionStorage.store('acdetails', JSON.stringify(userProfile));
            if (res.patientId) {
              this.route.navigate(['account/register']);
            } else {
              this.redirectToLexusNexus();
            }
          },
          (error) => {
            this.handleError(error, true);
          }
        );
    }
  }

  redirectToLexusNexus() {
    this.route.navigate(['profile-rx/security-questions']);
  }

  handleError(error, guest) {
    this.showmessage = true;
    this.showLoader = true;
    this.message = error.error.detail;

    if (guest) {
      const userProfile = {
        patientId: '',
        personId: '',
        publicAuthenticationDTO: {
          firstName: this.registerForm.value.firstName,
          lastName: this.registerForm.value.lastName,
          dateOfBirth: FormatDate(this.registerForm.value.dateOfBirth),
          last4ssn: this.registerForm.value.last4ssn
        }
      };
      this.$sessionStorage.store('acdetails', JSON.stringify(userProfile));
    }

    /* Account not in Enterprise RX */
    if (this.message.indexOf('We are not able to find your account') >= 0) {
      this.showTryAgain = false;
      this.showRegisterForMailOrder = false;
      if (guest) {
        this.redirectToLexusNexus();
      } else {
        this.registerForMailOrder();
      }
    }

    /* Account not found in Peoplesoft - hard stop the flow */
    if (this.message.indexOf('We are not able to validate your BJC account') >= 0) {
      this.showTryAgain = false;
      this.showRegisterForMailOrder = false;
    }

    /* Invalid BJC-NT credentials. Try again */
    if (this.message.indexOf('Invalid BJC-NT Credentials') >= 0) {
      this.showTryAgain = true;
      this.showRegisterForMailOrder = false;
    }

    /* BJCRx Account already exists - redirect to login */
    if (this.message.indexOf('Patient account already exists') >= 0) {
      this.showTryAgain = false;
      this.showRegisterForMailOrder = false;
    }
    // GUEST
    if (guest) {
      /* Prescription not found in Enterprise RX */
      if (this.message.indexOf('Prescription number you entered') >= 0) {
        this.showLexusNexusOption = true;
      }
      if (this.attempt < 3) {
        this.showTryAgain = true;
      } else {
        this.showTryAgain = false;
      }
    }
  }

  buildProfileCreateDTO(userAccountDTO) {
    const profileCreateDTO = {
      patientId: userAccountDTO.patientId,
      personId: userAccountDTO.personId,
      isMinor: false,
      publicAuthenticationDTO: userAccountDTO.publicAuthenticationDTO
    };
    return profileCreateDTO;
  }

  checkIsIEBrowser() {
    return isIEBrowser;
  }

  public registerForMailOrder() {
    this.route.navigate(['/profile-rx/mailorder']);
  }

  protected onSaveSuccess() {}

  protected onSaveError() {}

  tryagain() {
    this.registerWithBJC.reset();
    this.registerForm.reset();
    this.showmessage = false;
    this.submitted = false;
    if (this.message.indexOf('Prescription number you entered') >= 0) {
      this.attempt++;
    }
    this.message = '';
    this.showLexusNexusOption = false;
  }

  public ngOnDestroy(): void {
    this.profileRxCreateComponent$.next();
    this.profileRxCreateComponent$.unsubscribe();
  }
}

import { Route } from '@angular/router';

import HomeComponent from './home.component';

const HOME_ROUTE: Route = {
  path: '',
  component: HomeComponent,
  data: {
    authorities: [],
    pageTitle: 'home.title'
  }
};

export default HOME_ROUTE;
